import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  InputBaseComponentProps,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import * as React from 'react';
import { useState } from 'react';
import {
  answerContractRenewal,
  ContactObject_ContractRenewal,
  contractRenewalStateType,
  selectContractRenewStatus,
  patchContractError,
} from '../features/contractRenewal/contractRenewalSlice';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { channelId, idToken, userId } from '../utils/liff';
import liff from '@line/liff';
import moment from 'moment';
import { MobileDatePicker } from '@mui/x-date-pickers';

type ContractRenewalFormProps = {
  contractRenewal?: any;
};

interface QuestionItem {
  id: string;
  field?: keyof ContactObject_ContractRenewal;
  title?: string;
  shortTitle?: string;
  subTitle?: string;
  text?: string;
  type: 'radio' | 'checkbox' | 'text' | 'number' | 'date' | 'questions' | 'end';
  nextId?: string;
  options?: QuestionItemOption[];
  questions?: QuestionItem[];
  isOptional?: boolean;
  inputProps?: InputBaseComponentProps;
  inputAdornment?: { position: 'start' | 'end'; text: string };
  parent?: QuestionItem;
}
type QuestionItemOption = {
  /** 選択肢のラベル */
  label: string;
  /** textと登録する値が異なる場合に指定 */
  value?: string;
  /** 次の質問のID */
  nextId?: string;
  /** 選択した場合に表示する質問 */
  question?: QuestionItem;
};

const QUESTIONS: QuestionItem[] = [
  {
    id: 'A1',
    field: 'line_contract_renew_preference',
    title: '次回の契約更新に関してご回答ください',
    shortTitle: '次回の契約更新',
    type: 'radio',
    options: [
      { label: '希望する', nextId: 'B1' },
      { label: '希望しない', nextId: 'A2' },
      { label: '未定・検討中', nextId: 'A4' },
    ],
  },
  {
    id: 'A2',
    field: 'line_contaract_renew_problem',
    title: '理由について簡単に教えてください',
    shortTitle: '理由',
    type: 'radio',
    options: [
      { label: '業務内容や就業環境等に不安があるため', nextId: 'A3' },
      { label: '自身の事情により', nextId: 'A3' },
      { label: 'その他の理由のため', nextId: 'A3' },
    ],
  },
  {
    id: 'A3',
    field: 'staffing_want_answer',
    title: '当社より別の就業先の紹介を希望しますか？',
    shortTitle: '別の就業先の紹介',
    type: 'radio',
    options: [
      { label: '紹介を希望する', nextId: 'D1' },
      { label: '紹介を希望しない', nextId: 'A4' },
    ],
  },
  {
    id: 'A4',
    field: 'line_contaract_contact_preference',
    title:
      '今後の手続きについてご連絡いたします。ご連絡の際に営業担当からの一次連絡手段はどちらがよいですか？',
    shortTitle: '一次連絡手段',
    type: 'radio',
    options: [
      {
        label: 'LINEでの連絡を希望',
        value: 'LINEでの連絡希望',
        nextId: 'End1',
      },
      { label: '電話での連絡を希望', value: '電話での連絡希望', nextId: 'C1' },
    ],
  },
  {
    id: 'B1',
    field: 'line_contaract_renew_procedure',
    title: '今後の進め方についてご回答ください',
    shortTitle: '今後の進め方',
    type: 'radio',
    options: [
      {
        label: '特に就業状況に問題はないため、派遣先との確認を進めて欲しい',
        nextId: 'End2',
      },
      { label: '契約更新は希望するが、別途相談事項あり', nextId: 'A4' },
    ],
  },
  {
    id: 'C1',
    field: 'line_line_contaract_contact_time_preference',
    title: '希望の連絡時間帯はいつがよいですか？',
    shortTitle: '希望の連絡時間帯',
    type: 'radio',
    options: [
      { label: '午前', nextId: 'End1' },
      { label: '昼休み', nextId: 'End1' },
      { label: '午後', nextId: 'End1' },
      { label: '夕方', nextId: 'End1' },
    ],
  },
  {
    id: 'D1',
    title: '希望の条件を教えてください',
    shortTitle: '希望の条件',
    nextId: 'D2',
    type: 'questions',
    questions: [
      {
        id: 'D1-1',
        field: 'line_workday',
        shortTitle: '勤務日数',
        subTitle: '勤務日数（複数可）',
        type: 'checkbox',
        options: [
          { label: '週3日以内' },
          { label: '週4日' },
          { label: '週5日' },
        ],
      },
      {
        id: 'D1-2',
        field: 'line_workingtime',
        shortTitle: '勤務時間帯',
        subTitle: '勤務時間帯（複数可）',
        type: 'checkbox',
        options: [
          { label: '日勤のみ' },
          { label: '夜勤も可' },
          { label: '日夜シフト勤務可' },
          { label: '夜勤のみ' },
          { label: '時短勤務' },
          { label: 'フルタイム' },
        ],
      },
      {
        id: 'D1-3',
        field: 'line_preferred_job',
        shortTitle: '職種',
        subTitle: '職種（複数可）',
        type: 'checkbox',
        options: [
          { label: '事務全般' },
          { label: 'コールセンター・営業・接客販売' },
          { label: '工場・製造系' },
          { label: '倉庫・物流・軽作業' },
          { label: 'エンジニア（IT・技術）' },
          { label: 'ドライバー・配送・送迎' },
          {
            label: 'その他',
            question: {
              id: 'D1-3-1',
              field: 'line_jobother',
              type: 'text',
              shortTitle: 'その他',
              inputProps: {
                placeholder: '職種を入力してください',
              },
            },
          },
        ],
      },
      {
        id: 'D1-4',
        field: 'line_salaryhour',
        shortTitle: '時給',
        subTitle: '時給',
        type: 'number',
        inputProps: {
          placeholder: '希望時給',
          minLength: 3,
          maxLength: 4,
        },
        inputAdornment: { position: 'end', text: '円' },
      },
      {
        id: 'D1-5',
        field: 'line_want_date',
        shortTitle: '勤務開始希望日',
        subTitle: '勤務開始希望日',
        type: 'date',
        inputProps: {
          placeholder: '勤務開始希望日を選択',
        },
      },
      {
        id: 'D1-6',
        field: 'line_wayofworkplace',
        shortTitle: '通勤手段',
        subTitle: '通勤手段（複数可）',
        type: 'checkbox',
        options: [
          { label: '自家用車' },
          { label: 'バイク' },
          { label: '自転車' },
          { label: '徒歩圏内' },
          { label: '公共機関（電車・バス）' },
        ],
      },
      {
        id: 'D1-7',
        field: 'staffing_preferred_conditions',
        shortTitle: 'その他希望条件',
        subTitle: 'その他希望条件',
        type: 'text',
        isOptional: true,
        inputProps: {
          placeholder: 'その他の希望条件があれば記入してください',
          minRows: 2,
        },
      },
    ],
  },
  {
    id: 'D2',
    field: 'line_contaract_contact_preference',
    title: 'ご連絡の際に営業担当からの一次連絡手段はどちらがよいですか？',
    shortTitle: '一次連絡手段',
    type: 'radio',
    options: [
      {
        label: 'LINEでの連絡を希望',
        value: 'LINEでの連絡希望',
        nextId: 'End3',
      },
      { label: '電話での連絡を希望', value: '電話での連絡希望', nextId: 'D3' },
    ],
  },
  {
    id: 'D3',
    field: 'staffing_want_answer',
    title:
      `後日、担当支店より詳細のヒアリングのためお電話をいたします。\n` +
      `お電話可能な希望の日時を最大３つまで教えてください。`,
    shortTitle: 'お電話の希望日時',
    nextId: 'End3',
    type: 'questions',
    questions: [
      {
        id: 'D3-1',
        field: 'line_telday1',
        shortTitle: '第1希望',
        subTitle: '第1希望',
        type: 'date',
        isOptional: true,
        inputProps: {
          placeholder: '日付を選択',
        },
      },
      {
        id: 'D3-2',
        field: 'line_telday2',
        shortTitle: '第2希望',
        subTitle: '第2希望',
        type: 'date',
        isOptional: true,
        inputProps: {
          placeholder: '日付を選択',
        },
      },
      {
        id: 'D3-3',
        field: 'line_telday3',
        shortTitle: '第3希望',
        subTitle: '第3希望',
        type: 'date',
        isOptional: true,
        inputProps: {
          placeholder: '日付を選択',
        },
      },
      {
        id: 'D3-4',
        field: 'line_teltime',
        shortTitle: '希望の時間帯',
        subTitle: '希望の時間帯があれば入力ください',
        type: 'text',
        inputProps: {
          placeholder: '希望の時間帯',
          maxLength: 100,
        },
        isOptional: true,
      },
    ],
  },
  {
    id: 'End1',
    type: 'end',
    text:
      `ご回答いただきありがとうございました。\n` +
      `近日中に営業担当より連絡させていただき、ご状況や今後の進め方のご希望等について具体的にお聞かせ願えればと思います。\n` +
      `なお、このアンケートの回答内容によって契約更新が確定されるわけではありません。` +
      `契約更新希望の場合については就業先の企業とも交渉を進めたうえで、あらためて契約更新の有無を営業担当よりご連絡させていただきます。`,
  },
  {
    id: 'End2',
    type: 'end',
    text:
      `ご回答いただきありがとうございました。\n` +
      `別途、確認を進めまして営業担当よりご連絡致します。\n` +
      `引き続きどうぞよろしくお願い致します。`,
  },
  {
    id: 'End3',
    type: 'end',
    text:
      `ご回答いただきありがとうございました。\n` +
      `別途、確認を進めまして営業担当よりご連絡致します。\n` +
      `引き続きどうぞよろしくお願い致します。\n\n` +
      `なお、マイランスタッドより希望の条件や職務経歴などの更新が可能となっておりますのでお時間ある際に更新をお願いいたします。\n` +
      `マイランスタッド\n` +
      `https://www.randstad.co.jp/OCLSTF0000.do`,
  },
];

const ContractTest: React.FC<ContractRenewalFormProps> = ({
  contractRenewal,
}) => {
  const [formState, setFormState] =
    useState<ContactObject_ContractRenewal>(contractRenewal);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const contractRenewStatus = useAppSelector(selectContractRenewStatus);
  const patchError = useAppSelector(patchContractError);
  const [shownQuestions, setShownQuestions] = useState<QuestionItem[]>([
    QUESTIONS[0],
  ]);
  const dispatch = useAppDispatch();

  /**
   * 質問の回答変更時
   * @param question
   * @param value
   * @param checked
   */
  const handleChange = (
    question: QuestionItem,
    value: any,
    checked?: boolean,
  ) => {
    const option = question.options?.find(
      (opt) => opt.value === value || opt.label === value,
    );
    const _formState: any = {};

    // 複数選択可能な値は;で連結する
    if (question.type === 'checkbox') {
      let values =
        (question.field && formState[question.field]?.split(';')) || [];
      if (checked) {
        values?.push(value);
      } else {
        values = values?.filter((v) => v !== value);
      }
      value = values.join(';');
    }

    if (question.field) {
      // 表示している質問の回答をformStateに格納する
      for (const [key, value] of Object.entries(formState)) {
        let allQuestions = shownQuestions.slice();
        for (const q of shownQuestions) {
          if (q.questions) {
            allQuestions = allQuestions.concat(q.questions);
          }
        }
        if (allQuestions.some((q) => q.field === key)) {
          _formState[key] = value;
        }
      }
      if (value) {
        _formState[question.field] = value;
      } else {
        delete _formState[question.field];
      }
    }
    setFormState(_formState);

    const nextId = option?.nextId || question.nextId;
    if (nextId) {
      handleNext(
        nextId,
        shownQuestions.findIndex((q) => q.id === question.id) + 1,
      );
    }
    // 複数質問で任意以外が入力されていれば次の質問を表示
    else if (question.parent?.questions && question.parent?.nextId) {
      const required =
        question.parent.questions?.filter((q) => q.field && !q.isOptional) ||
        [];
      if (
        required.length <= 0 ||
        required.every((q) => Object.keys(_formState).includes(q.field || ''))
      ) {
        handleNext(
          question.parent.nextId,
          shownQuestions.findIndex((q) => q.id === question.parent?.id) + 1,
        );
      } else {
        // 全て入力して次の質問が表示された後に全て必須の条件を満たさなくなった場合は以降の質問を削除
        handleNext(
          question.parent.id,
          shownQuestions.findIndex((q) => q.id === question.parent?.id),
        );
      }
    }
  };

  /**
   * 次の質問を表示する
   * @param nextId 次の質問ID
   * @param index 表示する位置（途中の質問の場合はそれ以降の質問を消して次の質問を表示）
   */
  const handleNext = (nextId: string, index?: number) => {
    const nextQuestion = QUESTIONS.find((q) => q.id === nextId);
    // 次の質問が表示されてなければ表示する
    if (nextQuestion) {
      setShownQuestions(shownQuestions.slice(0, index).concat(nextQuestion));
      setIsCompleted(nextQuestion?.type === 'end');
    }
  };

  /**
   * 回答を送信する
   * @param values
   * @returns
   */
  const handleSubmit = (values: ContactObject_ContractRenewal) => {
    // ２重送信防止
    if (contractRenewStatus === 'submitting') {
      return;
    }
    setIsConfirmOpen(false);

    // 回答送信
    dispatch(
      answerContractRenewal({
        idToken: idToken() || '',
        channelId: channelId(),
        objectId: contractRenewal.hs_object_id,
        values,
      }),
    );

    // LINEメッセージ送信
    const text = ['【LINE契約更新：回答完了】'];
    const addAnswer = (question: QuestionItem) => {
      if (question.shortTitle) {
        const value: any = question.field && values[question.field];
        if (value || question.questions) {
          const _value =
            value instanceof Date ? moment(value).format('yyyy/M/D') : value;
          text.push(`${question.shortTitle}${_value ? `：${_value}` : ''}`);
        }
      }
    };
    for (const question of shownQuestions) {
      addAnswer(question);
      for (const subQuestion of question.questions || []) {
        addAnswer(subQuestion);
      }
    }
    liff.sendMessages([{ type: 'text', text: text.join(`\n`) }]);
  };

  return (
    <Box pb={8}>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#2175d9',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            契約更新確認
          </Typography>
        </Toolbar>
      </AppBar>
      {contractRenewal ? (
        <Stack
          m={2}
          padding={2}
          spacing={4}
          border={'1px solid rgba(215,215,215,1)'}
        >
          <Stack spacing={4}>
            <Box fontSize={'0.9rem'}>
              いつもご就業ありがとうございます。次回契約更新に関するご確認にてご連絡です。
              <br />
              今後の意向を早めに確認し、その意向に合わせてフォローや契約交渉をしていく目的でまずはLINEで意思のご確認をしております。
              <br />
              契約更新のご希望有無、営業担当との個別相談希望等を、アンケート形式でご回答ください。
            </Box>
            <Stack fontSize={'0.9rem'}>
              <InfoItem
                label={'スタッフコード'}
                value={contractRenewal.staffcobe}
              />
              <InfoItem
                label={'姓名'}
                value={`${contractRenewal.lastname || ''} ${
                  contractRenewal.firstname || ''
                }`}
              />
              <InfoItem
                label={'契約開始日'}
                value={
                  contractRenewal.f21_line_
                    ? moment(contractRenewal.f21_line_).format('YYYY年M月D日')
                    : ''
                }
              />
              <InfoItem
                label={'契約終了日'}
                value={
                  contractRenewal.f21_line_end
                    ? moment(contractRenewal.f21_line_end).format(
                        'YYYY年M月D日',
                      )
                    : ''
                }
              />
              <InfoItem
                label={'就業先企業名'}
                value={contractRenewal.line_ewclient_1}
              />
              <InfoItem
                label={'就業先本支店名'}
                value={contractRenewal.line_f21_}
              />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Divider />
            <Stack
              direction={'row'}
              alignItems={'center'}
              color={'#0f1941'}
              fontSize={'1em'}
              spacing={'4px'}
            >
              <NoteAltOutlinedIcon style={{ fontSize: '1.4em' }} />
              <span>契約更新に関するアンケート</span>
            </Stack>
          </Stack>

          {/* 質問 */}
          <Stack spacing={4}>
            {shownQuestions.map((question, index) => (
              <div key={question.id}>
                {0 < index && question.type != 'end' && <Divider />}
                <QuestionTitle {...question} />
                <Question
                  key={`question-${index}-${question.id}`}
                  question={question}
                  props={{
                    onChange: handleChange,
                    onNext: handleNext,
                    state: contractRenewStatus,
                    formState,
                  }}
                />
              </div>
            ))}
          </Stack>

          {contractRenewStatus === 'submitting' && (
            <Box py={8} textAlign={'center'}>
              <CircularProgress />
            </Box>
          )}

          {patchError && <ResultError error={patchError} />}

          {isCompleted && contractRenewStatus === 'idle' && (
            <AppBar
              position="fixed"
              color="primary"
              sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
            >
              <Toolbar>
                <Button
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ width: '100%' }}
                  onClick={() => setIsConfirmOpen(true)}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    回答する
                  </span>
                </Button>
              </Toolbar>
            </AppBar>
          )}
        </Stack>
      ) : (
        <Stack
          m={2}
          padding={2}
          spacing={4}
          border={'1px solid rgba(215,215,215,1)'}
        >
          <p>回答可能な契約更新がありません。</p>
          <CloseButton />
        </Stack>
      )}

      <Dialog
        open={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この内容で回答しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)} autoFocus>
            いいえ
          </Button>
          <Button onClick={() => handleSubmit(formState)}>はい</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContractTest;

/**
 * 表示情報の項目
 */
const InfoItem: React.VFC<{ label: string; value?: string }> = (param) => {
  return (
    <div>
      <span>{param.label}：</span>
      <span>{param.value}</span>
    </div>
  );
};

const QuestionTitle: React.VFC<QuestionItem> = (question) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Stack>
      {question.type != 'end' && (
        <Box>
          {question.title && (
            <Box py={2} ref={scrollRef}>
              <FormLabel id={question.id}>
                <Typography color="#0f1941" fontWeight="bold">
                  Q. {question.title}
                </Typography>
              </FormLabel>
            </Box>
          )}
          {question.subTitle && (
            <Box pb={1}>
              <FormLabel id={question.id}>
                <Typography color="#0f1941">● {question.subTitle}</Typography>
              </FormLabel>
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};

type QuestionParam = {
  question: QuestionItem;
  props: {
    onChange: (question: QuestionItem, value: any, checked?: boolean) => void;
    onNext?: (nextId: string) => void;
    state: contractRenewalStateType;
    formState: ContactObject_ContractRenewal;
  };
};
const Question: React.VFC<QuestionParam> = React.memo<QuestionParam>(
  ({ question, props }) => {
    React.useEffect(() => {
      // 複数の質問がある
      if (question.questions) {
        // 全ての質問が任意であれば次の質問を表示する
        if (
          question.nextId &&
          props.onNext &&
          question.questions.every(
            (q) => q.isOptional === true || q.isOptional != undefined,
          )
        ) {
          props.onNext(question.nextId);
        }
      }
    }, []);

    // ラジオボタン
    if (question.type === 'radio') {
      return (
        <FormControl>
          <RadioGroup
            aria-labelledby={question.field}
            name={question.field}
            value={question.field && props.formState[question.field]}
            onChange={(event) => props.onChange(question, event.target.value)}
          >
            {question.options?.map((option, index) => (
              <FormControlLabel
                key={`${question.field}_option_${index}`}
                value={option.value || option.label}
                control={<Radio />}
                disabled={props.state !== 'idle'}
                label={
                  <span style={{ fontSize: '0.9rem' }}>{option.label}</span>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }
    // チェックボックス
    else if (question.type === 'checkbox') {
      const isChecked = (
        field: keyof ContactObject_ContractRenewal,
        value?: string,
      ) => {
        return props.formState[field]
          ?.split(';')
          .some((v: string) => v === value);
      };
      return (
        <FormGroup>
          {question.options?.map((option, index) => (
            <Stack>
              <FormControlLabel
                key={`${question.field}_option_${index}`}
                control={
                  <Checkbox
                    checked={
                      question.field &&
                      isChecked(question.field, option.label || option.value)
                    }
                    disabled={props.state !== 'idle'}
                    value={option.value || option.label || ''}
                    onChange={(event) =>
                      props.onChange(
                        question,
                        event.target.value,
                        event.target.checked,
                      )
                    }
                  />
                }
                label={
                  <span style={{ fontSize: '0.9rem' }}>
                    {option.label || option.value}
                  </span>
                }
              />
              {
                // チェックしたときに表示するテキスト
                option.question &&
                  question.field &&
                  isChecked(question.field, option.label || option.value) && (
                    <QuestionTestField
                      question={option.question}
                      value={
                        option.question.field &&
                        props.formState[option.question.field]
                      }
                      onChange={(value) =>
                        option.question &&
                        props.onChange(option.question, value)
                      }
                      status={props.state}
                    />
                  )
              }
            </Stack>
          ))}
        </FormGroup>
      );
    }
    // テキスト
    else if (question.type === 'text' || question.type === 'number') {
      return (
        <FormControl fullWidth>
          <TextField
            id={question.field}
            key={question.field}
            type={question.type}
            variant="standard"
            value={question.field && props.formState[question.field]}
            disabled={props.state !== 'idle'}
            placeholder={question.inputProps?.placeholder}
            multiline={
              !!question.inputProps?.minRows || !!question.inputProps?.maxRows
            }
            minRows={question.inputProps?.minRows}
            maxRows={question.inputProps?.maxRows}
            inputProps={question.inputProps}
            InputProps={{
              startAdornment: question.inputAdornment?.position === 'start' && (
                <InputAdornment position="start">
                  {question.inputAdornment.text}
                </InputAdornment>
              ),
              endAdornment: question.inputAdornment?.position === 'end' && (
                <InputAdornment position="end">
                  {question.inputAdornment.text}
                </InputAdornment>
              ),
            }}
            onChange={(event) => props.onChange(question, event.target.value)}
          />
        </FormControl>
      );
    }
    // 複数項目
    else if (question.type === 'questions') {
      return (
        <Stack spacing={3}>
          {question.questions?.map((q) => (
            <Stack>
              <QuestionTitle {...q} />
              <Question
                key={`question_${question.id}_${q.id}`}
                question={{ ...q, parent: question }}
                props={props}
              />
            </Stack>
          ))}
        </Stack>
      );
    }
    // 日付
    else if (question.type === 'date') {
      const [openDatePicker, setOpenDatePicker] = useState(false);
      return (
        <FormControl>
          <MobileDatePicker
            key={question.field}
            inputFormat="yyyy/M/d"
            toolbarFormat="yyyy/M/d"
            value={question.field && props.formState[question.field]}
            disabled={props.state !== 'idle'}
            minDate={new Date()}
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            onChange={(date) => props.onChange(question, date)}
            renderInput={() => (
              <TextField
                id={question.field}
                key={`${question.field}-input`}
                variant="standard"
                disabled={props.state !== 'idle'}
                placeholder={question.inputProps?.placeholder}
                onClick={() => setOpenDatePicker(true)}
                value={
                  question.field &&
                  props.formState[question.field] &&
                  moment(props.formState[question.field]).format('yyyy/M/D')
                }
              />
            )}
          />
        </FormControl>
      );
    }
    //
    else if (question.type === 'end') {
      return props.state === 'finished' ? (
        <QuestionEnd question={question} />
      ) : (
        <></>
      );
    }
    return <></>;
  },
);

const QuestionEnd: React.VFC<{ question: QuestionItem }> = ({ question }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Stack
      key={`question-end_${question.id}`}
      pt={4}
      spacing={4}
      ref={scrollRef}
    >
      <Box fontSize={'14px'}>
        {question.text?.split(`\n`).map((text) => (
          <p>{text}</p>
        ))}
      </Box>
      <CloseButton />
    </Stack>
  );
};

const QuestionTestField: React.VFC<{
  question: QuestionItem;
  value: any;
  onChange: (value: string) => void;
  status?: contractRenewalStateType;
}> = ({ question, value, onChange, status }) => {
  return (
    <FormControl>
      <TextField
        id={question.field}
        key={question.field}
        type={question.type}
        variant="standard"
        value={value}
        disabled={status !== 'idle'}
        placeholder={question.inputProps?.placeholder}
        multiline={
          !!question.inputProps?.minRows || !!question.inputProps?.maxRows
        }
        minRows={question.inputProps?.minRows}
        maxRows={question.inputProps?.maxRows}
        inputProps={question.inputProps}
        InputProps={{
          startAdornment: question.inputAdornment?.position === 'start' && (
            <InputAdornment position="start">
              {question.inputAdornment.text}
            </InputAdornment>
          ),
          endAdornment: question.inputAdornment?.position === 'end' && (
            <InputAdornment position="end">
              {question.inputAdornment.text}
            </InputAdornment>
          ),
        }}
        onChange={(event) => onChange(event.target.value)}
      />
    </FormControl>
  );
};

/**
 * 閉じるボタン
 */
const CloseButton: React.VFC = () => {
  return (
    <Button
      color="inherit"
      aria-label="next key"
      sx={{
        marginTop: 1,
        width: '100%',
      }}
      onClick={() => {
        liff.closeWindow();
      }}
    >
      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>閉じる</span>
    </Button>
  );
};

const ResultError: React.VFC<{ error: { message?: string } }> = ({ error }) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Stack pt={4} spacing={4} ref={scrollRef}>
      <p>{`エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${userId()}`}</p>
      <Box fontSize={'14px'}>{error.message}</Box>
      <CloseButton />
    </Stack>
  );
};
