import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import liff from '@line/liff';
import { RootState } from '../../app/store';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const HUB_ID = process.env.REACT_APP_HUB_ID || '';
const OBJECT_TYPE = process.env.REACT_APP_ADVANCE_PAY_OBJECT_TYPE || '';
const PIPELINE = process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE || '';
const PIPELINE_STAGE =
  process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE_STAGE || '';
const PIPELINE_ARCHIVE_STAGE =
  process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE_ARCHIVE || '';

const PIPELINE_CS = process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE_CS || '';
const PIPELINE_STAGE_CS =
  process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE_STAGE_CS || '';
const PIPELINE_ARCHIVE_STAGE_CS =
  process.env.REACT_APP_SOCIAL_INSURANCE_PIPELINE_ARCHIVE_CS || '';

export const insuranceContact = createAsyncThunk(
  'insuranceContact',
  async (params: any) => {
    try {
      let data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '申請関連_to_コンタクト',
        properties: {
          // パイプライン
          hs_pipeline: params.values.staffCodeDigit ? PIPELINE_CS : PIPELINE,
          hs_pipeline_stage: params.values.staffCodeDigit
            ? PIPELINE_STAGE_CS
            : PIPELINE_STAGE,

          // 基本情報
          name: params.values.lastname + ' ' + params.values.firstname,
          lastname: params.values.lastname,
          firstname: params.values.firstname,
          staff_code: params.values.staffCode,
          staff_code_digit: params.values.staffCodeDigit,

          //社会保険
          social_target: params.values.socialTarget,
          basic_pension_number: params.values.basicPensionNumber1
            ? params.values.basicPensionNumber1 +
              params.values.basicPensionNumber2
            : '',
          relatives: params.values.relatives,
          social_insurance_other_company: params.values.socialOtherCompany,
          foreign_nationality: params.values.foreignNationality,

          // 雇用保険
          unemployment_target: params.values.unemploymentTarget,
          know_unemployment_number: params.values.knowUnemploymentNumber,
          unemployment_number: params.values.unemploymentNumber1
            ? params.values.unemploymentNumber1 +
              params.values.unemploymentNumber2 +
              params.values.unemploymentNumber3
            : '',
          get_insurance: params.values.getInsurance,
          company_name: params.values.companyName,
          unemployment_other_company: params.values.unemploymentOtherCompany,

          //資格確認書の発行が必要かどうか、とその理由
          check_myna_cert: params.values.check_myna_cert,
          reason_myna_cert: params.values.reason_myna_cert,
        },
      };

      //被扶養者
      if (
        params.values.socialTarget === 'いいえ' ||
        params.values.relatives === '無し'
      ) {
        data = {
          ...data,
          properties: {
            ...data.properties,
            relatives_lastname1: '',
            relatives_lastname_ruby1: '',
            relatives_firstname1: '',
            relatives_firstname_ruby1: '',
            relatives_birthday1: '',
            relatives_relationship1: '',

            relatives_lastname2: '',
            relatives_lastname_ruby2: '',
            relatives_firstname2: '',
            relatives_firstname_ruby2: '',
            relatives_birthday2: '',
            relatives_relationship2: '',

            relatives_lastname3: '',
            relatives_lastname_ruby3: '',
            relatives_firstname3: '',
            relatives_firstname_ruby3: '',
            relatives_birthday3: '',
            relatives_relationship3: '',

            relatives_lastname4: '',
            relatives_lastname_ruby4: '',
            relatives_firstname4: '',
            relatives_firstname_ruby4: '',
            relatives_birthday4: '',
            relatives_relationship4: '',

            relatives_lastname5: '',
            relatives_lastname_ruby5: '',
            relatives_firstname5: '',
            relatives_firstname_ruby5: '',
            relatives_birthday5: '',
            relatives_relationship5: '',

            relatives_lastname6: '',
            relatives_lastname_ruby6: '',
            relatives_firstname6: '',
            relatives_firstname_ruby6: '',
            relatives_birthday6: '',
            relatives_relationship6: '',

            relatives_lastname7: '',
            relatives_lastname_ruby7: '',
            relatives_firstname7: '',
            relatives_firstname_ruby7: '',
            relatives_birthday7: '',
            relatives_relationship7: '',

            relatives_lastname8: '',
            relatives_lastname_ruby8: '',
            relatives_firstname8: '',
            relatives_firstname_ruby8: '',
            relatives_birthday8: '',
            relatives_relationship8: '',

            relatives_lastname9: '',
            relatives_lastname_ruby9: '',
            relatives_firstname9: '',
            relatives_firstname_ruby9: '',
            relatives_birthday9: '',
            relatives_relationship9: '',

            relatives_lastname10: '',
            relatives_lastname_ruby10: '',
            relatives_firstname10: '',
            relatives_firstname_ruby10: '',
            relatives_birthday10: '',
            relatives_relationship10: '',
          },
        };
      } else {
        if (params.values.relativesLastnameSi1) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname1: params.values.relativesLastnameSi1,
              relatives_lastname_ruby1: params.values.relativesLastnameRubySi1,
              relatives_firstname1: params.values.relativesFirstnameSi1,
              relatives_firstname_ruby1:
                params.values.relativesFirstnameRubySi1,
              relatives_birthday1: params.values.relativesBirthdaySi1,
              relatives_relationship1: params.values.relativesRelationshipSi1,
            },
          };
        }

        if (params.values.relativesLastnameSi2) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname2: params.values.relativesLastnameSi2,
              relatives_lastname_ruby2: params.values.relativesLastnameRubySi2,
              relatives_firstname2: params.values.relativesFirstnameSi2,
              relatives_firstname_ruby2:
                params.values.relativesFirstnameRubySi2,
              relatives_birthday2: params.values.relativesBirthdaySi2,
              relatives_relationship2: params.values.relativesRelationshipSi2,
            },
          };
        }

        if (params.values.relativesLastnameSi3) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname3: params.values.relativesLastnameSi3,
              relatives_lastname_ruby3: params.values.relativesLastnameRubySi3,
              relatives_firstname3: params.values.relativesFirstnameSi3,
              relatives_firstname_ruby3:
                params.values.relativesFirstnameRubySi3,
              relatives_birthday3: params.values.relativesBirthdaySi3,
              relatives_relationship3: params.values.relativesRelationshipSi3,
            },
          };
        }

        if (params.values.relativesLastnameSi4) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname4: params.values.relativesLastnameSi4,
              relatives_lastname_ruby4: params.values.relativesLastnameRubySi4,
              relatives_firstname4: params.values.relativesFirstnameSi4,
              relatives_firstname_ruby4:
                params.values.relativesFirstnameRubySi4,
              relatives_birthday4: params.values.relativesBirthdaySi4,
              relatives_relationship4: params.values.relativesRelationshipSi4,
            },
          };
        }

        if (params.values.relativesLastnameSi5) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname5: params.values.relativesLastnameSi5,
              relatives_lastname_ruby5: params.values.relativesLastnameRubySi5,
              relatives_firstname5: params.values.relativesFirstnameSi5,
              relatives_firstname_ruby5:
                params.values.relativesFirstnameRubySi5,
              relatives_birthday5: params.values.relativesBirthdaySi5,
              relatives_relationship5: params.values.relativesRelationshipSi5,
            },
          };
        }

        if (params.values.relativesLastnameSi6) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname6: params.values.relativesLastnameSi6,
              relatives_lastname_ruby6: params.values.relativesLastnameRubySi6,
              relatives_firstname6: params.values.relativesFirstnameSi6,
              relatives_firstname_ruby6:
                params.values.relativesFirstnameRubySi6,
              relatives_birthday6: params.values.relativesBirthdaySi6,
              relatives_relationship6: params.values.relativesRelationshipSi6,
            },
          };
        }

        if (params.values.relativesLastnameSi7) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname7: params.values.relativesLastnameSi7,
              relatives_lastname_ruby7: params.values.relativesLastnameRubySi7,
              relatives_firstname7: params.values.relativesFirstnameSi7,
              relatives_firstname_ruby7:
                params.values.relativesFirstnameRubySi7,
              relatives_birthday7: params.values.relativesBirthdaySi7,
              relatives_relationship7: params.values.relativesRelationshipSi7,
            },
          };
        }

        if (params.values.relativesLastnameSi8) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname8: params.values.relativesLastnameSi8,
              relatives_lastname_ruby8: params.values.relativesLastnameRubySi8,
              relatives_firstname8: params.values.relativesFirstnameSi8,
              relatives_firstname_ruby8:
                params.values.relativesFirstnameRubySi8,
              relatives_birthday8: params.values.relativesBirthdaySi8,
              relatives_relationship8: params.values.relativesRelationshipSi8,
            },
          };
        }

        if (params.values.relativesLastnameSi9) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname9: params.values.relativesLastnameSi9,
              relatives_lastname_ruby9: params.values.relativesLastnameRubySi9,
              relatives_firstname9: params.values.relativesFirstnameSi9,
              relatives_firstname_ruby9:
                params.values.relativesFirstnameRubySi9,
              relatives_birthday9: params.values.relativesBirthdaySi9,
              relatives_relationship9: params.values.relativesRelationshipSi9,
            },
          };
        }

        if (params.values.relativesLastnameSi10) {
          data = {
            ...data,
            properties: {
              ...data.properties,
              relatives_lastname10: params.values.relativesLastnameSi10,
              relatives_lastname_ruby10:
                params.values.relativesLastnameRubySi10,
              relatives_firstname10: params.values.relativesFirstnameSi10,
              relatives_firstname_ruby10:
                params.values.relativesFirstnameRubySi10,
              relatives_birthday10: params.values.relativesBirthdaySi10,
              relatives_relationship10: params.values.relativesRelationshipSi10,
            },
          };
        }
      }

      let response;
      if (params.objectId) {
        response = await axios.patch(
          `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object`,
          {
            ...data,
            objectId: params.objectId,
          },
          {
            headers: {
              Authorization: `Bearer ${params.idToken}`,
              'X-ClientId': params.channelId,
            },
          },
        );
      } else {
        response = await axios.post(
          `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object`,
          data,
          {
            headers: {
              Authorization: `Bearer ${params.idToken}`,
              'X-ClientId': params.channelId,
            },
          },
        );
      }

      const id = response.data.id;
      console.log(id);

      liff
        .sendMessages([
          {
            type: 'text',
            text: `[自動送信：社会保険・雇用保険加入手続き]
フォームを登録しました。管理番号は${id}です。`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });

      return response.data;
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);

export const loadInsuranceContact = createAsyncThunk(
  'loadInsuranceContact',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object/${OBJECT_TYPE}`,
      {
        headers: {
          Authorization: `Bearer ${params.idToken}`,
          'X-ClientId': params.channelId,
        },
      },
    );
    return response.data;
  },
);

export const retrieveInsurance = createAsyncThunk(
  'retrieveInsurance',
  async (params: any) => {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object/${OBJECT_TYPE}`,
        {
          headers: {
            Authorization: `Bearer ${params.idToken}`,
            'X-ClientId': params.channelId,
          },
        },
      );
      let records = response.data;
      if (records.length > 0) {
        records = records.filter((record: any) => {
          return (
            (record.properties.hs_pipeline_stage !== PIPELINE_ARCHIVE_STAGE &&
              record.properties.hs_pipeline === PIPELINE) ||
            (record.properties.hs_pipeline_stage !==
              PIPELINE_ARCHIVE_STAGE_CS &&
              record.properties.hs_pipeline === PIPELINE_CS)
          );
        });

        records.sort((a: any, b: any) => {
          if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
            return -1;
          }
          if (moment(a.createdAt).isBefore(moment(b.createdAt))) {
            return 1;
          }
          return 0;
        });
      }
      console.log(records);

      return records.length > 0 ? records[0] : null;
    } catch (e) {
      {
        console.error(e);
        return null;
      }
    }
  },
);

export interface InsuranceState {
  insurance: any;
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: InsuranceState = {
  insurance: null,
  status: 'idle',
  loaded: false,
};

export const insuranceSlice = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveInsurance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveInsurance.fulfilled, (state, action) => {
        state.status = 'idle';
        state.insurance = action.payload;
        state.loaded = true;
      });
  },
});

export const selectInsurance = (state: RootState) => state.insurance.insurance;
export const isLoadedInsurance = (state: RootState) => state.insurance.loaded;

export default insuranceSlice.reducer;
