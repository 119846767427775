import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const HUB_ID = process.env.REACT_APP_HUB_ID || '';

const OBJECT_TYPE = process.env.REACT_APP_CONTRACT_RENEWAL_OBJECT_TYPE || '';
const PIPELINE_STAGE_ARCHIVE_ANSWERED =
  process.env.REACT_APP_CONTRACT_RENEWAL_PIPELINE_STAGE_ARCHIVE_ANSWERED || '';
const PIPELINE_STAGE_ARCHIVE_UNANSWERED =
  process.env.REACT_APP_CONTRACT_RENEWAL_PIPELINE_STAGE_ARCHIVE_UNANSWERED ||
  '';

export type contractRenewalStateType =
  | 'idle'
  | 'loading'
  | 'submitting'
  | 'finished'
  | 'failed';

export interface contractRenewalState {
  jobs: any[];
  status: contractRenewalStateType;
  contractRenewal: any;
  loaded: boolean;
  completed: boolean;
  error?: SerializedError;
}

/**
 * 契約更新で更新するコンタクトのオブジェクト
 */
export interface ContactObject_ContractRenewal {
  /** 契約更新 - アンケート自動送付日（LINE） */
  line_contaract_renew_survey_sent_date?: string | null;
  /** 契約更新 - アンケート自動督促日（LINE） */
  line_contaract_renew_survey_remind_date?: string | null;
  /** 契約更新 - アンケート送付元LINEアカウント（LINE） */
  line_contaract_renew_survey_sender_account?: string | null;
  /** 契約更新 - 希望（LINE） */
  line_contract_renew_preference?: string | null;
  /** 契約更新 - 進め方の希望（LINE） */
  line_contaract_renew_procedure?: string | null;
  /** 契約更新 - 相談事項（LINE） */
  line_contaract_renew_problem?: string | null;
  /** 契約更新 - 連絡時間帯の希望（LINE） */
  line_line_contaract_contact_time_preference?: string | null;
  /** 契約更新 - 連絡方法の希望（LINE） */
  line_contaract_contact_preference?: string | null;
  /** お仕事紹介希望（LINE/リプレイス） */
  staffing_want_answer?: string | null;
  /** 勤務日数（LINE/リプレイス） */
  line_workday?: string | null;
  /** 勤務時間帯（LINE/リプレイス） */
  line_workingtime?: string | null;
  /** 希望職種（LINE/リプレイス） */
  line_preferred_job?: string | null;
  /** 希望職種：その他（LINE/リプレイス） */
  line_jobother?: string | null;
  /** 希望時給（LINE/リプレイス） */
  line_salaryhour?: string | null;
  /** 勤務開始希望日（LINE） */
  line_want_date?: string | null;
  /** 通勤手段（LINE/リプレイス） */
  line_wayofworkplace?: string | null;
  /** 希望条件：自由記述式（LINE/リプレイス） */
  staffing_preferred_conditions?: string | null;
  /** 電話希望日①（LINE/リプレイス） */
  line_telday1?: string | null;
  /** 電話希望日②（LINE/リプレイス） */
  line_telday2?: string | null;
  /** 電話希望日③（LINE/リプレイス） */
  line_telday3?: string | null;
  /** 電話希望時間帯：自由記述（LINE/リプレイス） */
  line_teltime?: string | null;
}

interface ContractRenewalParams {
  idToken: string;
  channelId: string;
  objectId: string;
  values: ContactObject_ContractRenewal;
}

const initialState: contractRenewalState = {
  jobs: [],
  status: 'idle',
  contractRenewal: null,
  loaded: false,
  completed: false,
};

export const retrieveContractRenew = createAsyncThunk(
  'retrieveContractRenew',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object/${OBJECT_TYPE}`,
      {
        headers: {
          Authorization: `Bearer ${params.idToken}`,
          'X-ClientId': params.channelId,
        },
      },
    );
    let records = response.data;
    records = records.filter(
      (record: any) =>
        ![
          PIPELINE_STAGE_ARCHIVE_ANSWERED,
          PIPELINE_STAGE_ARCHIVE_UNANSWERED,
        ].includes(record.properties.hs_pipeline_stage),
    );
    return records.length > 0 ? records[0].properties : null;
  },
);

export const answerContractRenewal = createAsyncThunk(
  'answerContractRenewal',
  async (params: ContractRenewalParams) => {
    try {
      // 日付を数値に変換
      const values = Object.entries(params.values).reduce(
        (values, [key, value]) => {
          if (value instanceof Date) {
            value = value.setUTCHours(0, 0, 0, 0);
          }
          values[key] = value || '';
          return values;
        },
        {} as any,
      );
      // 前の回答を上書きするためクリアしてから設定
      const properties: ContactObject_ContractRenewal = {
        line_contract_renew_preference:
          values.line_contract_renew_preference || '',
        line_contaract_renew_procedure:
          values.line_contaract_renew_procedure || '',
        line_contaract_renew_problem: values.line_contaract_renew_problem || '',
        line_line_contaract_contact_time_preference:
          values.line_line_contaract_contact_time_preference || '',
        line_contaract_contact_preference:
          values.line_contaract_contact_preference || '',
        staffing_want_answer: values.staffing_want_answer || '',
        line_workday: values.line_workday || '',
        line_workingtime: values.line_workingtime || '',
        line_preferred_job: values.line_preferred_job || '',
        line_jobother: values.line_jobother || '',
        line_salaryhour: values.line_salaryhour || '',
        line_want_date: values.line_want_date || '',
        line_wayofworkplace: values.line_wayofworkplace || '',
        staffing_preferred_conditions:
          values.staffing_preferred_conditions || '',
        line_telday1: values.line_telday1 || '',
        line_telday2: values.line_telday2 || '',
        line_telday3: values.line_telday3 || '',
        line_teltime: values.line_teltime || '',
      };
      const response = await axios.patch(
        `${API_SERVER_URL}/line/v2/${HUB_ID}/custom-object`,
        {
          objectType: OBJECT_TYPE,
          objectId: params.objectId,
          associationType: 'LINE契約更新_to_contact',
          properties,
        },
        {
          headers: {
            Authorization: `Bearer ${params.idToken}`,
            'X-ClientId': params.channelId,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const contractRenewSlice = createSlice({
  name: 'contractRenew',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveContractRenew.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveContractRenew.fulfilled, (state, action) => {
        state.status = 'idle';
        state.contractRenewal = action.payload;
        state.loaded = true;
      })
      .addCase(retrieveContractRenew.rejected, (state) => {
        state.status = 'failed';
        state.loaded = true;
      });

    builder
      .addCase(answerContractRenewal.pending, (state) => {
        state.error = undefined;
        state.status = 'submitting';
      })
      .addCase(answerContractRenewal.fulfilled, (state) => {
        state.status = 'finished';
        state.completed = true;
      })
      .addCase(answerContractRenewal.rejected, (state, action) => {
        state.error = action.error;
        state.status = 'idle'; // 再送信できるようにidleにする
      });
  },
});

export const selectContractRenew = (state: RootState) =>
  state.contractRenew.contractRenewal;
export const selectContractRenewStatus = (state: RootState) =>
  state.contractRenew.status;
export const isLoaded = (state: RootState) => state.contractRenew.loaded;
export const patchContractError = (state: RootState) =>
  state.contractRenew.error;

export default contractRenewSlice.reducer;
