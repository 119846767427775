import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import liff from '@line/liff';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import EntryForm from './entryForm';
import SocialInsuranceForm from './socialInsuranceForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  isLoaded,
  retrieveCsEntry,
  retrieveEntry,
  selectEntry,
} from '../features/entry/entrySlice';
import { CookiesProvider } from 'react-cookie';
import EntryConfirm from './entryConfirm';
import {
  isLoadedInsurance,
  retrieveInsurance,
  selectInsurance,
} from '../features/socialInsurance/socialInsuranceSlice';
import SocialInsuranceConfirm from './socialInsuranceConfirm';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

class TopFormProps {
  profile: any;
}

const TopForm: React.FC<TopFormProps> = ({ profile }) => {
  const dispatch = useAppDispatch();
  const entry: any = useAppSelector(selectEntry);
  const loaded = useAppSelector(isLoaded);

  let edit = false;
  let branch = 'default';
  let next = 'default';
  let prev = 'default';
  let confirm = false;
  let type = 'default';
  const insurance: any = useAppSelector(selectInsurance);
  const loadedInsurance = useAppSelector(isLoadedInsurance);
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    edit = String(query['edit']) === 'true';
    next =
      String(query['next']) === 'next1' ||
      String(query['next']) === 'next2' ||
      String(query['next']) === 'next3'
        ? String(query['next'])
        : 'default';
    confirm = String(query['confirm']) === 'true';
    prev =
      String(query['prev']) === 'top' ||
      String(query['prev']) === 'prev1' ||
      String(query['prev']) === 'prev2' ||
      String(query['prev']) === 'prev3' ||
      String(query['prev']) === 'prev4' ||
      String(query['prev']) === 'prev5' ||
      String(query['prev']) === 'prev6' ||
      String(query['prev']) === 'completion' ||
      String(query['prev']) === 'edit'
        ? String(query['prev'])
        : 'default';
    branch =
      String(query['branch']) === 'inhouse' || String(query['branch']) === 'cs'
        ? String(query['branch'])
        : 'default';
    type =
      String(query['type']) === 'outsourcing' ||
      String(query['type']) === 'temporary'
        ? String(query['type'])
        : 'default';
  } catch (e) {
    console.error(e);
  }

  console.log(prev);
  console.log(insurance);

  useEffect(() => {
    document.title =
      prev === 'default' && branch === 'cs'
        ? '入職書類'
        : '入職書類・社会保険・雇用保険手続き';

    if (!loaded) {
      if (branch === 'cs') {
        dispatch(
          retrieveCsEntry({
            idToken: idToken(),
            channelId: channelId(),
          }),
        );
      } else {
        dispatch(
          retrieveEntry({
            idToken: idToken(),
            channelId: channelId(),
          }),
        );
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (!loadedInsurance) {
      dispatch(
        retrieveInsurance({
          idToken: idToken(),
          channelId: channelId(),
        }),
      );
    }
  }, [loadedInsurance]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
    liff.closeWindow();
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      selectDocument: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const branchCheck =
    branch && !(branch === 'default') ? `&branch=${branch}` : '';

  return (
    <span>
      {!loaded || !loadedInsurance ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : entry ? (
        prev === 'edit' ? (
          <div>
            <AppBar
              position="static"
              elevation={0}
              style={{
                alignItems: 'center',
                backgroundColor: '#6ba5b4',
              }}
            >
              <Toolbar>
                <Typography variant="h6" component="div">
                  {'提出書類の編集'}
                </Typography>
              </Toolbar>
            </AppBar>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                margin: '1em',
              }}
            >
              <div>
                <FormControl
                  sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                  error={formState.values.selectDocument === ''}
                >
                  <FormLabel
                    id="unemployment-label"
                    style={{
                      fontSize: '.8em',
                      color: '#0f1941',
                      marginBottom: '2em',
                    }}
                  >
                    編集を行いたい書類を選択してください。
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formState.values.selectDocument}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'selectDocument', event.target.value);
                    }}
                    style={{ marginBottom: '2em' }}
                  >
                    <FormControlLabel
                      value="入職手続き"
                      control={<Radio />}
                      label="入職手続き"
                    />
                    <FormControlLabel
                      value="社会保険・雇用保険手続き"
                      control={<Radio />}
                      label="社会保険・雇用保険手続き"
                      style={{ display: insurance ? '' : 'none' }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <Grid
              item
              xs={7}
              style={{
                textAlign: 'center',
              }}
            >
              <AppBar
                position="fixed"
                color="primary"
                sx={{
                  top: 'auto',
                  bottom: -2,
                  backgroundColor: '#1d6ad4',
                }}
              >
                <Grid
                  container
                  style={{
                    height: 55,
                  }}
                >
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      style={{
                        // backgroundColor: activeStep === 0 ? '#d9d9d9' : '#ff3300',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="back key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                        }}
                        onClick={() => {
                          if (
                            formState.values.selectDocument === '入職手続き'
                          ) {
                            window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?edit=true' +
                              branchCheck;
                          } else if (
                            formState.values.selectDocument ===
                            '社会保険・雇用保険手続き'
                          ) {
                            window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?next=next1&edit=true' +
                              branchCheck;
                          } else {
                            handleOpen2();
                          }
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          編集する
                        </span>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      // sx={{ width: '100%' }}
                      xs={6}
                      style={{
                        backgroundColor: '#d9d9d9',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          color: '#1d6ad4',
                        }}
                        /*disabled={applied}*/
                        onClick={() => {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?prev=top' +
                            branchCheck;
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {'戻る'}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                </Grid>
                {/*</Toolbar>*/}
              </AppBar>
            </Grid>

            <Dialog
              open={open2}
              onClose={handleClose2}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  編集を行いたい書類を選択してください。
                  <br />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose2}>閉じる</Button>
              </DialogActions>
            </Dialog>
          </div>
        ) : (edit && next !== 'next1') || prev === 'completion' ? (
          <CookiesProvider>
            <EntryForm
              entry={entry}
              edit={edit}
              branch={branch}
              prev={prev}
              confirm={confirm}
              backFromLiquid={false}
              type={type}
            />
          </CookiesProvider>
        ) : next === 'next1' ? (
          <CookiesProvider>
            <SocialInsuranceForm
              profile={profile}
              insurance={insurance}
              edit={edit}
              branch={branch}
              next={next}
              confirm={confirm}
            />
          </CookiesProvider>
        ) : (
          <div>
            <EntryConfirm entry={entry} branch={branch} prev={prev} />
            {insurance ? (
              <div>
                <Box sx={{ mt: 4 }} />
                <SocialInsuranceConfirm insurance={insurance} prev={prev} />
              </div>
            ) : (
              <Box sx={{ mt: 9 }} />
            )}
          </div>
        )
      ) : insurance ? (
        <CookiesProvider>
          <EntryForm
            entry={entry}
            edit={edit}
            branch={branch}
            prev={'default'}
            confirm={confirm}
            backFromLiquid={false}
            type={type}
          />
        </CookiesProvider>
      ) : next !== 'default' ? (
        <CookiesProvider>
          <SocialInsuranceForm
            profile={profile}
            insurance={insurance}
            edit={edit}
            branch={branch}
            next={next}
            confirm={confirm}
          />
        </CookiesProvider>
      ) : confirm ? (
        <CookiesProvider>
          <EntryForm
            entry={entry}
            edit={edit}
            branch={branch}
            prev={prev}
            confirm={confirm}
            backFromLiquid={false}
            type={type}
          />
          <SocialInsuranceForm
            profile={profile}
            insurance={insurance}
            edit={edit}
            branch={branch}
            next={next}
            confirm={confirm}
          />
        </CookiesProvider>
      ) : (
        <CookiesProvider>
          <EntryForm
            entry={entry}
            edit={edit}
            branch={branch}
            prev={prev}
            confirm={confirm}
            backFromLiquid={false}
            type={type}
          />
        </CookiesProvider>
      )}
      {!next ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              スマートフォン版LINEでご利用になれます。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ''
      )}
    </span>
  );
};

export default TopForm;
