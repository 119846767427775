import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';

import liff from '@line/liff';
import TextField from '@mui/material/TextField';
import answerSafetyConfirmation from '../features/safetyConfirmation/safetyConfirmationSlice';

class SafetyConfirmationFormProps {
  profile: any;
}

const SafetyConfirmationForm: React.FC<SafetyConfirmationFormProps> = ({
  profile,
}) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstname: profile.firstname,
      lastname: profile.lastname,
      staffCode: profile.staffCode,
      status: '',
      familyStatus: '',
      currentPlace: '',
      workAvailable: '',
      homeStatus: '',
      misc: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    dispatch(
      answerSafetyConfirmation({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
      }),
    );

    const text = `[安否確認]
名前: ${formState.values.lastname} ${formState.values.firstname}
スタッフコード: ${formState.values.staffCode}
ご自身の状況: ${formState.values.status}
ご家族の状況: ${formState.values.familyStatus}
現在いらっしゃる場所: ${formState.values.currentPlace}
出社の可否: ${formState.values.workAvailable}
ご自宅の状況: ${formState.values.homeStatus}
その他:
${formState.values.misc}`;

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component="form" noValidate autoComplete="off">
        <div>
          <TextField
            id="standard-basic"
            label="姓"
            variant="standard"
            value={formState.values.lastname}
            onChange={(event) => {
              handleChange(event, 'lastname', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            placeholder="山田"
          />
          <TextField
            id="standard-basic"
            label="名"
            variant="standard"
            value={formState.values.firstname}
            onChange={(event) => {
              handleChange(event, 'firstname', event.target.value);
            }}
            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
            placeholder="太郎"
          />
        </div>
        <div>
          <TextField
            id="staff-code"
            variant="standard"
            label="スタッフコード"
            type="tel"
            placeholder="00000000"
            value={formState.values.staffCode}
            inputProps={{ maxLength: '8' }}
            onChange={(event) => {
              handleChange(event, 'staffCode', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Divider />
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <div>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                Q1. ご自身の状況
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.status}
                onChange={(event) => {
                  handleChange(event, 'status', event.target.value);
                }}
              >
                <FormControlLabel
                  value="安全"
                  control={<Radio />}
                  label="安全"
                />
                <FormControlLabel
                  value="軽傷"
                  control={<Radio />}
                  label="軽傷"
                />
                <FormControlLabel
                  value="重傷"
                  control={<Radio />}
                  label="重傷"
                />
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <div>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                Q2. ご家族の状況
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.familyStatus}
                onChange={(event) => {
                  handleChange(event, 'familyStatus', event.target.value);
                }}
              >
                <FormControlLabel
                  value="無事"
                  control={<Radio />}
                  label="無事"
                />
                <FormControlLabel
                  value="軽傷"
                  control={<Radio />}
                  label="軽傷"
                />
                <FormControlLabel
                  value="重傷"
                  control={<Radio />}
                  label="重傷"
                />
                <FormControlLabel
                  value="単身で生活している"
                  control={<Radio />}
                  label="単身で生活している"
                />
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <div>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                Q3. 現在いらっしゃる場所
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.currentPlace}
                onChange={(event) => {
                  handleChange(event, 'currentPlace', event.target.value);
                }}
              >
                <FormControlLabel
                  value="ご自宅"
                  control={<Radio />}
                  label="ご自宅"
                />
                <FormControlLabel
                  value="就業先"
                  control={<Radio />}
                  label="就業先"
                />
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <div>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                Q4. 出社の可否
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.workAvailable}
                onChange={(event) => {
                  handleChange(event, 'workAvailable', event.target.value);
                }}
              >
                <FormControlLabel
                  value="出社済"
                  control={<Radio />}
                  label="出社済"
                />
                <FormControlLabel
                  value="明日にでも可能"
                  control={<Radio />}
                  label="明日にでも可能"
                />
                <FormControlLabel
                  value="未定"
                  control={<Radio />}
                  label="未定"
                />
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <div>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                Q5. ご自宅の状況
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.homeStatus}
                onChange={(event) => {
                  console.log(event.target.value);
                  handleChange(event, 'homeStatus', event.target.value);
                }}
              >
                <FormControlLabel
                  value="無事"
                  control={<Radio />}
                  label="無事"
                />
                <FormControlLabel
                  value="全壊"
                  control={<Radio />}
                  label="全壊"
                />
                <FormControlLabel
                  value="半壊"
                  control={<Radio />}
                  label="半壊"
                />
                <FormControlLabel
                  value="不明"
                  control={<Radio />}
                  label="不明"
                />
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              Q6.
              就業先からの情報等、何か当社にお伝えしておくことがあればご入力ください（任意）。
            </FormLabel>
          </FormControl>
          <div>
            <TextField
              multiline
              rows={4}
              id="misc"
              value={formState.values.misc}
              onChange={(event) => {
                handleChange(event, 'misc', event.target.value);
              }}
              sx={{ mt: 0, ml: 4, mr: 2, mb: 2, width: '80%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div style={{ height: '88px' }} />
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClickOpen}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              回答する
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'完了'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ご回答ありがとうございました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SafetyConfirmationForm;
