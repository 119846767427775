import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Accordion,
  AccordionDetails,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import { useCookies } from 'react-cookie';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import liff from '@line/liff';
import TextField from '@mui/material/TextField';
import { addressSearch, entryContact } from '../features/entry/entrySlice';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import axios from 'axios';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { insuranceContact } from '../features/socialInsurance/socialInsuranceSlice';

// @formatter:off
const steps = ['1', '2', '3', '4', '5', '6', '7'];

class EntryFormProps {
  entry: any;
  edit: boolean | undefined;
  branch: string | undefined;
  prev: string | undefined;
  confirm: boolean | undefined;
  backFromLiquid: boolean | undefined;
  type: string | undefined;
}

const EntryForm: React.FC<EntryFormProps> = ({
  entry,
  edit,
  branch,
  prev,
  confirm,
  backFromLiquid,
  type,
}) => {
  interface formName {
    //「社会保険・雇用保険手続き」「入職手続き」で利用するフォームの名前は全てここに追加すること。
    // マル扶 扶養者情報(扶養親族1)
    relativesLastname1: string;
    relativesLastnameRuby1: string;
    relativesFirstname1: string;
    relativesFirstnameRuby1: string;
    relativesBirthday1: string;
    relativesGender1: string;
    relativesRelationship1: string;
    relativesSalary1: string;
    relativesPension1: string;
    relativesOtherIncome1: string;
    relativesLiving1: string;
    relativesPostCode1: string;
    relativesPrefecture1: string;
    relativesMunicipalities1: string;
    relativesTownArea1: string;
    relativesOtherAddress1: string;
    relativesHandicapped1: string;
    relativesHandicappedDetail1: string;
    relativesHandicappedDegree1: string;
    relativesHandicappedOther1: string;
    relativesDeliveryDate1: string;
    relativesBereavement1: string;

    // マル扶 扶養者情報(扶養親族2)
    relativesLastname2: string;
    relativesLastnameRuby2: string;
    relativesFirstname2: string;
    relativesFirstnameRuby2: string;
    relativesBirthday2: string;
    relativesGender2: string;
    relativesRelationship2: string;
    relativesSalary2: string;
    relativesPension2: string;
    relativesOtherIncome2: string;
    relativesLiving2: string;
    relativesPostCode2: string;
    relativesPrefecture2: string;
    relativesMunicipalities2: string;
    relativesTownArea2: string;
    relativesOtherAddress2: string;
    relativesHandicapped2: string;
    relativesHandicappedDetail2: string;
    relativesHandicappedDegree2: string;
    relativesHandicappedOther2: string;
    relativesDeliveryDate2: string;
    relativesBereavement2: string;

    // マル扶 扶養者情報(扶養親族3)
    relativesLastname3: string;
    relativesLastnameRuby3: string;
    relativesFirstname3: string;
    relativesFirstnameRuby3: string;
    relativesBirthday3: string;
    relativesGender3: string;
    relativesRelationship3: string;
    relativesSalary3: string;
    relativesPension3: string;
    relativesOtherIncome3: string;
    relativesLiving3: string;
    relativesPostCode3: string;
    relativesPrefecture3: string;
    relativesMunicipalities3: string;
    relativesTownArea3: string;
    relativesOtherAddress3: string;
    relativesHandicapped3: string;
    relativesHandicappedDetail3: string;
    relativesHandicappedDegree3: string;
    relativesHandicappedOther3: string;
    relativesDeliveryDate3: string;
    relativesBereavement3: string;

    // マル扶 扶養者情報(扶養親族4)
    relativesLastname4: string;
    relativesLastnameRuby4: string;
    relativesFirstname4: string;
    relativesFirstnameRuby4: string;
    relativesBirthday4: string;
    relativesGender4: string;
    relativesRelationship4: string;
    relativesSalary4: string;
    relativesPension4: string;
    relativesOtherIncome4: string;
    relativesLiving4: string;
    relativesPostCode4: string;
    relativesPrefecture4: string;
    relativesMunicipalities4: string;
    relativesTownArea4: string;
    relativesOtherAddress4: string;
    relativesHandicapped4: string;
    relativesHandicappedDetail4: string;
    relativesHandicappedDegree4: string;
    relativesHandicappedOther4: string;
    relativesDeliveryDate4: string;
    relativesBereavement4: string;

    // マル扶 扶養者情報(扶養親族5)
    relativesLastname5: string;
    relativesLastnameRuby5: string;
    relativesFirstname5: string;
    relativesFirstnameRuby5: string;
    relativesBirthday5: string;
    relativesGender5: string;
    relativesRelationship5: string;
    relativesSalary5: string;
    relativesPension5: string;
    relativesOtherIncome5: string;
    relativesLiving5: string;
    relativesPostCode5: string;
    relativesPrefecture5: string;
    relativesMunicipalities5: string;
    relativesTownArea5: string;
    relativesOtherAddress5: string;
    relativesHandicapped5: string;
    relativesHandicappedDetail5: string;
    relativesHandicappedDegree5: string;
    relativesHandicappedOther5: string;
    relativesDeliveryDate5: string;
    relativesBereavement5: string;

    // マル扶 扶養者情報(扶養親族6)
    relativesLastname6: string;
    relativesLastnameRuby6: string;
    relativesFirstname6: string;
    relativesFirstnameRuby6: string;
    relativesBirthday6: string;
    relativesGender6: string;
    relativesRelationship6: string;
    relativesSalary6: string;
    relativesPension6: string;
    relativesOtherIncome6: string;
    relativesLiving6: string;
    relativesPostCode6: string;
    relativesPrefecture6: string;
    relativesMunicipalities6: string;
    relativesTownArea6: string;
    relativesOtherAddress6: string;
    relativesHandicapped6: string;
    relativesHandicappedDetail6: string;
    relativesHandicappedDegree6: string;
    relativesHandicappedOther6: string;
    relativesDeliveryDate6: string;
    relativesBereavement6: string;

    // マル扶 扶養者情報(扶養親族7)
    relativesLastname7: string;
    relativesLastnameRuby7: string;
    relativesFirstname7: string;
    relativesFirstnameRuby7: string;
    relativesBirthday7: string;
    relativesGender7: string;
    relativesRelationship7: string;
    relativesSalary7: string;
    relativesPension7: string;
    relativesOtherIncome7: string;
    relativesLiving7: string;
    relativesPostCode7: string;
    relativesPrefecture7: string;
    relativesMunicipalities7: string;
    relativesTownArea7: string;
    relativesOtherAddress7: string;
    relativesHandicapped7: string;
    relativesHandicappedDetail7: string;
    relativesHandicappedDegree7: string;
    relativesHandicappedOther7: string;
    relativesDeliveryDate7: string;
    relativesBereavement7: string;

    // マル扶 扶養者情報(扶養親族8)
    relativesLastname8: string;
    relativesLastnameRuby8: string;
    relativesFirstname8: string;
    relativesFirstnameRuby8: string;
    relativesBirthday8: string;
    relativesGender8: string;
    relativesRelationship8: string;
    relativesSalary8: string;
    relativesPension8: string;
    relativesOtherIncome8: string;
    relativesLiving8: string;
    relativesPostCode8: string;
    relativesPrefecture8: string;
    relativesMunicipalities8: string;
    relativesTownArea8: string;
    relativesOtherAddress8: string;
    relativesHandicapped8: string;
    relativesHandicappedDetail8: string;
    relativesHandicappedDegree8: string;
    relativesHandicappedOther8: string;
    relativesDeliveryDate8: string;
    relativesBereavement8: string;

    // マル扶 扶養者情報(扶養親族9)
    relativesLastname9: string;
    relativesLastnameRuby9: string;
    relativesFirstname9: string;
    relativesFirstnameRuby9: string;
    relativesBirthday9: string;
    relativesGender9: string;
    relativesRelationship9: string;
    relativesSalary9: string;
    relativesPension9: string;
    relativesOtherIncome9: string;
    relativesLiving9: string;
    relativesPostCode9: string;
    relativesPrefecture9: string;
    relativesMunicipalities9: string;
    relativesTownArea9: string;
    relativesOtherAddress9: string;
    relativesHandicapped9: string;
    relativesHandicappedDetail9: string;
    relativesHandicappedDegree9: string;
    relativesHandicappedOther9: string;
    relativesDeliveryDate9: string;
    relativesBereavement9: string;

    // マル扶 扶養者情報(扶養親族10)
    relativesLastname10: string;
    relativesLastnameRuby10: string;
    relativesFirstname10: string;
    relativesFirstnameRuby10: string;
    relativesBirthday10: string;
    relativesGender10: string;
    relativesRelationship10: string;
    relativesSalary10: string;
    relativesPension10: string;
    relativesOtherIncome10: string;
    relativesLiving10: string;
    relativesPostCode10: string;
    relativesPrefecture10: string;
    relativesMunicipalities10: string;
    relativesTownArea10: string;
    relativesOtherAddress10: string;
    relativesHandicapped10: string;
    relativesHandicappedDetail10: string;
    relativesHandicappedDegree10: string;
    relativesHandicappedOther10: string;
    relativesDeliveryDate10: string;
    relativesBereavement10: string;
    //資格確認書の発行が必要かどうか
    check_myna_cert: string;
    //資格確認書が必要な理由
    reason_myna_cert: string;
  }
  const dispatch = useAppDispatch();
  const [inputStart, setInputStart] = useState(edit ? true : false);
  const [cookies, setCookie] = useCookies();

  let defaultCount = cookies.count === undefined ? 0 : Number(cookies.count);
  if (edit) {
    if (entry.properties.relatives_lastname10) {
      defaultCount = 10;
    } else if (entry.properties.relatives_lastname9) {
      defaultCount = 9;
    } else if (entry.properties.relatives_lastname8) {
      defaultCount = 8;
    } else if (entry.properties.relatives_lastname7) {
      defaultCount = 7;
    } else if (entry.properties.relatives_lastname6) {
      defaultCount = 6;
    } else if (entry.properties.relatives_lastname5) {
      defaultCount = 5;
    } else if (entry.properties.relatives_lastname4) {
      defaultCount = 4;
    } else if (entry.properties.relatives_lastname3) {
      defaultCount = 3;
    } else if (entry.properties.relatives_lastname2) {
      defaultCount = 2;
    } else if (entry.properties.relatives_lastname1) {
      defaultCount = 1;
    }
  }
  const [count, setCount] = React.useState(defaultCount);

  const [numbers, setNumbers] = React.useState(
    !cookies.number
      ? count !== 0
        ? [...Array(count).keys()].map((i) => ++i)
        : [1]
      : [...cookies.number],
  );
  const [errMsg, setErrMsg] = React.useState('');
  //「社会保険・雇用保険手続き」「入職手続き」で利用するフォームの名前はformState.valuesに追加すること。
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      // 誓約書
      writtenOath: edit
        ? entry.properties.written_oath
        : cookies.writtenOath
        ? cookies.writtenOath
        : '',
      emergencyLastname: edit
        ? entry.properties.emergency_lastname
        : cookies.emergencyLastname
        ? cookies.emergencyLastname
        : '',
      emergencyFirstname: edit
        ? entry.properties.emergency_firstname
        : cookies.emergencyFirstname
        ? cookies.emergencyFirstname
        : '',
      emergencyRelationship: edit
        ? entry.properties.emergency_relationship
        : cookies.emergencyRelationship
        ? cookies.emergencyRelationship
        : '',
      emergencyTelNumber: edit
        ? entry.properties.emergency_tel_number
        : cookies.emergencyTelNumber
        ? cookies.emergencyTelNumber
        : '',

      // 共通項目
      lastname: edit
        ? entry.properties.lastname
        : cookies.lastname
        ? cookies.lastname
        : '',
      lastnameRuby: edit
        ? entry.properties.lastname_ruby
        : cookies.lastnameRuby
        ? cookies.lastnameRuby
        : '',
      firstname: edit
        ? entry.properties.firstname
        : cookies.firstname
        ? cookies.firstname
        : '',
      firstnameRuby: edit
        ? entry.properties.firstname_ruby
        : cookies.firstnameRuby
        ? cookies.firstnameRuby
        : '',
      birthday: edit
        ? entry.properties.birthday
        : cookies.birthday
        ? cookies.birthday
        : '',
      staffCodeDigit:
        branch === 'cs'
          ? edit
            ? entry.properties.staff_code.length === 6
              ? '6桁'
              : entry.properties.staff_code.length === 8
              ? '8桁'
              : ''
            : cookies.staffCodeDigit
            ? cookies.staffCodeDigit
            : ''
          : '',
      staffCode: edit
        ? entry.properties.staff_code
        : cookies.staffCode
        ? cookies.staffCode
        : '',
      gender: edit
        ? entry.properties.gender
        : cookies.gender
        ? cookies.gender
        : '',
      startDate: edit
        ? entry.properties.start_date
        : cookies.startDate
        ? cookies.startDate
        : '',
      branch: edit
        ? entry.properties.branch
        : cookies.branch
        ? cookies.branch
        : '',
      postCode1: edit
        ? entry.properties.post_code1
        : cookies.postCode1
        ? cookies.postCode1
        : '',
      prefecture1: edit
        ? entry.properties.prefecture1
        : cookies.prefecture1
        ? cookies.prefecture1
        : '',
      municipalities1: edit
        ? entry.properties.municipalities1
        : cookies.municipalities1
        ? cookies.municipalities1
        : '',
      townArea1: edit
        ? entry.properties.town_area1
        : cookies.townArea1
        ? cookies.townArea1
        : '',
      otherAddress1: edit
        ? entry.properties.other_address1
        : cookies.otherAddress1
        ? cookies.otherAddress1
        : '',
      registrationAddress: edit
        ? entry.properties.registration_address || '現住所と同じ'
        : cookies.registrationAddress
        ? cookies.registrationAddress
        : '現住所と同じ',
      postCode2: edit
        ? entry.properties.post_code2
        : cookies.postCode2
        ? cookies.postCode2
        : '',
      prefecture2: edit
        ? entry.properties.prefecture2
        : cookies.prefecture2
        ? cookies.prefecture2
        : '',
      municipalities2: edit
        ? entry.properties.municipalities2
        : cookies.municipalities2
        ? cookies.municipalities2
        : '',
      townArea2: edit
        ? entry.properties.town_area2
        : cookies.townArea2
        ? cookies.townArea2
        : '',
      otherAddress2: edit
        ? entry.properties.other_address2
        : cookies.otherAddress2
        ? cookies.otherAddress2
        : '',

      // マル扶
      lastname3: edit
        ? entry.properties.lastname3
        : cookies.lastname3
        ? cookies.lastname3
        : '',
      firstname3: edit
        ? entry.properties.firstname3
        : cookies.firstname3
        ? cookies.firstname3
        : '',
      relationship3: edit
        ? entry.properties.relationship3
        : cookies.relationship3
        ? cookies.relationship3
        : '',
      handicapped: edit
        ? entry.properties.handicapped || '該当なし'
        : cookies.handicapped
        ? cookies.handicapped
        : '該当なし', //初期値は該当なし
      handicappedDetail: edit
        ? entry.properties.handicapped_detail
        : cookies.handicappedDetail
        ? cookies.handicappedDetail
        : '',
      handicappedDegree: edit
        ? entry.properties.handicapped_degree
        : cookies.handicappedDegree
        ? cookies.handicappedDegree
        : '',
      handicappedOther: edit
        ? entry.properties.handicappedother
        : cookies.handicappedOther
        ? cookies.handicappedOther
        : '',
      deliveryDate: edit
        ? entry.properties.delivery_date
        : cookies.deliveryDate
        ? cookies.deliveryDate
        : '',
      workingStudent: edit
        ? entry.properties.working_student || '該当なし'
        : cookies.workingStudent
        ? cookies.workingStudent
        : '該当なし', //初期値は該当なし
      schoolLabel: edit
        ? entry.properties.school_label
        : cookies.schoolLabel
        ? cookies.schoolLabel
        : '',
      schoolName: edit
        ? entry.properties.school_name
        : cookies.schoolName
        ? cookies.schoolName
        : '',
      admissionDate: edit
        ? entry.properties.admission_date
        : cookies.admissionDate
        ? cookies.admissionDate
        : '',
      household: edit
        ? entry.properties.household || '申告者と同じ'
        : cookies.household
        ? cookies.household
        : '申告者と同じ',
      marriage: edit
        ? entry.properties.marriage || 'いいえ'
        : cookies.marriage
        ? cookies.marriage
        : 'いいえ', //初期値は'いいえ'
      dependentDeclaration: edit
        ? entry.properties.dependent_declaration
        : cookies.dependentDeclaration
        ? cookies.dependentDeclaration
        : '',
      situationSelection: edit
        ? entry.properties.situation_selection
        : cookies.situationSelection
        ? cookies.situationSelection
        : '',

      // マル扶 扶養者情報(パートナー)
      partnerRelationship: edit
        ? entry.properties.partner_relationship
        : cookies.partnerRelationship
        ? cookies.partnerRelationship
        : '',
      partnerLastname: edit
        ? entry.properties.partner_lastname
        : cookies.partnerLastname
        ? cookies.partnerLastname
        : '',
      partnerLastnameRuby: edit
        ? entry.properties.partner_lastname_ruby
        : cookies.partnerLastnameRuby
        ? cookies.partnerLastnameRuby
        : '',
      partnerFirstname: edit
        ? entry.properties.partner_firstname
        : cookies.partnerFirstname
        ? cookies.partnerFirstname
        : '',
      partnerFirstnameRuby: edit
        ? entry.properties.partner_firstname_ruby
        : cookies.partnerFirstnameRuby
        ? cookies.partnerFirstnameRuby
        : '',
      partnerBirthday: edit
        ? entry.properties.partner_birthday
        : cookies.partnerBirthday
        ? cookies.partnerBirthday
        : '',
      partnerGender: edit
        ? entry.properties.partner_gender
        : cookies.partnerGender
        ? cookies.partnerGender
        : '',
      partnerSalary: edit
        ? entry.properties.partner_salary || '0'
        : cookies.partnerSalary
        ? cookies.partnerSalary
        : '0',
      partnerPension: edit
        ? entry.properties.partner_pension || '0'
        : cookies.partnerPension
        ? cookies.partnerPension
        : '0',
      partnerOtherIncome: edit
        ? entry.properties.partner_other_income || '0'
        : cookies.partnerOtherIncome
        ? cookies.partnerOtherIncome
        : '0',
      partnerLiving: edit
        ? entry.properties.partner_living || '同居'
        : cookies.partnerLiving
        ? cookies.partnerLiving
        : '同居',
      partnerPostCode: edit
        ? entry.properties.partner_post_code
        : cookies.partnerPostCode
        ? cookies.partnerPostCode
        : '',
      partnerPrefecture: edit
        ? entry.properties.partner_prefecture
        : cookies.partnerPrefecture
        ? cookies.partnerPrefecture
        : '',
      partnerMunicipalities: edit
        ? entry.properties.partner_municipalities
        : cookies.partnerMunicipalities
        ? cookies.partnerMunicipalities
        : '',
      partnerTownArea: edit
        ? entry.properties.partner_town_area
        : cookies.partnerTownArea
        ? cookies.partnerTownArea
        : '',
      partnerOtherAddress: edit
        ? entry.properties.partner_other_address
        : cookies.partnerOtherAddress
        ? cookies.partnerOtherAddress
        : '',
      partnerHandicapped: edit
        ? entry.properties.partner_handicapped || '該当なし'
        : cookies.partnerHandicapped
        ? cookies.partnerHandicapped
        : '該当なし', //初期値は該当なし
      partnerHandicappedDetail: edit
        ? entry.properties.partner_handicapped_detail
        : cookies.partnerHandicappedDetail
        ? cookies.partnerHandicappedDetail
        : '',
      partnerHandicappedDegree: edit
        ? entry.properties.partner_handicapped_degree
        : cookies.partnerHandicappedDegree
        ? cookies.partnerHandicappedDegree
        : '',
      partnerHandicappedOther: edit
        ? entry.properties.partnerhandicappedother
        : cookies.partnerHandicappedOther
        ? cookies.partnerHandicappedOther
        : '',
      partnerDeliveryDate: edit
        ? entry.properties.partner_delivery_date
        : cookies.partnerDeliveryDate
        ? cookies.partnerDeliveryDate
        : '',
      partnerBereavement: edit
        ? entry.properties.partner_bereavement || '該当しない'
        : cookies.partnerBereavement
        ? cookies.partnerBereavement
        : '該当しない',

      // マル扶 扶養者情報(扶養親族1)
      relativesLastname1: edit
        ? entry.properties.relatives_lastname1
        : cookies.relativesLastname1
        ? cookies.relativesLastname1
        : '',
      relativesLastnameRuby1: edit
        ? entry.properties.relatives_lastname_ruby1
        : cookies.relativesLastnameRuby1
        ? cookies.relativesLastnameRuby1
        : '',
      relativesFirstname1: edit
        ? entry.properties.relatives_firstname1
        : cookies.relativesFirstname1
        ? cookies.relativesFirstname1
        : '',
      relativesFirstnameRuby1: edit
        ? entry.properties.relatives_firstname_ruby1
        : cookies.relativesFirstnameRuby1
        ? cookies.relativesFirstnameRuby1
        : '',
      relativesBirthday1: edit
        ? entry.properties.relatives_birthday1
        : cookies.relativesBirthday1
        ? cookies.relativesBirthday1
        : '',
      relativesGender1: edit
        ? entry.properties.relatives_gender1
        : cookies.relativesGender1
        ? cookies.relativesGender1
        : '',
      relativesRelationship1: edit
        ? entry.properties.relatives_relationship1
        : cookies.relativesRelationship1
        ? cookies.relativesRelationship1
        : '',
      relativesSalary1: edit
        ? entry.properties.relatives_salary1 || '0'
        : cookies.relativesSalary1
        ? cookies.relativesSalary1
        : '0',
      relativesPension1: edit
        ? entry.properties.relatives_pension1 || '0'
        : cookies.relativesPension1
        ? cookies.relativesPension1
        : '0',
      relativesOtherIncome1: edit
        ? entry.properties.relatives_other_income1 || '0'
        : cookies.relativesOtherIncome1
        ? cookies.relativesOtherIncome1
        : '0',
      relativesLiving1: edit
        ? entry.properties.relatives_living1 || '同居'
        : cookies.relativesLiving1
        ? cookies.relativesLiving1
        : '同居',
      relativesPostCode1: edit
        ? entry.properties.relatives_post_code1
        : cookies.relativesPostCode1
        ? cookies.relativesPostCode1
        : '',
      relativesPrefecture1: edit
        ? entry.properties.relatives_prefecture1
        : cookies.relativesPrefecture1
        ? cookies.relativesPrefecture1
        : '',
      relativesMunicipalities1: edit
        ? entry.properties.relatives_municipalities1
        : cookies.relativesMunicipalities1
        ? cookies.relativesMunicipalities1
        : '',
      relativesTownArea1: edit
        ? entry.properties.relatives_town_area1
        : cookies.relativesTownArea1
        ? cookies.relativesTownArea1
        : '',
      relativesOtherAddress1: edit
        ? entry.properties.relatives_other_address1
        : cookies.relativesOtherAddress1
        ? cookies.relativesOtherAddress1
        : '',
      relativesHandicapped1: edit
        ? entry.properties.relatives_handicapped1 || '該当なし'
        : cookies.relativesHandicapped1
        ? cookies.relativesHandicapped1
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail1: edit
        ? entry.properties.relatives_handicapped_detail1
        : cookies.relativesHandicappedDetail1
        ? cookies.relativesHandicappedDetail1
        : '',
      relativesHandicappedDegree1: edit
        ? entry.properties.relatives_handicapped_degree1
        : cookies.relativesHandicappedDegree1
        ? cookies.relativesHandicappedDegree1
        : '',
      relativesHandicappedOther1: edit
        ? entry.properties.relativeshandicappedother1
        : cookies.relativesHandicappedOther1
        ? cookies.relativesHandicappedOther1
        : '',
      relativesDeliveryDate1: edit
        ? entry.properties.relatives_delivery_date1
        : cookies.relativesDeliveryDate1
        ? cookies.relativesDeliveryDate1
        : '',
      relativesBereavement1: edit
        ? entry.properties.registration_bereavement1 || '該当しない'
        : cookies.relativesBereavement1
        ? cookies.relativesBereavement1
        : '該当しない',

      // マル扶 扶養者情報(扶養親族2)
      relativesLastname2: edit
        ? entry.properties.relatives_lastname2
        : cookies.relativesLastname2
        ? cookies.relativesLastname2
        : '',
      relativesLastnameRuby2: edit
        ? entry.properties.relatives_lastname_ruby2
        : cookies.relativesLastnameRuby2
        ? cookies.relativesLastnameRuby2
        : '',
      relativesFirstname2: edit
        ? entry.properties.relatives_firstname2
        : cookies.relativesFirstname2
        ? cookies.relativesFirstname2
        : '',
      relativesFirstnameRuby2: edit
        ? entry.properties.relatives_firstname_ruby2
        : cookies.relativesFirstnameRuby2
        ? cookies.relativesFirstnameRuby2
        : '',
      relativesBirthday2: edit
        ? entry.properties.relatives_birthday2
        : cookies.relativesBirthday2
        ? cookies.relativesBirthday2
        : '',
      relativesGender2: edit
        ? entry.properties.relatives_gender2
        : cookies.relativesGender2
        ? cookies.relativesGender2
        : '',
      relativesRelationship2: edit
        ? entry.properties.relatives_relationship2
        : cookies.relativesRelationship2
        ? cookies.relativesRelationship2
        : '',
      relativesSalary2: edit
        ? entry.properties.relatives_salary2 || '0'
        : cookies.relativesSalary2
        ? cookies.relativesSalary2
        : '0',
      relativesPension2: edit
        ? entry.properties.relatives_pension2 || '0'
        : cookies.relativesPension2
        ? cookies.relativesPension2
        : '0',
      relativesOtherIncome2: edit
        ? entry.properties.relatives_other_income2 || '0'
        : cookies.relativesOtherIncome2
        ? cookies.relativesOtherIncome2
        : '0',
      relativesLiving2: edit
        ? entry.properties.relatives_living2 || '同居'
        : cookies.relativesLiving2
        ? cookies.relativesLiving2
        : '同居',
      relativesPostCode2: edit
        ? entry.properties.relatives_post_code2
        : cookies.relativesPostCode2
        ? cookies.relativesPostCode2
        : '',
      relativesPrefecture2: edit
        ? entry.properties.relatives_prefecture2
        : cookies.relativesPrefecture2
        ? cookies.relativesPrefecture2
        : '',
      relativesMunicipalities2: edit
        ? entry.properties.relatives_municipalities2
        : cookies.relativesMunicipalities2
        ? cookies.relativesMunicipalities2
        : '',
      relativesTownArea2: edit
        ? entry.properties.relatives_town_area2
        : cookies.relativesTownArea2
        ? cookies.relativesTownArea2
        : '',
      relativesOtherAddress2: edit
        ? entry.properties.relatives_other_address2
        : cookies.relativesOtherAddress2
        ? cookies.relativesOtherAddress2
        : '',
      relativesHandicapped2: edit
        ? entry.properties.relatives_handicapped2 || '該当なし'
        : cookies.relativesHandicapped2
        ? cookies.relativesHandicapped2
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail2: edit
        ? entry.properties.relatives_handicapped_detail2
        : cookies.relativesHandicappedDetail2
        ? cookies.relativesHandicappedDetail2
        : '',
      relativesHandicappedDegree2: edit
        ? entry.properties.relatives_handicapped_degree2
        : cookies.relativesHandicappedDegree2
        ? cookies.relativesHandicappedDegree2
        : '',
      relativesHandicappedOther2: edit
        ? entry.properties.relativeshandicappedother2
        : cookies.relativesHandicappedOther2
        ? cookies.relativesHandicappedOther2
        : '',
      relativesDeliveryDate2: edit
        ? entry.properties.relatives_delivery_date2
        : cookies.relativesDeliveryDate2
        ? cookies.relativesDeliveryDate2
        : '',
      relativesBereavement2: edit
        ? entry.properties.registration_bereavement2 || '該当しない'
        : cookies.relativesBereavement2
        ? cookies.relativesBereavement2
        : '該当しない',

      // マル扶 扶養者情報(扶養親族3)
      relativesLastname3: edit
        ? entry.properties.relatives_lastname3
        : cookies.relativesLastname3
        ? cookies.relativesLastname3
        : '',
      relativesLastnameRuby3: edit
        ? entry.properties.relatives_lastname_ruby3
        : cookies.relativesLastnameRuby3
        ? cookies.relativesLastnameRuby3
        : '',
      relativesFirstname3: edit
        ? entry.properties.relatives_firstname3
        : cookies.relativesFirstname3
        ? cookies.relativesFirstname3
        : '',
      relativesFirstnameRuby3: edit
        ? entry.properties.relatives_firstname_ruby3
        : cookies.relativesFirstnameRuby3
        ? cookies.relativesFirstnameRuby3
        : '',
      relativesBirthday3: edit
        ? entry.properties.relatives_birthday3
        : cookies.relativesBirthday3
        ? cookies.relativesBirthday3
        : '',
      relativesGender3: edit
        ? entry.properties.relatives_gender3
        : cookies.relativesGender3
        ? cookies.relativesGender3
        : '',
      relativesRelationship3: edit
        ? entry.properties.relatives_relationship3
        : cookies.relativesRelationship3
        ? cookies.relativesRelationship3
        : '',
      relativesSalary3: edit
        ? entry.properties.relatives_salary3 || '0'
        : cookies.relativesSalary3
        ? cookies.relativesSalary3
        : '0',
      relativesPension3: edit
        ? entry.properties.relatives_pension3 || '0'
        : cookies.relativesPension3
        ? cookies.relativesPension3
        : '0',
      relativesOtherIncome3: edit
        ? entry.properties.relatives_other_income3 || '0'
        : cookies.relativesOtherIncome3
        ? cookies.relativesOtherIncome3
        : '0',
      relativesLiving3: edit
        ? entry.properties.relatives_living3 || '同居'
        : cookies.relativesLiving3
        ? cookies.relativesLiving3
        : '同居',
      relativesPostCode3: edit
        ? entry.properties.relatives_post_code3
        : cookies.relativesPostCode3
        ? cookies.relativesPostCode3
        : '',
      relativesPrefecture3: edit
        ? entry.properties.relatives_prefecture3
        : cookies.relativesPrefecture3
        ? cookies.relativesPrefecture3
        : '',
      relativesMunicipalities3: edit
        ? entry.properties.relatives_municipalities3
        : cookies.relativesMunicipalities3
        ? cookies.relativesMunicipalities3
        : '',
      relativesTownArea3: edit
        ? entry.properties.relatives_town_area3
        : cookies.relativesTownArea3
        ? cookies.relativesTownArea3
        : '',
      relativesOtherAddress3: edit
        ? entry.properties.relatives_other_address3
        : cookies.relativesOtherAddress3
        ? cookies.relativesOtherAddress3
        : '',
      relativesHandicapped3: edit
        ? entry.properties.relatives_handicapped3 || '該当なし'
        : cookies.relativesHandicapped3
        ? cookies.relativesHandicapped3
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail3: edit
        ? entry.properties.relatives_handicapped_detail3
        : cookies.relativesHandicappedDetail3
        ? cookies.relativesHandicappedDetail3
        : '',
      relativesHandicappedDegree3: edit
        ? entry.properties.relatives_handicapped_degree3
        : cookies.relativesHandicappedDegree3
        ? cookies.relativesHandicappedDegree3
        : '',
      relativesHandicappedOther3: edit
        ? entry.properties.relativeshandicappedother3
        : cookies.relativesHandicappedOther3
        ? cookies.relativesHandicappedOther3
        : '',
      relativesDeliveryDate3: edit
        ? entry.properties.relatives_delivery_date3
        : cookies.relativesDeliveryDate3
        ? cookies.relativesDeliveryDate3
        : '',
      relativesBereavement3: edit
        ? entry.properties.registration_bereavement3 || '該当しない'
        : cookies.relativesBereavement3
        ? cookies.relativesBereavement3
        : '該当しない',

      // マル扶 扶養者情報(扶養親族4)
      relativesLastname4: edit
        ? entry.properties.relatives_lastname4
        : cookies.relativesLastname4
        ? cookies.relativesLastname4
        : '',
      relativesLastnameRuby4: edit
        ? entry.properties.relatives_lastname_ruby4
        : cookies.relativesLastnameRuby4
        ? cookies.relativesLastnameRuby4
        : '',
      relativesFirstname4: edit
        ? entry.properties.relatives_firstname4
        : cookies.relativesFirstname4
        ? cookies.relativesFirstname4
        : '',
      relativesFirstnameRuby4: edit
        ? entry.properties.relatives_firstname_ruby4
        : cookies.relativesFirstnameRuby4
        ? cookies.relativesFirstnameRuby4
        : '',
      relativesBirthday4: edit
        ? entry.properties.relatives_birthday4
        : cookies.relativesBirthday4
        ? cookies.relativesBirthday4
        : '',
      relativesGender4: edit
        ? entry.properties.relatives_gender4
        : cookies.relativesGender4
        ? cookies.relativesGender4
        : '',
      relativesRelationship4: edit
        ? entry.properties.relatives_relationship4
        : cookies.relativesRelationship4
        ? cookies.relativesRelationship4
        : '',
      relativesSalary4: edit
        ? entry.properties.relatives_salary4 || '0'
        : cookies.relativesSalary4
        ? cookies.relativesSalary4
        : '0',
      relativesPension4: edit
        ? entry.properties.relatives_pension4 || '0'
        : cookies.relativesPension4
        ? cookies.relativesPension4
        : '0',
      relativesOtherIncome4: edit
        ? entry.properties.relatives_other_income4 || '0'
        : cookies.relativesOtherIncome4
        ? cookies.relativesOtherIncome4
        : '0',
      relativesLiving4: edit
        ? entry.properties.relatives_living4 || '同居'
        : cookies.relativesLiving4
        ? cookies.relativesLiving4
        : '同居',
      relativesPostCode4: edit
        ? entry.properties.relatives_post_code4
        : cookies.relativesPostCode4
        ? cookies.relativesPostCode4
        : '',
      relativesPrefecture4: edit
        ? entry.properties.relatives_prefecture4
        : cookies.relativesPrefecture4
        ? cookies.relativesPrefecture4
        : '',
      relativesMunicipalities4: edit
        ? entry.properties.relatives_municipalities4
        : cookies.relativesMunicipalities4
        ? cookies.relativesMunicipalities4
        : '',
      relativesTownArea4: edit
        ? entry.properties.relatives_town_area4
        : cookies.relativesTownArea4
        ? cookies.relativesTownArea4
        : '',
      relativesOtherAddress4: edit
        ? entry.properties.relatives_other_address4
        : cookies.relativesOtherAddress4
        ? cookies.relativesOtherAddress4
        : '',
      relativesHandicapped4: edit
        ? entry.properties.relatives_handicapped4 || '該当なし'
        : cookies.relativesHandicapped4
        ? cookies.relativesHandicapped4
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail4: edit
        ? entry.properties.relatives_handicapped_detail4
        : cookies.relativesHandicappedDetail4
        ? cookies.relativesHandicappedDetail4
        : '',
      relativesHandicappedDegree4: edit
        ? entry.properties.relatives_handicapped_degree4
        : cookies.relativesHandicappedDegree4
        ? cookies.relativesHandicappedDegree4
        : '',
      relativesHandicappedOther4: edit
        ? entry.properties.relativeshandicappedother4
        : cookies.relativesHandicappedOther4
        ? cookies.relativesHandicappedOther4
        : '',
      relativesDeliveryDate4: edit
        ? entry.properties.relatives_delivery_date4
        : cookies.relativesDeliveryDate4
        ? cookies.relativesDeliveryDate4
        : '',
      relativesBereavement4: edit
        ? entry.properties.registration_bereavement4 || '該当しない'
        : cookies.relativesBereavement4
        ? cookies.relativesBereavement4
        : '該当しない',

      // マル扶 扶養者情報(扶養親族5)
      relativesLastname5: edit
        ? entry.properties.relatives_lastname5
        : cookies.relativesLastname5
        ? cookies.relativesLastname5
        : '',
      relativesLastnameRuby5: edit
        ? entry.properties.relatives_lastname_ruby5
        : cookies.relativesLastnameRuby5
        ? cookies.relativesLastnameRuby5
        : '',
      relativesFirstname5: edit
        ? entry.properties.relatives_firstname5
        : cookies.relativesFirstname5
        ? cookies.relativesFirstname5
        : '',
      relativesFirstnameRuby5: edit
        ? entry.properties.relatives_firstname_ruby5
        : cookies.relativesFirstnameRuby5
        ? cookies.relativesFirstnameRuby5
        : '',
      relativesBirthday5: edit
        ? entry.properties.relatives_birthday5
        : cookies.relativesBirthday5
        ? cookies.relativesBirthday5
        : '',
      relativesGender5: edit
        ? entry.properties.relatives_gender5
        : cookies.relativesGender5
        ? cookies.relativesGender5
        : '',
      relativesRelationship5: edit
        ? entry.properties.relatives_relationship5
        : cookies.relativesRelationship5
        ? cookies.relativesRelationship5
        : '',
      relativesSalary5: edit
        ? entry.properties.relatives_salary5 || '0'
        : cookies.relativesSalary5
        ? cookies.relativesSalary5
        : '0',
      relativesPension5: edit
        ? entry.properties.relatives_pension5 || '0'
        : cookies.relativesPension5
        ? cookies.relativesPension5
        : '0',
      relativesOtherIncome5: edit
        ? entry.properties.relatives_other_income5 || '0'
        : cookies.relativesOtherIncome5
        ? cookies.relativesOtherIncome5
        : '0',
      relativesLiving5: edit
        ? entry.properties.relatives_living5 || '同居'
        : cookies.relativesLiving5
        ? cookies.relativesLiving5
        : '同居',
      relativesPostCode5: edit
        ? entry.properties.relatives_post_code5
        : cookies.relativesPostCode5
        ? cookies.relativesPostCode5
        : '',
      relativesPrefecture5: edit
        ? entry.properties.relatives_prefecture5
        : cookies.relativesPrefecture5
        ? cookies.relativesPrefecture5
        : '',
      relativesMunicipalities5: edit
        ? entry.properties.relatives_municipalities5
        : cookies.relativesMunicipalities5
        ? cookies.relativesMunicipalities5
        : '',
      relativesTownArea5: edit
        ? entry.properties.relatives_town_area5
        : cookies.relativesTownArea5
        ? cookies.relativesTownArea5
        : '',
      relativesOtherAddress5: edit
        ? entry.properties.relatives_other_address5
        : cookies.relativesOtherAddress5
        ? cookies.relativesOtherAddress5
        : '',
      relativesHandicapped5: edit
        ? entry.properties.relatives_handicapped5 || '該当なし'
        : cookies.relativesHandicapped5
        ? cookies.relativesHandicapped5
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail5: edit
        ? entry.properties.relatives_handicapped_detail5
        : cookies.relativesHandicappedDetail5
        ? cookies.relativesHandicappedDetail5
        : '',
      relativesHandicappedDegree5: edit
        ? entry.properties.relatives_handicapped_degree5
        : cookies.relativesHandicappedDegree5
        ? cookies.relativesHandicappedDegree5
        : '',
      relativesHandicappedOther5: edit
        ? entry.properties.relativeshandicappedother5
        : cookies.relativesHandicappedOther5
        ? cookies.relativesHandicappedOther5
        : '',
      relativesDeliveryDate5: edit
        ? entry.properties.relatives_delivery_date5
        : cookies.relativesDeliveryDate5
        ? cookies.relativesDeliveryDate5
        : '',
      relativesBereavement5: edit
        ? entry.properties.registration_bereavement5 || '該当しない'
        : cookies.relativesBereavement5
        ? cookies.relativesBereavement5
        : '該当しない',

      // マル扶 扶養者情報(扶養親族6)
      relativesLastname6: edit
        ? entry.properties.relatives_lastname6
        : cookies.relativesLastname6
        ? cookies.relativesLastname6
        : '',
      relativesLastnameRuby6: edit
        ? entry.properties.relatives_lastname_ruby6
        : cookies.relativesLastnameRuby6
        ? cookies.relativesLastnameRuby6
        : '',
      relativesFirstname6: edit
        ? entry.properties.relatives_firstname6
        : cookies.relativesFirstname6
        ? cookies.relativesFirstname6
        : '',
      relativesFirstnameRuby6: edit
        ? entry.properties.relatives_firstname_ruby6
        : cookies.relativesFirstnameRuby6
        ? cookies.relativesFirstnameRuby6
        : '',
      relativesBirthday6: edit
        ? entry.properties.relatives_birthday6
        : cookies.relativesBirthday6
        ? cookies.relativesBirthday6
        : '',
      relativesGender6: edit
        ? entry.properties.relatives_gender6
        : cookies.relativesGender6
        ? cookies.relativesGender6
        : '',
      relativesRelationship6: edit
        ? entry.properties.relatives_relationship6
        : cookies.relativesRelationship6
        ? cookies.relativesRelationship6
        : '',
      relativesSalary6: edit
        ? entry.properties.relatives_salary6 || '0'
        : cookies.relativesSalary6
        ? cookies.relativesSalary6
        : '0',
      relativesPension6: edit
        ? entry.properties.relatives_pension6 || '0'
        : cookies.relativesPension6
        ? cookies.relativesPension6
        : '0',
      relativesOtherIncome6: edit
        ? entry.properties.relatives_other_income6 || '0'
        : cookies.relativesOtherIncome6
        ? cookies.relativesOtherIncome6
        : '0',
      relativesLiving6: edit
        ? entry.properties.relatives_living6 || '同居'
        : cookies.relativesLiving6
        ? cookies.relativesLiving6
        : '同居',
      relativesPostCode6: edit
        ? entry.properties.relatives_post_code6
        : cookies.relativesPostCode6
        ? cookies.relativesPostCode6
        : '',
      relativesPrefecture6: edit
        ? entry.properties.relatives_prefecture6
        : cookies.relativesPrefecture6
        ? cookies.relativesPrefecture6
        : '',
      relativesMunicipalities6: edit
        ? entry.properties.relatives_municipalities6
        : cookies.relativesMunicipalities6
        ? cookies.relativesMunicipalities6
        : '',
      relativesTownArea6: edit
        ? entry.properties.relatives_town_area6
        : cookies.relativesTownArea6
        ? cookies.relativesTownArea6
        : '',
      relativesOtherAddress6: edit
        ? entry.properties.relatives_other_address6
        : cookies.relativesOtherAddress6
        ? cookies.relativesOtherAddress6
        : '',
      relativesHandicapped6: edit
        ? entry.properties.relatives_handicapped6 || '該当なし'
        : cookies.relativesHandicapped6
        ? cookies.relativesHandicapped6
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail6: edit
        ? entry.properties.relatives_handicapped_detail6
        : cookies.relativesHandicappedDetail6
        ? cookies.relativesHandicappedDetail6
        : '',
      relativesHandicappedDegree6: edit
        ? entry.properties.relatives_handicapped_degree6
        : cookies.relativesHandicappedDegree6
        ? cookies.relativesHandicappedDegree6
        : '',
      relativesHandicappedOther6: edit
        ? entry.properties.relativeshandicappedother6
        : cookies.relativesHandicappedOther6
        ? cookies.relativesHandicappedOther6
        : '',
      relativesDeliveryDate6: edit
        ? entry.properties.relatives_delivery_date6
        : cookies.relativesDeliveryDate6
        ? cookies.relativesDeliveryDate6
        : '',
      relativesBereavement6: edit
        ? entry.properties.registration_bereavement6 || '該当しない'
        : cookies.relativesBereavement6
        ? cookies.relativesBereavement6
        : '該当しない',

      // マル扶 扶養者情報(扶養親族7)
      relativesLastname7: edit
        ? entry.properties.relatives_lastname7
        : cookies.relativesLastname7
        ? cookies.relativesLastname7
        : '',
      relativesLastnameRuby7: edit
        ? entry.properties.relatives_lastname_ruby7
        : cookies.relativesLastnameRuby7
        ? cookies.relativesLastnameRuby7
        : '',
      relativesFirstname7: edit
        ? entry.properties.relatives_firstname7
        : cookies.relativesFirstname7
        ? cookies.relativesFirstname7
        : '',
      relativesFirstnameRuby7: edit
        ? entry.properties.relatives_firstname_ruby7
        : cookies.relativesFirstnameRuby7
        ? cookies.relativesFirstnameRuby7
        : '',
      relativesBirthday7: edit
        ? entry.properties.relatives_birthday7
        : cookies.relativesBirthday7
        ? cookies.relativesBirthday7
        : '',
      relativesGender7: edit
        ? entry.properties.relatives_gender7
        : cookies.relativesGender7
        ? cookies.relativesGender7
        : '',
      relativesRelationship7: edit
        ? entry.properties.relatives_relationship7
        : cookies.relativesRelationship7
        ? cookies.relativesRelationship7
        : '',
      relativesSalary7: edit
        ? entry.properties.relatives_salary7 || '0'
        : cookies.relativesSalary7
        ? cookies.relativesSalary7
        : '0',
      relativesPension7: edit
        ? entry.properties.relatives_pension7 || '0'
        : cookies.relativesPension7
        ? cookies.relativesPension7
        : '0',
      relativesOtherIncome7: edit
        ? entry.properties.relatives_other_income7 || '0'
        : cookies.relativesOtherIncome7
        ? cookies.relativesOtherIncome7
        : '0',
      relativesLiving7: edit
        ? entry.properties.relatives_living7 || '同居'
        : cookies.relativesLiving7
        ? cookies.relativesLiving7
        : '同居',
      relativesPostCode7: edit
        ? entry.properties.relatives_post_code7
        : cookies.relativesPostCode7
        ? cookies.relativesPostCode7
        : '',
      relativesPrefecture7: edit
        ? entry.properties.relatives_prefecture7
        : cookies.relativesPrefecture7
        ? cookies.relativesPrefecture7
        : '',
      relativesMunicipalities7: edit
        ? entry.properties.relatives_municipalities7
        : cookies.relativesMunicipalities7
        ? cookies.relativesMunicipalities7
        : '',
      relativesTownArea7: edit
        ? entry.properties.relatives_town_area7
        : cookies.relativesTownArea7
        ? cookies.relativesTownArea7
        : '',
      relativesOtherAddress7: edit
        ? entry.properties.relatives_other_address7
        : cookies.relativesOtherAddress7
        ? cookies.relativesOtherAddress7
        : '',
      relativesHandicapped7: edit
        ? entry.properties.relatives_handicapped7 || '該当なし'
        : cookies.relativesHandicapped7
        ? cookies.relativesHandicapped7
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail7: edit
        ? entry.properties.relatives_handicapped_detail7
        : cookies.relativesHandicappedDetail7
        ? cookies.relativesHandicappedDetail7
        : '',
      relativesHandicappedDegree7: edit
        ? entry.properties.relatives_handicapped_degree7
        : cookies.relativesHandicappedDegree7
        ? cookies.relativesHandicappedDegree7
        : '',
      relativesHandicappedOther7: edit
        ? entry.properties.relativeshandicappedother7
        : cookies.relativesHandicappedOther7
        ? cookies.relativesHandicappedOther7
        : '',
      relativesDeliveryDate7: edit
        ? entry.properties.relatives_delivery_date7
        : cookies.relativesDeliveryDate7
        ? cookies.relativesDeliveryDate7
        : '',
      relativesBereavement7: edit
        ? entry.properties.registration_bereavement7 || '該当しない'
        : cookies.relativesBereavement7
        ? cookies.relativesBereavement7
        : '該当しない',

      // マル扶 扶養者情報(扶養親族8)
      relativesLastname8: edit
        ? entry.properties.relatives_lastname8
        : cookies.relativesLastname8
        ? cookies.relativesLastname8
        : '',
      relativesLastnameRuby8: edit
        ? entry.properties.relatives_lastname_ruby8
        : cookies.relativesLastnameRuby8
        ? cookies.relativesLastnameRuby8
        : '',
      relativesFirstname8: edit
        ? entry.properties.relatives_firstname8
        : cookies.relativesFirstname8
        ? cookies.relativesFirstname8
        : '',
      relativesFirstnameRuby8: edit
        ? entry.properties.relatives_firstname_ruby8
        : cookies.relativesFirstnameRuby8
        ? cookies.relativesFirstnameRuby8
        : '',
      relativesBirthday8: edit
        ? entry.properties.relatives_birthday8
        : cookies.relativesBirthday8
        ? cookies.relativesBirthday8
        : '',
      relativesGender8: edit
        ? entry.properties.relatives_gender8
        : cookies.relativesGender8
        ? cookies.relativesGender8
        : '',
      relativesRelationship8: edit
        ? entry.properties.relatives_relationship8
        : cookies.relativesRelationship8
        ? cookies.relativesRelationship8
        : '',
      relativesSalary8: edit
        ? entry.properties.relatives_salary8 || '0'
        : cookies.relativesSalary8
        ? cookies.relativesSalary8
        : '0',
      relativesPension8: edit
        ? entry.properties.relatives_pension8 || '0'
        : cookies.relativesPension8
        ? cookies.relativesPension8
        : '0',
      relativesOtherIncome8: edit
        ? entry.properties.relatives_other_income8 || '0'
        : cookies.relativesOtherIncome8
        ? cookies.relativesOtherIncome8
        : '0',
      relativesLiving8: edit
        ? entry.properties.relatives_living8 || '同居'
        : cookies.relativesLiving8
        ? cookies.relativesLiving8
        : '同居',
      relativesPostCode8: edit
        ? entry.properties.relatives_post_code8
        : cookies.relativesPostCode8
        ? cookies.relativesPostCode8
        : '',
      relativesPrefecture8: edit
        ? entry.properties.relatives_prefecture8
        : cookies.relativesPrefecture8
        ? cookies.relativesPrefecture8
        : '',
      relativesMunicipalities8: edit
        ? entry.properties.relatives_municipalities8
        : cookies.relativesMunicipalities8
        ? cookies.relativesMunicipalities8
        : '',
      relativesTownArea8: edit
        ? entry.properties.relatives_town_area8
        : cookies.relativesTownArea8
        ? cookies.relativesTownArea8
        : '',
      relativesOtherAddress8: edit
        ? entry.properties.relatives_other_address8
        : cookies.relativesOtherAddress8
        ? cookies.relativesOtherAddress8
        : '',
      relativesHandicapped8: edit
        ? entry.properties.relatives_handicapped8 || '該当なし'
        : cookies.relativesHandicapped8
        ? cookies.relativesHandicapped8
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail8: edit
        ? entry.properties.relatives_handicapped_detail8
        : cookies.relativesHandicappedDetail8
        ? cookies.relativesHandicappedDetail8
        : '',
      relativesHandicappedDegree8: edit
        ? entry.properties.relatives_handicapped_degree8
        : cookies.relativesHandicappedDegree8
        ? cookies.relativesHandicappedDegree8
        : '',
      relativesHandicappedOther8: edit
        ? entry.properties.relativeshandicappedother8
        : cookies.relativesHandicappedOther8
        ? cookies.relativesHandicappedOther8
        : '',
      relativesDeliveryDate8: edit
        ? entry.properties.relatives_delivery_date8
        : cookies.relativesDeliveryDate8
        ? cookies.relativesDeliveryDate8
        : '',
      relativesBereavement8: edit
        ? entry.properties.registration_bereavement8 || '該当しない'
        : cookies.relativesBereavement8
        ? cookies.relativesBereavement8
        : '該当しない',

      // マル扶 扶養者情報(扶養親族9)
      relativesLastname9: edit
        ? entry.properties.relatives_lastname9
        : cookies.relativesLastname9
        ? cookies.relativesLastname9
        : '',
      relativesLastnameRuby9: edit
        ? entry.properties.relatives_lastname_ruby9
        : cookies.relativesLastnameRuby9
        ? cookies.relativesLastnameRuby9
        : '',
      relativesFirstname9: edit
        ? entry.properties.relatives_firstname9
        : cookies.relativesFirstname9
        ? cookies.relativesFirstname9
        : '',
      relativesFirstnameRuby9: edit
        ? entry.properties.relatives_firstname_ruby9
        : cookies.relativesFirstnameRuby9
        ? cookies.relativesFirstnameRuby9
        : '',
      relativesBirthday9: edit
        ? entry.properties.relatives_birthday9
        : cookies.relativesBirthday9
        ? cookies.relativesBirthday9
        : '',
      relativesGender9: edit
        ? entry.properties.relatives_gender9
        : cookies.relativesGender9
        ? cookies.relativesGender9
        : '',
      relativesRelationship9: edit
        ? entry.properties.relatives_relationship9
        : cookies.relativesRelationship9
        ? cookies.relativesRelationship9
        : '',
      relativesSalary9: edit
        ? entry.properties.relatives_salary9 || '0'
        : cookies.relativesSalary9
        ? cookies.relativesSalary9
        : '0',
      relativesPension9: edit
        ? entry.properties.relatives_pension9 || '0'
        : cookies.relativesPension9
        ? cookies.relativesPension9
        : '0',
      relativesOtherIncome9: edit
        ? entry.properties.relatives_other_income9 || '0'
        : cookies.relativesOtherIncome9
        ? cookies.relativesOtherIncome9
        : '0',
      relativesLiving9: edit
        ? entry.properties.relatives_living9 || '同居'
        : cookies.relativesLiving9
        ? cookies.relativesLiving9
        : '同居',
      relativesPostCode9: edit
        ? entry.properties.relatives_post_code9
        : cookies.relativesPostCode9
        ? cookies.relativesPostCode9
        : '',
      relativesPrefecture9: edit
        ? entry.properties.relatives_prefecture9
        : cookies.relativesPrefecture9
        ? cookies.relativesPrefecture9
        : '',
      relativesMunicipalities9: edit
        ? entry.properties.relatives_municipalities9
        : cookies.relativesMunicipalities9
        ? cookies.relativesMunicipalities9
        : '',
      relativesTownArea9: edit
        ? entry.properties.relatives_town_area9
        : cookies.relativesTownArea9
        ? cookies.relativesTownArea9
        : '',
      relativesOtherAddress9: edit
        ? entry.properties.relatives_other_address9
        : cookies.relativesOtherAddress9
        ? cookies.relativesOtherAddress9
        : '',
      relativesHandicapped9: edit
        ? entry.properties.relatives_handicapped9 || '該当なし'
        : cookies.relativesHandicapped9
        ? cookies.relativesHandicapped9
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail9: edit
        ? entry.properties.relatives_handicapped_detail9
        : cookies.relativesHandicappedDetail9
        ? cookies.relativesHandicappedDetail9
        : '',
      relativesHandicappedDegree9: edit
        ? entry.properties.relatives_handicapped_degree9
        : cookies.relativesHandicappedDegree9
        ? cookies.relativesHandicappedDegree9
        : '',
      relativesHandicappedOther9: edit
        ? entry.properties.relativeshandicappedother9
        : cookies.relativesHandicappedOther9
        ? cookies.relativesHandicappedOther9
        : '',
      relativesDeliveryDate9: edit
        ? entry.properties.relatives_delivery_date9
        : cookies.relativesDeliveryDate9
        ? cookies.relativesDeliveryDate9
        : '',
      relativesBereavement9: edit
        ? entry.properties.registration_bereavement9 || '該当しない'
        : cookies.relativesBereavement9
        ? cookies.relativesBereavement9
        : '該当しない',

      // マル扶 扶養者情報(扶養親族10)
      relativesLastname10: edit
        ? entry.properties.relatives_lastname10
        : cookies.relativesLastname10
        ? cookies.relativesLastname10
        : '',
      relativesLastnameRuby10: edit
        ? entry.properties.relatives_lastname_ruby10
        : cookies.relativesLastnameRuby10
        ? cookies.relativesLastnameRuby10
        : '',
      relativesFirstname10: edit
        ? entry.properties.relatives_firstname10
        : cookies.relativesFirstname10
        ? cookies.relativesFirstname10
        : '',
      relativesFirstnameRuby10: edit
        ? entry.properties.relatives_firstname_ruby10
        : cookies.relativesFirstnameRuby10
        ? cookies.relativesFirstnameRuby10
        : '',
      relativesBirthday10: edit
        ? entry.properties.relatives_birthday10
        : cookies.relativesBirthday10
        ? cookies.relativesBirthday10
        : '',
      relativesGender10: edit
        ? entry.properties.relatives_gender10
        : cookies.relativesGender10
        ? cookies.relativesGender10
        : '',
      relativesRelationship10: edit
        ? entry.properties.relatives_relationship10
        : cookies.relativesRelationship10
        ? cookies.relativesRelationship10
        : '',
      relativesSalary10: edit
        ? entry.properties.relatives_salary10 || '0'
        : cookies.relativesSalary10
        ? cookies.relativesSalary10
        : '0',
      relativesPension10: edit
        ? entry.properties.relatives_pension10 || '0'
        : cookies.relativesPension10
        ? cookies.relativesPension10
        : '0',
      relativesOtherIncome10: edit
        ? entry.properties.relatives_other_income10 || '0'
        : cookies.relativesOtherIncome10
        ? cookies.relativesOtherIncome10
        : '0',
      relativesLiving10: edit
        ? entry.properties.relatives_living10 || '同居'
        : cookies.relativesLiving10
        ? cookies.relativesLiving10
        : '同居',
      relativesPostCode10: edit
        ? entry.properties.relatives_post_code10
        : cookies.relativesPostCode10
        ? cookies.relativesPostCode10
        : '',
      relativesPrefecture10: edit
        ? entry.properties.relatives_prefecture10
        : cookies.relativesPrefecture10
        ? cookies.relativesPrefecture10
        : '',
      relativesMunicipalities10: edit
        ? entry.properties.relatives_municipalities10
        : cookies.relativesMunicipalities10
        ? cookies.relativesMunicipalities10
        : '',
      relativesTownArea10: edit
        ? entry.properties.relatives_town_area10
        : cookies.relativesTownArea10
        ? cookies.relativesTownArea10
        : '',
      relativesOtherAddress10: edit
        ? entry.properties.relatives_other_address10
        : cookies.relativesOtherAddress10
        ? cookies.relativesOtherAddress10
        : '',
      relativesHandicapped10: edit
        ? entry.properties.relatives_handicapped10 || '該当なし'
        : cookies.relativesHandicapped10
        ? cookies.relativesHandicapped10
        : '該当なし', //初期値は該当なし
      relativesHandicappedDetail10: edit
        ? entry.properties.relatives_handicapped_detail10
        : cookies.relativesHandicappedDetail10
        ? cookies.relativesHandicappedDetail10
        : '',
      relativesHandicappedDegree10: edit
        ? entry.properties.relatives_handicapped_degree10
        : cookies.relativesHandicappedDegree10
        ? cookies.relativesHandicappedDegree10
        : '',
      relativesHandicappedOther10: edit
        ? entry.properties.relativeshandicappedother10
        : cookies.relativesHandicappedOther10
        ? cookies.relativesHandicappedOther10
        : '',
      relativesDeliveryDate10: edit
        ? entry.properties.relatives_delivery_date10
        : cookies.relativesDeliveryDate10
        ? cookies.relativesDeliveryDate10
        : '',
      relativesBereavement10: edit
        ? entry.properties.registration_bereavement10 || '該当しない'
        : cookies.relativesBereavement10
        ? cookies.relativesBereavement10
        : '該当しない',

      //その他入力項目
      columnBDeclaration: edit
        ? entry.properties.column_b_declaration || '申告しない'
        : cookies.columnBDeclaration
        ? cookies.columnBDeclaration
        : '申告しない',
      carsForCommuting: edit
        ? entry.properties.cars_for_commuting || '申請しない'
        : cookies.carsForCommuting
        ? cookies.carsForCommuting
        : '申請しない',
      commuteStartDay: edit
        ? entry.properties.commute_start_day
        : cookies.commuteStartDay
        ? cookies.commuteStartDay
        : '',
      carNumber: edit
        ? entry.properties.car_number
        : cookies.carNumber
        ? cookies.carNumber
        : '',
      carNumberS1: edit
        ? entry.properties.car_number_s1
        : cookies.carNumberS1
        ? cookies.carNumberS1
        : '',
      carNumberS2: edit
        ? entry.properties.car_number_s2
        : cookies.carNumberS2
        ? cookies.carNumberS2
        : '',
      carNumberS3: edit
        ? entry.properties.car_number_s3
        : cookies.carNumberS3
        ? cookies.carNumberS3
        : '',
      carNumberS4: edit
        ? (() => {
            try {
              return entry.properties.car_number
                ? String(entry.properties.car_number).split('-')[0]
                : '';
            } catch (e) {
              console.error(e);
              return '';
            }
          })()
        : cookies.carNumberS4
        ? cookies.carNumberS4
        : '',
      carNumberS5: edit
        ? (() => {
            try {
              return entry.properties.car_number
                ? String(entry.properties.car_number).split('-')[1]
                : '';
            } catch (e) {
              console.error(e);
              return '';
            }
          })()
        : cookies.carNumberS5
        ? cookies.carNumberS5
        : '',
      carName: edit
        ? entry.properties.car_name
        : cookies.carName
        ? cookies.carName
        : '',
      carColor: edit
        ? entry.properties.car_color
        : cookies.carColor
        ? cookies.carColor
        : '',
      applicationDistinction: edit
        ? entry.properties.application_distinction || '新規'
        : cookies.applicationDistinction
        ? cookies.applicationDistinction
        : '新規', //初期値は新規
      carsType: edit
        ? entry.properties.cars_type
        : cookies.carsType
        ? cookies.carsType
        : '',
      diligenceName: edit
        ? entry.properties.diligence_name
        : cookies.diligenceName
        ? cookies.diligenceName
        : '',
      writtenOathCarsCommute: edit
        ? entry.properties.written_oath_cars_commute || 'いいえ'
        : cookies.writtenOathCarsCommute
        ? cookies.writtenOathCarsCommute
        : 'いいえ',

      //個人情報・機密情報保護研修
      training: edit
        ? entry.properties.training
        : cookies.training
        ? cookies.training
        : '',
      training1: edit ? 'ok' : cookies.training1 ? cookies.training1 : 'non',
      training2: edit ? 'ok' : cookies.training1 ? cookies.training2 : 'non',
      training3: edit ? 'ok' : cookies.training1 ? cookies.training3 : 'non',
      training4: edit ? 'ok' : cookies.training1 ? cookies.training4 : 'non',
      training5: edit ? 'ok' : cookies.training1 ? cookies.training5 : 'non',
      training6: edit ? 'ok' : cookies.training1 ? cookies.training6 : 'non',
      training7: edit ? 'ok' : cookies.training1 ? cookies.training7 : 'non',
      training8: edit ? 'ok' : cookies.training1 ? cookies.training8 : 'non',
      training9: edit ? 'ok' : cookies.training1 ? cookies.training9 : 'non',
      training10: edit ? 'ok' : cookies.training1 ? cookies.training10 : 'non',
      training11: edit ? 'ok' : cookies.training1 ? cookies.training11 : 'non',
      training12: edit ? 'ok' : cookies.training1 ? cookies.training12 : 'non',
      training13: edit ? 'ok' : cookies.training1 ? cookies.training13 : 'non',
      training14: edit ? 'ok' : cookies.training1 ? cookies.training14 : 'non',
      training15: edit ? 'ok' : cookies.training1 ? cookies.training15 : 'non',
      training16: edit ? 'ok' : cookies.training1 ? cookies.training16 : 'non',
      training17: edit ? 'ok' : cookies.training1 ? cookies.training17 : 'non',
      training18: edit ? 'ok' : cookies.training1 ? cookies.training18 : 'non',
      training19: edit ? 'ok' : cookies.training1 ? cookies.training19 : 'non',
      training20: edit ? 'ok' : cookies.training1 ? cookies.training20 : 'non',
      training21: edit ? 'ok' : cookies.training1 ? cookies.training21 : 'non',

      // DR
      drWrittenOath: edit
        ? entry.properties.dr_written_oath
        : cookies.drWrittenOath
        ? cookies.drWrittenOath
        : '',
      drConfirmingLetter: edit
        ? entry.properties.dr_confirming_letter
        : cookies.drConfirmingLetter
        ? cookies.drConfirmingLetter
        : '',
      drPersonalInformation: edit
        ? entry.properties.dr_personal_information
        : cookies.drPersonalInformation
        ? cookies.drPersonalInformation
        : '',
      drNotDrive: edit
        ? entry.properties.dr_written_oath
          ? '運転する'
          : '運転しない'
        : cookies.drNotDrive
        ? cookies.drNotDrive
        : '運転する',
      drNotProvided: edit
        ? entry.properties.dr_personal_information
          ? '提供する'
          : '提供しない'
        : cookies.drNotProvided
        ? cookies.drNotProvided
        : '提供する',

      // 社会保険
      socialTarget: cookies.socialTarget ? cookies.socialTarget : '',
      basicPensionNumber1: cookies.basicPensionNumber1
        ? cookies.basicPensionNumber1
        : '',
      basicPensionNumber2: cookies.basicPensionNumber2
        ? cookies.basicPensionNumber2
        : '',
      relatives: cookies.relatives ? cookies.relatives : '',
      socialOtherCompany: cookies.socialOtherCompany
        ? cookies.socialOtherCompany
        : '',
      foreignNationality: cookies.foreignNationality
        ? cookies.foreignNationality
        : 'いいえ',

      // 雇用保険
      unemploymentTarget: cookies.unemploymentTarget
        ? cookies.unemploymentTarget
        : '',
      knowUnemploymentNumber: cookies.knowUnemploymentNumber
        ? cookies.knowUnemploymentNumber
        : '',
      unemploymentNumber1: cookies.unemploymentNumber1
        ? cookies.unemploymentNumber1
        : '',
      unemploymentNumber2: cookies.unemploymentNumber2
        ? cookies.unemploymentNumber2
        : '',
      unemploymentNumber3: cookies.unemploymentNumber3
        ? cookies.unemploymentNumber3
        : '',
      getInsurance: cookies.getInsurance ? cookies.getInsurance : '',
      companyName: cookies.companyName ? cookies.companyName : '',
      unemploymentOtherCompany: cookies.unemploymentOtherCompany
        ? cookies.unemploymentOtherCompany
        : '',

      //被扶養者情報(被扶養者1)
      relativesLastnameSi1: cookies.relativesLastnameSi1
        ? cookies.relativesLastnameSi1
        : '',
      relativesLastnameRubySi1: cookies.relativesLastnameRubySi1
        ? cookies.relativesLastnameRubySi1
        : '',
      relativesFirstnameSi1: cookies.relativesFirstnameSi1
        ? cookies.relativesFirstnameSi1
        : '',
      relativesFirstnameRubySi1: cookies.relativesFirstnameRubySi1
        ? cookies.relativesFirstnameRubySi1
        : '',
      relativesBirthdaySi1: cookies.relativesBirthdaySi1
        ? cookies.relativesBirthdaySi1
        : '',
      relativesRelationshipSi1: cookies.relativesRelationshipSi1
        ? cookies.relativesRelationshipSi1
        : '',

      //被扶養者情報(被扶養者2)
      relativesLastnameSi2: cookies.relativesLastnameSi2
        ? cookies.relativesLastnameSi2
        : '',
      relativesLastnameRubySi2: cookies.relativesLastnameRubySi2
        ? cookies.relativesLastnameRubySi2
        : '',
      relativesFirstnameSi2: cookies.relativesFirstnameSi2
        ? cookies.relativesFirstnameSi2
        : '',
      relativesFirstnameRubySi2: cookies.relativesFirstnameRubySi2
        ? cookies.relativesFirstnameRubySi2
        : '',
      relativesBirthdaySi2: cookies.relativesBirthdaySi2
        ? cookies.relativesBirthdaySi2
        : '',
      relativesRelationshipSi2: cookies.relativesRelationshipSi2
        ? cookies.relativesRelationshipSi2
        : '',

      //被扶養者情報(被扶養者3)
      relativesLastnameSi3: cookies.relativesLastnameSi3
        ? cookies.relativesLastnameSi3
        : '',
      relativesLastnameRubySi3: cookies.relativesLastnameRubySi3
        ? cookies.relativesLastnameRubySi3
        : '',
      relativesFirstnameSi3: cookies.relativesFirstnameSi3
        ? cookies.relativesFirstnameSi3
        : '',
      relativesFirstnameRubySi3: cookies.relativesFirstnameRubySi3
        ? cookies.relativesFirstnameRubySi3
        : '',
      relativesBirthdaySi3: cookies.relativesBirthdaySi3
        ? cookies.relativesBirthdaySi3
        : '',
      relativesRelationshipSi3: cookies.relativesRelationshipSi3
        ? cookies.relativesRelationshipSi3
        : '',

      //被扶養者情報(被扶養者4)
      relativesLastnameSi4: cookies.relativesLastnameSi4
        ? cookies.relativesLastnameSi4
        : '',
      relativesLastnameRubySi4: cookies.relativesLastnameRubySi4
        ? cookies.relativesLastnameRubySi4
        : '',
      relativesFirstnameSi4: cookies.relativesFirstnameSi4
        ? cookies.relativesFirstnameSi4
        : '',
      relativesFirstnameRubySi4: cookies.relativesFirstnameRubySi4
        ? cookies.relativesFirstnameRubySi4
        : '',
      relativesBirthdaySi4: cookies.relativesBirthdaySi4
        ? cookies.relativesBirthdaySi4
        : '',
      relativesRelationshipSi4: cookies.relativesRelationshipSi4
        ? cookies.relativesRelationshipSi4
        : '',

      //被扶養者情報(被扶養者5)
      relativesLastnameSi5: cookies.relativesLastnameSi5
        ? cookies.relativesLastnameSi5
        : '',
      relativesLastnameRubySi5: cookies.relativesLastnameRubySi5
        ? cookies.relativesLastnameRubySi5
        : '',
      relativesFirstnameSi5: cookies.relativesFirstnameSi5
        ? cookies.relativesFirstnameSi5
        : '',
      relativesFirstnameRubySi5: cookies.relativesFirstnameRubySi5
        ? cookies.relativesFirstnameRubySi5
        : '',
      relativesBirthdaySi5: cookies.relativesBirthdaySi5
        ? cookies.relativesBirthdaySi5
        : '',
      relativesRelationshipSi5: cookies.relativesRelationshipSi5
        ? cookies.relativesRelationshipSi5
        : '',

      //被扶養者情報(被扶養者6)
      relativesLastnameSi6: cookies.relativesLastnameSi6
        ? cookies.relativesLastnameSi6
        : '',
      relativesLastnameRubySi6: cookies.relativesLastnameRubySi6
        ? cookies.relativesLastnameRubySi6
        : '',
      relativesFirstnameSi6: cookies.relativesFirstnameSi6
        ? cookies.relativesFirstnameSi6
        : '',
      relativesFirstnameRubySi6: cookies.relativesFirstnameRubySi6
        ? cookies.relativesFirstnameRubySi6
        : '',
      relativesBirthdaySi6: cookies.relativesBirthdaySi6
        ? cookies.relativesBirthdaySi6
        : '',
      relativesRelationshipSi6: cookies.relativesRelationshipSi6
        ? cookies.relativesRelationshipSi6
        : '',

      //被扶養者情報(被扶養者7)
      relativesLastnameSi7: cookies.relativesLastnameSi7
        ? cookies.relativesLastnameSi7
        : '',
      relativesLastnameRubySi7: cookies.relativesLastnameRubySi7
        ? cookies.relativesLastnameRubySi7
        : '',
      relativesFirstnameSi7: cookies.relativesFirstnameSi7
        ? cookies.relativesFirstnameSi7
        : '',
      relativesFirstnameRubySi7: cookies.relativesFirstnameRubySi7
        ? cookies.relativesFirstnameRubySi7
        : '',
      relativesBirthdaySi7: cookies.relativesBirthdaySi7
        ? cookies.relativesBirthdaySi7
        : '',
      relativesRelationshipSi7: cookies.relativesRelationshipSi7
        ? cookies.relativesRelationshipSi7
        : '',

      //被扶養者情報(被扶養者8)
      relativesLastnameSi8: cookies.relativesLastnameSi8
        ? cookies.relativesLastnameSi8
        : '',
      relativesLastnameRubySi8: cookies.relativesLastnameRubySi8
        ? cookies.relativesLastnameRubySi8
        : '',
      relativesFirstnameSi8: cookies.relativesFirstnameSi8
        ? cookies.relativesFirstnameSi8
        : '',
      relativesFirstnameRubySi8: cookies.relativesFirstnameRubySi8
        ? cookies.relativesFirstnameRubySi8
        : '',
      relativesBirthdaySi8: cookies.relativesBirthdaySi8
        ? cookies.relativesBirthdaySi8
        : '',
      relativesRelationshipSi8: cookies.relativesRelationshipSi8
        ? cookies.relativesRelationshipSi8
        : '',

      //被扶養者情報(被扶養者9)
      relativesLastnameSi9: cookies.relativesLastnameSi9
        ? cookies.relativesLastnameSi9
        : '',
      relativesLastnameRubySi9: cookies.relativesLastnameRubySi9
        ? cookies.relativesLastnameRubySi9
        : '',
      relativesFirstnameSi9: cookies.relativesFirstnameSi9
        ? cookies.relativesFirstnameSi9
        : '',
      relativesFirstnameRubySi9: cookies.relativesFirstnameRubySi9
        ? cookies.relativesFirstnameRubySi9
        : '',
      relativesBirthdaySi9: cookies.relativesBirthdaySi9
        ? cookies.relativesBirthdaySi9
        : '',
      relativesRelationshipSi9: cookies.relativesRelationshipSi9
        ? cookies.relativesRelationshipSi9
        : '',

      //被扶養者情報(被扶養者10)
      relativesLastnameSi10: cookies.relativesLastnameSi10
        ? cookies.relativesLastnameSi10
        : '',
      relativesLastnameRubySi10: cookies.relativesLastnameRubySi10
        ? cookies.relativesLastnameRubySi10
        : '',
      relativesFirstnameSi10: cookies.relativesFirstnameSi10
        ? cookies.relativesFirstnameSi10
        : '',
      relativesFirstnameRubySi10: cookies.relativesFirstnameRubySi10
        ? cookies.relativesFirstnameRubySi10
        : '',
      relativesBirthdaySi10: cookies.relativesBirthdaySi10
        ? cookies.relativesBirthdaySi10
        : '',
      relativesRelationshipSi10: cookies.relativesRelationshipSi10
        ? cookies.relativesRelationshipSi10
        : '',

      //資格確認書発行の有無
      check_myna_cert: cookies.check_myna_cert ? cookies.check_myna_cert : '',

      //資格確認書発行の有無
      reason_myna_cert: cookies.reason_myna_cert
        ? cookies.reason_myna_cert
        : '',
    },
    errors: {},
    touched: {},
  });

  const [writtenOath, setWrittenOath] = useState(false);
  const [carWrittenOath, setCarWrittenOath] = useState(false);
  const [drWrittenOath, setDrWrittenOath] = useState(false);
  const [drConfirmingLetter, setDrConfirmingLetter] = useState(false);
  const [drPersonalInformation, setDrPersonalInformation] = useState(false);

  // 緊急連絡先の電話番号エラー文字
  let errWord1 = '';
  if (formState.values.emergencyTelNumber === '') {
    errWord1 = '必須項目';
  } else {
    errWord1 = '10桁以上入力してください';
  }
  // スタッフコードのエラー文字
  let errWord2 = '';
  if (formState.values.staffCode === '') {
    errWord2 = '必須項目';
  } else {
    errWord2 = '8桁入力してください';
  }
  let errWord4 = '';
  if (formState.values.staffCode === '') {
    errWord4 = '必須項目';
  } else {
    errWord4 = '6桁入力してください';
  }

  // 車両通勤許可の分類番号のエラー判定
  let errWord3 = '';
  if (String(formState.values.carNumberS2).match(/^[A-Za-z0-9]*$/)) {
    errWord3 = '必須項目';
  } else {
    errWord3 = '英数字のみ';
  }

  // 配偶者の給与のエラー判定
  const errPartnerSalary = (salary: any) => {
    return salary === '' || salary < '0' || salary > '1500000';
  };

  // 配偶者の老齢年金のエラー判定
  const today = new Date();
  const year = today.getFullYear();

  const partnerAge = (birthday: any) => {
    if (year - new Date(birthday).getFullYear() < 65) {
      return 1633334;
    } else {
      return 2050000;
    }
  };

  const errPartnerPension = (pension: any) => {
    const upper = partnerAge(formState.values.partnerBirthday);
    return pension === '' || pension < '0' || pension > upper;
  };

  // 扶養親族の給与のエラー判定
  const errRelativesSalary = (salary: any) => {
    return salary === '' || salary < '0' || salary > '1030000';
  };

  // 扶養親族の老齢年金のエラー判定
  const relativesAge = (birthday: any) => {
    if (year - new Date(birthday).getFullYear() < 65) {
      return 1080000;
    } else {
      return 1580000;
    }
  };

  const errRelativesPension = (pension: any, birthday: any) => {
    const upper = relativesAge(birthday);
    return pension === '' || pension < '0' || pension > upper;
  };

  const [activeStep, setActiveStep] = React.useState(
    prev === 'prev2'
      ? 1
      : prev === 'prev3'
      ? 2
      : prev === 'prev4'
      ? 3
      : prev === 'prev5'
      ? 4
      : prev === 'prev6'
      ? 5
      : backFromLiquid
      ? 6
      : 0,
  );
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleChange = (event: any, field: any, value: any) => {
    setCookie(field, value);
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open7, setOpen7] = React.useState(false);
  const [open8, setOpen8] = React.useState(false);
  const [open9, setOpen9] = React.useState(false);
  const [open10, setOpen10] = React.useState(false);
  const [open11, setOpen11] = React.useState(false);

  const processing = useRef(false);

  const handleClickOpen = (e: any) => {
    setOpen(true);

    e.preventDefault();

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) {
      handleClose();
      return;
    }

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    //必要項目より　現住所と同じ場合に住民票のプロパティにも同じものをセットする
    if (formState.values.registrationAddress === '現住所と同じ') {
      formState.values.postCode2 = formState.values.postCode1;
      formState.values.prefecture2 = formState.values.prefecture1;
      formState.values.municipalities2 = formState.values.municipalities1;
      formState.values.townArea2 = formState.values.townArea1;
      formState.values.otherAddress2 = formState.values.otherAddress1;
    }

    // マル扶より 乙欄申告を行う時はマル扶を全て空欄にする
    if (formState.values.columnBDeclaration === '申告する') {
      // 世帯主の項目
      formState.values.household = '';
      formState.values.lastname3 = '';
      formState.values.firstname3 = '';
      formState.values.relationship3 = '';
      // 申告者の障害者区分
      formState.values.handicapped = '';
      formState.values.handicappedDetail = '';
      formState.values.handicappedDegree = '';
      formState.values.deliveryDate = '';
      // 勤労学生区分
      formState.values.workingStudent = '';
      formState.values.schoolLabel = '';
      formState.values.schoolName = '';
      formState.values.admissionDate = '';
      // 配偶者の項目
      formState.values.marriage = '';
      formState.values.dependentDeclaration = '';
      formState.values.partnerRelationship = '';
      formState.values.partnerLastname = '';
      formState.values.partnerLastnameRuby = '';
      formState.values.partnerFirstname = '';
      formState.values.partnerFirstnameRuby = '';
      formState.values.partnerBirthday = '';
      formState.values.partnerGender = '';
      formState.values.partnerSalary = '';
      formState.values.partnerPension = '';
      formState.values.partnerOtherIncome = '';
      formState.values.partnerLiving = '';
      formState.values.partnerPostCode = '';
      formState.values.partnerPrefecture = '';
      formState.values.partnerMunicipalities = '';
      formState.values.partnerTownArea = '';
      formState.values.partnerOtherAddress = '';
      formState.values.partnerHandicapped = '';
      formState.values.partnerHandicappedDetail = '';
      formState.values.partnerHandicappedDegree = '';
      formState.values.partnerDeliveryDate = '';
      formState.values.partnerBereavement = '';
      // 配偶者の障害者区分
      formState.values.partnerHandicapped = '';
      formState.values.partnerHandicappedDetail = '';
      formState.values.partnerHandicappedDegree = '';
      formState.values.partnerDeliveryDate = '';
      // ひとり親&寡婦の項目
      // formState.values.singleParentSituation = '';
      // formState.values.classification = '';
      // 扶養家族の項目
      for (let cnt = 10; 0 < cnt; cnt--) {
        handleValueClear(`relativesLastname${cnt}`);
        handleValueClear(`relativesLastnameRuby${cnt}`);
        handleValueClear(`relativesFirstname${cnt}`);
        handleValueClear(`relativesFirstnameRuby${cnt}`);
        handleValueClear(`relativesBirthday${cnt}`);
        handleValueClear(`relativesGender${cnt}`);
        handleValueClear(`relativesRelationship${cnt}`);
        handleValueClear(`relativesSalary${cnt}`);
        handleValueClear(`relativesPension${cnt}`);
        handleValueClear(`relativesOtherIncome${cnt}`);
        handleValueClear(`relativesLiving${cnt}`);
        handleValueClear(`relativesPostCode${cnt}`);
        handleValueClear(`relativesPrefecture${cnt}`);
        handleValueClear(`relativesMunicipalities${cnt}`);
        handleValueClear(`relativesTownArea${cnt}`);
        handleValueClear(`relativesOtherAddress${cnt}`);
        handleValueClear(`relativesHandicapped${cnt}`);
        handleValueClear(`relativesHandicappedDetail${cnt}`);
        handleValueClear(`relativesHandicappedDegree${cnt}`);
        handleValueClear(`relativesHandicappedOther${cnt}`);
        handleValueClear(`relativesDeliveryDate${cnt}`);
        handleValueClear(`relativesBereavement${cnt}`);
      }
    } else {
      //マル扶より　世帯主が申告者と同じなら申告者の情報を世帯主にセットする
      if (formState.values.household === '申告者と同じ') {
        formState.values.lastname3 = formState.values.lastname;
        formState.values.firstname3 = formState.values.firstname;
        formState.values.relationship3 = '本人';
      }

      //マル扶より　申告者が結婚していない場合に配偶者の値を空にする
      if (formState.values.marriage === 'いいえ') {
        formState.values.dependentDeclaration = '';
        formState.values.partnerRelationship = '';
        formState.values.partnerLastname = '';
        formState.values.partnerLastnameRuby = '';
        formState.values.partnerFirstname = '';
        formState.values.partnerFirstnameRuby = '';
        formState.values.partnerBirthday = '';
        formState.values.partnerGender = '';
        formState.values.partnerSalary = '';
        formState.values.partnerPension = '';
        formState.values.partnerOtherIncome = '';
        formState.values.partnerLiving = '';
        formState.values.partnerPostCode = '';
        formState.values.partnerPrefecture = '';
        formState.values.partnerMunicipalities = '';
        formState.values.partnerTownArea = '';
        formState.values.partnerOtherAddress = '';
        formState.values.partnerHandicapped = '';
        formState.values.partnerHandicappedDetail = '';
        formState.values.partnerHandicappedDegree = '';
        formState.values.partnerHandicappedOther = '';
        formState.values.partnerDeliveryDate = '';
        formState.values.partnerBereavement = '';
      }

      //マル扶より　配偶者が障害者に該当しない場合に値を空にする
      if (formState.values.partnerHandicapped === '該当なし') {
        formState.values.partnerHandicappedDetail = '';
        formState.values.partnerHandicappedDegree = '';
        formState.values.partnerHandicappedOther = '';
        formState.values.partnerDeliveryDate = '';
      }

      //マル扶より　配偶者が同居の場合に値を空にする
      if (formState.values.partnerLiving === '同居') {
        formState.values.partnerPostCode = '';
        formState.values.partnerPrefecture = '';
        formState.values.partnerMunicipalities = '';
        formState.values.partnerTownArea = '';
        formState.values.partnerOtherAddress = '';
      }

      //マル扶より　申告者が障害者に該当しない場合に値を空にする
      if (formState.values.handicapped === '該当なし') {
        formState.values.handicappedDetail = '';
        formState.values.handicappedDegree = '';
        formState.values.handicappedOther = '';
        formState.values.deliveryDate = '';
      }

      //マル扶より　申告者が勤労学生に該当しない場合に値を空にする
      if (formState.values.workingStudent === '該当なし') {
        formState.values.schoolLabel = '';
        formState.values.schoolName = '';
        formState.values.admissionDate = '';
      }

      //マル扶より　結婚している場合は「寡婦/ひとり親 状況選択」を空にする
      if (formState.values.marriage === 'はい') {
        formState.values.situationSelection = '';
      }

      //マル扶より　扶養親族のうち使用しないものを空欄にする
      for (let cnt = 10; count < cnt; cnt--) {
        handleValueClear(`relativesLastname${cnt}`);
        handleValueClear(`relativesLastnameRuby${cnt}`);
        handleValueClear(`relativesFirstname${cnt}`);
        handleValueClear(`relativesFirstnameRuby${cnt}`);
        handleValueClear(`relativesBirthday${cnt}`);
        handleValueClear(`relativesGender${cnt}`);
        handleValueClear(`relativesRelationship${cnt}`);
        handleValueClear(`relativesSalary${cnt}`);
        handleValueClear(`relativesPension${cnt}`);
        handleValueClear(`relativesOtherIncome${cnt}`);
        handleValueClear(`relativesLiving${cnt}`);
        handleValueClear(`relativesPostCode${cnt}`);
        handleValueClear(`relativesPrefecture${cnt}`);
        handleValueClear(`relativesMunicipalities${cnt}`);
        handleValueClear(`relativesTownArea${cnt}`);
        handleValueClear(`relativesOtherAddress${cnt}`);
        handleValueClear(`relativesHandicapped${cnt}`);
        handleValueClear(`relativesHandicappedDetail${cnt}`);
        handleValueClear(`relativesHandicappedDegree${cnt}`);
        handleValueClear(`relativesHandicappedOther${cnt}`);
        handleValueClear(`relativesDeliveryDate${cnt}`);
        handleValueClear(`relativesBereavement${cnt}`);
      }

      for (let i = 1; i <= count; i++) {
        //マル扶より　扶養親族が障害者でない場合に値を空にする
        if (handleValue(`relativesHandicapped${i}`) === '該当なし') {
          handleValueClear(`relativesHandicappedDetail${i}`);
          handleValueClear(`relativesHandicappedDegree${i}`);
          handleValueClear(`relativesHandicappedOther${i}`);
          handleValueClear(`relativesDeliveryDate${i}`);
        }
        //マル扶より　扶養親族が同居の場合に値を空にする
        if (handleValue(`relativesLiving${i}`) === '同居') {
          handleValueClear(`relativesPostCode${i}`);
          handleValueClear(`relativesPrefecture${i}`);
          handleValueClear(`relativesMunicipalities${i}`);
          handleValueClear(`relativesTownArea${i}`);
          handleValueClear(`relativesOtherAddress${i}`);
        }
      }
    }

    //車輌通勤許可が申請しないの時に項目を空白にする
    if (formState.values.carsForCommuting !== '申請する') {
      formState.values.commuteStartDay = '';
      formState.values.carNumber = '';
      formState.values.carNumberS1 = '';
      formState.values.carNumberS2 = '';
      formState.values.carNumberS3 = '';
      formState.values.carNumberS4 = '';
      formState.values.carNumberS5 = '';
      formState.values.carName = '';
      formState.values.carColor = '';
      formState.values.applicationDistinction = '';
      formState.values.carsType = '';
      formState.values.diligenceName = '';
      formState.values.writtenOathCarsCommute = '';
    }

    //DR以外は値を空にする
    if (!DR) {
      formState.values.drWrittenOath = '';
      formState.values.drConfirmingLetter = '';
      formState.values.drPersonalInformation = '';
    }

    if (formState.values.drNotDrive === '運転しない') {
      formState.values.drWrittenOath = '';
      formState.values.drConfirmingLetter = '';
    }

    if (formState.values.drNotProvided === '提供しない') {
      formState.values.drPersonalInformation = '';
    }

    if (String(formState.values.basicPensionNumber1) === 'null') {
      formState.values.basicPensionNumber1 = '';
      formState.values.basicPensionNumber2 = '';
    }

    if (String(formState.values.unemploymentNumber1) === 'null') {
      formState.values.unemploymentNumber1 = '';
      formState.values.unemploymentNumber2 = '';
      formState.values.unemploymentNumber3 = '';
    }

    //被扶養者の有無が無しの時に項目を空白にする
    if (formState.values.socialTarget === 'いいえ') {
      formState.values.foreignNationality = '';
    }

    if (
      confirm &&
      !(
        (formState.values.unemploymentTarget === 'いいえ' ||
          formState.values.unemploymentOtherCompany === 'はい') &&
        formState.values.socialTarget === 'いいえ'
      )
    ) {
      dispatch(
        insuranceContact({
          idToken: idToken(),
          channelId: channelId(),
          values: formState.values,
        }),
      );
    }

    dispatch(
      entryContact({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
        objectId: edit ? entry.id : null,
      }),
    );

    if (confirm) {
      setTimeout(() => {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?prev=completion' +
          (branch === 'cs' ? '&branch=cs' : '');
      }, 1500);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo({
        top: 0,
        // behavior: 'smooth',
      });
    }

    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleOpen5 = () => {
    setWrittenOath(true);
    setCookie('checkWrittenOath', 'true');
    setOpen5(true);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleOpen7 = () => {
    setCarWrittenOath(true);
    setCookie('checkCarWrittenOath', 'true');
    setOpen7(true);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };

  // const handleOpen8 = () => {
  //   setOpen8(true);
  // };
  const handleClose8 = () => {
    setOpen8(false);
  };

  const handleOpen9 = () => {
    setDrWrittenOath(true);
    setCookie('checkDrWrittenOath', 'true');
    setOpen9(true);
  };
  const handleClose9 = () => {
    setOpen9(false);
  };

  const handleOpen10 = () => {
    setDrConfirmingLetter(true);
    setCookie('checkDrConfirmingLetter', 'true');
    setOpen10(true);
  };
  const handleClose10 = () => {
    setOpen10(false);
  };

  const handleOpen11 = () => {
    setDrPersonalInformation(true);
    setCookie('checkDrPersonalInformation', 'true');
    setOpen11(true);
  };
  const handleClose11 = () => {
    setOpen11(false);
  };

  const handleStart = () => {
    setInputStart(!inputStart);
  };

  const handleNext = () => {
    if (
      activeStep === 1 &&
      (formState.values.writtenOath === '' ||
        formState.values.writtenOath === 'いいえ')
    ) {
      handleOpen2();
      return;
    }
    if (activeStep === 1) {
      // 必須チェック
      if (formState.values.emergencyLastname === '') {
        setErrMsg('『姓』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.emergencyFirstname === '') {
        setErrMsg('『名』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.emergencyRelationship === '') {
        setErrMsg('『続柄』が未選択です。');
        handleOpen3();
        return;
      } else if (
        formState.values.emergencyRelationship === 'その他' ||
        formState.values.emergencyRelationship === 'その他()'
      ) {
        setErrMsg('『続柄（その他）』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.emergencyTelNumber.length < 10) {
        setErrMsg('『電話番号』が未入力です。');
        handleOpen3();
        return;
      }
    }
    if (activeStep === 0) {
      // 必須チェック
      if (formState.values.lastname === '') {
        setErrMsg('『姓』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.firstname === '') {
        setErrMsg('『名』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.lastnameRuby === '') {
        setErrMsg('『セイ』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.firstnameRuby === '') {
        setErrMsg('『メイ』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.birthday === '') {
        setErrMsg('『誕生日』が未選択です。');
        handleOpen3();
        return;
      } else if (formState.values.staffCodeDigit === '' && branch === 'cs') {
        setErrMsg('『スタッフコード』が未選択です。');
        handleOpen3();
        return;
      } else if (
        (formState.values.staffCodeDigit === '6桁' &&
          formState.values.staffCode.length !== 6 &&
          branch === 'cs') ||
        (formState.values.staffCodeDigit === '8桁' &&
          formState.values.staffCode.length !== 8 &&
          branch === 'cs') ||
        (formState.values.staffCode.length !== 8 && !(branch === 'cs'))
      ) {
        setErrMsg('『スタッフコード』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.gender === '') {
        setErrMsg('『性別』が未選択です。');
        handleOpen3();
        return;
      } else if (formState.values.startDate === '') {
        setErrMsg('『就業開始日』が未選択です。');
        handleOpen3();
        return;
      } else if (formState.values.branch === '') {
        setErrMsg('『支店』が未選択です。');
        handleOpen3();
        return;
      } else if (
        !(branch === 'cs') &&
        (formState.values.branch === '新宿CS支店' ||
          formState.values.branch === 'エンジニア事業部 北海道' ||
          formState.values.branch === 'エンジニア事業部 仙台' ||
          formState.values.branch === 'エンジニア事業部 新宿' ||
          formState.values.branch === 'エンジニア事業部 東海' ||
          formState.values.branch === 'エンジニア事業部 関西' ||
          formState.values.branch === 'エンジニア事業部 広島' ||
          formState.values.branch === 'エンジニア事業部 九州')
      ) {
        setErrMsg('『支店』が未選択です。');
        handleOpen3();
        return;
      } else if (
        branch === 'cs' &&
        !(
          formState.values.branch === '新宿CS支店' ||
          formState.values.branch === 'エンジニア事業部 北海道' ||
          formState.values.branch === 'エンジニア事業部 仙台' ||
          formState.values.branch === 'エンジニア事業部 新宿' ||
          formState.values.branch === 'エンジニア事業部 東海' ||
          formState.values.branch === 'エンジニア事業部 関西' ||
          formState.values.branch === 'エンジニア事業部 広島' ||
          formState.values.branch === 'エンジニア事業部 九州'
        )
      ) {
        setErrMsg('『支店』が未選択です。');
        handleOpen3();
        return;
      } else if (formState.values.postCode1 === '') {
        setErrMsg('『郵便番号（現住所）』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.prefecture1 === '') {
        setErrMsg('『都道府県（現住所）』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.municipalities1 === '') {
        setErrMsg('『市区町村（現住所）』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.townArea1 === '') {
        setErrMsg('『町域・番地（現住所）』が未入力です。');
        handleOpen3();
        return;
      } else if (formState.values.registrationAddress === '現住所と異なる') {
        // 申告者の現住所と住民票住所が異なる場合
        if (formState.values.postCode2 === '') {
          setErrMsg('『郵便番号（住民票）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.prefecture2 === '') {
          setErrMsg('『都道府県（住民票）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.municipalities2 === '') {
          setErrMsg('『市区町村（住民票）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.townArea2 === '') {
          setErrMsg('『町域・番地（住民票）』が未入力です。');
          handleOpen3();
          return;
        }
      }
    }
    if (activeStep === 2) {
      // 乙欄申告を行わない場合
      if (formState.values.columnBDeclaration === '申告しない') {
        // 申告者が障害者の場合
        if (formState.values.handicapped !== '該当なし') {
          if (formState.values.handicappedDetail === '') {
            setErrMsg('申告者の『障害内容』が未選択です。');
            handleOpen3();
            return;
          } else if (
            formState.values.handicappedDetail !== 'その他' &&
            formState.values.handicappedDegree === ''
          ) {
            setErrMsg('申告者の『障害の程度』が未選択です。');
            handleOpen3();
            return;
          } else if (
            formState.values.handicappedDetail !== 'その他' &&
            formState.values.deliveryDate === ''
          ) {
            setErrMsg('申告者の『交付日』が未選択です。');
            handleOpen3();
            return;
          } else if (
            formState.values.handicappedDetail === 'その他' &&
            formState.values.handicappedOther === ''
          ) {
            setErrMsg('申告者の『障害（その他）の詳細』が未入力です。');
            handleOpen3();
            return;
          }
        }
        // 申告者が勤労学生の場合
        if (formState.values.workingStudent !== '該当なし') {
          if (formState.values.schoolLabel === '') {
            setErrMsg('『学校種別』が未選択です。');
            handleOpen3();
            return;
          } else if (formState.values.schoolName === '') {
            setErrMsg('『学校名』が未入力です。');
            handleOpen3();
            return;
          } else if (formState.values.admissionDate === '') {
            setErrMsg('『入学年月日』が未入力です。');
            handleOpen3();
            return;
          }
        }
        // 世帯主が申告者と異なる場合
        if (formState.values.household === '申告者と異なる') {
          if (formState.values.lastname3 === '') {
            setErrMsg('世帯主の『姓』が未入力です。');
            handleOpen3();
            return;
          } else if (formState.values.firstname3 === '') {
            setErrMsg('世帯主の『名』が未入力です。');
            handleOpen3();
            return;
          } else if (formState.values.relationship3 === '') {
            setErrMsg('世帯主の『続柄』が未選択です。');
            handleOpen3();
            return;
          } else if (
            formState.values.relationship3 === 'その他' ||
            formState.values.relationship3 === 'その他()'
          ) {
            setErrMsg('世帯主の『続柄（その他）』が未入力です。');
            handleOpen3();
            return;
          }
        }
        // パートナーありの場合
        if (formState.values.marriage === 'はい') {
          if (formState.values.dependentDeclaration === '') {
            setErrMsg('『配偶者の控除申告』が未選択です。');
            handleOpen3();
            return;
          } else if (formState.values.dependentDeclaration === 'はい') {
            if (formState.values.partnerLastname === '') {
              setErrMsg('配偶者の『姓』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerFirstname === '') {
              setErrMsg('配偶者の『名』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerLastnameRuby === '') {
              setErrMsg('配偶者の『セイ』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerFirstnameRuby === '') {
              setErrMsg('配偶者の『メイ』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerBirthday === '') {
              setErrMsg('配偶者の『誕生日』が未選択です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerGender === '') {
              setErrMsg('配偶者の『性別』が未選択です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerSalary === '') {
              setErrMsg('配偶者の『給与』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerPension === '') {
              setErrMsg('配偶者の『老齢年金』が未入力です。');
              handleOpen3();
              return;
            } else if (formState.values.partnerLiving === '別居') {
              if (formState.values.partnerPostCode === '') {
                setErrMsg('配偶者の『郵便番号』が未入力です。');
                handleOpen3();
                return;
              } else if (formState.values.partnerPrefecture === '') {
                setErrMsg('配偶者の『都道府県』が未入力です。');
                handleOpen3();
                return;
              } else if (formState.values.partnerMunicipalities === '') {
                setErrMsg('配偶者の『市区町村』が未入力です。');
                handleOpen3();
                return;
              } else if (formState.values.partnerTownArea === '') {
                setErrMsg('配偶者の『町域・番地』が未入力です。');
                handleOpen3();
                return;
              }
            } else if (formState.values.partnerHandicapped !== '該当なし') {
              // パートナーが障害者の場合
              if (formState.values.partnerHandicappedDetail === '') {
                setErrMsg('配偶者の『障害内容』が未選択です。');
                handleOpen3();
                return;
              } else if (
                formState.values.partnerHandicappedDetail !== 'その他' &&
                formState.values.partnerHandicappedDegree === ''
              ) {
                setErrMsg('配偶者の『障害の程度』が未選択です。');
                handleOpen3();
                return;
              } else if (
                formState.values.partnerHandicappedDetail !== 'その他' &&
                formState.values.partnerDeliveryDate === ''
              ) {
                setErrMsg('配偶者の『交付日』が未選択です。');
                handleOpen3();
                return;
              } else if (
                formState.values.partnerHandicappedDetail === 'その他' &&
                formState.values.partnerHandicappedOther === ''
              ) {
                setErrMsg('配偶者の『障害（その他）の詳細』が未入力です。');
                handleOpen3();
                return;
              }
            }
          }
        }
        // ひとり親または寡婦の場合
        if (formState.values.marriage === 'いいえ') {
          if (formState.values.situationSelection === '') {
            setErrMsg('『寡婦/ひとり親 状況選択』が未選択です。');
            handleOpen3();
            return;
          }

          if (count === 0) {
            if (
              formState.values.situationSelection ===
                '夫と離婚または死別後、婚姻しておらず、子以外の親族を扶養申告する（寡婦）' ||
              formState.values.situationSelection ===
                '現在婚姻しておらず、子を扶養申告する（ひとり親）'
            ) {
              setErrMsg('『扶養親族』を追加してください。');
              handleOpen3();
              return;
            }
          }
          // ひとり親の場合
          // if (formState.values.singleParentSituation === 'ひとり親') {
          //   if (formState.values.classification === '') {
          //     setErrMsg('『ひとり親/寡婦状況』が未選択です。');
          //     handleOpen3();
          //     return;
          //   } else if (formState.values.haveDependents === '') {
          //     setErrMsg('『扶養対象の有無』が未選択です。');
          //     handleOpen3();
          //     return;
          //   }
          // }
          // 寡婦の場合
          // if (formState.values.singleParentSituation === '寡婦') {
          //   if (formState.values.classification === '') {
          //     setErrMsg('『ひとり親/寡婦状況』が未選択です。');
          //     handleOpen3();
          //     return;
          //   } else if (
          //     formState.values.classification ===
          //     `${
          //       formState.values.gender === '男性' ? '妻' : '夫'
          //     }と離婚した後、婚姻していない`
          //   ) {
          //     if (formState.values.haveDependents === '') {
          //       setErrMsg('『扶養対象の有無』が未選択です。');
          //       handleOpen3();
          //       return;
          //     }
          //   } else if (formState.values.classification === '結婚歴がない') {
          //     setErrMsg(
          //       `寡婦の場合『ひとり親/寡婦状況』で'結婚歴がない'は選択できません。`,
          //     );
          //     handleOpen3();
          //     return;
          //   }
          // }
          // if (formState.values.haveDependents === '有り' && count === 0) {
          //   setErrMsg('『扶養親族』を追加してください。');
          //   handleOpen3();
          //   return;
          // }
        }
        // 扶養親族がいる場合
        if (count !== 0) {
          for (let cnt = 1; cnt < count + 1; cnt++) {
            const relativesLastname = handleValue(`relativesLastname${cnt}`);
            const relativesLastnameRuby = handleValue(
              `relativesLastnameRuby${cnt}`,
            );
            const relativesFirstname = handleValue(`relativesFirstname${cnt}`);
            const relativesFirstnameRuby = handleValue(
              `relativesFirstnameRuby${cnt}`,
            );
            const relativesBirthday = handleValue(`relativesBirthday${cnt}`);
            const relativesGender = handleValue(`relativesGender${cnt}`);
            const relativesRelationship = handleValue(
              `relativesRelationship${cnt}`,
            );
            const relativesSalary = handleValue(`relativesSalary${cnt}`);
            const relativesPension = handleValue(`relativesPension${cnt}`);
            const relativesLiving = handleValue(`relativesLiving${cnt}`);
            const relativesPostCode = handleValue(`relativesPostCode${cnt}`);
            const relativesPrefecture = handleValue(
              `relativesPrefecture${cnt}`,
            );
            const relativesMunicipalities = handleValue(
              `relativesMunicipalities${cnt}`,
            );
            const relativesTownArea = handleValue(`relativesTownArea${cnt}`);
            const relativesHandicapped = handleValue(
              `relativesHandicapped${cnt}`,
            );
            const relativesHandicappedDetail = handleValue(
              `relativesHandicappedDetail${cnt}`,
            );
            // const relativesHandicappedDegree = handleValue(
            //   `relativesHandicappedDegree${cnt}`,
            // );
            const relativesHandicappedOther = handleValue(
              `relativesHandicappedOther${cnt}`,
            );
            const relativesDeliveryDate = handleValue(
              `relativesDeliveryDate${cnt}`,
            );
            // 扶養親族の必須情報
            if (!relativesLastname) {
              setErrMsg(`扶養親族${cnt}の『姓』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesFirstname) {
              setErrMsg(`扶養親族${cnt}の『名』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesLastnameRuby) {
              setErrMsg(`扶養親族${cnt}の『セイ』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesFirstnameRuby) {
              setErrMsg(`扶養親族${cnt}の『メイ』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesBirthday) {
              setErrMsg(`扶養親族${cnt}の『誕生日』が未選択です。`);
              handleOpen3();
              return;
            } else if (!relativesGender) {
              setErrMsg(`扶養親族${cnt}の『性別』が未選択です。`);
              handleOpen3();
              return;
            } else if (!relativesRelationship) {
              setErrMsg(`扶養親族${cnt}の『続柄』が未選択です。`);
              handleOpen3();
              return;
            } else if (
              relativesRelationship === 'その他' ||
              relativesRelationship === 'その他()'
            ) {
              setErrMsg(`扶養親族${cnt}の『続柄（その他）』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesSalary) {
              setErrMsg(`扶養親族${cnt}の『給与』が未入力です。`);
              handleOpen3();
              return;
            } else if (!relativesPension) {
              setErrMsg(`扶養親族${cnt}の『老齢年金』が未入力です。`);
              handleOpen3();
              return;
            } else if (relativesLiving === '別居') {
              if (!relativesPostCode) {
                setErrMsg(`扶養親族${cnt}の『郵便番号』が未入力です。`);
                handleOpen3();
                return;
              } else if (!relativesPrefecture) {
                setErrMsg(`扶養親族${cnt}の『都道府県』が未入力です。`);
                handleOpen3();
                return;
              } else if (!relativesMunicipalities) {
                setErrMsg(`扶養親族${cnt}の『市区町村』が未入力です。`);
                handleOpen3();
                return;
              } else if (!relativesTownArea) {
                setErrMsg(`扶養親族${cnt}の『町域・番地』が未入力です。`);
                handleOpen3();
                return;
              }
            }
            // 配偶者が障害者の場合
            if (relativesHandicapped !== '該当なし') {
              if (!relativesHandicappedDetail) {
                setErrMsg(`扶養親族${cnt}の『障害内容』が未選択です。`);
                handleOpen3();
                return;
              } else if (
                relativesHandicappedDetail !== 'その他' &&
                relativesHandicappedDetail === ''
              ) {
                setErrMsg(`扶養親族${cnt}の『障害の程度』が未選択です。`);
                handleOpen3();
                return;
              } else if (
                relativesHandicappedDetail !== 'その他' &&
                relativesDeliveryDate === ''
              ) {
                setErrMsg(`扶養親族${cnt}の『交付日』が未選択です。`);
                handleOpen3();
                return;
              } else if (
                relativesHandicappedDetail === 'その他' &&
                relativesHandicappedOther === ''
              ) {
                setErrMsg(
                  `扶養親族${cnt}の『障害（その他）の詳細』が未入力です。`,
                );
                handleOpen3();
                return;
              }
            }
          }
        }
      }
    }
    if (activeStep === 3) {
      //車輌通勤を申請する場合
      if (formState.values.carsForCommuting === '申請する') {
        if (formState.values.commuteStartDay === '') {
          setErrMsg('『通勤開始日』が未選択です。');
          handleOpen3();
          return;
        } else if (formState.values.carNumberS1 === '') {
          setErrMsg('『車輌番号（地域名）』が未入力です。');
          handleOpen3();
          return;
        } else if (
          (formState.values.carsType === '自家用車' &&
            formState.values.carNumberS2 === '') ||
          !String(formState.values.carNumberS2).match(/^[A-Za-z0-9]*$/) ||
          (formState.values.carsType === 'バイク' &&
            String(formState.values.carNumberS2).length > 1) ||
          (formState.values.carsType === '自家用車' &&
            String(cookies.carNumberS2).length < 2)
        ) {
          setErrMsg('『車輌番号（分類番号）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.carNumberS3 === '') {
          setErrMsg('『車輌番号（ひらがな）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.carNumberS4 === '') {
          setErrMsg('『車輌番号（連番）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.carNumberS5 === '') {
          setErrMsg('『車輌番号（連番）』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.carName === '') {
          setErrMsg('『車名』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.carColor === '') {
          setErrMsg('『車の色』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.applicationDistinction === '') {
          setErrMsg('『申請区分（車輌通勤）』が未選択です。');
          handleOpen3();
          return;
        } else if (formState.values.carsType === '') {
          setErrMsg('『車輌種別』が未選択です。');
          handleOpen3();
          return;
        } else if (formState.values.diligenceName === '') {
          setErrMsg('『勤務先名』が未入力です。');
          handleOpen3();
          return;
        } else if (formState.values.writtenOathCarsCommute === 'いいえ') {
          handleOpen2();
          return;
        }
        formState.values.carNumber = `${formState.values.carNumberS4}-${formState.values.carNumberS5}`;
      }
    }

    //研修のチェック
    if (
      activeStep === 4 &&
      (formState.values.training1 === 'non' ||
        formState.values.training2 === 'non' ||
        formState.values.training3 === 'non' ||
        formState.values.training4 === 'non' ||
        formState.values.training5 === 'non' ||
        formState.values.training6 === 'non' ||
        formState.values.training7 === 'non' ||
        formState.values.training8 === 'non' ||
        formState.values.training9 === 'non' ||
        formState.values.training10 === 'non' ||
        formState.values.training11 === 'non' ||
        formState.values.training12 === 'non' ||
        formState.values.training13 === 'non' ||
        formState.values.training14 === 'non' ||
        formState.values.training15 === 'non' ||
        formState.values.training16 === 'non' ||
        formState.values.training17 === 'non' ||
        formState.values.training18 === 'non' ||
        formState.values.training19 === 'non' ||
        formState.values.training20 === 'non' ||
        formState.values.training21 === 'non')
    ) {
      setErrMsg('未確認の項目があります。');
      handleOpen3();
      return;
    }
    if (
      activeStep === 4 &&
      formState.values.training1 === 'ok' &&
      formState.values.training2 === 'ok' &&
      formState.values.training3 === 'ok' &&
      formState.values.training4 === 'ok' &&
      formState.values.training5 === 'ok' &&
      formState.values.training6 === 'ok' &&
      formState.values.training7 === 'ok' &&
      formState.values.training8 === 'ok' &&
      formState.values.training9 === 'ok' &&
      formState.values.training10 === 'ok' &&
      formState.values.training11 === 'ok' &&
      formState.values.training12 === 'ok' &&
      formState.values.training13 === 'ok' &&
      formState.values.training14 === 'ok' &&
      formState.values.training15 === 'ok' &&
      formState.values.training16 === 'ok' &&
      formState.values.training17 === 'ok' &&
      formState.values.training18 === 'ok' &&
      formState.values.training19 === 'ok' &&
      formState.values.training20 === 'ok' &&
      formState.values.training21 === 'ok'
    ) {
      formState.values.training = '研修済み';
      setCookie('training', '研修済み');
    }

    //DRの同意チェック
    if (
      activeStep === 5 &&
      DR &&
      formState.values.drNotDrive === '運転する' &&
      (formState.values.drConfirmingLetter === '同意しない' ||
        formState.values.drConfirmingLetter === null ||
        formState.values.drWrittenOath === '同意しない' ||
        formState.values.drWrittenOath === null)
    ) {
      setErrMsg('未確認の項目があります。');
      handleOpen3();
      return;
    }
    console.log(formState.values.drNotProvided);
    console.log(formState.values.drPersonalInformation);

    if (
      activeStep === 5 &&
      DR &&
      formState.values.drNotProvided === '提供する' &&
      (formState.values.drPersonalInformation === '同意しない' ||
        formState.values.drPersonalInformation === null)
    ) {
      setErrMsg('未確認の項目があります。');
      handleOpen3();
      return;
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });
  };

  const handleSearch = (event: any, key: string) => {
    let postCode = '';
    let prefecture = '';
    let municipalities = '';
    let town = '';
    let townArea = '';
    switch (key) {
      case '1':
        postCode = formState.values.postCode1;
        prefecture = 'prefecture1';
        municipalities = 'municipalities1';
        town = 'townArea1';
        break;
      case '2':
        postCode = formState.values.postCode2;
        prefecture = 'prefecture2';
        municipalities = 'municipalities2';
        town = 'townArea2';
        break;
      case 'partner':
        postCode = formState.values.partnerPostCode;
        prefecture = 'partnerPrefecture';
        municipalities = 'partnerMunicipalities';
        town = 'partnerTownArea';
        break;
      case 'relatives1':
        postCode = formState.values.relativesPostCode1;
        prefecture = 'relativesPrefecture1';
        municipalities = 'relativesMunicipalities1';
        town = 'relativesTownArea1';
        break;
      case 'relatives2':
        postCode = formState.values.relativesPostCode2;
        prefecture = 'relativesPrefecture2';
        municipalities = 'relativesMunicipalities2';
        town = 'relativesTownArea2';
        break;
      case 'relatives3':
        postCode = formState.values.relativesPostCode3;
        prefecture = 'relativesPrefecture3';
        municipalities = 'relativesMunicipalities3';
        town = 'relativesTownArea3';
        break;
      case 'relatives4':
        postCode = formState.values.relativesPostCode4;
        prefecture = 'relativesPrefecture4';
        municipalities = 'relativesMunicipalities4';
        town = 'relativesTownArea4';
        break;
      case 'relatives5':
        postCode = formState.values.relativesPostCode5;
        prefecture = 'relativesPrefecture5';
        municipalities = 'relativesMunicipalities5';
        town = 'relativesTownArea5';
        break;
      case 'relatives6':
        postCode = formState.values.relativesPostCode6;
        prefecture = 'relativesPrefecture6';
        municipalities = 'relativesMunicipalities6';
        town = 'relativesTownArea6';
        break;
      case 'relatives7':
        postCode = formState.values.relativesPostCode7;
        prefecture = 'relativesPrefecture7';
        municipalities = 'relativesMunicipalities7';
        town = 'relativesTownArea7';
        break;
      case 'relatives8':
        postCode = formState.values.relativesPostCode8;
        prefecture = 'relativesPrefecture8';
        municipalities = 'relativesMunicipalities8';
        town = 'relativesTownArea8';
        break;
      case 'relatives9':
        postCode = formState.values.relativesPostCode9;
        prefecture = 'relativesPrefecture9';
        municipalities = 'relativesMunicipalities9';
        town = 'relativesTownArea9';
        break;
      case 'relatives10':
        postCode = formState.values.relativesPostCode10;
        prefecture = 'relativesPrefecture10';
        municipalities = 'relativesMunicipalities10';
        town = 'relativesTownArea10';
        break;
    }
    if (!postCode || postCode.length !== 7) {
      return;
    }
    const result = dispatch(addressSearch(postCode));
    result.then((extract) =>
      handleChange(event, prefecture, extract.payload.address[0].prefecture),
    );
    result.then((extract) =>
      handleChange(event, municipalities, extract.payload.address[0].city),
    );
    result
      .then(
        (extract) => (
          (townArea += extract.payload.address[0].town || ''),
          (townArea += extract.payload.address[0].house_number || '')
        ),
      )
      .then(() => handleChange(event, town, townArea));
  };

  const changeNumber = (value: string) => {
    return value
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (ch) =>
        String.fromCharCode(ch.charCodeAt(0) - 0xfee0),
      )
      .replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-');
  };

  const handleValue = (value: any) => {
    const key: keyof formName = value;
    return formState.values[key];
  };

  const handleValueClear = (value: any) => {
    const key: keyof formName = value;
    formState.values[key] = '';
  };

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const doubled = numbers.map((number) => {
    return (
      <Accordion
        sx={{ boxShadow: 'none' }}
        expanded={expanded === 'panel' + number}
        onChange={handleAccordion('panel' + number)}
        key={number}
      >
        <AccordionSummary
          sx={{
            width: '100%',
            border: 'solid 0.5px #e6e6e6',
            backgroundColor: '#ffffff',
          }}
          aria-controls={`panel${number}d-content`}
          id={`panel${number}d-header`}
        >
          <Typography>扶養親族{number}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div>
              <TextField
                id="standard-basic"
                label="姓"
                variant="standard"
                value={handleValue(`relativesLastname${number}`)}
                error={handleValue(`relativesLastname${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesLastname${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="山田"
                helperText={
                  handleValue(`relativesLastname${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
              <TextField
                id="standard-basic"
                label="名"
                variant="standard"
                value={handleValue(`relativesFirstname${number}`)}
                error={handleValue(`relativesFirstname${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesFirstname${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="太郎"
                helperText={
                  handleValue(`relativesFirstname${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
            </div>
            <div>
              <TextField
                id="standard-basic"
                label="セイ"
                variant="standard"
                value={handleValue(`relativesLastnameRuby${number}`)}
                error={handleValue(`relativesLastnameRuby${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesLastnameRuby${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="ﾔﾏﾀﾞ"
                helperText={
                  handleValue(`relativesLastnameRuby${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
              <TextField
                id="standard-basic"
                label="メイ"
                variant="standard"
                value={handleValue(`relativesFirstnameRuby${number}`)}
                error={handleValue(`relativesFirstnameRuby${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesFirstnameRuby${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="ﾀﾛｳ"
                helperText={
                  handleValue(`relativesFirstnameRuby${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
            </div>
            <div>
              {/*<TextField*/}
              {/*  id="birthday"*/}
              {/*  variant="standard"*/}
              {/*  className={'birthday-field'}*/}
              {/*  label="生年月日"*/}
              {/*  type="date"*/}
              {/*  value={handleValue(`relativesBirthday${number}`)}*/}
              {/*  error={handleValue(`relativesBirthday${number}`) === ''}*/}
              {/*  onChange={(event) => {*/}
              {/*    console.log(event.target.value);*/}
              {/*    const targetDate = moment(event.target.value);*/}
              {/*    if (targetDate.isBefore(moment())) {*/}
              {/*      handleChange(*/}
              {/*        event,*/}
              {/*        `relativesBirthday${number}`,*/}
              {/*        event.target.value,*/}
              {/*      );*/}
              {/*    } else {*/}
              {/*      handleOpen8();*/}
              {/*      handleChange(event, `relativesBirthday${number}`, '');*/}
              {/*    }*/}
              {/*  }}*/}
              {/*  sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}*/}
              {/*  InputLabelProps={{*/}
              {/*    shrink: true,*/}
              {/*  }}*/}
              {/*  helperText={*/}
              {/*    handleValue(`relativesBirthday${number}`) === ''*/}
              {/*      ? '必須項目'*/}
              {/*      : false*/}
              {/*  }*/}
              {/*/>*/}
              <MobileDatePicker
                label="生年月日"
                inputFormat="yyyy/M/d"
                toolbarFormat="yyyy/M/d"
                value={handleValue(`relativesBirthday${number}`) || '2000/1/1'}
                maxDate={new Date()}
                onChange={(date) => {
                  console.log(date);
                  handleChange(
                    {
                      target: {
                        name: `relativesBirthday${number}`,
                      },
                    },
                    `relativesBirthday${number}`,
                    moment(date).format('YYYY-MM-DD'),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!handleValue(`relativesBirthday${number}`)}
                    helperText={
                      !handleValue(`relativesBirthday${number}`)
                        ? '必須項目'
                        : false
                    }
                    {...params}
                  />
                )}
              />
            </div>
            <div>
              <FormControl
                sx={{ mt: 2, width: '80%' }}
                error={!handleValue(`relativesGender${number}`)}
              >
                <FormLabel
                  id="gendar-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  性別
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={handleValue(`relativesGender${number}`)}
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(
                      event,
                      `relativesGender${number}`,
                      event.target.value,
                    );
                  }}
                >
                  <FormControlLabel
                    value="女性"
                    control={<Radio />}
                    label="女性"
                  />
                  <FormControlLabel
                    value="男性"
                    control={<Radio />}
                    label="男性"
                  />
                </RadioGroup>
                <FormHelperText
                  style={{
                    display: !handleValue(`relativesGender${number}`)
                      ? ''
                      : 'none',
                  }}
                >
                  必須項目
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <InputLabel
                sx={{ mt: 2, color: '#0f1941', fontSize: '0.8em' }}
                id="relationship-label"
              >
                {'続柄'}
              </InputLabel>
              <FormControl
                sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}
                error={handleValue(`relativesRelationship${number}`) === ''}
              >
                <Select
                  labelId="relationship-label"
                  id="relationship-select"
                  variant="standard"
                  value={
                    String(
                      handleValue(`relativesRelationship${number}`),
                    ).substring(0, 3) === 'その他'
                      ? 'その他'
                      : handleValue(`relativesRelationship${number}`)
                  }
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(
                      event,
                      `relativesRelationship${number}`,
                      event.target.value,
                    );
                  }}
                >
                  <MenuItem value={''} />
                  <MenuItem value={'夫'}>夫</MenuItem>
                  <MenuItem value={'妻'}>妻</MenuItem>
                  <MenuItem value={'長男'}>長男</MenuItem>
                  <MenuItem value={'次男'}>次男</MenuItem>
                  <MenuItem value={'三男'}>三男</MenuItem>
                  <MenuItem value={'長女'}>長女</MenuItem>
                  <MenuItem value={'次女'}>次女</MenuItem>
                  <MenuItem value={'三女'}>三女</MenuItem>
                  <MenuItem value={'四男'}>四男</MenuItem>
                  <MenuItem value={'四女'}>四女</MenuItem>
                  <MenuItem value={'五男'}>五男</MenuItem>
                  <MenuItem value={'五女'}>五女</MenuItem>
                  <MenuItem value={'六男'}>六女</MenuItem>
                  <MenuItem value={'子'}>子</MenuItem>
                  <MenuItem value={'父'}>父</MenuItem>
                  <MenuItem value={'母'}>母</MenuItem>
                  <MenuItem value={'義父'}>義父</MenuItem>
                  <MenuItem value={'義母'}>義母</MenuItem>
                  <MenuItem value={'兄'}>兄</MenuItem>
                  <MenuItem value={'姉'}>姉</MenuItem>
                  <MenuItem value={'弟'}>弟</MenuItem>
                  <MenuItem value={'妹'}>妹</MenuItem>
                  <MenuItem value={'祖父'}>祖父</MenuItem>
                  <MenuItem value={'祖母'}>祖母</MenuItem>
                  <MenuItem value={'曾祖父'}>曾祖父</MenuItem>
                  <MenuItem value={'甥'}>甥</MenuItem>
                  <MenuItem value={'姪'}>姪</MenuItem>
                  <MenuItem value={'叔父'}>叔父</MenuItem>
                  <MenuItem value={'叔母'}>叔母</MenuItem>
                  <MenuItem value={'伯父'}>伯父</MenuItem>
                  <MenuItem value={'伯母'}>伯母</MenuItem>
                  <MenuItem value={'孫'}>孫</MenuItem>
                  <MenuItem value={'曾孫'}>曾孫</MenuItem>
                  <MenuItem value={'いとこ'}>いとこ</MenuItem>
                  <MenuItem value={'はとこ'}>はとこ</MenuItem>
                  <MenuItem value={'その他'}>その他</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    display:
                      handleValue(`relativesRelationship${number}`) === ''
                        ? ''
                        : 'none',
                  }}
                >
                  必須項目
                </FormHelperText>
              </FormControl>
            </div>
            <div
              style={{
                display:
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 3) === 'その他' ||
                  String(cookies[`relativesRelationship${number}`]).substring(
                    0,
                    3,
                  ) === 'その他'
                    ? ''
                    : 'none',
              }}
            >
              <TextField
                id="standard-basic"
                label="その他"
                variant="standard"
                value={
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 4) === 'その他('
                    ? String(
                        handleValue(`relativesRelationship${number}`),
                      ).substring(
                        4,
                        String(handleValue(`relativesRelationship${number}`))
                          .length - 1,
                      )
                    : ''
                }
                error={
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 5) === 'その他()' ||
                  String(cookies[`relativesRelationship${number}`]).substring(
                    0,
                    5,
                  ) === 'その他()' ||
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 5) === 'その他' ||
                  String(cookies[`relativesRelationship${number}`]).substring(
                    0,
                    5,
                  ) === 'その他'
                }
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesRelationship${number}`,
                    `その他(${event.target.value})`,
                  );
                }}
                sx={{ ml: 0, mr: 2, mb: 2, width: '50%' }}
                placeholder="友人"
                helperText={
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 5) === 'その他()' ||
                  String(cookies[`relativesRelationship${number}`]).substring(
                    0,
                    5,
                  ) === 'その他()' ||
                  String(
                    handleValue(`relativesRelationship${number}`),
                  ).substring(0, 5) === 'その他' ||
                  String(cookies[`relativesRelationship${number}`]).substring(
                    0,
                    5,
                  ) === 'その他'
                    ? '必須項目'
                    : false
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <InputLabel
              sx={{
                mt: 4,
                color: '#0f1941',
                fontSize: '1em',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'収入'}{' '}
              <InfoIcon
                sx={{ fontSize: 16, color: '#2175d9' }}
                onClick={(event) => {
                  setPopoverAnchorEl(event.currentTarget);
                  setPopover(true);
                  setPopoverContent(2);
                }}
              />
            </InputLabel>
            <div>
              <TextField
                id="salary"
                variant="standard"
                label="給与"
                type="tel"
                value={handleValue(`relativesSalary${number}`)}
                error={errRelativesSalary(
                  handleValue(`relativesSalary${number}`),
                )}
                inputProps={{ maxLength: '20' }}
                onChange={(event) => {
                  console.log(event.target.value);
                  const onlyNums = String(event.target.value).replace(
                    /[^0-9]/g,
                    '',
                  );
                  console.log(onlyNums);
                  handleChange(event, `relativesSalary${number}`, onlyNums);
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  errRelativesSalary(handleValue(`relativesSalary${number}`))
                    ? '給与収入見込額は0～1,030,000円の範囲で入力してください'
                    : false
                }
              />
            </div>
            <div>
              <TextField
                id="pension"
                variant="standard"
                label="老齢年金"
                type="tel"
                value={handleValue(`relativesPension${number}`)}
                error={errRelativesPension(
                  handleValue(`relativesPension${number}`),
                  handleValue(`relativesBirthday${number}`),
                )}
                inputProps={{ maxLength: '20' }}
                onChange={(event) => {
                  console.log(event.target.value);
                  const onlyNums = String(event.target.value).replace(
                    /[^0-9]/g,
                    '',
                  );
                  console.log(onlyNums);
                  handleChange(event, `relativesPension${number}`, onlyNums);
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  errRelativesPension(
                    handleValue(`relativesPension${number}`),
                    handleValue(`relativesBirthday${number}`),
                  )
                    ? `給与収入見込額は0～${relativesAge(
                        handleValue(`relativesBirthday${number}`),
                      )}円の範囲で入力してください`
                    : false
                }
              />
            </div>
            <div>
              <TextField
                id="otherIncome"
                variant="standard"
                label="その他収入"
                type="number"
                value={handleValue(`relativesOtherIncome${number}`)}
                inputProps={{ maxLength: '20' }}
                onChange={(event) => {
                  console.log(event.target.value);
                  handleChange(
                    event,
                    `relativesOtherIncome${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">円</InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <InputLabel sx={{ mt: 4, color: '#0f1941', fontSize: '1em' }}>
              {'住所'}
            </InputLabel>
            <InputLabel
              sx={{ mt: 4, color: '#0f1941', fontSize: '1em' }}
              id="living"
            >
              {`あなたは扶養親族${number}と同居していますか`}
            </InputLabel>
            <FormControl sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}>
              <Select
                labelId="living"
                id="living-select"
                variant="standard"
                value={handleValue(`relativesLiving${number}`)}
                onChange={(event) => {
                  console.log(event.target.value);
                  handleChange(
                    event,
                    `relativesLiving${number}`,
                    event.target.value,
                  );
                }}
              >
                <MenuItem value={'同居'}>同居</MenuItem>
                <MenuItem value={'別居'}>別居</MenuItem>
              </Select>
            </FormControl>
            <Box
              sx={{
                width: '90%',
                ml: 0,
                mb: 0,
                fontSize: '0.8em',
                fontWeight: 'bold',
                color: '#e74536',
                display:
                  handleValue(`relativesLiving${number}`) === '別居'
                    ? ''
                    : 'none',
              }}
            >
              {
                '※日本国外に1年以上住んでいる扶養親族の登録については別途確認書類が必要なため、ここでの登録ができません。一旦、扶養なしで登録していただき、担当コンサルタントへご連絡ください。'
              }
            </Box>
            <div
              style={{
                display:
                  handleValue(`relativesLiving${number}`) === '同居'
                    ? 'none'
                    : '',
              }}
            >
              <div>
                <TextField
                  id="post-code"
                  variant="standard"
                  label={'郵便番号'}
                  type="tel"
                  placeholder="0000000"
                  value={handleValue(`relativesPostCode${number}`)}
                  error={handleValue(`relativesPostCode${number}`) === ''}
                  inputProps={{ maxLength: '7' }}
                  onChange={(event) => {
                    console.log(event.target.value);
                    const onlyNums = String(event.target.value).replace(
                      /[^0-9]/g,
                      '',
                    );
                    console.log(onlyNums);
                    handleChange(event, `relativesPostCode${number}`, onlyNums);
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    handleValue(`relativesPostCode${number}`) === ''
                      ? '必須項目'
                      : false
                  }
                />
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mb: '2em', mt: '2em' }}
                  onClick={(event) => handleSearch(event, `relatives${number}`)}
                >
                  住所検索
                </Button>
              </div>
              <div>
                <TextField
                  id="prefectures"
                  label={'都道府県'}
                  variant="standard"
                  value={handleValue(`relativesPrefecture${number}`)}
                  error={handleValue(`relativesPrefecture${number}`) === ''}
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesPrefecture${number}`,
                      event.target.value,
                    );
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                  placeholder={'東京都'}
                  helperText={
                    handleValue(`relativesPrefecture${number}`) === ''
                      ? '必須項目'
                      : false
                  }
                />
              </div>
              <div>
                <TextField
                  id="municipalities"
                  label={'市区町村'}
                  variant="standard"
                  value={handleValue(`relativesMunicipalities${number}`)}
                  error={handleValue(`relativesMunicipalities${number}`) === ''}
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesMunicipalities${number}`,
                      event.target.value,
                    );
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                  placeholder={'中央区'}
                  helperText={
                    handleValue(`relativesMunicipalities${number}`) === ''
                      ? '必須項目'
                      : false
                  }
                />
              </div>
              <div>
                <TextField
                  id="town-area"
                  label={'町域・番地'}
                  variant="standard"
                  value={handleValue(`relativesTownArea${number}`)}
                  error={handleValue(`relativesTownArea${number}`) === ''}
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesTownArea${number}`,
                      event.target.value,
                    );
                  }}
                  onBlur={(event) => {
                    handleChange(
                      event,
                      `relativesTownArea${number}`,
                      changeNumber(handleValue(`relativesTownArea${number}`)),
                    );
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                  placeholder={'日本橋３丁目６ー２'}
                  helperText={
                    handleValue(`relativesTownArea${number}`) === ''
                      ? '必須項目'
                      : false
                  }
                />
              </div>
              <div>
                <TextField
                  id="other-address"
                  label={'建物名など'}
                  variant="standard"
                  value={handleValue(`relativesOtherAddress${number}`)}
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesOtherAddress${number}`,
                      event.target.value,
                    );
                  }}
                  onBlur={(event) => {
                    handleChange(
                      event,
                      `relativesOtherAddress${number}`,
                      changeNumber(
                        handleValue(`relativesOtherAddress${number}`),
                      ),
                    );
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                  placeholder={'日本橋フロント　４階'}
                />
              </div>
            </div>
            <div>
              <InputLabel
                sx={{
                  mt: 4,
                  color: '#0f1941',
                  fontSize: '1em',
                }}
                id="handicapped-label"
              >
                {'障害者区分'}
              </InputLabel>
              <FormControl sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}>
                <Select
                  labelId="handicapped-label"
                  id="handicapped-select"
                  variant="standard"
                  value={
                    handleValue(`relativesHandicapped${number}`) || '該当なし'
                  }
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(
                      event,
                      `relativesHandicapped${number}`,
                      event.target.value,
                    );
                  }}
                >
                  <MenuItem value={'該当なし'}>該当なし</MenuItem>
                  <MenuItem value={'該当する'}>該当する</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display:
                  handleValue(`relativesHandicapped${number}`) !== '該当なし'
                    ? ''
                    : 'none',
              }}
            >
              <div>
                <InputLabel
                  sx={{
                    mt: 4,
                    color: '#0f1941',
                    fontSize: '1em',
                  }}
                  id="handicapped-detail-label"
                >
                  {'障害内容'}
                </InputLabel>
                <FormControl
                  sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}
                  error={
                    handleValue(`relativesHandicappedDetail${number}`) === ''
                  }
                >
                  <Select
                    labelId="handicapped-detail-label"
                    id="handicapped-detail-select"
                    variant="standard"
                    value={handleValue(`relativesHandicappedDetail${number}`)}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(
                        event,
                        `relativesHandicappedDetail${number}`,
                        event.target.value,
                      );

                      handleChange(
                        event,
                        `relativesHandicappedDegree${number}`,
                        '',
                      );
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem value={'身体障害'}>身体障害</MenuItem>
                    <MenuItem value={'精神障害'}>精神障害</MenuItem>
                    <MenuItem value={'知的障害'}>知的障害</MenuItem>
                    <MenuItem value={'その他'}>その他</MenuItem>
                  </Select>
                  <FormHelperText
                    style={{
                      display:
                        handleValue(`relativesHandicappedDetail${number}`) ===
                        ''
                          ? ''
                          : 'none',
                    }}
                  >
                    必須項目
                  </FormHelperText>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    handleValue(`relativesHandicappedDetail${number}`) ===
                    'その他'
                      ? ''
                      : 'none',
                }}
              >
                <TextField
                  id="handicapped-other"
                  label={'障害内容「その他」の詳細'}
                  variant="standard"
                  value={handleValue(`relativesHandicappedOther${number}`)}
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesHandicappedOther${number}`,
                      event.target.value,
                    );
                  }}
                  onBlur={(event) => {
                    handleChange(
                      event,
                      `relativesHandicappedOther${number}`,
                      changeNumber(
                        handleValue(`relativesHandicappedOther${number}`),
                      ),
                    );
                  }}
                  sx={{ mt: 2, mr: 2, mb: 2, width: '90%' }}
                  placeholder={'記述してください'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    handleValue(`relativesHandicappedOther${number}`) === ''
                  }
                  helperText={
                    handleValue(`relativesHandicappedOther${number}`) === ''
                      ? '必須項目'
                      : false
                  }
                />
              </div>
              <div
                style={{
                  display:
                    handleValue(`relativesHandicappedDetail${number}`) ===
                    'その他'
                      ? 'none'
                      : '',
                }}
              >
                <InputLabel
                  sx={{
                    mt: 4,
                    color: '#0f1941',
                    fontSize: '1em',
                  }}
                  id="handicapped-degree-label"
                >
                  {'障害の程度'}
                </InputLabel>
                <FormControl
                  sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}
                  error={
                    handleValue(`relativesHandicappedDegree${number}`) === ''
                  }
                >
                  <Select
                    labelId="handicapped-degree-label"
                    id="handicapped-degree-select"
                    variant="standard"
                    value={handleValue(`relativesHandicappedDegree${number}`)}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(
                        event,
                        `relativesHandicappedDegree${number}`,
                        event.target.value,
                      );
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      value={'1級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '身体障害' ||
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '精神障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      1級
                    </MenuItem>
                    <MenuItem
                      value={'2級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '身体障害' ||
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '精神障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      2級
                    </MenuItem>
                    <MenuItem
                      value={'3級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '身体障害' ||
                          handleValue(`relativesHandicappedDetail${number}`) ===
                            '精神障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      3級
                    </MenuItem>
                    <MenuItem
                      value={'4級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '身体障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      4級
                    </MenuItem>
                    <MenuItem
                      value={'5級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '身体障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      5級
                    </MenuItem>
                    <MenuItem
                      value={'6級'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '身体障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      6級
                    </MenuItem>
                    <MenuItem
                      value={'A'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '知的障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      A
                    </MenuItem>
                    <MenuItem
                      value={'B'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '知的障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      B
                    </MenuItem>
                    <MenuItem
                      value={'C'}
                      style={{
                        display:
                          handleValue(`relativesHandicappedDetail${number}`) ===
                          '知的障害'
                            ? ''
                            : 'none',
                      }}
                    >
                      C
                    </MenuItem>
                  </Select>
                  <FormHelperText
                    style={{
                      display:
                        handleValue(`relativesHandicappedDegree${number}`) ===
                        ''
                          ? ''
                          : 'none',
                    }}
                  >
                    必須項目
                  </FormHelperText>
                </FormControl>
                <div>
                  {/*<TextField*/}
                  {/*  id="deliveryDate"*/}
                  {/*  variant="standard"*/}
                  {/*  className={'delivery-date'}*/}
                  {/*  label="交付日"*/}
                  {/*  type="date"*/}
                  {/*  value={handleValue(`relativesDeliveryDate${number}`)}*/}
                  {/*  error={handleValue(`relativesDeliveryDate${number}`) === ''}*/}
                  {/*  onChange={(event) => {*/}
                  {/*    console.log(event.target.value);*/}
                  {/*    const targetDate = moment(event.target.value);*/}
                  {/*    if (targetDate.isBefore(moment())) {*/}
                  {/*      handleChange(*/}
                  {/*        event,*/}
                  {/*        `relativesDeliveryDate${number}`,*/}
                  {/*        event.target.value,*/}
                  {/*      );*/}
                  {/*    } else {*/}
                  {/*      handleOpen8();*/}
                  {/*      handleChange(*/}
                  {/*        event,*/}
                  {/*        `relativesDeliveryDate${number}`,*/}
                  {/*        '',*/}
                  {/*      );*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}*/}
                  {/*  InputLabelProps={{*/}
                  {/*    shrink: true,*/}
                  {/*  }}*/}
                  {/*  helperText={*/}
                  {/*    handleValue(`relativesDeliveryDate${number}`) === ''*/}
                  {/*      ? '必須項目'*/}
                  {/*      : false*/}
                  {/*  }*/}
                  {/*/>*/}
                  <MobileDatePicker
                    label="交付日"
                    inputFormat="yyyy/M/d"
                    toolbarFormat="yyyy/M/d"
                    value={
                      handleValue(`relativesDeliveryDate${number}`) ||
                      '2000/1/1'
                    }
                    onChange={(date) => {
                      console.log(date);
                      handleChange(
                        {
                          target: {
                            name: `relativesDeliveryDate${number}`,
                          },
                        },
                        `relativesDeliveryDate${number}`,
                        moment(date).format('YYYY-MM-DD'),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!handleValue(`relativesDeliveryDate${number}`)}
                        helperText={
                          !handleValue(`relativesDeliveryDate${number}`)
                            ? '必須項目'
                            : false
                        }
                        {...params}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <Box
                sx={{
                  mt: 2,
                  color: '#0f1941',
                  fontSize: '0.8em',
                  width: '90%',
                }}
              >
                {
                  '万が一、当年中に死別している場合は、下のボックスにチェックを付けてください'
                }
              </Box>
              <Box
                sx={{
                  mt: 2,
                  color: '#0f1941',
                  fontSize: '1em',
                }}
              >
                <Checkbox
                  checked={
                    handleValue(`relativesBereavement${number}`) !==
                    '該当しない'
                  }
                  onChange={(event) => {
                    handleChange(
                      event,
                      `relativesBereavement${number}`,
                      handleValue(`relativesBereavement${number}`) ===
                        '該当しない'
                        ? '当年死別'
                        : '該当しない',
                    );
                  }}
                />
                当年の中途で死別
              </Box>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<SVGSVGElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState(0);
  const [showPopover, setPopover] = React.useState(false);
  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
    setPopover(false);
  };
  const popovers = [
    <div>
      あなたを担当するランスタッドの営業担当支店を選択ください。
      <br />
      不明な場合は担当コンサルタントにご確認下さい。
    </div>,
    <div>
      <span style={{ color: '#e74536' }}>配偶者とは？</span>
      <br />
      配偶者とは、婚姻関係にある夫・妻をさします。
      <br />
      控除対象者となる条件は、年間の収入や、年金の収入によって変わります。
      <br />
      <br />
      ※下記参照
      <br />
      <img
        src="/images/fuyo-table.png"
        alt="fuyo-table"
        style={{ width: '100%' }}
      />
    </div>,
    <div>
      <span style={{ color: '#e74536' }}>収入とは？</span>
      <br />
      収入：今年の1月から12月までの総支給額（交通費を除く）です。
    </div>,
    <div>
      <span style={{ color: '#e74536' }}>新規・既存の区別</span>
      <br />
      新規：車両通勤申請が初回
      <br />
      既存：初回申請後、再提出する場合
    </div>,
    <div>
      あなたが実際に就業する派遣先（就業先）の会社名を記入ください。
      <br />
      不明な場合は担当コンサルタントにご確認下さい。
    </div>,
  ];

  const DR =
    formState.values.branch === 'DR北関東支店（DR小山課/DR高崎課）' ||
    formState.values.branch === 'DR東京・千葉支店（DR新宿課/DR船橋課）' ||
    formState.values.branch === 'DR埼玉支店（DRさいたま課/DR所沢課）' ||
    formState.values.branch === 'DR神奈川支店（DR横浜課/DR厚木課）' ||
    formState.values.branch === 'DR名阪支店（DR名古屋課/DR大阪課）';

  const branchCheck =
    branch && !(branch === 'default') ? `&branch=${branch}` : '';

  if (prev === 'default') {
    if (DR) {
      steps[6] = '7';
    } else {
      steps.splice(6, 1);
    }
  } else {
    if (DR) {
      steps[7] = '8';
    } else {
      steps.splice(7, 1);
    }
  }
  // console.log(steps);

  if (confirm) {
    if (formState.values.carsForCommuting === '申請する') {
      formState.values.carNumber = `${formState.values.carNumberS4}-${formState.values.carNumberS5}`;
    }
  }
  console.log(cookies.carNumberS4);
  console.log(formState.values.carNumberS4);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 0 &&
            !inputStart &&
            !confirm &&
            (prev === 'default' || prev === 'top')
              ? 'ごあいさつ'
              : ''}
            {activeStep === 1 && !confirm ? '誓約書に同意' : ''}
            {(activeStep === 0 && inputStart) ||
            (activeStep === 0 &&
              prev !== 'default' &&
              prev !== 'top' &&
              prev !== 'completion')
              ? '必要項目の入力'
              : ''}
            {activeStep === 2 ? '扶養控除等（異動）申告書' : ''}
            {activeStep === 3 ? 'その他の入力' : ''}
            {activeStep === 4 ? '個人情報・機密情報保護研修' : ''}
            {activeStep === 5 && DR ? '確認書・誓約書に同意' : ''}
            {prev !== 'default' &&
            ((activeStep === 6 && DR) || (activeStep === 5 && !DR))
              ? '社会保険・雇用保険加入手続き'
              : ''}
            {(activeStep === 0 && confirm) ||
            (prev === 'default' &&
              ((activeStep === 6 && DR) || (activeStep === 5 && !DR))) ||
            (prev !== 'default' &&
              ((activeStep === 7 && DR) || (activeStep === 6 && !DR)))
              ? '確認画面'
              : ''}
            {(activeStep === 0 && prev === 'completion') ||
            (prev !== 'default' &&
              ((activeStep === 8 && DR) || (activeStep === 7 && !DR))) ||
            (prev === 'default' &&
              ((activeStep === 7 && DR) || (activeStep === 6 && !DR)))
              ? 'おつかれさまでした'
              : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2, display: confirm ? 'none' : '' }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ display: !inputStart ? 'none' : '' }}
        >
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} />
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 &&
        !inputStart &&
        (prev === 'default' || prev === 'top') ? (
          <span>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                padding: '1em',
                // marginTop: '1em',
              }}
            >
              <div
                style={{
                  color: '#696969',
                  fontSize: '13px',
                  marginBottom: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                {moment().format('YYYY年M月D日')}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/randstad-logo.png" alt="logo" width="80%" />
              </div>
              <div
                style={{
                  display: type === 'temporary' ? '' : 'none',
                }}
              >
                <Box
                  sx={{
                    width: '90%',
                    mt: 3,
                    mb: 1,
                    color: '#e74536',
                    border: 'solid',
                    padding: '.8rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    ※必ずご確認ください※
                  </div>
                  <div>
                    こちらは雇用形態が「派遣」の方のお手続きページです。誤って選択した場合は閉じるボタンを押してください。
                    <br />
                    <br />
                    教育訓練は派遣契約の方のみ必須（有給）となり、請負・委託契約の方はご自身の学習のために受講すること自体は可能ですが、お支払いの対象にはなりませんのでご注意ください。
                  </div>
                </Box>
              </div>
              <div
                style={{
                  display: type === 'outsourcing' ? '' : 'none',
                }}
              >
                <Box
                  sx={{
                    width: '90%',
                    mt: 3,
                    mb: 1,
                    color: '#e74536',
                    border: 'solid',
                    padding: '.8rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    ※必ずご確認ください※
                  </div>
                  <div>
                    こちらは雇用形態が「請負・委託」の方のお手続きページです。誤って選択した場合は閉じるボタンを押してください。
                  </div>
                </Box>
              </div>
              <br />
              この度はご入職おめでとうございます。
              {/*<br />*/}
              {/*<br />*/}
              {/*<br />*/}
              まず初めにこちらから入職手続きをお願いいたします。
              <br />
              <br />
              {/*<Link href="https://www.randstad.co.jp/OCLSTF0000.do">*/}
              {/*  マイランスタッド*/}
              {/*</Link>*/}
              <br />
              <br />
              {/*<br />*/}
              {/*登録にはいくつかご確認いただく必要がございます。*/}
              {/*<br />*/}
              {/*<br />*/}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                以下の登録を行いますのでご確認・ご準備をお願いいたします。
              </span>
              <div>
                <dl>
                  <dt>①基本情報の入力</dt>
                </dl>
                <dl>
                  <dt>②誓約書と緊急連絡先登録</dt>
                </dl>
                <dl>
                  <dt>③扶養控除等（異動）申告書</dt>
                </dl>
                <dl>
                  <dt
                    style={{
                      marginBottom: '8px',
                    }}
                  >
                    ④車両通勤許可申請
                  </dt>
                  <dd
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    準備書類等：運転免許証・車情報（車種・色・ナンバー）
                    <br />
                    ・車検証・自賠責保険証・任意自動車保険証
                    <br />
                    ※自家用車にて通勤される場合は、申請が必須となります。
                  </dd>
                </dl>
                <dl>
                  <dt>⑤個人情報研修</dt>
                </dl>
                <dl>
                  <dt>⑥スタートアップ研修</dt>
                </dl>
                <dl>
                  <dt>⑦社会保険・雇用保険手続き</dt>
                </dl>
                <div
                  style={{
                    color: '#e74536',
                  }}
                >
                  ①～⑦のお手続き完了後に下記２点のご案内がございますので、忘れずにご登録をお願いします。
                  <br />
                  ・④車両通勤申請に必要な画像
                  <br />
                  ・口座申請登録（給与振込口座）
                  <br />
                </div>
              </div>
            </div>
            <Box sx={{ mt: 9 }} />
          </span>
        ) : (
          ''
        )}
        {activeStep === 1 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <Box
                  sx={{ mt: 2, ml: 2, justifyContent: 'left', display: 'flex' }}
                >
                  <span>以下の内容を確認してください。</span>
                </Box>
                <Button
                  sx={{ mt: 2, ml: 1, fontSize: 16, fontWeight: 'bold' }}
                  onClick={handleOpen5}
                >
                  ◆就業開始時誓約書
                </Button>
                <Box
                  sx={{
                    mb: 3,
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <span>
                    <Checkbox
                      checked={formState.values.writtenOath === 'はい'}
                      disabled={!writtenOath}
                      onChange={(event) => {
                        handleChange(
                          event,
                          'writtenOath',
                          formState.values.writtenOath === 'はい'
                            ? 'いいえ'
                            : 'はい',
                        );
                      }}
                    />{' '}
                    誓約書について確認しました。
                  </span>
                </Box>
                <Divider />
                <Box component="form" noValidate autoComplete="off">
                  <div>
                    <InputLabel
                      sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    >
                      {'緊急連絡先'}
                    </InputLabel>
                  </div>
                  <div>
                    <TextField
                      id="standard-basic"
                      label="姓"
                      variant="standard"
                      value={formState.values.emergencyLastname}
                      error={
                        formState.values.emergencyLastname === '' ||
                        cookies.emergencyLastname === ''
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'emergencyLastname',
                          event.target.value,
                        );
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                      placeholder="山田"
                      helperText={
                        formState.values.emergencyLastname === '' ||
                        cookies.emergencyLastname == ''
                          ? '必須項目'
                          : false
                      }
                    />
                    <TextField
                      id="standard-basic"
                      label="名"
                      variant="standard"
                      value={formState.values.emergencyFirstname}
                      error={
                        formState.values.emergencyFirstname === '' ||
                        cookies.emergencyFirstname === ''
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'emergencyFirstname',
                          event.target.value,
                        );
                      }}
                      sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                      placeholder="太郎"
                      helperText={
                        formState.values.emergencyFirstname === '' ||
                        cookies.emergencyFirstname === ''
                          ? '必須項目'
                          : false
                      }
                    />
                  </div>
                  <div>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '0.8em' }}
                      id="relationship-label"
                    >
                      {'続柄'}
                    </InputLabel>
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                      error={
                        formState.values.emergencyRelationship === '' ||
                        cookies.emergencyRelationship === ''
                      }
                    >
                      <Select
                        labelId="relationship-label"
                        id="relationship-select"
                        variant="standard"
                        value={
                          String(
                            formState.values.emergencyRelationship,
                          ).substring(0, 3) === 'その他'
                            ? 'その他'
                            : formState.values.emergencyRelationship
                        }
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'emergencyRelationship',
                            event.target.value,
                          );
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'夫'}>夫</MenuItem>
                        <MenuItem value={'妻'}>妻</MenuItem>
                        <MenuItem value={'長男'}>長男</MenuItem>
                        <MenuItem value={'次男'}>次男</MenuItem>
                        <MenuItem value={'三男'}>三男</MenuItem>
                        <MenuItem value={'長女'}>長女</MenuItem>
                        <MenuItem value={'次女'}>次女</MenuItem>
                        <MenuItem value={'三女'}>三女</MenuItem>
                        <MenuItem value={'四男'}>四男</MenuItem>
                        <MenuItem value={'四女'}>四女</MenuItem>
                        <MenuItem value={'五男'}>五男</MenuItem>
                        <MenuItem value={'五女'}>五女</MenuItem>
                        <MenuItem value={'六男'}>六女</MenuItem>
                        <MenuItem value={'子'}>子</MenuItem>
                        <MenuItem value={'父'}>父</MenuItem>
                        <MenuItem value={'母'}>母</MenuItem>
                        <MenuItem value={'義父'}>義父</MenuItem>
                        <MenuItem value={'義母'}>義母</MenuItem>
                        <MenuItem value={'兄'}>兄</MenuItem>
                        <MenuItem value={'姉'}>姉</MenuItem>
                        <MenuItem value={'弟'}>弟</MenuItem>
                        <MenuItem value={'妹'}>妹</MenuItem>
                        <MenuItem value={'祖父'}>祖父</MenuItem>
                        <MenuItem value={'祖母'}>祖母</MenuItem>
                        <MenuItem value={'曾祖父'}>曾祖父</MenuItem>
                        <MenuItem value={'甥'}>甥</MenuItem>
                        <MenuItem value={'姪'}>姪</MenuItem>
                        <MenuItem value={'叔父'}>叔父</MenuItem>
                        <MenuItem value={'叔母'}>叔母</MenuItem>
                        <MenuItem value={'伯父'}>伯父</MenuItem>
                        <MenuItem value={'伯母'}>伯母</MenuItem>
                        <MenuItem value={'孫'}>孫</MenuItem>
                        <MenuItem value={'曾孫'}>曾孫</MenuItem>
                        <MenuItem value={'いとこ'}>いとこ</MenuItem>
                        <MenuItem value={'はとこ'}>はとこ</MenuItem>
                        <MenuItem value={'その他'}>その他</MenuItem>
                      </Select>
                      <FormHelperText
                        style={{
                          display:
                            formState.values.emergencyRelationship === '' ||
                            cookies.emergencyRelationship === ''
                              ? ''
                              : 'none',
                        }}
                      >
                        必須項目
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display:
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 3) === 'その他' ||
                        String(cookies.emergencyRelationship).substring(
                          0,
                          3,
                        ) === 'その他'
                          ? ''
                          : 'none',
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="その他"
                      variant="standard"
                      value={
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 4) === 'その他('
                          ? String(
                              formState.values.emergencyRelationship,
                            ).substring(
                              4,
                              String(formState.values.emergencyRelationship)
                                .length - 1,
                            )
                          : ''
                      }
                      error={
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 5) === 'その他()' ||
                        String(cookies.emergencyRelationship).substring(
                          0,
                          5,
                        ) === 'その他()' ||
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 5) === 'その他' ||
                        String(cookies.emergencyRelationship).substring(
                          0,
                          5,
                        ) === 'その他'
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'emergencyRelationship',
                          `その他(${event.target.value})`,
                        );
                      }}
                      sx={{ ml: 4, mr: 2, mb: 2, width: '50%' }}
                      placeholder="友人"
                      helperText={
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 5) === 'その他()' ||
                        String(cookies.emergencyRelationship).substring(
                          0,
                          5,
                        ) === 'その他()' ||
                        String(
                          formState.values.emergencyRelationship,
                        ).substring(0, 5) === 'その他' ||
                        String(cookies.emergencyRelationship).substring(
                          0,
                          5,
                        ) === 'その他'
                          ? '必須項目'
                          : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="telNumber"
                      variant="standard"
                      label="電話番号"
                      type="tel"
                      placeholder="00000000000"
                      value={formState.values.emergencyTelNumber}
                      error={
                        String(formState.values.emergencyTelNumber).length <
                          10 ||
                        (!edit &&
                          String(cookies.emergencyTelNumber).length < 10)
                      }
                      inputProps={{ maxLength: '11' }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        const onlyNums = String(event.target.value).replace(
                          /[^0-9]/g,
                          '',
                        );
                        console.log(onlyNums);
                        handleChange(event, 'emergencyTelNumber', onlyNums);
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '50%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        String(formState.values.emergencyTelNumber).length <
                          10 || String(cookies.emergencyTelNumber).length < 10
                          ? errWord1
                          : false
                      }
                    />
                  </div>
                  <Box sx={{ ml: 4, fontSize: '0.7em', width: '80%' }}>
                    {'登録は携帯電話・固定電話どちらでも可能です。'}
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                      mt: 3,
                      mb: 3,
                      color: '#0f1941',
                      fontSize: '0.5em',
                      width: '80%',
                    }}
                  >
                    {
                      '※皆様の生命・財産・健康を脅かす恐れがある場合や、連絡先に連絡がつかず、やむを得ず当社が必要と判断した場合は、緊急連絡先に連絡させていただくことがございます。あらかじめご了承ください。'
                    }
                  </Box>
                </Box>
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {(activeStep === 0 && inputStart) ||
        (activeStep === 0 &&
          prev !== 'default' &&
          prev !== 'top' &&
          prev !== 'completion') ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <TextField
                    id="standard-basic"
                    label="姓"
                    variant="standard"
                    value={formState.values.lastname}
                    error={
                      formState.values.lastname === '' ||
                      cookies.lastname === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'lastname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    placeholder="山田"
                    helperText={
                      formState.values.lastname === '' ||
                      cookies.lastname === ''
                        ? '必須項目'
                        : false
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="名"
                    variant="standard"
                    value={formState.values.firstname}
                    error={
                      formState.values.firstname === '' ||
                      cookies.firstname === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'firstname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="太郎"
                    helperText={
                      formState.values.firstname === '' ||
                      cookies.firstname === ''
                        ? '必須項目'
                        : false
                    }
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="セイ"
                    variant="standard"
                    value={formState.values.lastnameRuby}
                    error={
                      formState.values.lastnameRuby === '' ||
                      cookies.lastnameRuby === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'lastnameRuby', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    placeholder="ﾔﾏﾀﾞ"
                    helperText={
                      formState.values.lastnameRuby === '' ||
                      cookies.lastnameRuby === ''
                        ? '必須項目'
                        : false
                    }
                  />
                  <TextField
                    id="standard-basic"
                    label="メイ"
                    variant="standard"
                    value={formState.values.firstnameRuby}
                    error={
                      formState.values.firstnameRuby === '' ||
                      cookies.firstnameRuby === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'firstnameRuby', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="ﾀﾛｳ"
                    helperText={
                      formState.values.firstnameRuby === '' ||
                      cookies.firstnameRuby === ''
                        ? '必須項目'
                        : false
                    }
                  />
                </div>
                <div>
                  {/*<TextField*/}
                  {/*  id="birthday"*/}
                  {/*  variant="standard"*/}
                  {/*  className={'birthday-field'}*/}
                  {/*  label="生年月日"*/}
                  {/*  type="date"*/}
                  {/*  value={formState.values.birthday}*/}
                  {/*  error={*/}
                  {/*    formState.values.birthday === '' ||*/}
                  {/*    cookies.birthday === ''*/}
                  {/*  }*/}
                  {/*  onChange={(event) => {*/}
                  {/*    console.log(event.target.value);*/}
                  {/*    const targetDate = moment(event.target.value);*/}
                  {/*    if (targetDate.isBefore(moment())) {*/}
                  {/*      handleChange(event, 'birthday', event.target.value);*/}
                  {/*    } else {*/}
                  {/*      handleOpen8();*/}
                  {/*      handleChange(event, 'birthday', '');*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                  {/*  InputLabelProps={{*/}
                  {/*    shrink: true,*/}
                  {/*  }}*/}
                  {/*  helperText={*/}
                  {/*    formState.values.birthday === '' ||*/}
                  {/*    cookies.birthday === ''*/}
                  {/*      ? '必須項目'*/}
                  {/*      : false*/}
                  {/*  }*/}
                  {/*/>*/}
                  <MobileDatePicker
                    label="生年月日"
                    inputFormat="yyyy/M/d"
                    toolbarFormat="yyyy/M/d"
                    value={formState.values.birthday || '2000/1/1'}
                    maxDate={new Date()}
                    onChange={(date) => {
                      console.log(date);
                      handleChange(
                        {
                          target: {
                            name: 'birthday',
                          },
                        },
                        'birthday',
                        moment(date).format('YYYY-MM-DD'),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          formState.values.birthday === '' ||
                          cookies.birthday === ''
                        }
                        helperText={
                          formState.values.birthday === '' ||
                          cookies.birthday === ''
                            ? '必須項目'
                            : false
                        }
                        {...params}
                      />
                    )}
                  />
                </div>
                <div>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    error={
                      formState.values.gender === '' || cookies.gender === ''
                    }
                  >
                    <FormLabel
                      id="gendar-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      性別
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.gender}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'gender', event.target.value);

                        // ひとり親/寡婦区分 - 状況選択
                        // handleChange(event, 'singleParentSituation', '');
                        handleChange(event, 'classification', '');
                      }}
                    >
                      <FormControlLabel
                        value="女性"
                        control={<Radio />}
                        label="女性"
                      />
                      <FormControlLabel
                        value="男性"
                        control={<Radio />}
                        label="男性"
                      />
                    </RadioGroup>
                    <FormHelperText
                      style={{
                        display:
                          formState.values.gender === '' ||
                          cookies.gender === ''
                            ? ''
                            : 'none',
                      }}
                    >
                      必須項目
                    </FormHelperText>
                  </FormControl>
                </div>
                <div>
                  {/*<TextField*/}
                  {/*  id="startDate"*/}
                  {/*  variant="standard"*/}
                  {/*  className={'start-date-field'}*/}
                  {/*  label="就業開始日"*/}
                  {/*  type="date"*/}
                  {/*  value={formState.values.startDate}*/}
                  {/*  error={*/}
                  {/*    formState.values.startDate === '' ||*/}
                  {/*    cookies.startDate === ''*/}
                  {/*  }*/}
                  {/*  onChange={(event) => {*/}
                  {/*    console.log(event.target.value);*/}
                  {/*    // const targetDate = moment(event.target.value);*/}
                  {/*    handleChange(event, 'startDate', event.target.value);*/}
                  {/*    // if (targetDate.isBefore(moment())) {*/}
                  {/*    // } else {*/}
                  {/*    //   handleOpen8();*/}
                  {/*    //   handleChange(event, 'birthday', '');*/}
                  {/*    // }*/}
                  {/*  }}*/}
                  {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                  {/*  InputLabelProps={{*/}
                  {/*    shrink: true,*/}
                  {/*  }}*/}
                  {/*  helperText={*/}
                  {/*    formState.values.startDate === '' ||*/}
                  {/*    cookies.startDate === ''*/}
                  {/*      ? '必須項目'*/}
                  {/*      : false*/}
                  {/*  }*/}
                  {/*/>*/}
                  <MobileDatePicker
                    label="就業開始日"
                    inputFormat="yyyy/M/d"
                    toolbarFormat="yyyy/M/d"
                    value={formState.values.startDate}
                    onChange={(date) => {
                      console.log(date);
                      handleChange(
                        {
                          target: {
                            name: 'startDate',
                          },
                        },
                        'startDate',
                        moment(date).format('YYYY-MM-DD'),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={
                          formState.values.startDate === '' ||
                          cookies.startDate === ''
                        }
                        helperText={
                          formState.values.startDate === '' ||
                          cookies.startDate === ''
                            ? '必須項目'
                            : false
                        }
                        {...params}
                      />
                    )}
                  />
                </div>
                <div>
                  <InputLabel
                    sx={{
                      ml: 4,
                      mt: 4,
                      color: '#0f1941',
                      fontSize: '1em',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    id="branch-label"
                  >
                    {'支店名'}{' '}
                    <InfoIcon
                      sx={{ fontSize: 16, color: '#2175d9' }}
                      onClick={(event) => {
                        setPopoverAnchorEl(event.currentTarget);
                        setPopover(true);
                        setPopoverContent(0);
                      }}
                    />
                  </InputLabel>
                  <div
                    style={{
                      display: !(branch === 'inhouse' || branch === 'cs')
                        ? ''
                        : 'none',
                    }}
                  >
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                      error={
                        formState.values.branch === '' || cookies.branch === ''
                      }
                    >
                      <Select
                        labelId="branch-label"
                        id="branch-select"
                        variant="standard"
                        value={formState.values.branch}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'branch', event.target.value);
                          handleChange(event, 'drWrittenOath', '同意しない');
                          handleChange(
                            event,
                            'drConfirmingLetter',
                            '同意しない',
                          );
                          handleChange(
                            event,
                            'drPersonalInformation',
                            '同意しない',
                          );
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'札幌支店'}>札幌支店</MenuItem>
                        <MenuItem value={'千歳支店'}>千歳支店</MenuItem>
                        <MenuItem value={'米沢支店'}>米沢支店</MenuItem>
                        <MenuItem value={'仙台支店'}>仙台支店</MenuItem>
                        <MenuItem value={'郡山支店'}>郡山支店</MenuItem>
                        <MenuItem value={'新潟支店'}>新潟支店</MenuItem>
                        <MenuItem value={'長岡支店'}>長岡支店</MenuItem>
                        <MenuItem value={'久喜支店'}>久喜支店</MenuItem>
                        <MenuItem value={'水戸支店'}>水戸支店</MenuItem>
                        <MenuItem value={'つくば支店'}>つくば支店</MenuItem>
                        <MenuItem value={'筑西支店'}>筑西支店</MenuItem>
                        <MenuItem value={'古河支店'}>古河支店</MenuItem>
                        <MenuItem value={'宇都宮ファクトリー第1支店'}>
                          宇都宮ファクトリー第1支店
                        </MenuItem>
                        <MenuItem value={'宇都宮ファクトリー第2支店'}>
                          宇都宮ファクトリー第2支店
                        </MenuItem>
                        <MenuItem value={'宇都宮ファクトリー第3支店'}>
                          宇都宮ファクトリー第3支店
                        </MenuItem>
                        <MenuItem value={'栃木第１支店'}>栃木第１支店</MenuItem>
                        <MenuItem value={'栃木第２支店'}>栃木第２支店</MenuItem>
                        <MenuItem value={'栃木第３支店'}>栃木第３支店</MenuItem>
                        <MenuItem value={'情報の森支店'}>情報の森支店</MenuItem>
                        <MenuItem value={'那須ファクトリー支店'}>
                          那須ファクトリー支店
                        </MenuItem>
                        <MenuItem value={'清原支店'}>清原支店</MenuItem>
                        <MenuItem value={'小山ファクトリー支店'}>
                          小山ファクトリー支店
                        </MenuItem>
                        <MenuItem value={'足利支店'}>足利支店</MenuItem>
                        <MenuItem value={'熊谷支店'}>熊谷支店</MenuItem>
                        <MenuItem value={'高崎支店'}>高崎支店</MenuItem>
                        <MenuItem value={'伊勢崎支店'}>伊勢崎支店</MenuItem>
                        <MenuItem value={'東京第1営業部'}>
                          東京第1営業部
                        </MenuItem>
                        <MenuItem value={'東京第2営業部'}>
                          東京第2営業部
                        </MenuItem>
                        <MenuItem value={'横浜支店'}>横浜支店</MenuItem>
                        <MenuItem value={'厚木支店'}>厚木支店</MenuItem>
                        <MenuItem value={'立川支店'}>立川支店</MenuItem>
                        <MenuItem value={'東京ベイ支店'}>東京ベイ支店</MenuItem>
                        <MenuItem value={'さいたま支店'}>さいたま支店</MenuItem>
                        <MenuItem value={'千葉支店'}>千葉支店</MenuItem>
                        <MenuItem value={'船橋支店'}>船橋支店</MenuItem>
                        <MenuItem value={'所沢支店'}>所沢支店</MenuItem>
                        <MenuItem value={'首都圏支店'}>首都圏支店</MenuItem>
                        <MenuItem value={'静岡支店'}>静岡支店</MenuItem>
                        <MenuItem value={'名古屋支店'}>名古屋支店</MenuItem>
                        <MenuItem value={'四日市支店'}>四日市支店</MenuItem>
                        <MenuItem value={'京都支店'}>京都支店</MenuItem>
                        <MenuItem value={'大阪支店'}>大阪支店</MenuItem>
                        <MenuItem value={'難波支店'}>難波支店</MenuItem>
                        <MenuItem value={'神戸支店'}>神戸支店</MenuItem>
                        <MenuItem value={'高松支店'}>高松支店</MenuItem>
                        <MenuItem value={'松山支店'}>松山支店</MenuItem>
                        <MenuItem value={'広島支店'}>広島支店</MenuItem>
                        <MenuItem value={'北九州支店'}>北九州支店</MenuItem>
                        <MenuItem value={'福岡支店'}>福岡支店</MenuItem>
                        <MenuItem value={'熊本支店'}>熊本支店</MenuItem>
                        <MenuItem value={'宮崎支店'}>宮崎支店</MenuItem>
                        <MenuItem value={'鹿児島支店'}>鹿児島支店</MenuItem>
                        <MenuItem value={'REG-A'}>REG-A</MenuItem>
                        <MenuItem value={'ソースライト課'}>
                          ソースライト課
                        </MenuItem>
                        <MenuItem value={'DR北関東支店（DR小山課/DR高崎課）'}>
                          DR北関東支店（DR小山課/DR高崎課）
                        </MenuItem>
                        <MenuItem
                          value={'DR東京・千葉支店（DR新宿課/DR船橋課）'}
                        >
                          DR東京・千葉支店（DR新宿課/DR船橋課）
                        </MenuItem>
                        <MenuItem value={'DR埼玉支店（DRさいたま課/DR所沢課）'}>
                          DR埼玉支店（DRさいたま課/DR所沢課）
                        </MenuItem>
                        <MenuItem value={'DR神奈川支店（DR横浜課/DR厚木課）'}>
                          DR神奈川支店（DR横浜課/DR厚木課）
                        </MenuItem>
                        <MenuItem value={'DR名阪支店（DR名古屋課/DR大阪課）'}>
                          DR名阪支店（DR名古屋課/DR大阪課）
                        </MenuItem>
                        <MenuItem
                          value={'エンジニアリングソリューションサービス'}
                        >
                          エンジニアリングソリューションサービス
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        style={{
                          display:
                            formState.values.branch === '' ||
                            cookies.branch === ''
                              ? ''
                              : 'none',
                        }}
                      >
                        必須項目
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: branch === 'inhouse' ? '' : 'none',
                    }}
                  >
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                      error={
                        formState.values.branch === '' || cookies.branch === ''
                      }
                    >
                      <Select
                        labelId="branch-label"
                        id="branch-select"
                        variant="standard"
                        value={formState.values.branch}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'branch', event.target.value);
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'宮城ニコン（戸倉）'}>
                          宮城ニコン（戸倉）
                        </MenuItem>
                        <MenuItem value={'東北電力（契約センター）'}>
                          東北電力（契約センター）
                        </MenuItem>
                        <MenuItem value={'東北電力（料金事務センター）'}>
                          東北電力（料金事務センター）
                        </MenuItem>
                        <MenuItem value={'プルデンシャル生命'}>
                          プルデンシャル生命
                        </MenuItem>
                        <MenuItem value={'NTT'}>NTT</MenuItem>
                        <MenuItem value={'仙台ニコン'}>仙台ニコン</MenuItem>
                        <MenuItem
                          style={{ fontSize: '.9em' }}
                          value={'マーレエンジンコンポーネンツジャパン山形工場'}
                        >
                          マーレエンジンコンポーネンツジャパン山形工場
                        </MenuItem>
                        <MenuItem value={'ディーアンドエム事業所'}>
                          ディーアンドエム事業所
                        </MenuItem>
                        <MenuItem value={'リコー事業所'}>リコー事業所</MenuItem>
                        {/*<MenuItem value={'インハウス（第2営業グループ）'}>*/}
                        {/*  インハウス（第2営業グループ）*/}
                        {/*</MenuItem>*/}
                        <MenuItem value={'キヤノンメディカルシステムズ'}>
                          キヤノンメディカルシステムズ
                        </MenuItem>
                        <MenuItem value={'電興製作所'}>電興製作所</MenuItem>
                        <MenuItem value={'JAうつのみや'}>JAうつのみや</MenuItem>
                        <MenuItem value={'ミツトヨ'}>ミツトヨ</MenuItem>
                        <MenuItem value={'パナソニック住宅設備'}>
                          パナソニック住宅設備
                        </MenuItem>
                        <MenuItem value={'三菱重工パワー精密鋳造事業所'}>
                          三菱重工パワー精密鋳造事業所
                        </MenuItem>
                        <MenuItem value={'SII'}>SII</MenuItem>
                        <MenuItem value={'ゼブラ'}>ゼブラ</MenuItem>
                        <MenuItem value={'ソニーMS'}>ソニーMS</MenuItem>
                        <MenuItem value={'日立GLS'}>日立GLS</MenuItem>
                        <MenuItem value={'MonotaRO'}>MonotaRO</MenuItem>
                        <MenuItem value={'デンソーテン'}>デンソーテン</MenuItem>
                        <MenuItem value={'P&G高崎'}>P&G高崎</MenuItem>
                        <MenuItem value={'太陽誘電'}>太陽誘電</MenuItem>
                        <MenuItem value={'PILOT伊勢崎'}>PILOT伊勢崎</MenuItem>
                        <MenuItem value={'ポッカサッポロ'}>
                          ポッカサッポロ
                        </MenuItem>
                        <MenuItem value={'パナソニック大泉'}>
                          パナソニック大泉
                        </MenuItem>
                        <MenuItem value={'コダック'}>コダック</MenuItem>
                        <MenuItem value={'TOPPAN　新潟'}>TOPPAN　新潟</MenuItem>
                        <MenuItem value={'サンエス工業事業所'}>
                          サンエス工業事業所
                        </MenuItem>
                        <MenuItem value={'デンカ青海新潟事業所'}>
                          デンカ青海新潟事業所
                        </MenuItem>
                        <MenuItem value={'ZACROSオフィス'}>
                          ZACROSオフィス
                        </MenuItem>
                        <MenuItem value={'パイロット平塚'}>
                          パイロット平塚
                        </MenuItem>
                        <MenuItem value={'SONY DADC-Y'}>SONY DADC-Y</MenuItem>
                        <MenuItem value={'RYOBI'}>RYOBI</MenuItem>
                        <MenuItem
                          style={{ fontSize: '.9em' }}
                          value={
                            'シャープディスプレイカラーフィルター久居事業所'
                          }
                        >
                          シャープディスプレイカラーフィルター久居事業所
                        </MenuItem>
                        <MenuItem value={'凸版印刷（亀山）'}>
                          凸版印刷（亀山）
                        </MenuItem>
                        <MenuItem value={'エルソルプロダクツ'}>
                          エルソルプロダクツ
                        </MenuItem>
                        <MenuItem value={'坂角'}>坂角</MenuItem>
                        <MenuItem value={'宮崎日機装オフィス'}>
                          宮崎日機装オフィス
                        </MenuItem>
                        <MenuItem value={'楽天多摩'}>楽天多摩</MenuItem>
                        <MenuItem value={'楽天市川'}>楽天市川</MenuItem>
                        <MenuItem value={'楽天大阪'}>楽天大阪</MenuItem>
                        <MenuItem value={'EL APSCオフィス'}>
                          EL APSCオフィス
                        </MenuItem>
                        <MenuItem value={'TMJオフィス'}>TMJオフィス</MenuItem>
                        <MenuItem value={'アルパインオフィス'}>
                          アルパインオフィス
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        style={{
                          display:
                            formState.values.branch === '' ||
                            cookies.branch === ''
                              ? ''
                              : 'none',
                        }}
                      >
                        必須項目
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: branch === 'cs' ? '' : 'none',
                    }}
                  >
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                      error={
                        formState.values.branch === '' || cookies.branch === ''
                      }
                    >
                      <Select
                        labelId="branch-label"
                        id="branch-select"
                        variant="standard"
                        value={formState.values.branch}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'branch', event.target.value);
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'新宿CS支店'}>新宿CS支店</MenuItem>
                        <MenuItem value={'エンジニア事業部 北海道'}>
                          エンジニア事業部 北海道
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 仙台'}>
                          エンジニア事業部 仙台
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 新宿'}>
                          エンジニア事業部 新宿
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 東海'}>
                          エンジニア事業部 東海
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 関西'}>
                          エンジニア事業部 関西
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 広島'}>
                          エンジニア事業部 広島
                        </MenuItem>
                        <MenuItem value={'エンジニア事業部 九州'}>
                          エンジニア事業部 九州
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        style={{
                          display:
                            formState.values.branch === '' ||
                            cookies.branch === ''
                              ? ''
                              : 'none',
                        }}
                      >
                        必須項目
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: branch === 'cs' ? '' : 'none',
                  }}
                >
                  <FormControl
                    sx={{ mt: 4, ml: 4, mr: 2, mb: 1, width: '80%' }}
                    error={
                      formState.values.staffCodeDigit === '' ||
                      cookies.staffCodeDigit === ''
                    }
                  >
                    <FormLabel
                      id="gendar-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      スタッフコードの桁数を選択
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.staffCodeDigit}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'staffCodeDigit',
                          event.target.value,
                        );
                      }}
                    >
                      <FormControlLabel
                        value="6桁"
                        control={<Radio />}
                        label="6桁"
                      />
                      <FormControlLabel
                        value="8桁"
                        control={<Radio />}
                        label="8桁"
                      />
                    </RadioGroup>
                    <FormHelperText
                      style={{
                        display:
                          formState.values.staffCodeDigit === '' ||
                          cookies.staffCodeDigit === ''
                            ? ''
                            : 'none',
                      }}
                    >
                      必須項目
                    </FormHelperText>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      !(branch === 'cs') ||
                      (branch === 'cs' && formState.values.staffCodeDigit)
                        ? ''
                        : 'none',
                  }}
                >
                  <TextField
                    id="staff-code"
                    variant="standard"
                    label="スタッフコード"
                    type="tel"
                    placeholder="00000000"
                    value={formState.values.staffCode}
                    error={
                      formState.values.staffCodeDigit === '6桁' &&
                      branch === 'cs'
                        ? String(formState.values.staffCode).length !== 6 ||
                          (!edit && String(cookies.staffCode).length !== 6)
                        : String(formState.values.staffCode).length !== 8 ||
                          (!edit && String(cookies.staffCode).length !== 8)
                    }
                    inputProps={{
                      maxLength:
                        formState.values.staffCodeDigit === '6桁' &&
                        branch === 'cs'
                          ? '6'
                          : '8',
                    }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      const onlyNums = String(event.target.value).replace(
                        /[^0-9]/g,
                        '',
                      );
                      console.log(onlyNums);
                      handleChange(event, 'staffCode', onlyNums);
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formState.values.staffCodeDigit === '6桁' &&
                      branch === 'cs'
                        ? String(formState.values.staffCode).length !== 6 ||
                          (!edit && String(cookies.staffCode).length !== 6)
                          ? errWord4
                          : false
                        : String(formState.values.staffCode).length !== 8 ||
                          (!edit && String(cookies.staffCode).length !== 8)
                        ? errWord2
                        : false
                    }
                  />
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}
                  >
                    {'現住所'}
                  </InputLabel>
                </div>
                <div>
                  <TextField
                    id="post-code"
                    variant="standard"
                    label="郵便番号"
                    type="tel"
                    placeholder="1000001"
                    value={formState.values.postCode1}
                    error={
                      formState.values.postCode1 === '' ||
                      cookies.postCode1 === ''
                    }
                    inputProps={{ maxLength: '7' }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      const onlyNums = String(event.target.value).replace(
                        /[^0-9]/g,
                        '',
                      );
                      console.log(onlyNums);
                      handleChange(event, 'postCode1', onlyNums);
                    }}
                    onBlur={() => {
                      changeNumber(formState.values.postCode1);
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formState.values.postCode1 === '' ||
                      cookies.postCode1 === ''
                        ? '必須項目'
                        : false
                    }
                  />
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mb: '2em', mt: '2em' }}
                    onClick={(event) => handleSearch(event, '1')}
                  >
                    住所検索
                  </Button>
                </div>
                <div>
                  <TextField
                    id="prefectures"
                    label="都道府県"
                    variant="standard"
                    value={formState.values.prefecture1}
                    error={
                      formState.values.prefecture1 === '' ||
                      cookies.prefecture1 === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'prefecture1', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    placeholder="東京都"
                    helperText={
                      formState.values.prefecture1 === '' ||
                      cookies.prefecture1 === ''
                        ? '必須項目'
                        : false
                    }
                  />
                </div>
                <div>
                  <TextField
                    id="municipalities"
                    label="市区町村"
                    variant="standard"
                    value={formState.values.municipalities1}
                    error={
                      formState.values.municipalities1 === '' ||
                      cookies.municipalities1 === ''
                    }
                    onChange={(event) => {
                      handleChange(
                        event,
                        'municipalities1',
                        event.target.value,
                      );
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    placeholder="中央区"
                    helperText={
                      formState.values.municipalities1 === '' ||
                      cookies.municipalities1 === ''
                        ? '必須項目'
                        : false
                    }
                  />
                </div>
                <div>
                  <TextField
                    id="town-area"
                    label="町域・番地"
                    variant="standard"
                    value={formState.values.townArea1}
                    error={
                      formState.values.townArea1 === '' ||
                      cookies.townArea1 === ''
                    }
                    onChange={(event) => {
                      handleChange(event, 'townArea1', event.target.value);
                    }}
                    onBlur={(event) => {
                      handleChange(
                        event,
                        'townArea1',
                        changeNumber(formState.values.townArea1),
                      );
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    placeholder="日本橋３丁目６ー２"
                    helperText={
                      formState.values.townArea1 === '' ||
                      cookies.townArea1 === ''
                        ? '必須項目'
                        : false
                    }
                  />
                </div>
                <div>
                  <TextField
                    id="other-address"
                    label="建物名など"
                    variant="standard"
                    value={formState.values.otherAddress1}
                    onChange={(event) => {
                      handleChange(event, 'otherAddress1', event.target.value);
                    }}
                    onBlur={(event) => {
                      handleChange(
                        event,
                        'otherAddress1',
                        changeNumber(formState.values.otherAddress1),
                      );
                    }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    placeholder="日本橋フロント　４階"
                  />
                </div>
                <div>
                  <InputLabel
                    sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}
                  >
                    {'住民票登録住所'}
                  </InputLabel>
                </div>
                <div>
                  <Box sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}>
                    <Checkbox
                      checked={
                        formState.values.registrationAddress ===
                        '現住所と異なる'
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'registrationAddress',
                          formState.values.registrationAddress ===
                            '現住所と異なる'
                            ? '現住所と同じ'
                            : '現住所と異なる',
                        );
                      }}
                    />
                    現住所と異なる
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.registrationAddress === '現住所と異なる'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <TextField
                      id="post-code"
                      variant="standard"
                      label="郵便番号"
                      type="tel"
                      placeholder="0000000"
                      value={formState.values.postCode2}
                      error={
                        formState.values.postCode2 === '' ||
                        cookies.postCode2 === ''
                      }
                      inputProps={{ maxLength: '7' }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        const onlyNums = String(event.target.value).replace(
                          /[^0-9]/g,
                          '',
                        );
                        console.log(onlyNums);
                        handleChange(event, 'postCode2', onlyNums);
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        formState.values.postCode2 === '' ||
                        cookies.postCode2 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mb: '2em', mt: '2em' }}
                      onClick={(event) => handleSearch(event, '2')}
                    >
                      住所検索
                    </Button>
                  </div>
                  <div>
                    <TextField
                      id="prefectures"
                      label="都道府県"
                      variant="standard"
                      value={formState.values.prefecture2}
                      error={
                        formState.values.prefecture2 === '' ||
                        cookies.prefecture2 === ''
                      }
                      onChange={(event) => {
                        handleChange(event, 'prefecture2', event.target.value);
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                      placeholder="東京都"
                      helperText={
                        formState.values.prefecture2 === '' ||
                        cookies.prefecture2 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id="municipalities"
                      label="市区町村"
                      variant="standard"
                      value={formState.values.municipalities2}
                      error={
                        formState.values.municipalities2 === '' ||
                        cookies.municipalities2 === ''
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'municipalities2',
                          event.target.value,
                        );
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                      placeholder="中央区"
                      helperText={
                        formState.values.municipalities2 === '' ||
                        cookies.municipalities2 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id="town-area"
                      label="町域・番地"
                      variant="standard"
                      value={formState.values.townArea2}
                      error={
                        formState.values.townArea2 === '' ||
                        cookies.townArea2 === ''
                      }
                      onChange={(event) => {
                        handleChange(event, 'townArea2', event.target.value);
                      }}
                      onBlur={(event) => {
                        handleChange(
                          event,
                          'townArea2',
                          changeNumber(formState.values.townArea2),
                        );
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                      placeholder="日本橋３丁目６ー２"
                      helperText={
                        formState.values.townArea2 === '' ||
                        cookies.townArea2 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      id="other-address"
                      label="建物名など"
                      variant="standard"
                      value={formState.values.otherAddress2}
                      onChange={(event) => {
                        handleChange(
                          event,
                          'otherAddress2',
                          event.target.value,
                        );
                      }}
                      onBlur={(event) => {
                        handleChange(
                          event,
                          'otherAddress2',
                          changeNumber(formState.values.otherAddress2),
                        );
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                      placeholder="日本橋フロント　４階"
                    />
                  </div>
                </div>
              </Box>
              <br />
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {activeStep === 2 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <TextField
                    id="standard-basic"
                    label="姓"
                    variant="standard"
                    value={formState.values.lastname}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                  />
                  <TextField
                    id="standard-basic"
                    label="名"
                    variant="standard"
                    value={formState.values.firstname}
                    disabled
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="セイ"
                    variant="standard"
                    value={formState.values.lastnameRuby}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                  />
                  <TextField
                    id="standard-basic"
                    label="メイ"
                    variant="standard"
                    value={formState.values.firstnameRuby}
                    disabled
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                  />
                </div>
                <div>
                  <TextField
                    id="birthday"
                    variant="standard"
                    className={'birthday-field'}
                    label="生年月日"
                    type="date"
                    value={formState.values.birthday}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                  >
                    <FormLabel
                      id="gendar-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      性別
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.gender}
                      aria-disabled={true}
                    >
                      <FormControlLabel
                        disabled
                        value="女性"
                        control={<Radio />}
                        label="女性"
                      />
                      <FormControlLabel
                        disabled
                        value="男性"
                        control={<Radio />}
                        label="男性"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <Divider />
                <div>
                  <InputLabel
                    sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}
                  >
                    {'住所（居所）'}
                  </InputLabel>
                </div>
                <div>
                  <TextField
                    id="post-code"
                    variant="standard"
                    label="郵便番号"
                    type="tel"
                    value={formState.values.postCode1}
                    disabled
                    inputProps={{ maxLength: '7' }}
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="prefectures"
                    label="都道府県"
                    variant="standard"
                    value={formState.values.prefecture1}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                  />
                </div>
                <div>
                  <TextField
                    id="municipalities"
                    label="市区町村"
                    variant="standard"
                    value={formState.values.municipalities1}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                  />
                </div>
                <div>
                  <TextField
                    id="town-area"
                    label="町域・番地"
                    variant="standard"
                    value={formState.values.townArea1}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                  />
                </div>
                <div
                  style={{
                    display: formState.values.otherAddress1 ? '' : 'none',
                  }}
                >
                  <TextField
                    id="other-address"
                    label="建物名など"
                    variant="standard"
                    value={formState.values.otherAddress1}
                    disabled
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                  />
                </div>
                <div>
                  <InputLabel
                    sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}
                  >
                    {'住民票登録住所'}
                  </InputLabel>
                </div>
                <div>
                  <Box sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}>
                    <Checkbox
                      checked={
                        formState.values.registrationAddress ===
                        '現住所と異なる'
                      }
                      disabled
                    />
                    住所（居所）と異なる
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.registrationAddress === '現住所と異なる'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <TextField
                      id="post-code"
                      variant="standard"
                      label="郵便番号"
                      type="tel"
                      value={formState.values.postCode2}
                      inputProps={{ maxLength: '7' }}
                      disabled
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="prefectures"
                      label="都道府県"
                      variant="standard"
                      value={formState.values.prefecture2}
                      disabled
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="municipalities"
                      label="市区町村"
                      variant="standard"
                      value={formState.values.municipalities2}
                      disabled
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="town-area"
                      label="町域・番地"
                      variant="standard"
                      value={formState.values.townArea2}
                      disabled
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    />
                  </div>
                  <div
                    style={{
                      display: formState.values.otherAddress2 ? '' : 'none',
                    }}
                  >
                    <TextField
                      id="other-address"
                      label="建物名など"
                      variant="standard"
                      value={formState.values.otherAddress2}
                      disabled
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                    />
                  </div>
                </div>
                <Divider />
                <div>
                  <Box sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}>
                    <Checkbox
                      checked={
                        formState.values.columnBDeclaration === '申告する'
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'columnBDeclaration',
                          formState.values.columnBDeclaration === '申告する'
                            ? '申告しない'
                            : '申告する',
                        );
                      }}
                    />
                    乙欄申告書
                  </Box>
                  <Box
                    sx={{
                      width: '90%',
                      ml: 3,
                      mb: 2,
                      fontSize: '0.8em',
                      fontWeight: 'bold',
                      color: '#e74536',
                    }}
                  >
                    {
                      '※現在、他社で給与収入があり、当社がメインではない場合（当社が副業となります）は乙欄に該当します'
                    }
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.columnBDeclaration === '申告する'
                        ? 'none'
                        : '',
                  }}
                >
                  <Divider />
                  <div>
                    <Box
                      sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    >
                      世帯主の氏名
                      <Checkbox
                        checked={formState.values.household === '申告者と同じ'}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'household',
                            formState.values.household === '申告者と異なる'
                              ? '申告者と同じ'
                              : '申告者と異なる',
                          );
                        }}
                      />
                      申告者と同じ
                    </Box>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.household === '申告者と同じ'
                          ? 'none'
                          : '',
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="姓"
                      variant="standard"
                      value={formState.values.lastname3}
                      error={
                        formState.values.lastname3 === '' ||
                        cookies.lastname3 === ''
                      }
                      onChange={(event) => {
                        handleChange(event, 'lastname3', event.target.value);
                      }}
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                      placeholder="山田"
                      helperText={
                        formState.values.lastname3 === '' ||
                        cookies.lastname3 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                    <TextField
                      id="standard-basic"
                      label="名"
                      variant="standard"
                      value={formState.values.firstname3}
                      error={
                        formState.values.firstname3 === '' ||
                        cookies.firstname3 === ''
                      }
                      onChange={(event) => {
                        handleChange(event, 'firstname3', event.target.value);
                      }}
                      sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                      placeholder="太郎"
                      helperText={
                        formState.values.firstname3 === '' ||
                        cookies.firstname3 === ''
                          ? '必須項目'
                          : false
                      }
                    />
                    <div>
                      <InputLabel
                        sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                        id="relationship-label"
                      >
                        {'続柄'}
                      </InputLabel>
                      <FormControl
                        sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        error={
                          formState.values.relationship3 === '' ||
                          cookies.relationship3 === ''
                        }
                      >
                        <Select
                          labelId="relationship-label"
                          id="relationship-select"
                          variant="standard"
                          // value={formState.values.relationship3}
                          value={
                            String(formState.values.relationship3).substring(
                              0,
                              3,
                            ) === 'その他'
                              ? 'その他'
                              : formState.values.relationship3
                          }
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'relationship3',
                              event.target.value,
                            );
                          }}
                        >
                          <MenuItem value={''} />
                          <MenuItem value={'夫'}>夫</MenuItem>
                          <MenuItem value={'妻'}>妻</MenuItem>
                          <MenuItem value={'長男'}>長男</MenuItem>
                          <MenuItem value={'次男'}>次男</MenuItem>
                          <MenuItem value={'三男'}>三男</MenuItem>
                          <MenuItem value={'長女'}>長女</MenuItem>
                          <MenuItem value={'次女'}>次女</MenuItem>
                          <MenuItem value={'三女'}>三女</MenuItem>
                          <MenuItem value={'四男'}>四男</MenuItem>
                          <MenuItem value={'四女'}>四女</MenuItem>
                          <MenuItem value={'五男'}>五男</MenuItem>
                          <MenuItem value={'五女'}>五女</MenuItem>
                          <MenuItem value={'六男'}>六女</MenuItem>
                          <MenuItem value={'子'}>子</MenuItem>
                          <MenuItem value={'父'}>父</MenuItem>
                          <MenuItem value={'母'}>母</MenuItem>
                          <MenuItem value={'義父'}>義父</MenuItem>
                          <MenuItem value={'義母'}>義母</MenuItem>
                          <MenuItem value={'兄'}>兄</MenuItem>
                          <MenuItem value={'姉'}>姉</MenuItem>
                          <MenuItem value={'弟'}>弟</MenuItem>
                          <MenuItem value={'妹'}>妹</MenuItem>
                          <MenuItem value={'祖父'}>祖父</MenuItem>
                          <MenuItem value={'祖母'}>祖母</MenuItem>
                          <MenuItem value={'曾祖父'}>曾祖父</MenuItem>
                          <MenuItem value={'甥'}>甥</MenuItem>
                          <MenuItem value={'姪'}>姪</MenuItem>
                          <MenuItem value={'叔父'}>叔父</MenuItem>
                          <MenuItem value={'叔母'}>叔母</MenuItem>
                          <MenuItem value={'伯父'}>伯父</MenuItem>
                          <MenuItem value={'伯母'}>伯母</MenuItem>
                          <MenuItem value={'孫'}>孫</MenuItem>
                          <MenuItem value={'曾孫'}>曾孫</MenuItem>
                          <MenuItem value={'いとこ'}>いとこ</MenuItem>
                          <MenuItem value={'はとこ'}>はとこ</MenuItem>
                          <MenuItem value={'その他'}>その他</MenuItem>
                        </Select>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.relationship3 === '' ||
                              cookies.relationship3 === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        String(formState.values.relationship3).substring(
                          0,
                          3,
                        ) === 'その他' ||
                        String(cookies.relationship3).substring(0, 3) ===
                          'その他'
                          ? ''
                          : 'none',
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="その他"
                      variant="standard"
                      value={
                        String(formState.values.relationship3).substring(
                          0,
                          4,
                        ) === 'その他('
                          ? String(formState.values.relationship3).substring(
                              4,
                              String(formState.values.relationship3).length - 1,
                            )
                          : ''
                      }
                      error={
                        String(formState.values.relationship3).substring(
                          0,
                          5,
                        ) === 'その他()' ||
                        String(cookies.relationship3).substring(0, 5) ===
                          'その他()' ||
                        String(formState.values.relationship3).substring(
                          0,
                          5,
                        ) === 'その他' ||
                        String(cookies.relationship3).substring(0, 5) ===
                          'その他'
                      }
                      onChange={(event) => {
                        handleChange(
                          event,
                          'relationship3',
                          `その他(${event.target.value})`,
                        );
                      }}
                      sx={{ ml: 4, mr: 2, mb: 2, width: '50%' }}
                      placeholder="友人"
                      helperText={
                        String(formState.values.relationship3).substring(
                          0,
                          5,
                        ) === 'その他()' ||
                        String(cookies.relationship3).substring(0, 5) ===
                          'その他()' ||
                        String(formState.values.relationship3).substring(
                          0,
                          5,
                        ) === 'その他' ||
                        String(cookies.relationship3).substring(0, 5) ===
                          'その他'
                          ? '必須項目'
                          : false
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                      id="handicapped-label"
                    >
                      {'障害者区分'}
                    </InputLabel>
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                    >
                      <Select
                        labelId="handicapped-label"
                        id="handicapped-select"
                        variant="standard"
                        value={formState.values.handicapped || '該当なし'}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'handicapped',
                            event.target.value,
                          );
                        }}
                      >
                        <MenuItem value={'該当なし'}>該当なし</MenuItem>
                        <MenuItem value={'該当する'}>該当する</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.handicapped !== '該当なし'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                        id="handicapped-detail-label"
                      >
                        {'障害内容'}
                      </InputLabel>
                      <FormControl
                        sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        error={
                          formState.values.handicappedDetail === '' ||
                          cookies.handicappedDetail === ''
                        }
                      >
                        <Select
                          labelId="handicapped-detail-label"
                          id="handicapped-detail-select"
                          variant="standard"
                          value={formState.values.handicappedDetail}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'handicappedDetail',
                              event.target.value,
                            );

                            handleChange(event, 'handicappedDegree', '');
                          }}
                        >
                          <MenuItem value={''} />
                          <MenuItem value={'身体障害'}>身体障害</MenuItem>
                          <MenuItem value={'精神障害'}>精神障害</MenuItem>
                          <MenuItem value={'知的障害'}>知的障害</MenuItem>
                          <MenuItem value={'その他'}>その他</MenuItem>
                        </Select>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.handicappedDetail === '' ||
                              cookies.handicappedDetail === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display:
                          formState.values.handicappedDetail === 'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <TextField
                        id="handicapped-other"
                        label={'障害内容「その他」の詳細'}
                        variant="standard"
                        value={formState.values.handicappedOther}
                        onChange={(event) => {
                          handleChange(
                            event,
                            `handicappedOther`,
                            event.target.value,
                          );
                        }}
                        sx={{ ml: 4, mt: 4, mb: 4, width: '80%' }}
                        placeholder={'記述してください'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={formState.values.handicappedOther === ''}
                        helperText={
                          formState.values.handicappedOther === '' ||
                          cookies.handicappedOther === ''
                            ? '必須項目'
                            : false
                        }
                      />
                    </div>
                    <div
                      style={{
                        display:
                          formState.values.handicappedDetail === 'その他'
                            ? 'none'
                            : '',
                      }}
                    >
                      <InputLabel
                        sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                        id="handicapped-degree-label"
                      >
                        {'障害の程度'}
                      </InputLabel>
                      <FormControl
                        sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        error={
                          formState.values.handicappedDegree === '' ||
                          cookies.handicappedDegree === ''
                        }
                      >
                        <Select
                          labelId="handicapped-degree-label"
                          id="handicapped-degree-select"
                          variant="standard"
                          value={formState.values.handicappedDegree}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'handicappedDegree',
                              event.target.value,
                            );
                          }}
                        >
                          <MenuItem value={''} />
                          <MenuItem
                            value={'1級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                  '身体障害' ||
                                formState.values.handicappedDetail ===
                                  '精神障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            1級
                          </MenuItem>
                          <MenuItem
                            value={'2級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                  '身体障害' ||
                                formState.values.handicappedDetail ===
                                  '精神障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            2級
                          </MenuItem>
                          <MenuItem
                            value={'3級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                  '身体障害' ||
                                formState.values.handicappedDetail ===
                                  '精神障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            3級
                          </MenuItem>
                          <MenuItem
                            value={'4級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '身体障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            4級
                          </MenuItem>
                          <MenuItem
                            value={'5級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '身体障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            5級
                          </MenuItem>
                          <MenuItem
                            value={'6級'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '身体障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            6級
                          </MenuItem>
                          <MenuItem
                            value={'A'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '知的障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            A
                          </MenuItem>
                          <MenuItem
                            value={'B'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '知的障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            B
                          </MenuItem>
                          <MenuItem
                            value={'C'}
                            style={{
                              display:
                                formState.values.handicappedDetail ===
                                '知的障害'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            C
                          </MenuItem>
                        </Select>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.handicappedDegree === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                      <div>
                        {/*<TextField*/}
                        {/*  id="deliveryDate"*/}
                        {/*  variant="standard"*/}
                        {/*  className={'delivery-date'}*/}
                        {/*  label="交付日"*/}
                        {/*  type="date"*/}
                        {/*  value={formState.values.deliveryDate}*/}
                        {/*  error={formState.values.deliveryDate === ''}*/}
                        {/*  onChange={(event) => {*/}
                        {/*    console.log(event.target.value);*/}
                        {/*    const targetDate = moment(event.target.value);*/}
                        {/*    if (targetDate.isBefore(moment())) {*/}
                        {/*      handleChange(*/}
                        {/*        event,*/}
                        {/*        'deliveryDate',*/}
                        {/*        event.target.value,*/}
                        {/*      );*/}
                        {/*    } else {*/}
                        {/*      handleOpen8();*/}
                        {/*      handleChange(event, 'deliveryDate', '');*/}
                        {/*    }*/}
                        {/*  }}*/}
                        {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                        {/*  InputLabelProps={{*/}
                        {/*    shrink: true,*/}
                        {/*  }}*/}
                        {/*  helperText={*/}
                        {/*    formState.values.deliveryDate === '' ||*/}
                        {/*    cookies.deliveryDate === ''*/}
                        {/*      ? '必須項目'*/}
                        {/*      : false*/}
                        {/*  }*/}
                        {/*/>*/}
                        <MobileDatePicker
                          label="交付日"
                          inputFormat="yyyy/M/d"
                          toolbarFormat="yyyy/M/d"
                          value={formState.values.deliveryDate || '2000/1/1'}
                          maxDate={new Date()}
                          onChange={(date) => {
                            console.log(date);
                            handleChange(
                              {
                                target: {
                                  name: 'deliveryDate',
                                },
                              },
                              'deliveryDate',
                              moment(date).format('YYYY-MM-DD'),
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                formState.values.deliveryDate === '' ||
                                cookies.deliveryDate === ''
                              }
                              helperText={
                                formState.values.deliveryDate === '' ||
                                cookies.deliveryDate === ''
                                  ? '必須項目'
                                  : false
                              }
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                      id="working-student-label"
                      style={{ display: 'inline-block' }}
                    >
                      {'勤労学生区分'}
                    </InputLabel>
                    <IconButton
                      sx={{ pt: 0 }}
                      onClick={handleOpen4}
                      style={{ display: 'inline-block' }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                    >
                      <Select
                        labelId="working-student-label"
                        id="working-student-select"
                        variant="standard"
                        value={formState.values.workingStudent || '該当なし'}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'workingStudent',
                            event.target.value,
                          );
                        }}
                      >
                        <MenuItem value={'該当なし'}>該当なし</MenuItem>
                        <MenuItem value={'該当する'}>該当する</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.workingStudent === '該当する'
                          ? ''
                          : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                      id="schools-label"
                    >
                      {'学校種別'}
                    </InputLabel>
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                      error={
                        formState.values.schoolLabel === '' ||
                        cookies.schoolLabel === ''
                      }
                    >
                      <Select
                        labelId="schools-label"
                        id="schools-select"
                        variant="standard"
                        value={formState.values.schoolLabel}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'schoolLabel',
                            event.target.value,
                          );
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'大学、短期大学、大学院'}>
                          大学、短期大学、大学院
                          <br />
                          「大学校」は除く
                        </MenuItem>
                        <MenuItem value={'高等専門学校（高専）'}>
                          高等専門学校（高専）
                          <br />
                          高専ではない「専門学校」は除く
                        </MenuItem>
                        <MenuItem value={'専門学校（高専でないもの'}>
                          専門学校（高専でないもの）
                          <br />
                          専門学校のもの
                        </MenuItem>
                        <MenuItem value={'大学校（大学でないもの）'}>
                          大学校（大学でないもの）
                          <br />
                          専修学校のもの
                        </MenuItem>
                        <MenuItem value={'高等専修学校'}>
                          高等専修学校
                          <br />
                          専修学校のもの
                        </MenuItem>
                        <MenuItem value={'専修学校'}>専修学校</MenuItem>
                        <MenuItem value={'各種学校'}>各種学校</MenuItem>
                        <MenuItem value={'職業訓練学校'}>職業訓練学校</MenuItem>
                        <MenuItem value={'高等学校（高校）'}>
                          高等学校（高校）
                        </MenuItem>
                        <MenuItem value={'特別支援学校'}>特別支援学校</MenuItem>
                        <MenuItem value={'中学校およびその他の一条校'}>
                          中学校およびその他の一条校
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        style={{
                          display:
                            formState.values.schoolLabel === '' ||
                            cookies.schoolLabel === ''
                              ? ''
                              : 'none',
                        }}
                      >
                        必須項目
                      </FormHelperText>
                    </FormControl>
                    <div>
                      <TextField
                        id="schoolName"
                        label="学校名"
                        variant="standard"
                        value={formState.values.schoolName}
                        error={
                          formState.values.schoolName === '' ||
                          cookies.schoolName === ''
                        }
                        onChange={(event) => {
                          handleChange(event, 'schoolName', event.target.value);
                        }}
                        onBlur={(event) => {
                          handleChange(
                            event,
                            'schoolName',
                            changeNumber(formState.values.schoolName),
                          );
                        }}
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                        placeholder="日本大学"
                        helperText={
                          formState.values.schoolName === '' ||
                          cookies.schoolName === ''
                            ? '必須項目'
                            : false
                        }
                      />
                    </div>
                    <div>
                      {/*<TextField*/}
                      {/*  id="admissionDate"*/}
                      {/*  variant="standard"*/}
                      {/*  className={'admission-date'}*/}
                      {/*  label="入学年月日"*/}
                      {/*  type="date"*/}
                      {/*  value={formState.values.admissionDate}*/}
                      {/*  error={*/}
                      {/*    formState.values.admissionDate === '' ||*/}
                      {/*    cookies.admissionDate === ''*/}
                      {/*  }*/}
                      {/*  onChange={(event) => {*/}
                      {/*    console.log(event.target.value);*/}
                      {/*    const targetDate = moment(event.target.value);*/}
                      {/*    if (targetDate.isBefore(moment())) {*/}
                      {/*      handleChange(*/}
                      {/*        event,*/}
                      {/*        'admissionDate',*/}
                      {/*        event.target.value,*/}
                      {/*      );*/}
                      {/*    } else {*/}
                      {/*      handleOpen8();*/}
                      {/*      handleChange(event, 'admissionDate', '');*/}
                      {/*    }*/}
                      {/*  }}*/}
                      {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                      {/*  InputLabelProps={{*/}
                      {/*    shrink: true,*/}
                      {/*  }}*/}
                      {/*  helperText={*/}
                      {/*    formState.values.admissionDate === '' ||*/}
                      {/*    cookies.admissionDate === ''*/}
                      {/*      ? '必須項目'*/}
                      {/*      : false*/}
                      {/*  }*/}
                      {/*/>*/}
                      <MobileDatePicker
                        label="入学年月日"
                        inputFormat="yyyy/M/d"
                        toolbarFormat="yyyy/M/d"
                        value={formState.values.admissionDate || '2000/1/1'}
                        maxDate={new Date()}
                        onChange={(date) => {
                          console.log(date);
                          handleChange(
                            {
                              target: {
                                name: 'admissionDate',
                              },
                            },
                            'admissionDate',
                            moment(date).format('YYYY-MM-DD'),
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              formState.values.admissionDate === '' ||
                              cookies.admissionDate === ''
                            }
                            helperText={
                              formState.values.admissionDate === '' ||
                              cookies.admissionDate === ''
                                ? '必須項目'
                                : false
                            }
                            {...params}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        ml: 4,
                        mt: 4,
                        color: '#0f1941',
                        fontSize: '1em',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {'配偶者について'}{' '}
                      <InfoIcon
                        sx={{ fontSize: 16, color: '#2175d9' }}
                        onClick={(event) => {
                          setPopoverAnchorEl(event.currentTarget);
                          setPopover(true);
                          setPopoverContent(1);
                        }}
                      />
                    </InputLabel>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                      id="marriage"
                    >
                      {'ご結婚していますか'}
                    </InputLabel>
                    <FormControl
                      sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                    >
                      <Select
                        labelId="marriage"
                        id="marriage-select"
                        variant="standard"
                        value={formState.values.marriage}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'marriage', event.target.value);
                          handleChange(event, 'situationSelection', '');
                        }}
                      >
                        <MenuItem value={'いいえ'}>いいえ</MenuItem>
                        <MenuItem value={'はい'}>はい</MenuItem>
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        display:
                          formState.values.marriage === 'はい' ? '' : 'none',
                      }}
                    >
                      <InputLabel
                        sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                        id="dependentDeclaration"
                      >
                        {'配偶者を控除対象として申告しますか'}
                      </InputLabel>
                      <FormControl
                        sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        error={
                          formState.values.dependentDeclaration === '' ||
                          cookies.dependentDeclaration === ''
                        }
                      >
                        <Select
                          labelId="dependentDeclaration"
                          id="dependentDeclaration-select"
                          variant="standard"
                          value={formState.values.dependentDeclaration}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'dependentDeclaration',
                              event.target.value,
                            );
                            if (
                              formState.values.dependentDeclaration === 'いいえ'
                            ) {
                              handleChange(event, 'partnerRelationship', '');
                            } else {
                              handleChange(
                                event,
                                'partnerRelationship',
                                formState.values.gender === '女性'
                                  ? '夫'
                                  : '妻',
                              );
                            }
                          }}
                        >
                          <MenuItem value={'いいえ'}>いいえ</MenuItem>
                          <MenuItem value={'はい'}>はい</MenuItem>
                        </Select>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.dependentDeclaration === '' ||
                              cookies.dependentDeclaration === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                      <div
                        style={{
                          display:
                            formState.values.dependentDeclaration === 'はい' &&
                            formState.values.marriage === 'はい'
                              ? ''
                              : 'none',
                        }}
                      >
                        <InputLabel
                          sx={{
                            ml: 4,
                            mt: 4,
                            color: '#0f1941',
                            fontSize: '1em',
                          }}
                        >
                          {'配偶者についてお聞きします'}
                        </InputLabel>
                        <div>
                          <TextField
                            id="standard-basic"
                            label="姓"
                            variant="standard"
                            value={formState.values.partnerLastname}
                            error={
                              formState.values.partnerLastname === '' ||
                              cookies.partnerLastname === ''
                            }
                            onChange={(event) => {
                              handleChange(
                                event,
                                'partnerLastname',
                                event.target.value,
                              );
                            }}
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                            placeholder="山田"
                            helperText={
                              formState.values.partnerLastname === '' ||
                              cookies.partnerLastname === ''
                                ? '必須項目'
                                : false
                            }
                          />
                          <TextField
                            id="standard-basic"
                            label="名"
                            variant="standard"
                            value={formState.values.partnerFirstname}
                            error={
                              formState.values.partnerFirstname === '' ||
                              cookies.partnerFirstname === ''
                            }
                            onChange={(event) => {
                              handleChange(
                                event,
                                'partnerFirstname',
                                event.target.value,
                              );
                            }}
                            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                            placeholder="太郎"
                            helperText={
                              formState.values.partnerFirstname === '' ||
                              cookies.partnerFirstname === ''
                                ? '必須項目'
                                : false
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            id="standard-basic"
                            label="セイ"
                            variant="standard"
                            value={formState.values.partnerLastnameRuby}
                            error={
                              formState.values.partnerLastnameRuby === '' ||
                              cookies.partnerLastnameRuby === ''
                            }
                            onChange={(event) => {
                              handleChange(
                                event,
                                'partnerLastnameRuby',
                                event.target.value,
                              );
                            }}
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                            placeholder="ﾔﾏﾀﾞ"
                            helperText={
                              formState.values.partnerLastnameRuby === '' ||
                              cookies.partnerLastnameRuby === ''
                                ? '必須項目'
                                : false
                            }
                          />
                          <TextField
                            id="standard-basic"
                            label="メイ"
                            variant="standard"
                            value={formState.values.partnerFirstnameRuby}
                            error={
                              formState.values.partnerFirstnameRuby === '' ||
                              cookies.partnerFirstnameRuby === ''
                            }
                            onChange={(event) => {
                              handleChange(
                                event,
                                'partnerFirstnameRuby',
                                event.target.value,
                              );
                            }}
                            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                            placeholder="ﾀﾛｳ"
                            helperText={
                              formState.values.partnerFirstnameRuby === '' ||
                              cookies.partnerFirstnameRuby === ''
                                ? '必須項目'
                                : false
                            }
                          />
                        </div>
                        <div>
                          {/*<TextField*/}
                          {/*  id="partner-birthday"*/}
                          {/*  variant="standard"*/}
                          {/*  className={'birthday-field'}*/}
                          {/*  label="生年月日"*/}
                          {/*  type="date"*/}
                          {/*  value={formState.values.partnerBirthday}*/}
                          {/*  error={*/}
                          {/*    formState.values.partnerBirthday === '' ||*/}
                          {/*    cookies.partnerBirthday === ''*/}
                          {/*  }*/}
                          {/*  onChange={(event) => {*/}
                          {/*    console.log(event.target.value);*/}
                          {/*    const targetDate = moment(event.target.value);*/}
                          {/*    if (targetDate.isBefore(moment())) {*/}
                          {/*      handleChange(*/}
                          {/*        event,*/}
                          {/*        'partnerBirthday',*/}
                          {/*        event.target.value,*/}
                          {/*      );*/}
                          {/*    } else {*/}
                          {/*      handleOpen8();*/}
                          {/*      handleChange(event, 'partnerBirthday', '');*/}
                          {/*    }*/}
                          {/*  }}*/}
                          {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                          {/*  InputLabelProps={{*/}
                          {/*    shrink: true,*/}
                          {/*  }}*/}
                          {/*  helperText={*/}
                          {/*    formState.values.partnerBirthday === '' ||*/}
                          {/*    cookies.partnerBirthday === ''*/}
                          {/*      ? '必須項目'*/}
                          {/*      : false*/}
                          {/*  }*/}
                          {/*/>*/}
                          <MobileDatePicker
                            label="生年月日"
                            inputFormat="yyyy/M/d"
                            toolbarFormat="yyyy/M/d"
                            value={
                              formState.values.partnerBirthday || '2000/1/1'
                            }
                            maxDate={new Date()}
                            onChange={(date) => {
                              console.log(date);
                              handleChange(
                                {
                                  target: {
                                    name: 'partnerBirthday',
                                  },
                                },
                                'partnerBirthday',
                                moment(date).format('YYYY-MM-DD'),
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                sx={{
                                  mt: 2,
                                  ml: 4,
                                  mr: 2,
                                  mb: 2,
                                  width: '35%',
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  formState.values.partnerBirthday === '' ||
                                  cookies.partnerBirthday === ''
                                }
                                helperText={
                                  formState.values.partnerBirthday === '' ||
                                  cookies.partnerBirthday === ''
                                    ? '必須項目'
                                    : false
                                }
                                {...params}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <FormControl
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                            error={
                              formState.values.partnerGender === '' ||
                              cookies.partnerGender === ''
                            }
                          >
                            <FormLabel
                              id="gendar-label"
                              style={{ fontSize: '.8em', color: '#0f1941' }}
                            >
                              性別
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={formState.values.partnerGender}
                              onChange={(event) => {
                                console.log(event.target.value);
                                handleChange(
                                  event,
                                  'partnerGender',
                                  event.target.value,
                                );
                              }}
                            >
                              <FormControlLabel
                                value="女性"
                                control={<Radio />}
                                label="女性"
                              />
                              <FormControlLabel
                                value="男性"
                                control={<Radio />}
                                label="男性"
                              />
                            </RadioGroup>
                            <FormHelperText
                              style={{
                                display:
                                  formState.values.partnerGender === '' ||
                                  cookies.partnerGender === ''
                                    ? ''
                                    : 'none',
                              }}
                            >
                              必須項目
                            </FormHelperText>
                          </FormControl>
                        </div>
                        <InputLabel
                          sx={{
                            ml: 4,
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '1em',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {'収入'}{' '}
                          <InfoIcon
                            sx={{ fontSize: 16, color: '#2175d9' }}
                            onClick={(event) => {
                              setPopoverAnchorEl(event.currentTarget);
                              setPopover(true);
                              setPopoverContent(2);
                            }}
                          />
                        </InputLabel>
                        <div>
                          <TextField
                            id="partnerSalary"
                            variant="standard"
                            label="給与"
                            type="tel"
                            value={formState.values.partnerSalary}
                            error={errPartnerSalary(
                              formState.values.partnerSalary,
                            )}
                            inputProps={{ maxLength: '20' }}
                            onChange={(event) => {
                              console.log(event.target.value);
                              const onlyNums = String(
                                event.target.value,
                              ).replace(/[^0-9]/g, '');
                              console.log(onlyNums);
                              handleChange(event, 'partnerSalary', onlyNums);
                            }}
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  円
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={
                              errPartnerSalary(formState.values.partnerSalary)
                                ? '給与収入見込額は0～1,500,000円の範囲で入力してください'
                                : false
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            id="partnerPension"
                            variant="standard"
                            label="老齢年金"
                            type="tel"
                            value={formState.values.partnerPension}
                            error={errPartnerPension(
                              formState.values.partnerPension,
                            )}
                            inputProps={{ maxLength: '20' }}
                            onChange={(event) => {
                              console.log(event.target.value);
                              const onlyNums = String(
                                event.target.value,
                              ).replace(/[^0-9]/g, '');
                              console.log(onlyNums);
                              handleChange(event, 'partnerPension', onlyNums);
                            }}
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  円
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={
                              errPartnerPension(formState.values.partnerPension)
                                ? `給与収入見込額は0～${partnerAge(
                                    formState.values.partnerBirthday,
                                  )}円の範囲で入力してください`
                                : false
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            id="partnerOtherIncome"
                            variant="standard"
                            label="その他収入"
                            type="number"
                            value={formState.values.partnerOtherIncome}
                            inputProps={{ maxLength: '20' }}
                            onChange={(event) => {
                              console.log(event.target.value);
                              handleChange(
                                event,
                                'partnerOtherIncome',
                                event.target.value,
                              );
                            }}
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  円
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <InputLabel
                          sx={{
                            ml: 4,
                            mt: 4,
                            color: '#0f1941',
                            fontSize: '1em',
                          }}
                        >
                          {'住所'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 4,
                            mt: 4,
                            color: '#0f1941',
                            fontSize: '1em',
                          }}
                          id="partnerLiving"
                        >
                          {'あなたは配偶者と同居していますか'}
                        </InputLabel>
                        <FormControl
                          sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        >
                          <Select
                            labelId="partnerLiving"
                            id="partnerLiving-select"
                            variant="standard"
                            value={formState.values.partnerLiving}
                            onChange={(event) => {
                              console.log(event.target.value);
                              handleChange(
                                event,
                                'partnerLiving',
                                event.target.value,
                              );
                            }}
                          >
                            <MenuItem value={'同居'}>同居</MenuItem>
                            <MenuItem value={'別居'}>別居</MenuItem>
                          </Select>
                        </FormControl>
                        <Box
                          sx={{
                            width: '80%',
                            ml: 4,
                            mb: 0,
                            fontSize: '0.8em',
                            fontWeight: 'bold',
                            color: '#e74536',
                            display:
                              formState.values.partnerLiving === '別居'
                                ? ''
                                : 'none',
                          }}
                        >
                          {
                            '※日本国外に1年以上住んでいる扶養親族の登録については別途確認書類が必要なため、ここでの登録ができません。一旦、扶養なしで登録していただき、担当コンサルタントへご連絡ください。'
                          }
                        </Box>
                        <div
                          style={{
                            display:
                              formState.values.partnerLiving === '同居'
                                ? 'none'
                                : '',
                          }}
                        >
                          <div>
                            <TextField
                              id="post-code"
                              variant="standard"
                              label={'郵便番号'}
                              type="tel"
                              placeholder="0000000"
                              value={formState.values.partnerPostCode}
                              error={
                                formState.values.partnerPostCode === '' ||
                                cookies.partnerPostCode === ''
                              }
                              inputProps={{ maxLength: '7' }}
                              onChange={(event) => {
                                console.log(event.target.value);
                                const onlyNums = String(
                                  event.target.value,
                                ).replace(/[^0-9]/g, '');
                                console.log(onlyNums);
                                handleChange(
                                  event,
                                  'partnerPostCode',
                                  onlyNums,
                                );
                              }}
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              helperText={
                                formState.values.partnerPostCode === '' ||
                                cookies.partnerPostCode === ''
                                  ? '必須項目'
                                  : false
                              }
                            />
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ mb: '2em', mt: '2em' }}
                              onClick={(event) =>
                                handleSearch(event, 'partner')
                              }
                            >
                              住所検索
                            </Button>
                          </div>
                          <div>
                            <TextField
                              id="prefectures"
                              label={'都道府県'}
                              variant="standard"
                              value={formState.values.partnerPrefecture}
                              error={
                                formState.values.partnerPrefecture === '' ||
                                cookies.partnerPrefecture === ''
                              }
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  'partnerPrefecture',
                                  event.target.value,
                                );
                              }}
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                              placeholder={'東京都'}
                              helperText={
                                formState.values.partnerPrefecture === '' ||
                                cookies.partnerPrefecture === ''
                                  ? '必須項目'
                                  : false
                              }
                            />
                          </div>
                          <div>
                            <TextField
                              id="municipalities"
                              label={'市区町村'}
                              variant="standard"
                              value={formState.values.partnerMunicipalities}
                              error={
                                formState.values.partnerMunicipalities === '' ||
                                cookies.partnerMunicipalities === ''
                              }
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  'partnerMunicipalities',
                                  event.target.value,
                                );
                              }}
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                              placeholder={'中央区'}
                              helperText={
                                formState.values.partnerMunicipalities === '' ||
                                cookies.partnerMunicipalities === ''
                                  ? '必須項目'
                                  : false
                              }
                            />
                          </div>
                          <div>
                            <TextField
                              id="town-area"
                              label={'町域・番地'}
                              variant="standard"
                              value={formState.values.partnerTownArea}
                              error={
                                formState.values.partnerTownArea === '' ||
                                cookies.partnerTownArea === ''
                              }
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  'partnerTownArea',
                                  event.target.value,
                                );
                              }}
                              onBlur={(event) => {
                                handleChange(
                                  event,
                                  'partnerTownArea',
                                  changeNumber(
                                    formState.values.partnerTownArea,
                                  ),
                                );
                              }}
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                              placeholder={'日本橋３丁目６ー２'}
                              helperText={
                                formState.values.partnerTownArea === '' ||
                                cookies.partnerTownArea === ''
                                  ? '必須項目'
                                  : false
                              }
                            />
                          </div>
                          <div>
                            <TextField
                              id="other-address"
                              label={'建物名など'}
                              variant="standard"
                              value={formState.values.partnerOtherAddress}
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  'partnerOtherAddress',
                                  event.target.value,
                                );
                              }}
                              onBlur={(event) => {
                                handleChange(
                                  event,
                                  'partnerOtherAddress',
                                  changeNumber(
                                    formState.values.partnerOtherAddress,
                                  ),
                                );
                              }}
                              sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                              placeholder={'日本橋フロント　４階'}
                            />
                          </div>
                        </div>
                        <div>
                          <InputLabel
                            sx={{
                              ml: 4,
                              mt: 4,
                              color: '#0f1941',
                              fontSize: '1em',
                            }}
                            id="handicapped-label"
                          >
                            {'障害者区分'}
                          </InputLabel>
                          <FormControl
                            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                          >
                            <Select
                              labelId="handicapped-label"
                              id="handicapped-select"
                              variant="standard"
                              value={
                                formState.values.partnerHandicapped ||
                                '該当なし'
                              }
                              onChange={(event) => {
                                console.log(event.target.value);
                                handleChange(
                                  event,
                                  'partnerHandicapped',
                                  event.target.value,
                                );
                              }}
                            >
                              <MenuItem value={'該当なし'}>該当なし</MenuItem>
                              <MenuItem value={'該当する'}>該当する</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          style={{
                            display:
                              formState.values.partnerHandicapped !== '該当なし'
                                ? ''
                                : 'none',
                          }}
                        >
                          <div>
                            <InputLabel
                              sx={{
                                ml: 4,
                                mt: 4,
                                color: '#0f1941',
                                fontSize: '1em',
                              }}
                              id="handicapped-detail-label"
                            >
                              {'障害内容'}
                            </InputLabel>
                            <FormControl
                              sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                              error={
                                formState.values.partnerHandicappedDetail ===
                                  '' || cookies.partnerHandicappedDetail === ''
                              }
                            >
                              <Select
                                labelId="handicapped-detail-label"
                                id="handicapped-detail-select"
                                variant="standard"
                                value={
                                  formState.values.partnerHandicappedDetail
                                }
                                onChange={(event) => {
                                  console.log(event.target.value);
                                  handleChange(
                                    event,
                                    'partnerHandicappedDetail',
                                    event.target.value,
                                  );

                                  handleChange(
                                    event,
                                    'partnerHandicappedDegree',
                                    '',
                                  );
                                }}
                              >
                                <MenuItem value={''} />
                                <MenuItem value={'身体障害'}>身体障害</MenuItem>
                                <MenuItem value={'精神障害'}>精神障害</MenuItem>
                                <MenuItem value={'知的障害'}>知的障害</MenuItem>
                                <MenuItem value={'その他'}>その他</MenuItem>
                              </Select>
                              <FormHelperText
                                style={{
                                  display:
                                    formState.values
                                      .partnerHandicappedDetail === '' ||
                                    cookies.partnerHandicappedDetail === ''
                                      ? ''
                                      : 'none',
                                }}
                              >
                                必須項目
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div
                            style={{
                              display:
                                formState.values.partnerHandicappedDetail ===
                                'その他'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            <TextField
                              id="handicapped-other"
                              label={'障害内容「その他」の詳細'}
                              variant="standard"
                              value={formState.values.partnerHandicappedOther}
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  `partnerHandicappedOther`,
                                  event.target.value,
                                );
                              }}
                              sx={{ ml: 4, mt: 4, mb: 4, width: '80%' }}
                              placeholder={'記述してください'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                formState.values.partnerHandicappedOther === ''
                              }
                              helperText={
                                formState.values.partnerHandicappedOther ===
                                  '' || cookies.partnerHandicappedOther === ''
                                  ? '必須項目'
                                  : false
                              }
                            />
                          </div>
                          <div
                            style={{
                              display:
                                formState.values.partnerHandicappedDetail ===
                                'その他'
                                  ? 'none'
                                  : '',
                            }}
                          >
                            <InputLabel
                              sx={{
                                ml: 4,
                                mt: 4,
                                color: '#0f1941',
                                fontSize: '1em',
                              }}
                              id="handicapped-degree-label"
                            >
                              {'障害の程度'}
                            </InputLabel>
                            <FormControl
                              sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                              error={
                                formState.values.partnerHandicappedDegree ===
                                  '' || cookies.partnerHandicappedDegree === ''
                              }
                            >
                              <Select
                                labelId="handicapped-degree-label"
                                id="handicapped-degree-select"
                                variant="standard"
                                value={
                                  formState.values.partnerHandicappedDegree
                                }
                                onChange={(event) => {
                                  console.log(event.target.value);
                                  handleChange(
                                    event,
                                    'partnerHandicappedDegree',
                                    event.target.value,
                                  );
                                }}
                              >
                                <MenuItem value={''} />
                                <MenuItem
                                  value={'1級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail ===
                                        '身体障害' ||
                                      formState.values
                                        .partnerHandicappedDetail === '精神障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  1級
                                </MenuItem>
                                <MenuItem
                                  value={'2級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail ===
                                        '身体障害' ||
                                      formState.values
                                        .partnerHandicappedDetail === '精神障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  2級
                                </MenuItem>
                                <MenuItem
                                  value={'3級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail ===
                                        '身体障害' ||
                                      formState.values
                                        .partnerHandicappedDetail === '精神障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  3級
                                </MenuItem>
                                <MenuItem
                                  value={'4級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '身体障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  4級
                                </MenuItem>
                                <MenuItem
                                  value={'5級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '身体障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  5級
                                </MenuItem>
                                <MenuItem
                                  value={'6級'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '身体障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  6級
                                </MenuItem>
                                <MenuItem
                                  value={'A'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '知的障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  A
                                </MenuItem>
                                <MenuItem
                                  value={'B'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '知的障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  B
                                </MenuItem>
                                <MenuItem
                                  value={'C'}
                                  style={{
                                    display:
                                      formState.values
                                        .partnerHandicappedDetail === '知的障害'
                                        ? ''
                                        : 'none',
                                  }}
                                >
                                  C
                                </MenuItem>
                              </Select>
                              <FormHelperText
                                style={{
                                  display:
                                    formState.values
                                      .partnerHandicappedDegree === '' ||
                                    cookies.partnerHandicappedDegree === ''
                                      ? ''
                                      : 'none',
                                }}
                              >
                                必須項目
                              </FormHelperText>
                            </FormControl>
                            <div>
                              {/*<TextField*/}
                              {/*  id="deliveryDate"*/}
                              {/*  variant="standard"*/}
                              {/*  className={'delivery-date'}*/}
                              {/*  label="交付日"*/}
                              {/*  type="date"*/}
                              {/*  value={formState.values.partnerDeliveryDate}*/}
                              {/*  error={*/}
                              {/*    formState.values.partnerDeliveryDate === '' ||*/}
                              {/*    cookies.partnerDeliveryDate === ''*/}
                              {/*  }*/}
                              {/*  onChange={(event) => {*/}
                              {/*    console.log(event.target.value);*/}
                              {/*    const targetDate = moment(event.target.value);*/}
                              {/*    if (targetDate.isBefore(moment())) {*/}
                              {/*      handleChange(*/}
                              {/*        event,*/}
                              {/*        'partnerDeliveryDate',*/}
                              {/*        event.target.value,*/}
                              {/*      );*/}
                              {/*    } else {*/}
                              {/*      handleOpen8();*/}
                              {/*      handleChange(*/}
                              {/*        event,*/}
                              {/*        'partnerDeliveryDate',*/}
                              {/*        '',*/}
                              {/*      );*/}
                              {/*    }*/}
                              {/*  }}*/}
                              {/*  sx={{*/}
                              {/*    mt: 2,*/}
                              {/*    ml: 4,*/}
                              {/*    mr: 2,*/}
                              {/*    mb: 2,*/}
                              {/*    width: '35%',*/}
                              {/*  }}*/}
                              {/*  InputLabelProps={{*/}
                              {/*    shrink: true,*/}
                              {/*  }}*/}
                              {/*  helperText={*/}
                              {/*    formState.values.partnerDeliveryDate === '' ||*/}
                              {/*    cookies.partnerDeliveryDate === ''*/}
                              {/*      ? '必須項目'*/}
                              {/*      : false*/}
                              {/*  }*/}
                              {/*/>*/}
                              <MobileDatePicker
                                label="交付日"
                                inputFormat="yyyy/M/d"
                                toolbarFormat="yyyy/M/d"
                                value={
                                  formState.values.partnerDeliveryDate ||
                                  '2000/1/1'
                                }
                                maxDate={new Date()}
                                onChange={(date) => {
                                  console.log(date);
                                  handleChange(
                                    {
                                      target: {
                                        name: 'partnerDeliveryDate',
                                      },
                                    },
                                    'partnerDeliveryDate',
                                    moment(date).format('YYYY-MM-DD'),
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    sx={{
                                      mt: 2,
                                      ml: 4,
                                      mr: 2,
                                      mb: 2,
                                      width: '35%',
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={
                                      formState.values.partnerDeliveryDate ===
                                        '' || cookies.birthday === ''
                                    }
                                    helperText={
                                      formState.values.partnerDeliveryDate ===
                                        '' || cookies.birthday === ''
                                        ? '必須項目'
                                        : false
                                    }
                                    {...params}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <Box
                            sx={{
                              ml: 4,
                              mt: 2,
                              color: '#0f1941',
                              fontSize: '1em',
                              width: '80%',
                            }}
                          >
                            {
                              '万が一、当年中に死別している場合は、下のボックスにチェックを付けてください'
                            }
                          </Box>
                          <Box
                            sx={{
                              ml: 2,
                              mt: 2,
                              color: '#0f1941',
                              fontSize: '1em',
                            }}
                          >
                            <Checkbox
                              checked={
                                formState.values.partnerBereavement ===
                                '当年死別'
                              }
                              onChange={(event) => {
                                handleChange(
                                  event,
                                  'partnerBereavement',
                                  formState.values.partnerBereavement ===
                                    '該当しない'
                                    ? '当年死別'
                                    : '該当しない',
                                );
                              }}
                            />
                            当年の中途で死別
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display:
                        formState.values.marriage === 'いいえ' ? '' : 'none',
                    }}
                  >
                    <div
                    // style={{
                    //   display:
                    //     formState.values.singleParentSituation ===
                    //       'ひとり親' ||
                    //     formState.values.singleParentSituation === '寡婦'
                    //       ? ''
                    //       : 'none',
                    // }}
                    >
                      <InputLabel
                        sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                        id="single-parent_widow-classification"
                      >
                        {'寡婦/ひとり親 状況選択'}
                      </InputLabel>
                      <FormControl
                        sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
                        // error={
                        //   formState.values.classification === '' ||
                        //   cookies.classification === '' ||
                        //   ((formState.values.singleParentSituation === '寡婦' ||
                        //     cookies.singleParentSituation === '寡婦') &&
                        //     (formState.values.classification ===
                        //       '結婚歴がない' ||
                        //       cookies.classification === '結婚歴がない'))
                        // }
                      >
                        <Select
                          labelId="single-parent_widow-classification"
                          id="single-parent_widow-classification-select"
                          variant="standard"
                          value={formState.values.situationSelection}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'situationSelection',
                              event.target.value,
                            );
                          }}
                        >
                          <MenuItem
                            value={`夫と死別後、婚姻しておらず、扶養親族なし（寡婦）`}
                            style={{
                              paddingTop: '15px',
                              paddingBottom: '15px',
                              whiteSpace: 'normal',
                            }}
                          >
                            夫と死別後、婚姻しておらず、扶養親族なし（寡婦）
                          </MenuItem>
                          <MenuItem
                            value={`夫と離婚または死別後、婚姻しておらず、子以外の親族を扶養申告する（寡婦）`}
                            style={{ whiteSpace: 'normal' }}
                          >
                            夫と離婚または死別後、婚姻しておらず、子以外の親族を扶養申告する（寡婦）
                          </MenuItem>
                          <MenuItem
                            value={`現在婚姻しておらず、子を扶養申告する（ひとり親）`}
                            style={{ paddingTop: '15px', whiteSpace: 'normal' }}
                          >
                            現在婚姻しておらず、子を扶養申告する（ひとり親）
                          </MenuItem>
                          <MenuItem value={`該当なし`}>該当なし</MenuItem>
                        </Select>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.situationSelection === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                        {/*<FormHelperText*/}
                        {/*  style={{*/}
                        {/*    display:*/}
                        {/*      formState.values.classification === '' ||*/}
                        {/*      cookies.classification === '' ||*/}
                        {/*      ((formState.values.singleParentSituation ===*/}
                        {/*        '寡婦' ||*/}
                        {/*        cookies.singleParentSituation === '寡婦') &&*/}
                        {/*        (formState.values.classification ===*/}
                        {/*          '結婚歴がない' ||*/}
                        {/*          cookies.classification === '結婚歴がない'))*/}
                        {/*        ? ''*/}
                        {/*        : 'none',*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  {(formState.values.singleParentSituation === '寡婦' ||*/}
                        {/*    cookies.singleParentSituation === '寡婦') &&*/}
                        {/*  (formState.values.classification === '結婚歴がない' ||*/}
                        {/*    cookies.classification === '結婚歴がない')*/}
                        {/*    ? '『結婚歴がない』は設定不可'*/}
                        {/*    : '必須項目'}*/}
                        {/*</FormHelperText>*/}
                      </FormControl>
                    </div>
                    {/*<InputLabel*/}
                    {/*  sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}*/}
                    {/*  id="single-parent_situation"*/}
                    {/*>*/}
                    {/*  {'ひとり親/寡婦区分'}*/}
                    {/*</InputLabel>*/}
                    {/*<FormControl*/}
                    {/*  sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}*/}
                    {/*>*/}
                    {/*  <Select*/}
                    {/*    labelId="single-parent_situation"*/}
                    {/*    id="single-parent_situation-select"*/}
                    {/*    variant="standard"*/}
                    {/*    value={formState.values.singleParentSituation}*/}
                    {/*    onChange={(event) => {*/}
                    {/*      console.log(event.target.value);*/}
                    {/*      handleChange(*/}
                    {/*        event,*/}
                    {/*        'singleParentSituation',*/}
                    {/*        event.target.value,*/}
                    {/*      );*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <MenuItem value={'該当なし'}>該当なし</MenuItem>*/}
                    {/*    <MenuItem value={'ひとり親'}>ひとり親</MenuItem>*/}
                    {/*    <MenuItem*/}
                    {/*      value={'寡婦'}*/}
                    {/*      style={{*/}
                    {/*        display:*/}
                    {/*          formState.values.gender !== '女性' ? 'none' : '',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      寡婦*/}
                    {/*    </MenuItem>*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    display:*/}
                    {/*      formState.values.singleParentSituation ===*/}
                    {/*        'ひとり親' ||*/}
                    {/*      formState.values.singleParentSituation === '寡婦'*/}
                    {/*        ? ''*/}
                    {/*        : 'none',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <InputLabel*/}
                    {/*    sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}*/}
                    {/*    id="single-parent_widow-classification"*/}
                    {/*  >*/}
                    {/*    {'状況選択'}*/}
                    {/*  </InputLabel>*/}
                    {/*  <FormControl*/}
                    {/*    sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}*/}
                    {/*    error={*/}
                    {/*      formState.values.classification === '' ||*/}
                    {/*      cookies.classification === '' ||*/}
                    {/*      ((formState.values.singleParentSituation === '寡婦' ||*/}
                    {/*        cookies.singleParentSituation === '寡婦') &&*/}
                    {/*        (formState.values.classification ===*/}
                    {/*          '結婚歴がない' ||*/}
                    {/*          cookies.classification === '結婚歴がない'))*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    <Select*/}
                    {/*      labelId="single-parent_widow-classification"*/}
                    {/*      id="single-parent_widow-classification-select"*/}
                    {/*      variant="standard"*/}
                    {/*      value={formState.values.classification}*/}
                    {/*      onChange={(event) => {*/}
                    {/*        console.log(event.target.value);*/}
                    {/*        handleChange(*/}
                    {/*          event,*/}
                    {/*          'classification',*/}
                    {/*          event.target.value,*/}
                    {/*        );*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <MenuItem value={''} />*/}
                    {/*      <MenuItem*/}
                    {/*        value={`${*/}
                    {/*          formState.values.gender === '男性' ? '妻' : '夫'*/}
                    {/*        }と死別した後、婚姻していない`}*/}
                    {/*      >*/}
                    {/*        {formState.values.gender === '男性' ? '妻' : '夫'}*/}
                    {/*        と死別した後、婚姻していない*/}
                    {/*      </MenuItem>*/}
                    {/*      <MenuItem*/}
                    {/*        value={`${*/}
                    {/*          formState.values.gender === '男性' ? '妻' : '夫'*/}
                    {/*        }と離婚した後、婚姻していない`}*/}
                    {/*      >*/}
                    {/*        {formState.values.gender === '男性' ? '妻' : '夫'}*/}
                    {/*        と離婚した後、婚姻していない*/}
                    {/*      </MenuItem>*/}
                    {/*      <MenuItem*/}
                    {/*        value={`${*/}
                    {/*          formState.values.gender === '男性' ? '妻' : '夫'*/}
                    {/*        }の生死が明らかでない`}*/}
                    {/*      >*/}
                    {/*        {formState.values.gender === '男性' ? '妻' : '夫'}*/}
                    {/*        の生死が明らかでない*/}
                    {/*      </MenuItem>*/}
                    {/*      <MenuItem*/}
                    {/*        value={'結婚歴がない'}*/}
                    {/*        style={{*/}
                    {/*          display:*/}
                    {/*            formState.values.singleParentSituation ===*/}
                    {/*            '寡婦'*/}
                    {/*              ? 'none'*/}
                    {/*              : '',*/}
                    {/*        }}*/}
                    {/*      >*/}
                    {/*        結婚歴がない*/}
                    {/*      </MenuItem>*/}
                    {/*    </Select>*/}
                    {/*    <FormHelperText*/}
                    {/*      style={{*/}
                    {/*        display:*/}
                    {/*          formState.values.classification === '' ||*/}
                    {/*          cookies.classification === '' ||*/}
                    {/*          ((formState.values.singleParentSituation ===*/}
                    {/*            '寡婦' ||*/}
                    {/*            cookies.singleParentSituation === '寡婦') &&*/}
                    {/*            (formState.values.classification ===*/}
                    {/*              '結婚歴がない' ||*/}
                    {/*              cookies.classification === '結婚歴がない'))*/}
                    {/*            ? ''*/}
                    {/*            : 'none',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      {(formState.values.singleParentSituation === '寡婦' ||*/}
                    {/*        cookies.singleParentSituation === '寡婦') &&*/}
                    {/*      (formState.values.classification === '結婚歴がない' ||*/}
                    {/*        cookies.classification === '結婚歴がない')*/}
                    {/*        ? '『結婚歴がない』は設定不可'*/}
                    {/*        : '必須項目'}*/}
                    {/*    </FormHelperText>*/}
                    {/*  </FormControl>*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    display:*/}
                    {/*      formState.values.singleParentSituation ===*/}
                    {/*        'ひとり親' ||*/}
                    {/*      formState.values.singleParentSituation === '寡婦'*/}
                    {/*        ? ''*/}
                    {/*        : 'none',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <InputLabel*/}
                    {/*    sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}*/}
                    {/*    id="have-dependents"*/}
                    {/*  >*/}
                    {/*    {'扶養対象の有無'}*/}
                    {/*  </InputLabel>*/}
                    {/*  <FormControl*/}
                    {/*    sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}*/}
                    {/*    error={*/}
                    {/*      formState.values.haveDependents === '' ||*/}
                    {/*      cookies.haveDependents === ''*/}
                    {/*    }*/}
                    {/*  >*/}
                    {/*    <Select*/}
                    {/*      labelId="have-dependents"*/}
                    {/*      id="have-dependents-select"*/}
                    {/*      variant="standard"*/}
                    {/*      value={formState.values.haveDependents}*/}
                    {/*      onChange={(event) => {*/}
                    {/*        console.log(event.target.value);*/}
                    {/*        handleChange(*/}
                    {/*          event,*/}
                    {/*          'haveDependents',*/}
                    {/*          event.target.value,*/}
                    {/*        );*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <MenuItem value={''} />*/}
                    {/*      <MenuItem value={`有り`}>{'有り'}</MenuItem>*/}
                    {/*      <MenuItem value={`無し`}>{'無し'}</MenuItem>*/}
                    {/*    </Select>*/}
                    {/*  </FormControl>*/}
                    {/*</div>*/}
                  </div>
                  <Divider />
                  <div>
                    <Button
                      style={{ display: count === 10 ? 'none' : '' }}
                      variant="outlined"
                      sx={{
                        ml: 4,
                        mt: 4,
                        width: '80%',
                      }}
                      aria-label="reduce"
                      onClick={() => {
                        setCookie('count', count + 1);
                        setCount(count + 1);
                        if (count >= 1) {
                          setNumbers([...numbers, count + 1]);
                          setCookie('number', [...numbers, count + 1]);
                        }
                      }}
                    >
                      <AddIcon fontSize="small" />
                      {'扶養親族を追加する'}
                    </Button>
                    <Button
                      style={{ display: count === 0 ? 'none' : '' }}
                      variant="outlined"
                      sx={{ ml: 4, mt: 2, width: '80%' }}
                      aria-label="increase"
                      onClick={() => {
                        if (count >= 2) {
                          const newNumber = numbers.filter(
                            (number) => number !== count,
                          );
                          setNumbers(newNumber);
                          setCookie('number', newNumber);
                        }
                        setCookie('count', Math.max(count - 1, 0));
                        setCount(Math.max(count - 1, 0));
                      }}
                    >
                      <RemoveIcon fontSize="small" />
                      {'扶養親族を減らす'}
                    </Button>
                  </div>
                  <div style={{ display: count === 0 ? 'none' : '' }}>
                    <Divider sx={{ mt: 4 }} />
                    <Box sx={{ ml: 2, mt: 2, width: '90%' }}>{doubled}</Box>
                  </div>
                </div>
              </Box>
              <br />
            </div>
            <Box sx={{ mt: 12 }} />
          </div>
        ) : (
          ''
        )}
        {activeStep === 3 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    mr: 2,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span>以下が必要な場合は申請してください。</span>
                  <span
                    style={{
                      color: '#e74536',
                      fontSize: '.9em',
                      marginTop: '8px',
                    }}
                  >
                    車輛通勤の申請が不要な場合は、そのまま次へ進んでください。
                  </span>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    display: 'flex',
                  }}
                >
                  <span>
                    <Checkbox
                      checked={formState.values.carsForCommuting === '申請する'}
                      onChange={(event) => {
                        handleChange(
                          event,
                          'carsForCommuting',
                          formState.values.carsForCommuting === '申請する'
                            ? '申請しない'
                            : '申請する',
                        );
                      }}
                    />
                    車輌通勤許可申請書
                  </span>
                </Box>
                <Box component="form" noValidate autoComplete="off">
                  <div
                    style={{
                      display:
                        formState.values.carsForCommuting === '申請する'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      {/*<TextField*/}
                      {/*  id="start_day"*/}
                      {/*  variant="standard"*/}
                      {/*  className={'start-day-field'}*/}
                      {/*  label="通勤開始日"*/}
                      {/*  type="date"*/}
                      {/*  value={formState.values.commuteStartDay}*/}
                      {/*  error={formState.values.commuteStartDay === ''}*/}
                      {/*  onChange={(event) => {*/}
                      {/*    console.log(event.target.value);*/}
                      {/*    handleChange(*/}
                      {/*      event,*/}
                      {/*      'commuteStartDay',*/}
                      {/*      event.target.value,*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
                      {/*  InputLabelProps={{*/}
                      {/*    shrink: true,*/}
                      {/*  }}*/}
                      {/*  helperText={*/}
                      {/*    formState.values.commuteStartDay === ''*/}
                      {/*      ? '必須項目'*/}
                      {/*      : false*/}
                      {/*  }*/}
                      {/*/>*/}
                      <MobileDatePicker
                        label="通勤開始日"
                        inputFormat="yyyy/M/d"
                        toolbarFormat="yyyy/M/d"
                        value={formState.values.commuteStartDay}
                        onChange={(date) => {
                          console.log(date);
                          handleChange(
                            {
                              target: {
                                name: 'commuteStartDay',
                              },
                            },
                            'commuteStartDay',
                            moment(date).format('YYYY-MM-DD'),
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              formState.values.commuteStartDay === '' ||
                              cookies.birthday === ''
                            }
                            helperText={
                              formState.values.commuteStartDay === '' ||
                              cookies.commuteStartDay === ''
                                ? '必須項目'
                                : false
                            }
                            {...params}
                          />
                        )}
                      />
                    </div>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                    >
                      {'車輌番号'}
                    </InputLabel>
                    <div>
                      <FormControl
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                        error={formState.values.carsType === ''}
                      >
                        <FormLabel
                          id="cars-type"
                          style={{ fontSize: '.8em', color: '#0f1941' }}
                        >
                          車輌種別
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="cars-type-label"
                          name="cars-type"
                          value={formState.values.carsType}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(event, 'carsType', event.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="自家用車"
                            control={<Radio />}
                            label="自家用車"
                          />
                          <FormControlLabel
                            value="バイク"
                            control={<Radio />}
                            label="バイク"
                          />
                        </RadioGroup>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.carsType === '' ? '' : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <TextField
                        id="standard-basic"
                        label="地域名"
                        variant="standard"
                        value={formState.values.carNumberS1}
                        error={formState.values.carNumberS1 === ''}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'carNumberS1',
                            event.target.value,
                          );
                        }}
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                        placeholder="練馬"
                        helperText={
                          formState.values.carNumberS1 === ''
                            ? '必須項目'
                            : false
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="分類番号"
                        variant="standard"
                        value={formState.values.carNumberS2}
                        error={
                          (formState.values.carsType === '自家用車' &&
                            formState.values.carNumberS2 === '') ||
                          !String(formState.values.carNumberS2).match(
                            /^[A-Za-z0-9]*$/,
                          ) ||
                          (formState.values.carsType === 'バイク' &&
                            String(formState.values.carNumberS2).length > 1) ||
                          (formState.values.carsType === 'バイク' &&
                            !edit &&
                            String(cookies.carNumberS2).length > 1) ||
                          (formState.values.carsType === '自家用車' &&
                            String(formState.values.carNumberS2).length < 2) ||
                          (formState.values.carsType === '自家用車' &&
                            !edit &&
                            String(cookies.carNumberS2).length < 2)
                        }
                        onChange={(event) => {
                          handleChange(
                            event,
                            'carNumberS2',
                            event.target.value,
                          );
                        }}
                        onBlur={(event) => {
                          handleChange(
                            event,
                            'carNumberS2',
                            changeNumber(formState.values.carNumberS2),
                          );
                        }}
                        sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                        inputProps={{ maxLength: '3' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="123"
                        helperText={
                          formState.values.carsType === '自家用車' &&
                          formState.values.carNumberS2 === ''
                            ? errWord3
                            : !String(formState.values.carNumberS2).match(
                                /^[A-Za-z0-9]*$/,
                              ) ||
                              (formState.values.carsType === 'バイク' &&
                                String(formState.values.carNumberS2).length >
                                  1) ||
                              (formState.values.carsType === 'バイク' &&
                                String(cookies.carNumberS2).length > 1) ||
                              (formState.values.carsType === '自家用車' &&
                                String(formState.values.carNumberS2).length <
                                  2) ||
                              (formState.values.carsType === '自家用車' &&
                                String(cookies.carNumberS2).length < 2)
                            ? '入力桁数が不正です'
                            : false
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        id="standard-basic"
                        label="ひらがな"
                        variant="standard"
                        value={formState.values.carNumberS3}
                        error={formState.values.carNumberS3 === ''}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'carNumberS3',
                            event.target.value,
                          );
                        }}
                        sx={{ ml: 4, mr: 2, mb: 2, width: '30%' }}
                        placeholder="あ"
                        helperText={
                          formState.values.carNumberS3 === ''
                            ? '必須項目'
                            : false
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="連番1"
                        variant="standard"
                        type="tel"
                        value={formState.values.carNumberS4}
                        error={formState.values.carNumberS4 === ''}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'carNumberS4',
                            event.target.value,
                          );
                        }}
                        sx={{ ml: 2, mr: 2, mb: 2, width: '15%' }}
                        inputProps={{ maxLength: '2' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="12"
                        helperText={
                          formState.values.carNumberS4 === ''
                            ? '必須項目'
                            : false
                        }
                      />
                      <div
                        style={{
                          marginTop: '8%',
                          color: '#0f1941',
                          fontSize: '1em',
                          display: 'inline-block',
                        }}
                      >
                        {'-'}
                      </div>
                      <TextField
                        id="standard-basic"
                        label="連番2"
                        variant="standard"
                        type="tel"
                        value={formState.values.carNumberS5}
                        error={formState.values.carNumberS5 === ''}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'carNumberS5',
                            event.target.value,
                          );
                        }}
                        sx={{ ml: 1, mr: 2, mb: 2, width: '15%' }}
                        inputProps={{ maxLength: '2' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="34"
                        helperText={
                          formState.values.carNumberS5 === ''
                            ? '必須項目'
                            : false
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        id="standard-basic"
                        label="車名"
                        variant="standard"
                        value={formState.values.carName}
                        error={formState.values.carName === ''}
                        onChange={(event) => {
                          handleChange(event, 'carName', event.target.value);
                        }}
                        onBlur={(event) => {
                          handleChange(
                            event,
                            'carName',
                            changeNumber(formState.values.carName),
                          );
                        }}
                        sx={{ mt: 2, ml: 4, mr: 2, width: '80%' }}
                        placeholder="ヤリス"
                        helperText={
                          formState.values.carName === '' ? '必須項目' : false
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="standard-basic"
                        label="色"
                        variant="standard"
                        value={formState.values.carColor}
                        error={formState.values.carColor === ''}
                        onChange={(event) => {
                          handleChange(event, 'carColor', event.target.value);
                        }}
                        sx={{ mt: 2, ml: 4, mr: 2, width: '80%' }}
                        placeholder="白"
                        helperText={
                          formState.values.carColor === '' ? '必須項目' : false
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div>
                      <FormControl
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                        error={formState.values.applicationDistinction === ''}
                      >
                        <FormLabel
                          id="application-distinction"
                          style={{
                            fontSize: '.8em',
                            color: '#0f1941',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          申請区分{' '}
                          <InfoIcon
                            sx={{ fontSize: 16, color: '#2175d9' }}
                            onClick={(event) => {
                              setPopoverAnchorEl(event.currentTarget);
                              setPopover(true);
                              setPopoverContent(3);
                            }}
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="application-distinction-label"
                          name="application-distinction"
                          value={formState.values.applicationDistinction}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'applicationDistinction',
                              event.target.value,
                            );
                          }}
                        >
                          <FormControlLabel
                            value="新規"
                            control={<Radio />}
                            label="新規"
                          />
                          <FormControlLabel
                            value="既存"
                            control={<Radio />}
                            label="既存"
                          />
                        </RadioGroup>
                        <FormHelperText
                          style={{
                            display:
                              formState.values.applicationDistinction === ''
                                ? ''
                                : 'none',
                          }}
                        >
                          必須項目
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}
                      >
                        <FormLabel
                          id="application-distinction"
                          style={{
                            fontSize: '.8em',
                            color: '#0f1941',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          勤務先名{' '}
                          <InfoIcon
                            sx={{ fontSize: 16, color: '#2175d9' }}
                            onClick={(event) => {
                              setPopoverAnchorEl(event.currentTarget);
                              setPopover(true);
                              setPopoverContent(4);
                            }}
                          />
                        </FormLabel>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          value={formState.values.diligenceName}
                          error={formState.values.diligenceName === ''}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'diligenceName',
                              event.target.value,
                            );
                          }}
                          onBlur={(event) => {
                            handleChange(
                              event,
                              'diligenceName',
                              changeNumber(formState.values.diligenceName),
                            );
                          }}
                          placeholder="ランスタッド株式会社"
                          helperText={
                            formState.values.diligenceName === ''
                              ? '必須項目'
                              : false
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </div>
                    <InputLabel
                      sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
                    >
                      {'続いて以下を確認してください。'}
                    </InputLabel>
                    <Button
                      sx={{ mt: 3, ml: 3, fontSize: 16, fontWeight: 'bold' }}
                      onClick={handleOpen7}
                    >
                      ◆車輌通勤許可誓約書
                    </Button>
                    <Box
                      sx={{
                        mt: 0,
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <span>
                        <Checkbox
                          disabled={!carWrittenOath}
                          checked={
                            formState.values.writtenOathCarsCommute === 'はい'
                          }
                          onChange={(event) => {
                            if (
                              formState.values.writtenOathCarsCommute ===
                              'いいえ'
                            ) {
                              handleChange(
                                event,
                                'writtenOathCarsCommute',
                                'はい',
                              );
                            } else {
                              handleChange(
                                event,
                                'writtenOathCarsCommute',
                                'いいえ',
                              );
                            }
                          }}
                        />{' '}
                        誓約書について確認しました。
                      </span>
                    </Box>
                  </div>
                </Box>
              </span>
              <br />
            </div>
            <Box sx={{ mt: 12 }} />
          </div>
        ) : (
          ''
        )}
        {activeStep === 4 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '0.8em',
                  }}
                >
                  <span>理解した項目にチェックを入れてください。</span>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    display: 'flex',
                  }}
                >
                  <span>
                    <Checkbox
                      checked={formState.values.training1 === 'ok'}
                      onChange={(event) => {
                        handleChange(
                          event,
                          'training1',
                          formState.values.training1 === 'ok' ? 'non' : 'ok',
                        );
                      }}
                    />
                    <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                      1.個人情報とは
                    </span>
                    <Box
                      sx={{
                        mt: 1,
                        ml: 1,
                        mb: 2,
                        width: '90%',
                        fontSize: '0.9em',
                      }}
                    >
                      当該情報に含まれる氏名、生年月日その他の記述などによって特定の個人を識別できるもの、または個人識別符号が含まれるものが個人情報に該当します。
                    </Box>
                    <Box
                      sx={{
                        border: 'dashed',
                        width: '90%',
                        p: 1,
                        fontSize: '0.9em',
                      }}
                    >
                      ≪個人情報に該当するもの≫
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        氏名、住所、電話番号、年齢、生年月日、所属会社名、職位、社員名簿、住所録、緊急連絡網
                        など。
                      </span>
                      <br />
                      氏名のみで特定の個人を識別できる場合は「個人情報」となり、また、姓（名字）だけでは誰かを特定できませんが、その姓（名字）に「○□△会社に勤務」「東京都○△区○△町△番地在住」などの情報が加われば、その人が誰であるかを特定できるため、「個人情報」となります。
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        DNA、指紋、手指の静脈、運転免許証番号、基礎年金番号、マイナンバーなど。
                      </span>
                      <br />
                      個人の身体的特徴を変換した符号や公的機関が個人に割り振った番号などが「個人情報」となります。
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Checkbox
                        checked={formState.values.training2 === 'ok'}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'training2',
                            formState.values.training2 === 'ok' ? 'non' : 'ok',
                          );
                        }}
                      />
                      <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        2.個人情報・機密情報の取扱い原則
                      </span>
                      <Box sx={{ ml: 1, width: '90%', fontSize: '0.9em' }}>
                        就業先では、就業先の個人情報や機密情報に触れる機会があります、就業先の個人情報や機密情報を取り扱う各プロセスにおいて、セキュリティを意識し注意して取扱いをします。
                      </Box>
                      <br />
                      <Box sx={{ ml: 1, width: '90%', fontSize: '0.9em' }}>
                        <Box
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'center',
                          }}
                        >
                          ・
                        </Box>
                        <Box sx={{ width: '85%', display: 'inline-block' }}>
                          取得するときは、業務上の必要な範囲内で取得しなければなりません。
                        </Box>
                      </Box>
                      <br />
                      <Box sx={{ ml: 1, width: '85%', fontSize: '0.9em' }}>
                        <Box
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'center',
                          }}
                        >
                          ・
                        </Box>
                        <Box sx={{ width: '80%', display: 'inline-block' }}>
                          利用するときは、利用目的の範囲内で利用する、指定された目的以外に利用してはいけません。
                        </Box>
                      </Box>
                      <Box
                        sx={{ ml: 5, mt: 1, fontSize: '0.9em', width: '80%' }}
                      >
                        就業先の許可なく、記録媒体等に複写、複製してはいけません。
                      </Box>
                      <Box
                        sx={{ ml: 5, mt: 1, fontSize: '0.9em', width: '80%' }}
                      >
                        資料やデータを許可なく第三者に提供してはいけません。
                      </Box>
                      <Box
                        sx={{ ml: 5, mt: 1, fontSize: '0.9em', width: '80%' }}
                      >
                        指示された業務が終了したら、預かった資料やマニュアルは速やかに返却します。
                      </Box>
                      <br />
                      <Box sx={{ ml: 1, width: '80%', fontSize: '0.9em' }}>
                        <Box
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'center',
                          }}
                        >
                          ・
                        </Box>
                        <Box sx={{ width: '90%', display: 'inline-block' }}>
                          移送するときは、紛失、漏洩、誤配等の危険を最小限にするようにしなければなりません。
                        </Box>
                      </Box>
                      <br />
                      <Box sx={{ ml: 1, width: '80%', fontSize: '0.9em' }}>
                        <Box
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'center',
                          }}
                        >
                          ・
                        </Box>
                        <Box sx={{ width: '90%', display: 'inline-block' }}>
                          保管するときは、決められた所定の場所、施錠キャビネット等に保管しなければなりません。
                        </Box>
                      </Box>
                      <br />
                      <Box sx={{ ml: 1, width: '90%', fontSize: '0.9em' }}>
                        <Box
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            textAlign: 'center',
                          }}
                        >
                          ・
                        </Box>
                        <Box sx={{ width: '90%', display: 'inline-block' }}>
                          廃棄するときは、就業先の指示に従い、シュレッダー等により適正に廃棄します。
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        3.注意するポイント
                      </span>
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      （１）入退出許可証（社員証）、パスワード等の取扱い
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training3 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training3',
                              formState.values.training3 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ①入退出許可証が貸与されたら、紛失、盗難に注意します。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training4 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training4',
                              formState.values.training4 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ②他人と貸し借りは絶対にしてはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training5 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training5',
                              formState.values.training5 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ③就業先システムのアカウント／パスワードは他の者には開示せず、適切に管理します。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training6 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training6',
                              formState.values.training6 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ④万が一紛失等の際は、悪用されないよう、すぐに責任者に連絡し、紛失の手続きをします。
                      </Box>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        border: 'dashed',
                        width: '90%',
                        p: 1,
                        fontSize: '0.9em',
                      }}
                    >
                      （事例）入館証（セキュリティカード）が入ったカバンを電車に置いてきてしまった。駅に届いている可能性があるため、その日は「忘れました」と嘘をついた。次の日、駅に届けられていなかったため、会社で紛失手続きをしたところ、カード使用履歴にて会社に侵入されていたことが発覚・・・。
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        <ReportProblemIcon sx={{ verticalAlign: 'bottom' }} />
                        早急に手続きをしないと個人情報や社内秘の情報が盗まれる事件に発展することになりかねません。
                      </span>
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      （２）就業先構内での取扱い
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training7 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training7',
                              formState.values.training7 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ①コピー・FAX・プリンター機に資料を放置してはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training8 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training8',
                              formState.values.training8 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ②就業先の書類やデータを自宅に持ち帰ったり、クラウド上のデータを個人アカウントへ転用してはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training9 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training9',
                              formState.values.training9 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ③就業先で情報セキュリティに関する規程やルールが定められている場合はそれに従います。
                      </Box>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        border: 'dashed',
                        width: '90%',
                        p: 1,
                        fontSize: '0.9em',
                      }}
                    >
                      （事例）自宅で仕事をするため職場のデータをUSBに保存して無断で持ち出ししていたが、内部監査で書類の作成時刻が勤務時間外になっていることがわかり、事情聴取をされ、持ち出しが発覚・・・。
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        <ReportProblemIcon sx={{ verticalAlign: 'bottom' }} />
                        データの持ち出しは、USBの紛失やパソコンのウイルス感染など、漏洩の危険にさらす行為になります。
                      </span>
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      （３）ネットワーク、Eメールの取扱い
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training10 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training10',
                              formState.values.training10 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ①メールやFAXを送信する場合は、宛先や送る内容に間違いがないことを十分確認してから送信します。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training11 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training11',
                              formState.values.training11 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ②複数名へ一斉にメールをする場合は、必ず宛先BCCを利用します。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training12 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training12',
                              formState.values.training12 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ③業務目的以外でインターネットに接続してWEBサイトを閲覧したり、私用メールをしてはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training13 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training13',
                              formState.values.training13 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ④ウイルスの感染となるため心当たりのないメール（添付ファイル）は不用意に開封してはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training14 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training14',
                              formState.values.training14 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ⑤不特定多数が閲覧できるサイトへ就業先の情報を掲載、投稿してはいけません。
                        <br />
                        （Twitter・BLOG・Facebook・電子掲示板など）
                      </Box>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        border: 'dashed',
                        width: '80%',
                        p: 1,
                        fontSize: '0.9em',
                        ml: 2,
                      }}
                    >
                      （事例）業務中に自身の机上にある食べ物をスマートフォンで撮影し、Twitterに投稿。
                      <br />
                      しかしその画像には机上にあった社名などが記載された申告書類が写りこんでおり、取引先の情報が流出・・・。
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        <ReportProblemIcon sx={{ verticalAlign: 'bottom' }} />
                        SNSへの不用意な投稿がきっかけで会社の個人情報や機密情報を漏洩させてしまう場合があります。
                      </span>
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      （４）情報の持ち出し、運搬の取扱い（許可を得て、業務で持ち出しをするとき）
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training15 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training15',
                              formState.values.training15 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ①運搬に適したバックに入れ、肌身離さないようにし、目が届かない場所、電車の網棚、着席時の足元などに置いてはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training16 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training16',
                              formState.values.training16 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ②少しの時間でも、自動車に放置してはいけません。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training17 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training17',
                              formState.values.training17 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ③貸与されている携帯電話、ノートPC、タブレット等の紛失、盗難に注意します。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training18 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training18',
                              formState.values.training18 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ④運転中は寄り道や回り道をしてはいけません、就業先の情報を持ったまま飲酒は厳禁です。
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%' }}>
                      <Box
                        sx={{
                          width: '10%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                          textAlign: 'center',
                        }}
                      >
                        <Checkbox
                          checked={formState.values.training19 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training19',
                              formState.values.training19 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '80%',
                          display: 'inline-block',
                          fontSize: '0.9em',
                          ml: 2,
                        }}
                      >
                        ⑤飲食店、交通機関、エレベーターなど公共の場で、不適切な発言（就業先の情報や中傷）をしてはいけません。
                      </Box>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        border: 'dashed',
                        width: '80%',
                        p: 1,
                        fontSize: '0.9em',
                        ml: 2,
                      }}
                    >
                      （事例）職場の近くの喫茶店で同僚とランチの最中に、顧客であるA社を中傷するような会話をしていた。
                      <br />
                      しかし同じ店内にいたA社の関係者に聞かれていたことにより、後日、その会社から取引停止を告げられた・・・。
                      <br />
                      <br />
                      <span style={{ textDecoration: 'underline' }}>
                        <ReportProblemIcon sx={{ verticalAlign: 'bottom' }} />
                        公共の場では身内や知り合いがいることも考えられます。この発言を今この場でしてしまうと、もしかすると大変なことになってしまうかもという意識を忘れてはいけません。
                      </span>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        4.個人情報・機密情報の漏洩による影響
                      </span>
                      <Box sx={{ mt: 1, width: '90%' }}>
                        <Checkbox
                          checked={formState.values.training20 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training20',
                              formState.values.training20 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        />
                        <Box
                          sx={{
                            width: '80%',
                            display: 'inline-block',
                            fontSize: '0.9em',
                            ml: 2,
                          }}
                        >
                          次のことを十分認識し、個人情報等の保護に努めることが必要です。
                        </Box>
                      </Box>
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>
                      （１）漏洩した個人情報はどうなる？
                    </Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%', fontSize: '0.9em' }}>
                      情報はインターネット上の闇のマーケットで販売され、勧誘の電話や高値品の紹介などが届くようになるなど軽度な被害から、近年ではオレオレ詐欺、架空請求詐欺、偽装恐喝など巧妙な手口で悪用される被害が出ています。
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>（２）企業への影響</Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%', fontSize: '0.9em' }}>
                      個人情報の流出などによる企業側のダメージは計り知れません。
                      <br />
                      社会的信用の失墜、業務停止、ともすれば巨額な賠償にも発展しかねない大きな影響を受けます。
                      <br />
                      この他にも、顧客対応、漏洩情報の回収、マスコミ対応なども発生し、人手や時間、金銭的に大きな打撃を受けます。
                    </Box>
                    <br />
                    <Box sx={{ ml: 1, width: '90%' }}>（３）個人への影響</Box>
                    <Box sx={{ mt: 1, ml: 1, width: '90%', fontSize: '0.9em' }}>
                      個人情報の取扱いに関するルールを故意に違反したり、または重大な過失により、個人情報を漏洩させた場合は、法律による罰則に加えて、会社の規程により、懲戒解雇を含め厳しい処分が行われます。
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        5.事故発生時の対応
                      </span>
                      <Box sx={{ mt: 1, width: '90%' }}>
                        <Checkbox
                          checked={formState.values.training21 === 'ok'}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'training21',
                              formState.values.training21 === 'ok'
                                ? 'non'
                                : 'ok',
                            );
                          }}
                          sx={{
                            width: '10%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        />
                        <Box
                          sx={{
                            width: '80%',
                            display: 'inline-block',
                            fontSize: '0.9em',
                            ml: 2,
                          }}
                        >
                          万が一、紛失や盗難、情報漏洩等に気付いた場合、または可能性がある場合でも、自分だけで判断せず、直ちに就業先の責任者に報告し判断を仰いでください。
                          <br />
                          すぐに連絡・相談をすることにより、2次被害を抑えるアクションに繋げることができます。
                        </Box>
                      </Box>
                    </Box>
                    <br />
                    <Box sx={{ fontSize: '0.8em', margin: '0 1em 2em' }}>
                      当社は、プライバシーマーク認定事業者として、個人情報保護体制を維持・強化しながら、個人情報保護管理の徹底に努めています。
                      <br />
                      自身を守るためにも正しく理解して頂き、共に個人情報保護に取り組んでください。
                      <br />
                      ※本テキストは、当社 HP
                      派遣マイランスタッド内で参照できます。
                    </Box>
                  </span>
                </Box>
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {DR && activeStep === 5 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <Box sx={{ mt: 2, justifyContent: 'center', display: 'flex' }}>
                  <span style={{ fontSize: '.8em', fontWeight: 'bold' }}>
                    ～ドライバー業務へご就業されるスタッフのみなさまへ～
                  </span>
                </Box>
                <Box
                  sx={{
                    mt: 4,
                    ml: 2,
                    mr: 2,
                    mb: 2,
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <span style={{ fontSize: '.9em' }}>
                    ドライバー業務へあたるみなさまに、ご就業にあたり各種誓約書・承諾書等のご提出をお願いしております。
                    以下の内容を必ずご確認いただき、チェックボックスへチェックを入れて次へ進んでください。
                  </span>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    mr: 2,
                    mb: 3,
                  }}
                >
                  <span
                    style={{
                      color: '#e74536',
                      fontSize: '.9em',
                    }}
                  >
                    ※各内容をタップしていただくと詳細が表示されます。
                  </span>
                  <br />
                  <br />
                  <span
                    style={{
                      color: '#e74536',
                      fontSize: '.9em',
                    }}
                  >
                    ※運転業務に該当しない方は、一番下の「運転業務ではない」にチェックを入れて次に進んでください。
                  </span>
                  <br />
                  <br />
                  <span
                    style={{
                      color: '#e74536',
                      fontSize: '.9em',
                    }}
                  >
                    ※各種資格証明書等を企業に提供する予定がない場合は、「個人情報の提供予定なし」にチェックをいれてください。
                  </span>
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    mb: 1,
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        sx={{
                          display:
                            formState.values.drNotDrive === '運転しない'
                              ? 'none'
                              : '',
                          marginRight: '4px',
                        }}
                        checked={
                          formState.values.drConfirmingLetter === '同意する'
                        }
                        disabled={!drConfirmingLetter}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'drConfirmingLetter',
                            formState.values.drConfirmingLetter === '同意する'
                              ? '同意しない'
                              : '同意する',
                          );
                        }}
                      />{' '}
                      <div
                        style={{
                          width: '18px',
                          height: '18px',
                          background: '#cacaca',
                          borderRadius: '3px',
                          margin: '12px 16px 12px 12px',
                          display:
                            formState.values.drNotDrive === '運転しない'
                              ? ''
                              : 'none',
                        }}
                      ></div>
                      <Button
                        sx={{
                          padding: '0px',
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                        }}
                        onClick={handleOpen10}
                      >
                        運転の安全確保の為の確認書へ同意します
                      </Button>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    mb: 1,
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        sx={{
                          display:
                            formState.values.drNotDrive === '運転しない'
                              ? 'none'
                              : '',
                          marginRight: '4px',
                        }}
                        checked={formState.values.drWrittenOath === '同意する'}
                        disabled={!drWrittenOath}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'drWrittenOath',
                            formState.values.drWrittenOath === '同意する'
                              ? '同意しない'
                              : '同意する',
                          );
                        }}
                      />{' '}
                      <div
                        style={{
                          width: '18px',
                          height: '18px',
                          background: '#cacaca',
                          borderRadius: '3px',
                          margin: '12px 16px 12px 12px',
                          display:
                            formState.values.drNotDrive === '運転しない'
                              ? ''
                              : 'none',
                        }}
                      ></div>
                      <Button
                        sx={{
                          padding: '0px',
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                        }}
                        onClick={handleOpen9}
                      >
                        運転の安全確保の為の誓約書へ同意します
                      </Button>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    mb: 1,
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        sx={{
                          display:
                            formState.values.drConfirmingLetter ===
                              '同意する' ||
                            formState.values.drWrittenOath === '同意する'
                              ? 'none'
                              : '',
                          marginRight: '4px',
                        }}
                        checked={formState.values.drNotDrive === '運転しない'}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'drNotDrive',
                            formState.values.drNotDrive === '運転する'
                              ? '運転しない'
                              : '運転する',
                          );
                        }}
                      />{' '}
                      <div
                        style={{
                          width: '18px',
                          height: '18px',
                          background: '#cacaca',
                          borderRadius: '3px',
                          margin: '12px 16px 12px 12px',
                          display:
                            formState.values.drConfirmingLetter ===
                              '同意する' ||
                            formState.values.drWrittenOath === '同意する'
                              ? ''
                              : 'none',
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                          paddingTop: '11px',
                        }}
                      >
                        運転業務ではない
                      </span>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{
                    ml: 4,
                    mr: 2,
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Divider />
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    mb: 1,
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        sx={{
                          display:
                            formState.values.drNotProvided === '提供しない'
                              ? 'none'
                              : '',
                          marginRight: '4px',
                        }}
                        checked={
                          formState.values.drPersonalInformation === '同意する'
                        }
                        disabled={!drPersonalInformation}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'drPersonalInformation',
                            formState.values.drPersonalInformation ===
                              '同意する'
                              ? '同意しない'
                              : '同意する',
                          );
                        }}
                      />{' '}
                      <div
                        style={{
                          width: '18px',
                          height: '18px',
                          background: '#cacaca',
                          borderRadius: '3px',
                          margin: '12px 16px 12px 12px',
                          display:
                            formState.values.drNotProvided === '提供しない'
                              ? ''
                              : 'none',
                        }}
                      ></div>
                      <Button
                        sx={{
                          padding: '0px',
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                        }}
                        onClick={handleOpen11}
                      >
                        個人情報提供承諾のお願いへ同意します
                      </Button>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{
                    ml: 3,
                    mb: 6,
                    justifyContent: 'left',
                    display: 'flex',
                  }}
                >
                  <span>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        sx={{
                          display:
                            formState.values.drPersonalInformation ===
                            '同意する'
                              ? 'none'
                              : '',
                          marginRight: '4px',
                        }}
                        checked={
                          formState.values.drNotProvided === '提供しない'
                        }
                        onChange={(event) => {
                          handleChange(
                            event,
                            'drNotProvided',
                            formState.values.drNotProvided === '提供する'
                              ? '提供しない'
                              : '提供する',
                          );
                        }}
                      />{' '}
                      <div
                        style={{
                          width: '18px',
                          height: '18px',
                          background: '#cacaca',
                          borderRadius: '3px',
                          margin: '12px 16px 12px 12px',
                          display:
                            formState.values.drPersonalInformation ===
                            '同意する'
                              ? ''
                              : 'none',
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: '.8rem',
                          fontWeight: 'bold',
                          paddingTop: '11px',
                        }}
                      >
                        個人情報の提供予定なし
                      </span>
                    </div>
                  </span>
                </Box>
                <Divider />
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {prev !== 'default' &&
        ((activeStep === 6 && DR) || (activeStep === 5 && !DR)) ? (
          <span>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                padding: '1em',
                marginTop: '1em',
                marginBottom: '4em',
              }}
            >
              <div
                style={{
                  color: '#696969',
                  fontSize: '13px',
                  marginBottom: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                {moment().format('YYYY年M月D日')}
              </div>
              <br />
              <span
                style={{
                  color: '#e74536',
                }}
              >
                必ずご就業が確定された方のみお手続きをお願いします。
                <br />
              </span>
              <br />
              社会保険・雇用保険のいずれか１つ以上が加入対象となっている場合は、「次へ」を押して入力画面にお進みください。
              <br />
              どちらも加入対象ではない方は、「スキップ」ボタンを押してください。
              <br />
              <br />
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                登録にあたり以下を確認させていただきます。お手数ですが、確認できる書類を事前にご準備ください。
              </span>
              <br />
              <br />
              ■社会保険の加入手続き
              <br />
              ・基礎年金番号
              <br />
              <br />
              ■雇用保険の加入手続き
              <br />
              ・雇用保険被保険者番号
              <br />
              ＊番号が分からない場合は、過去の就業歴からお調べいたしますので企業名をご確認させていただきます。
              <br />
              <br />
              LINEで申告がご不安な方
              <br />
              社会保険センターでも承ります。お手数ですが、以下の電話番号へご連絡のうえ、本画面のスキップボタンを押してください。
              <br />
              <br />
              <span
                style={{
                  color: '#1d6ad4',
                  display: 'block',
                }}
              >
                社会保険センター 0120-940-911
                <br />
                （平日9:00〜18:00）
              </span>
            </div>
          </span>
        ) : (
          ''
        )}
      </Box>
      <Box sx={{ m: 2 }}>
        {/*{(activeStep === 7 && DR) ||*/}
        {/*(activeStep === 6 && !DR) ||*/}
        {/*(activeStep === 0 && prev === 'confirm') ? (*/}
        {(activeStep === 0 && confirm) ||
        (prev !== 'default' &&
          ((activeStep === 7 && DR) || (activeStep === 6 && !DR))) ||
        (prev === 'default' &&
          ((activeStep === 6 && DR) || (activeStep === 5 && !DR))) ? (
          <div>
            <div
              style={{
                display: confirm ? '' : 'none',
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  // ml: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '1em',
                }}
              >
                <span>入力内容を確認してください。</span>
              </Box>
              <InputLabel
                sx={{
                  mt: 3,
                  // ml: 4,
                  color: '#0f1941',
                  fontSize: '1.1em',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {'入職手続き'}
              </InputLabel>
            </div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <div
                  style={{
                    display: confirm ? 'none' : '',
                  }}
                >
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '1em',
                    }}
                  >
                    <span>入力内容を確認してください。</span>
                  </Box>
                </div>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev1' +
                        branchCheck;
                    } else {
                      setActiveStep(0);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'申告者情報'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.lastname}　${formState.values.firstname}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名(フリガナ):'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.lastnameRuby}　${formState.values.firstnameRuby}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'生年月日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.birthday}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'性別:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.gender === '男性' ? '男性' : '女性'}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'就業開始日:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.startDate}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'支店名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.branch}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'スタッフコード:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.staffCode}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'障害者区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.handicapped}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.handicapped === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害内容:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.handicappedDetail}`}
                      </Box>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.handicappedDetail !== 'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の程度:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.handicappedDegree}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'交付日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.deliveryDate}`}
                        </InputLabel>
                      </div>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.handicappedDetail === 'その他'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害の詳細:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.handicappedOther}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'勤労学生区分:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.workingStudent}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.workingStudent === '該当なし'
                          ? 'none'
                          : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'学校種別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.schoolLabel}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'学校名:'}
                      </InputLabel>
                      <Box
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {`${formState.values.schoolName}`}
                      </Box>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'入学年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.admissionDate}`}
                      </InputLabel>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev2' +
                        branchCheck;
                    } else {
                      setActiveStep(1);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'緊急連絡先'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'誓約書に同意:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {'同意する'}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'連絡先氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.emergencyLastname}　${formState.values.emergencyFirstname}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'続柄:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.emergencyRelationship}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'電話番号:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.emergencyTelNumber}`}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev3' +
                        branchCheck;
                    } else {
                      setActiveStep(2);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'現住所'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'郵便番号:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.postCode1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'都道府県:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.prefecture1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'市区町村:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.municipalities1}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'町域・番地:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.townArea1}`}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.otherAddress1 === '' ? 'none' : '',
                    }}
                  >
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'建物名など:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.otherAddress1}`}
                      </InputLabel>
                    </div>
                  </span>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev3' +
                        branchCheck;
                      setActiveStep(2);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'住民票登録住所'}
                  </InputLabel>

                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'現住所との相違:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.registrationAddress === '現住所と異なる'
                        ? '異なる'
                        : '同じ'}
                    </InputLabel>
                  </div>
                  <span
                    style={{
                      display:
                        formState.values.registrationAddress ===
                        '現住所と異なる'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'郵便番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.postCode2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'都道府県:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.prefecture2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'市区町村:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.municipalities2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'町域・番地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.townArea2}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.otherAddress2 === '' ? 'none' : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'建物名など:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.otherAddress2}`}
                        </InputLabel>
                      </div>
                    </span>
                  </span>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev3' +
                        branchCheck;
                    } else {
                      setActiveStep(2);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'甲乙申告'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'乙欄申告:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.columnBDeclaration === '申告する'
                        ? '申告する'
                        : '申告しない'}
                    </InputLabel>
                  </div>
                </Box>
                <Box
                  sx={{
                    ml: 4,
                    display:
                      formState.values.columnBDeclaration === '申告しない'
                        ? ''
                        : 'none',
                  }}
                >
                  <Box
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'世帯主'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'世帯主の氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.household === '申告者と同じ'
                          ? '申告者と同じ'
                          : '申告者と異なる'}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.household === '申告者と同じ'
                            ? 'none'
                            : '',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.lastname3}　${formState.values.firstname3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relationship3}`}
                        </InputLabel>
                      </div>
                    </span>
                  </Box>
                  <Box
                    sx={
                      {
                        // display:
                        //   formState.values.singleParentSituation !== '該当なし'
                        //     ? 'none'
                        //     : '',
                      }
                    }
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'配偶者について'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'結婚:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.marriage === 'いいえ'
                          ? 'していない'
                          : 'している'}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.marriage === 'はい' ? '' : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'控除申告:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {formState.values.dependentDeclaration === 'はい'
                            ? 'する'
                            : 'しない'}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.dependentDeclaration === 'はい'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'氏名:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerLastname}　${formState.values.partnerFirstname}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'氏名(フリガナ):'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerLastnameRuby}　${formState.values.partnerFirstnameRuby}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'生年月日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerBirthday}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'性別:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerGender}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'収入(給与):'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`¥${formState.values.partnerSalary}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'収入(老齢年金):'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`¥${formState.values.partnerPension}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'収入(その他収入):'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`¥${formState.values.partnerOtherIncome}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'居住地:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerLiving}`}
                          </InputLabel>
                        </div>
                        <span
                          style={{
                            display:
                              formState.values.partnerLiving === '別居'
                                ? ''
                                : 'none',
                          }}
                        >
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'郵便番号:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerPostCode}`}
                            </InputLabel>
                          </div>
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'都道府県:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerPrefecture}`}
                            </InputLabel>
                          </div>
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'市区町村:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerMunicipalities}`}
                            </InputLabel>
                          </div>
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'町域・番地:'}
                            </InputLabel>
                            <InputLabel
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                              }}
                            >
                              {`${formState.values.partnerTownArea}`}
                            </InputLabel>
                          </div>
                          <span
                            style={{
                              display:
                                formState.values.partnerOtherAddress === ''
                                  ? 'none'
                                  : '',
                            }}
                          >
                            <Divider />
                            <div>
                              <InputLabel
                                sx={{
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  width: '40%',
                                  textAlign: 'right',
                                  display: 'inline-block',
                                }}
                              >
                                {'建物名など:'}
                              </InputLabel>
                              <InputLabel
                                sx={{
                                  ml: 2,
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  textDecoration: 'underline',
                                  width: '50%',
                                  display: 'inline-block',
                                }}
                              >
                                {`${formState.values.partnerOtherAddress}`}
                              </InputLabel>
                            </div>
                          </span>
                        </span>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害者区分:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.partnerHandicapped}`}
                          </InputLabel>
                        </div>
                        <span
                          style={{
                            display:
                              formState.values.partnerHandicapped === '該当なし'
                                ? 'none'
                                : '',
                          }}
                        >
                          <Divider />
                          <div>
                            <InputLabel
                              sx={{
                                color: '#0f1941',
                                fontSize: '0.9em',
                                width: '40%',
                                textAlign: 'right',
                                display: 'inline-block',
                              }}
                            >
                              {'障害内容:'}
                            </InputLabel>
                            <Box
                              sx={{
                                ml: 2,
                                color: '#0f1941',
                                fontSize: '0.9em',
                                textDecoration: 'underline',
                                width: '50%',
                                display: 'inline-block',
                                verticalAlign: 'top',
                              }}
                            >
                              {`${formState.values.partnerHandicappedDetail}`}
                            </Box>
                          </div>
                          <Divider />
                          <span
                            style={{
                              display:
                                formState.values.partnerHandicappedDetail !==
                                'その他'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            <div>
                              <InputLabel
                                sx={{
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  width: '40%',
                                  textAlign: 'right',
                                  display: 'inline-block',
                                }}
                              >
                                {'障害の程度:'}
                              </InputLabel>
                              <InputLabel
                                sx={{
                                  ml: 2,
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  textDecoration: 'underline',
                                  width: '50%',
                                  display: 'inline-block',
                                }}
                              >
                                {`${formState.values.partnerHandicappedDegree}`}
                              </InputLabel>
                            </div>
                            <Divider />
                            <div>
                              <InputLabel
                                sx={{
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  width: '40%',
                                  textAlign: 'right',
                                  display: 'inline-block',
                                }}
                              >
                                {'交付日:'}
                              </InputLabel>
                              <InputLabel
                                sx={{
                                  ml: 2,
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  textDecoration: 'underline',
                                  width: '50%',
                                  display: 'inline-block',
                                }}
                              >
                                {`${formState.values.partnerDeliveryDate}`}
                              </InputLabel>
                            </div>
                          </span>
                          <span
                            style={{
                              display:
                                formState.values.partnerHandicappedDetail ===
                                'その他'
                                  ? ''
                                  : 'none',
                            }}
                          >
                            <div>
                              <InputLabel
                                sx={{
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  width: '40%',
                                  textAlign: 'right',
                                  display: 'inline-block',
                                }}
                              >
                                {'障害の詳細:'}
                              </InputLabel>
                              <InputLabel
                                sx={{
                                  ml: 2,
                                  color: '#0f1941',
                                  fontSize: '0.9em',
                                  textDecoration: 'underline',
                                  width: '50%',
                                  display: 'inline-block',
                                }}
                              >
                                {`${formState.values.partnerHandicappedOther}`}
                              </InputLabel>
                            </div>
                          </span>
                        </span>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'当年死別:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {formState.values.partnerBereavement ===
                            '該当しない'
                              ? 'いいえ'
                              : 'はい'}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display:
                        formState.values.marriage === 'いいえ' ? '' : 'none',
                    }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'寡婦/ひとり親について'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {'状況:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          whiteSpace: 'normal',
                        }}
                      >
                        {`${formState.values.situationSelection}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 1 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族1'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname1}　${formState.values.relativesFirstname1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby1}　${formState.values.relativesFirstnameRuby1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving1}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving1 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea1}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress1 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress1}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped1}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped1 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail1}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail1 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${
                              formState.values.relativesHandicappedDegree1 || ''
                            }`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate1}`}
                          </InputLabel>
                        </div>
                      </span>

                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail1 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${
                              formState.values.relativesHandicappedOther1 || ''
                            }`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement1 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 2 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族2'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname2}　${formState.values.relativesFirstname2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby2}　${formState.values.relativesFirstnameRuby2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving2}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving2 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea2}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress2 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress2}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped2}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped2 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail2}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail2 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${
                              formState.values.relativesHandicappedDegree2 || ''
                            }`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate2}`}
                          </InputLabel>
                        </div>
                      </span>

                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail2 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther2}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement2 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 3 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族3'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname3}　${formState.values.relativesFirstname3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby3}　${formState.values.relativesFirstnameRuby3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving3}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving3 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea3}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress3 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress3}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped3}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped3 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail3}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail3 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree3}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate3}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail3 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther3}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement3 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 4 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族4'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname4}　${formState.values.relativesFirstname4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby4}　${formState.values.relativesFirstnameRuby4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving4}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving4 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea4}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress4 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress4}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped4}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped4 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail4}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail4 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree4}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate4}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail3 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther3}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement4 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 5 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族5'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname5}　${formState.values.relativesFirstname5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby5}　${formState.values.relativesFirstnameRuby5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving5}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving5 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea5}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress5 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress5}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped5}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped5 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail5}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail5 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree5}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate5}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail5 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther5}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement5 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 6 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族6'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname6}　${formState.values.relativesFirstname6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby6}　${formState.values.relativesFirstnameRuby6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving6}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving6 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea6}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress6 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress6}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped6}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped6 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail6}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail6 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree6}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate6}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail6 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther6}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement6 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 7 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族7'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname7}　${formState.values.relativesFirstname7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby7}　${formState.values.relativesFirstnameRuby7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving7}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving7 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea7}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress7 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress7}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped7}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped7 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail7}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail7 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree7}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate7}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail7 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther7}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement7 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 8 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族8'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname8}　${formState.values.relativesFirstname8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby8}　${formState.values.relativesFirstnameRuby8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving8}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving8 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea8}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress8 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress8}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped8}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped8 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail8}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail8 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree8}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate8}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail8 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther8}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement8 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 9 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族9'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname9}　${formState.values.relativesFirstname9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby9}　${formState.values.relativesFirstnameRuby9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving9}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving9 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea9}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress9 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress9}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped9}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped9 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail9}`}
                        </Box>
                      </div>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail9 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree9}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate9}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail9 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther9}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement9 === '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: count >= 10 ? '' : 'none' }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?prev=prev3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'扶養親族10'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname10}　${formState.values.relativesFirstname10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby10}　${formState.values.relativesFirstnameRuby10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'性別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesGender10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(給与):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesSalary10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(老齢年金):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesPension10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'収入(その他収入):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`¥${formState.values.relativesOtherIncome10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'居住地:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLiving10}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesLiving10 === '別居'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'郵便番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPostCode10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'都道府県:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesPrefecture10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'市区町村:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesMunicipalities10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'町域・番地:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesTownArea10}`}
                        </InputLabel>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesOtherAddress10 === ''
                              ? 'none'
                              : '',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'建物名など:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesOtherAddress10}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'障害者区分:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesHandicapped10}`}
                      </InputLabel>
                    </div>
                    <span
                      style={{
                        display:
                          formState.values.relativesHandicapped10 === '該当なし'
                            ? 'none'
                            : '',
                      }}
                    >
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'障害内容:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.relativesHandicappedDetail10}`}
                        </Box>
                      </div>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail10 !==
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の程度:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedDegree10}`}
                          </InputLabel>
                        </div>
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'交付日:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesDeliveryDate10}`}
                          </InputLabel>
                        </div>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.relativesHandicappedDetail2 ===
                            'その他'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <div>
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'障害の詳細:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.relativesHandicappedOther2}`}
                          </InputLabel>
                        </div>
                      </span>
                    </span>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'当年死別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.relativesBereavement10 ===
                        '該当しない'
                          ? 'いいえ'
                          : 'はい'}
                      </InputLabel>
                    </div>
                  </Box>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev4' +
                        branchCheck;
                    } else {
                      setActiveStep(3);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'その他の申告'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'車輌通勤許可申請:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {formState.values.carsForCommuting === '申請する'
                        ? '申請する'
                        : '申請しない'}
                    </InputLabel>
                  </div>
                  <Box
                    style={{
                      display:
                        formState.values.carsForCommuting === '申請する'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'通勤開始日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.commuteStartDay}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌番号（地域名）:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carNumberS1}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌番号（分類番号）:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carNumberS2}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌番号（ひらがな）:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carNumberS3}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌番号（連番）:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carNumber}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carName}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車の色:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carColor}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'申請区分(車輌通勤):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.applicationDistinction}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌種別:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.carsType}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'勤務先名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.diligenceName}
                      </InputLabel>
                    </div>
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'車輌通勤許可誓約書:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.writtenOathCarsCommute}
                      </InputLabel>
                    </div>
                  </Box>
                </Box>
                <Box
                  sx={{ ml: 4 }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev5' +
                        branchCheck;
                    } else {
                      setActiveStep(4);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'個人情報機密情報保護研修'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {/*{'個人情報機密情報保護研修:'}*/}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {'修学済み'}
                    </InputLabel>
                  </div>
                  <br />
                </Box>
                <Box
                  sx={{ ml: 4, display: DR ? '' : 'none' }}
                  onClick={() => {
                    if (confirm) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?prev=prev6' +
                        branchCheck;
                    } else {
                      setActiveStep(5);
                      window.scrollTo({
                        top: 0,
                        // behavior: 'smooth',
                      });
                    }
                  }}
                >
                  <InputLabel
                    sx={{
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'ドライバー業務について'}
                  </InputLabel>
                  <div
                    style={{
                      display:
                        formState.values.drNotDrive === '運転しない'
                          ? 'none'
                          : '',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '65%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'運転の安全確保の為の確認書:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '20%',
                          display: 'inline-block',
                        }}
                      >
                        {'同意する'}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '65%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'運転の安全確保の為の誓約書:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '20%',
                          display: 'inline-block',
                        }}
                      >
                        {'同意する'}
                      </InputLabel>
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.drNotDrive === '運転しない'
                          ? ''
                          : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '65%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'運転業務ではない:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '20%',
                          display: 'inline-block',
                        }}
                      >
                        {'はい'}
                      </InputLabel>
                    </div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display:
                        formState.values.drNotProvided === '提供しない'
                          ? 'none'
                          : '',
                    }}
                  >
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '65%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'個人情報提供承諾のお願い:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '20%',
                        display: 'inline-block',
                      }}
                    >
                      {'同意する'}
                    </InputLabel>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.drNotProvided === '提供しない'
                          ? ''
                          : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '65%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'個人情報の提供予定なし:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '20%',
                        display: 'inline-block',
                      }}
                    >
                      {'はい'}
                    </InputLabel>
                  </div>

                  <br />
                </Box>
              </span>
            </div>
            <div
              style={{
                display: confirm ? 'none' : '',
              }}
            >
              <Box sx={{ mt: 9 }} />
            </div>
          </div>
        ) : (
          ''
        )}
        {(prev === 'completion' && branch === 'cs') ||
        (prev !== 'default' && activeStep === 7 && branch === 'cs') ||
        (prev === 'default' && activeStep === 6 && branch === 'cs') ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
                padding: '1em',
              }}
            >
              <span>
                <span>
                  おつかれさまでした。
                  {/*<br />*/}
                  こちらのページでの入力は完了となります。
                  <br />
                  <br />
                  {/*ご入力いただきました内容はご確認いただけますが編集はできません。*/}
                  {/*/!*<br />*!/*/}
                  {/*万が一、内容に誤りがあった場合は担当までご連絡ください。*/}
                  {/*<br />*/}
                  {/*<br />*/}
                </span>
              </span>
              <span
                style={{
                  display: edit ? '' : 'none',
                }}
              >
                <br />
                完了ボタンを押して終了してください。
              </span>
              <span
                style={{
                  display: edit ? 'none' : '',
                }}
              >
                <br />
                こちらの画面は閉じていただき、トーク上のご案内をご確認ください。
                <br />
              </span>
              <span
                style={{
                  display:
                    prev === 'completion' &&
                    !(
                      (formState.values.unemploymentTarget === 'いいえ' ||
                        formState.values.unemploymentOtherCompany === 'はい') &&
                      formState.values.socialTarget === 'いいえ'
                    )
                      ? ''
                      : 'none',
                }}
              >
                <br />
                <br />
                <br />
                <span
                  style={{
                    color: '#e74536',
                  }}
                >
                  社会保険手続きについて、ご登録翌日以降に訂正がある場合は以下にご連絡下さい。
                  <br />
                  <br />
                  【社会保険センター】
                  <br />
                  0120-940-911（平日9：00～18：00）
                </span>
                <br />
                <br />
                -マイナンバーご登録のお願い-
                <br />
                Fast numberからマイナンバーのご登録がお済みでしょうか？
                <br />
                未だお済みではない場合、お手数ですが以下からアプリをダウンロードしていただき、ご登録をお願いいたします。
                <br />
                <br />
                Fast numberアプリをダウンロード
                <br />
                <span
                  style={{
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://apps.apple.com/jp/app/fastnumber-ファストナンバー/id1054252418`,
                      external: true,
                    });
                  }}
                >
                  iOS
                </span>
                <br />
                <span
                  style={{
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://play.google.com/store/apps/details?id=jp.co.toppanf.fastnumber&hl=ja&gl=US&pli=1`,
                      external: true,
                    });
                  }}
                >
                  Android
                </span>
                <br />
                <br />＊
                ログインに必要なID/パスワードはコンサルタントからご案内させて頂いております。
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {(prev === 'completion' && !(branch === 'cs')) ||
        (activeStep === 1 && confirm) ||
        (prev !== 'default' &&
          branch !== 'cs' &&
          ((activeStep === 8 && DR) || (activeStep === 7 && !DR))) ||
        (prev === 'default' &&
          branch !== 'cs' &&
          ((activeStep === 7 && DR) || (activeStep === 6 && !DR))) ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
                padding: '1em',
              }}
            >
              <span>
                <span>
                  おつかれさまでした。
                  {/*<br />*/}
                  こちらのページでの入力は完了となります。
                  <br />
                  <br />
                  {/*ご入力いただきました内容はご確認いただけますが編集はできません。*/}
                  {/*/!*<br />*!/*/}
                  {/*万が一、内容に誤りがあった場合は担当までご連絡ください。*/}
                  {/*<br />*/}
                  {/*<br />*/}
                </span>
                <div
                  style={{
                    display:
                      formState.values.carsForCommuting === '申請する'
                        ? ''
                        : 'none',
                  }}
                >
                  <Box
                    sx={{
                      ml: 1,
                      mt: 2,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      ・車輌通勤許可申請書の添付書類
                    </div>
                    <div
                      style={{
                        marginTop: '8px',
                        fontSize: '.9em',
                      }}
                    >
                      以下よりアクセスし、必要書類の撮影とアップロードをお願いします。
                    </div>
                    {/*<div*/}
                    {/*  style={{*/}
                    {/*    fontSize: '.9em',*/}
                    {/*    color: '#e74536',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  （1度に最大4枚まで可。5枚以上になる場合はまず4枚まで撮影後、もう一度以下よりアクセスし残りの撮影をお願いします）*/}
                    {/*</div>*/}
                  </Box>
                  <Button
                    sx={{
                      ml: 2,
                      mt: 1,
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 'bold',
                      textAlign: 'left',
                    }}
                    onClick={async () => {
                      const context: any = liff.getContext();
                      const response = await axios.get(
                        `https://asia-northeast1-little-help-connect.cloudfunctions.net/getLiquidUrl?uid=${
                          formState.values.staffCode
                        }&liffid=${context ? context.liffId : ''}`,
                      );
                      console.log(response.data);

                      liff.openWindow({
                        url: response.data.application_url,
                        external: true,
                      });
                    }}
                  >
                    撮影とアップロードに進む
                  </Button>
                  <div
                    style={{
                      fontSize: '.9em',
                      padding: '0 8px',
                      fontWeight: 'bold',
                      color: '#e74536',
                    }}
                  >
                    （注意）こちらから車両関連書類以外の書類はご提出いただかないようお願いします
                  </div>
                  <br />
                  <div
                    style={{
                      fontSize: '.9em',
                      padding: '0 8px',
                    }}
                  >
                    【提出が必要な書類と箇所】
                    <br />
                    ①運転免許証（表面）
                    <br />
                    ※裏面に記載事項がある場合は裏面も必要です。
                    <br />
                    ②自動車検査証（表面のみ）
                    <br />
                    ※撮影画面では車検証を横向き（印字面が横向きになるよう）にして縦枠に収まるように撮影ください。
                    <br />
                    ③自賠責保険証（表面のみ）
                    <br />
                    ➃任意保険加入証明書（以下の情報が記載されている箇所を撮影ください。複数ページまたがる場合はその箇所ごとに撮影ください）
                    <br />
                    ・車種、保険期間、賠償の種類、賠償額
                  </div>
                </div>
                <Box
                  sx={{
                    mt: 2,
                    justifyContent: 'center',
                    display: 'flex',
                    fontWeight: 'bold',
                    color: '#e74536',
                  }}
                >
                  <span>最後に以下にアクセスして登録を行なってください。</span>
                </Box>
                <Box sx={{ ml: 1, mt: 2, display: 'flex' }}>
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    ・口座情報入力
                  </span>
                </Box>
                <Button
                  sx={{ ml: 2, fontSize: 16, fontWeight: 'bold' }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://www.randstad.co.jp/OCLSTF0000.do?uid=${formState.values.staffCode}&pid=line`,
                      external: true,
                    });
                  }}
                >
                  口座情報入力フォーム
                </Button>
                <br />
              </span>
              <br />
              <span
                style={{
                  fontSize: '.9em',
                  marginTop: '8px',
                }}
              >
                ※既に口座情報入力が完了している場合はこれで手続きは完了です。完了ボタンを押して終了してください。
              </span>
              <span
                style={{
                  display:
                    prev === 'completion' &&
                    !(
                      (formState.values.unemploymentTarget === 'いいえ' ||
                        formState.values.unemploymentOtherCompany === 'はい') &&
                      formState.values.socialTarget === 'いいえ'
                    )
                      ? ''
                      : 'none',
                }}
              >
                <br />
                <br />
                <br />
                <span
                  style={{
                    color: '#e74536',
                  }}
                >
                  社会保険手続きについて、ご登録翌日以降に訂正がある場合は以下にご連絡下さい。
                  <br />
                  <br />
                  【社会保険センター】
                  <br />
                  0120-940-911（平日9：00～18：00）
                </span>
                <br />
                <br />
                -マイナンバーご登録のお願い-
                <br />
                Fast numberからマイナンバーのご登録がお済みでしょうか？
                <br />
                未だお済みではない場合、お手数ですが以下からアプリをダウンロードしていただき、ご登録をお願いいたします。
                <br />
                <br />
                Fast numberアプリをダウンロード
                <br />
                <span
                  style={{
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://apps.apple.com/jp/app/fastnumber-ファストナンバー/id1054252418`,
                      external: true,
                    });
                  }}
                >
                  iOS
                </span>
                <br />
                <span
                  style={{
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://play.google.com/store/apps/details?id=jp.co.toppanf.fastnumber&hl=ja&gl=US&pli=1`,
                      external: true,
                    });
                  }}
                >
                  Android
                </span>
                <br />
                <br />＊
                ログインに必要なID/パスワードはコンサルタントからご案内させて頂いております。
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        <Grid
          item
          xs={7}
          style={{
            textAlign: 'center',
          }}
        >
          <AppBar
            position="fixed"
            color="primary"
            sx={{
              top: 'auto',
              bottom: -2,
              backgroundColor: '#1d6ad4',
            }}
          >
            <Grid
              container
              style={{
                height: 55,
              }}
            >
              {(prev !== 'default' &&
                ((activeStep === 8 && DR) || (activeStep === 7 && !DR))) ||
              (prev === 'default' &&
                ((activeStep === 7 && DR) || (activeStep === 6 && !DR))) ||
              (!inputStart && prev === 'default' && !confirm) ||
              (!inputStart && prev === 'top' && !confirm) ||
              (!inputStart && prev === 'completion' && !confirm) ? (
                <React.Fragment>
                  <Grid
                    item
                    sx={{ width: '100%' }}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      /*disabled={applied}*/
                      onClick={
                        !inputStart &&
                        prev !== 'completion' &&
                        prev !== 'prev5' &&
                        prev !== 'prev6'
                          ? handleStart
                          : () => {
                              liff.closeWindow();
                            }
                      }
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {!inputStart &&
                        prev !== 'completion' &&
                        prev !== 'prev5' &&
                        prev !== 'prev6'
                          ? '入力を開始する'
                          : '完了'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={6}
                    style={{
                      // backgroundColor: activeStep === 0 ? '#d9d9d9' : '#ff3300',
                      backgroundColor: '#d9d9d9',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="back key"
                      sx={{
                        // backgroundColor:
                        //   activeStep === 0 ? '#d9d9d9' : '#ff3300',
                        backgroundColor: '#d9d9d9',
                        marginTop: 1,
                        width: '100%',
                        color: '#1d6ad4',
                      }}
                      disabled={activeStep === 0 && !confirm && !edit}
                      onClick={() => {
                        activeStep === 0 && edit
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?prev=edit' +
                              branchCheck)
                          : prev !== 'default' &&
                            ((activeStep === 6 && DR) ||
                              (activeStep === 5 && !DR))
                          ? handleNext()
                          : activeStep === 0
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?next=next3' +
                              branchCheck)
                          : handleBack();
                      }}
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {prev !== 'default' &&
                        ((activeStep === 6 && DR) || (activeStep === 5 && !DR))
                          ? 'スキップ'
                          : '前へ'}
                      </span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      onClick={() => {
                        prev !== 'default' &&
                        ((activeStep === 6 && DR) || (activeStep === 5 && !DR))
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?next=next1' +
                              (edit ? '&edit=true' : '') +
                              branchCheck)
                          : (prev !== 'default' &&
                              ((activeStep === 7 && DR) ||
                                (activeStep === 6 && !DR))) ||
                            (prev === 'default' &&
                              ((activeStep === 6 && DR) ||
                                (activeStep === 5 && !DR))) ||
                            (activeStep === 0 && confirm)
                          ? handleOpen()
                          : handleNext();
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          width: '50%',
                        }}
                      >
                        {(prev !== 'default' &&
                          ((activeStep === 7 && DR) ||
                            (activeStep === 6 && !DR))) ||
                        (prev === 'default' &&
                          ((activeStep === 6 && DR) ||
                            (activeStep === 5 && !DR))) ||
                        (activeStep === 0 && confirm)
                          ? '登録'
                          : '次へ'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            {/*</Toolbar>*/}
          </AppBar>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'最終確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            登録内容に間違いなければ登録してください。
            <br />
            <br />
            <span
              style={{
                fontSize: '0.9em',
                color: '#e74536',
              }}
            >
              {confirm &&
              !(
                (formState.values.unemploymentTarget === 'いいえ' ||
                  formState.values.unemploymentOtherCompany === 'はい') &&
                formState.values.socialTarget === 'いいえ'
              )
                ? '※LINEの登録については、初回のみとし登録した内容の変更期限は当日限りとなります。以降の申請は入社手続きについては「書面」、社会保険手続きについてはお電話にてご連絡での申請となりますのでご了承ください。'
                : '※LINEの登録については、初回のみとし登録した内容の変更期限は当日限りとなります。以降の申請については「書面」での申請になりますのでご了承下さい。'}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>戻る</Button>
          <Button onClick={handleClickOpen} autoFocus>
            登録する
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'誓約書を確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            誓約書を確認しチェックを入れてください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'必須項目が未入力'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'よくある質問'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.家族ではなく本人が学生かどうかですか。
                <br />
                <br />
                A.はい、勤労学生として申告出来るのは申告者本人が学生である場合に限られます。ご自身が学生でなければ、扶養親族の中に学生がいても申告できません。
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.大学と大学校はどう違いますか。
                <br />
                <br />
                A.「大学」とは、「東京大学」や「早稲田大学」などのような、一般的な意味での大学のことを指します（詳しくは、学校教育法の第一条に定められている「大学」になります）。正式な学校名でも「大学校」とはならず「大学」になっています。
                <br />
                なお、「大学院」は一般に大学の中に設置されているものなので、学校としては「大学」になります。
                <br />
                一方、「大学校」とは、前述の大学ではない専修学校（いわゆる専門学校）などが学校名の一部として記載しているものです。「大学校」と名の付く学校については「大学校（大学でないもの）」を選択してください。
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.高等専門学校と高等専修学校はどう違いますか。
                <br />
                <br />
                A.「高等専門学校」とは、学校教育法の第一条に定められている学校であり、学校名が明確に「○○高等専門学校」となっています。
                <br />
                一方、「高等専修学校」とは、専修学校（いわゆる専門学校）の一種で、中学校を卒業した人を主な対象とした学校です。
                <br />
                参考までにWikipediaのページへのリンクを記載しますので、必要に応じてご覧ください。（Wikipediaの記載内容の正確性は保証できませんのであらかじめご了承ください）
                <br />
                <Link href="https://ja.wikipedia.org/wiki/日本の高等専門学校一覧">
                  日本の高等専門学校一覧
                </Link>
                <br />
                <Link href="https://ja.wikipedia.org/wiki/専修学校高等課程">
                  専修学校高等課程
                </Link>
                …高等専修学校の学校名が確認できます
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.専修学校とはどのような学校が含まれますか。
                <br />
                <br />
                A.「専修学校」とは、いわゆる専門学校のことで、高校を卒業した人が次の進路として選択する場合が多い学校です。
                <br />
                なお、一般的に「高等専修学校」も専修学校の一種ですが、ここでは区別して選択していただいています。中学校を卒業した人を主な対象とした専門学校に在籍している場合は「高等専修学校」を選択してください。
                <br />
                参考までにWikipediaのページへのリンクを記載しますので、必要に応じてご覧ください。（Wikipediaの記載内容の正確性は保証できませんのであらかじめご了承ください）
                <br />
                <Link href="https://ja.wikipedia.org/wiki/日本専修学校一覧">
                  日本専修学校一覧
                </Link>
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.各種学校とはどのような学校が含まれますか。
                <br />
                <br />
                A.「各種学校」とは、教養、料理、裁縫などの分野を教育する施設として設置されています。
                <br />
                例えば珠算学校、予備校、日本語学校、自動車学校、インターナショナルスクール、朝鮮学校などの外国人学校が含まれます。
                <br />
                参考までにWikipediaのページへのリンクを記載しますので、必要に応じてご覧ください。（Wikipediaの記載内容の正確性は保証できませんのであらかじめご了承ください）
                <br />
                <Link href="https://ja.wikipedia.org/wiki/各種学校">
                  各種学校
                </Link>
                …外部リンクとして、各都道府県ごとの各種学校の名称を調べることができるページへのリンク集があります
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.職業訓練学校とはどのような学校が含まれますか。
                <br />
                <br />
                A.勤労学生として申告できる学校の定義として、「職業能力開発促進法の規定による認定職業訓練を行う職業訓練法人で一定の要件に当てはまる課程を履修するもの」と定められていますので、在籍している学校が職業訓練法人によって運営されている場合、その学校の学生課のような学生との事務手続きを行なう所で、勤労学生として申告可能であるかどうかをご確認いただくことで判明します。
                <br />
                なお、勤労学生として申告可能である場合、以下の証明書類が必要になりますので、ご確認の際に併せて証明書類をご請求していただくと便利です。
                <br />
                学校が「勤労学生控除の対象になる学校であること」を証明する書類
                <br />
                あなたが在学していることを証明する「在学証明書」
              </span>
              <span style={{ borderBottom: 'solid', display: 'inline-block' }}>
                Q.「その他の一条校」とはどのような学校ですか。
                <br />
                <br />
                A.ここでの選択肢にない「一条校」に在籍している場合、「その他の一条校」を選択してください。
                <br />
                「一条校」とは、学校教育法の第一条に定められている学校のことを言い、ここで「その他の一条校」に該当する学校は以下のようなものになります。
                <br />
                <br />
                幼稚園
                <br />
                小学校
                <br />
                中学校
                <br />
                義務教育学校（いわゆる小中一貫校など）
                <br />
                中等教育学校（いわゆる中高一貫校など）
              </span>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open5}
        onClose={handleClose5}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'就業開始時誓約書'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
              ランスタッド株式会社　御中
              <br />
              <br />
              私は、貴社の従業員として就業を開始するにあたり、雇用契約、就業規則及び以下の事項を遵守することを誓約します。本誓約書に違背した場合には、貴社の就業規則に則り処分を受けること、また、法的責任を負うことに異議ございません。
              <br />
              <br />
              1.就業規則および指揮命令に従い、誠実に業務を遂行いたします。
              <br />
              <br />
              <span style={{ fontSize: '0.8em' }}>
                ◇遅刻・欠勤・有給休暇取得の際は、必ず事前に就業先責任者（派遣従業員の場合は派遣先責任者、業務契約社員の場合は貴社管理者とし、以下同じ）および貴社の営業担当者へ連絡をすること。
                <br />
                ◇時間外や休日出勤については、就業先責任者の指示を仰ぐこと。
                <br />
                ◇就業に関する規程を遵守し、所定終業時刻以降は、承認又は指示を受けたときを除き速やかに退勤すること。
                <br />
                ◇機密情報又は個人情報（個人番号及び特定個人情報を含む）（以下、「機密情報等」という）を取り扱う場合には、所定のルール・手順、チェック体制などの確認をとって行うこと。また、機密情報等の保護に関する教育を実施する場合、当該教育を受講するとともに、その内容を遵守すること。
                <br />
                ◇職場の正常な秩序維持のために、所持品の検査を求められた場合は、これに協力すること。
              </span>
              <br />
              <br />
              <br />
              2.信用と品位を失墜させないよう、行動いたします。
              <br />
              <br />
              <span style={{ fontSize: '0.8em' }}>
                ◇ビジネスにふさわしい身だしなみ（服装・髪型・爪など）を心がけること。
                <br />
                ◇就業中は、勝手に職場を離れたり、私用面会、私用電話、私用メールをしないこと。
                <br />
                ◇職場のパソコン等を使用して、インターネット上の私的な閲覧や交信等の行為を一切しないこと。
                <br />
                ◇日常携行品以外の私物をみだりに職場に持ち込まないこと。
                <br />
                ◇就業中は電話その他の接遇時の言語においても意を配り、必要以上の冗長に流れる雑談に陥ることや性的な言動により人を不愉快にさせないこと。
                <br />
                ◇就業先や貴社において、ビラの配布、掲示、署名運動等自己の利益を図る政治運動、宗教活動及びこれらに類する勧誘行為等、業務に関係のない活動をしないこと。
                <br />
                ◇職場の風紀、秩序を乱す等業務の妨害となる行為をしないこと。
                <br />
                ◇いわゆるハラスメントとみなされる行為（セクハラ、パワハラ、マタハラ等）により、相手方が不利益を受けたり、その行為により職場関係を悪化させるようなことをしないこと。
                <br />
                ◇反社会的勢力（暴力団およびその関係団体等）と一切関係がなく、また今後も一切関係をもたないこと。
              </span>
              <br />
              <br />
              <br />
              3.業務上知り得た一切の機密情報等を、在職中はもとより、退職後といえども、いかなる第三者にも漏洩いたしません。
              <br />
              <br />
              <span style={{ fontSize: '0.8em' }}>
                ◇記録装置（カメラ・ビデオ・録音機能がついたもの等）や記録媒体（フラッシュメモリ、CD-R等）、個人所有のパソコンの持ち込みに関しては、就業先責任者の指示に従うものとし、これらの許可なく使用しないこと。
                <br />
                ◇機密情報等を業務の遂行以外の目的に使用しないこと。また業務の遂行のために必要な範囲以外には一切の機密情報等にアクセスしないこと。
                <br />
                ◇あらかじめ指示された場所（特に定め、または指示がない場合は雇用契約書上の就業場所）で、機密情報等を利用・アクセスその他の取り扱いを行うものとし、当該場所から許可なく機密情報等を持ち出さないこと。また、当該場所以外で機密情報を話題にしないこと。
                <br />
                ◇機密情報等や業務内容、業務上知り得た職場に関することを、インターネット上の掲示板、ブログ、ソーシャルネットワークサービス、その他インターネット上の媒体に書き込むなどして公表しないこと。なお、これらの行為は、犯罪行為となるだけではなく、高額な損害賠償責任を負うことを十分に認識すること。
                <br />
                ◇機密情報等に関する事故（機密情報の漏洩、紛失、き損など、但し、これらに限定されない。）を発生させた場合、または発生させた可能性がある場合、直ちに就業先責任者に当該事故について報告すること。
                <br />
                ◇その他、機密情報等の取扱いに関し、貴社および就業先責任者の指示する事項について遵守すること。
              </span>
              <br />
              <br />
              <br />
              4.就業上で貸与・交付を受けた物品等（書類、資料、セキュリティーカード、制服、携帯電話、名刺等、但しこれに限定されない。以下「貸与物」という）は、指示があった場合、または就業終了時に全てを速やかに返却いたします。
              <br />
              <br />
              <span style={{ fontSize: '0.8em' }}>
                ◇貸与物を貸与物本来の用途、使用態様で使用する他、常時整備し、品質の維持に努める等、善良な管理者としての注意義務をもって管理し、承認なしに貸与物を改造し、または付属品を付加する等の変更を加えないこと。
                <br />
                ◇貸与物を業務遂行目的に限り使用するものとし、他の目的で使用しないこと、および第三者に使用させないこと。
                <br />
                ◇貸与物の利用・管理状況の報告の求めに対して速やかに応じ、貸与物の取扱い検査に協力すること。
              </span>
              <br />
              <br />
              <br />
              5.健康状態は、心身ともに業務遂行に支障ありません。また、以後、支障をきたす恐れがある場合には、速やかに貴社に報告いたします。
              <br />
              <br />
              6.契約条件が社会保険（健康保険・厚生年金・介護保険）・雇用保険の加入要件を満たす場合は、それぞれ加入します。また退職時は契約終了日付での脱退手続きに伴い、すみやかに健康保険証を貴社に返却いたします。
              <br />
              <br />
              7.私が貴社に申告又は提供する個人情報は事実に相違がないことを誓約いたします。
              <br />
              貴社から事実確認のために必要な証明書等の提示又は提出を求められた場合には、それに応じます。
              <br />
              <br />
              8.本誓約書に違反、または故意もしくは重大な過失により貴社に損害を与えた場合は、その損害を賠償いたします。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose5} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open7}
        onClose={handleClose7}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'車輌通勤許可申請書'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
              ランスタッド株式会社　御中
              <br />
              <br />
              私は、自己所有車輌を通勤に使用するにあたり、交通法規ならびに「通勤手当支給規程（スタッフ）」を遵守することを誓約いたします。また、通勤手当支給規程（スタッフ）に定める各自動車保険を付保している証として、以下の書類を添付いたします。
              <br />
              <br />
              1.運転免許証の写し
              <br />
              2.自動車検査証及び自賠責保険の写し
              <br />
              3.任意保険加入証明書の写し
              <br />
              <br />
              なお、上記の対象車両及び保険内容、登録住所、自動車運転免許に変更があった場合には、速やかに貴社に報告いたします。
              <br />
              また、万一これらに違反し、会社に損害を与えた時は、その損害を賠償いたします。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose7} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open8}
        onClose={handleClose8}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            選択した日付は無効です。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose8} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open9}
        onClose={handleClose9}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: '1.1em', textAlign: 'center' }}
        >
          {'誓約書'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                fontSize: '0.8em',
                display: 'block',
                textAlign: 'right',
              }}
            >
              DR 事業部用 H27.10 版
            </span>
            <br />
            <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
              ランスタッド株式会社
              <br />
              代表取締役社長 猿谷 哲 殿
              <br />
              <br />
              私はランスタッド株式会社（以下「ランスタッド」）で
              ＤＲ事業部に係わる業務（以下「本業務」）に就業するにあたり以下を誓約します。
              <br />
              <br />
              １.
              私の故意または過失、法令の違反行為、報告の詐称または隠蔽により、ランスタッド
              又は第三者に損害が発生した場合、私個人に損害賠償責任が発生する可能性があるこ
              とを認識し、そのような責任が発生した場合、速やかに私の責任を履行します。
              <br />
              <br />
              ２.
              私の業務遂行中に発生した事故や荷物の破損、汚損、溶解等に関しては、上記第
              1
              項でいう故意・過失やその他の不正行為の悪性により、ランスタッドが、法律に基
              づき私に賠償請求することがあることを認識しています。このような場合、当該精
              求にしたがって速やかに賠償します。
              <br />
              <br />
              ３.
              私は派遣先より貸与を受けたもの（制服や携帯電話等）は必ず本業務から離職する
              と同時又はそれ以前に返却します。万が一、該当貸与物を紛失、破損等をしてしま
              った場合は、派遣先又はランスタッドの請求に従い、自身の責任により弁償等を行
              います。
              <br />
              <br />
              ４.
              金銭授受のある本業務に従事した場合、金銭の紛失、盗難などは、第１項の趣旨に
              従い、請求があれば、私が弁償等をします。
              <br />
              <br />
              ５.
              タイムシートは適正に記入し、定められた提出期限までにランスタッドに提出しま
              す。タイムシートの偽造や虚偽の報告をした場合、ランスタッドの該当する就業規
              則に従い懲戒等の処分があることを認識しています。
              <br />
              <br />
              ６.
              私は、本業務の遂行にあたり、ランスタッドにおいて入れ墨（タトゥーその他の名
              称を問わず体に関して色素を施すものを含む）が固く禁止されていることを認識し
              ています。私は、現在、入れ墨を施しておらず、また、就業開始後から貴社を退職
              するまでの間においても、入れ墨を施しません。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose9} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open10}
        onClose={handleClose10}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: '1.1em', textAlign: 'center' }}
        >
          {'運転の安全確保のための確認書'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                fontSize: '0.8em',
                display: 'block',
                textAlign: 'right',
              }}
            >
              DR 事業部用 H27.10 版
            </span>
            <br />
            <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
              ランスタッド株式会社
              <br />
              代表取締役社長 猿谷 哲 殿
              <br />
              <br />
              私、（以下「私」）はランスタッド株式会社（以下「ランスタッド」）で
              DR
              事業部に係わる自動車運転の業務（以下「本業務」）に（申し込むにあたり/従事しておりますが）、私及び
              第三者の安全の目的で、以下の確認を行います。
              <br />
              <br />
              １．
              本業務の遂行にあたり、私、第三者及びランスタッドにおける安全の確保（以下「本安全の確保」）
              の目的で、①運転者である私が、運転に支障を及ぼす可能性のある障害を有しないことを確認（以
              下「本確認」）する必要性があること、及び
              ②そのような障害又はその懸念がある場合には、ラ
              ンスタッドが紹介する医師の診断を受けて、最終的に本業務の可否を法令及び医学上の見地から客
              観的に判断すること（以下「本安全の確保手続き」）が必要であることについて、ランスタッドか
              ら説明を受けました。私は、本確認および本安全の確保手続きの必要性について認識し、理解して
              います。
              <br />
              <br />
              ２．
              上記目的のため、私は以下の一方であることを確認し、同意します。 (1)
              現在のところ、私は統合失調、てんかん、そううつ病（双極性障害）及び重度の眠気
              の症状を呈する睡眠障害など、本業務を遂行するのに重大な障害を有していないことを
              確認します。また、アルコール、麻薬、大麻、アヘン、覚せい剤等の中毒者でないこと
              を申告いたします。（上記症状、障害、中毒を以下総称して「運転上の障害の可能性」）
              (2)
              現在、私は運転上の障害の可能性を有しているか、その有無について懸念を有していま
              すが、本業務への就業又は継続を希望しています。一方で、私は本業務上の安全の確保
              も熱望しているため、ランスタッドの紹介する医師その他専門家（以下総称して「医師
              等」）の診断を受け、客観的アドバイスに従うこと、及び当該医師等の判断をランスタッ
              ドが医師から直接受け取り、私の本業務への就業の可否を検討し、決定することに同意
              します。
              <br />
              <br />
              ３．
              私が本業務に就業している場合又は今後就業する場合、ランスタッドが安全確保のため、上
              記の申告内容の再確認を定期的に行うことについて同意するとともに、私自身が上記の病気や
              障害に気づいた場合には、速やかにランスタッドに申告します。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose10} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open11}
        onClose={handleClose11}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: '1.1em', textAlign: 'center' }}
        >
          {'個人情報提供承諾のお願い'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                fontSize: '0.8em',
                display: 'block',
                textAlign: 'right',
              }}
            >
              DR 事業部用 2024.9 版
            </span>
            <br />
            <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
              東京都新宿区西新宿1-26-2
              <br />
              新宿野村ビル16階
              <br />
              ランスタッド株式会社　DR事業部
              <br />
              部長　今関　裕
              <br />
              電話　0120-230-440
              <br />　 　　
              <br />
              拝啓
              時下ますますご清栄のこととお喜び申し上げます。毎々格別のお引き立てをいただき、ありがとうございます。
              <br />
              さて、今般お客様の個人情報を、下記の条件で提供したく、ご連絡差し上げております。
              <br />
              つきましては、以下ご確認の上、ご同意の可否チェック、ご署名、ご捺印いただけますよう、宜しくお願い致します。
              <br />
              敬具
            </span>
            <br />
            <br />
            <span
              style={{
                fontSize: '0.8em',
                fontWeight: 'bold',
                display: 'block',
                textAlign: 'center',
              }}
            >
              記
            </span>
            <br />
            <span style={{ fontSize: '0.8em' }}>
              <b>弊社における個人情報の取得方法</b>
              <br />
              ご本人より取得（Web画面への入力、書面記載ご提出、聞き取り等により取得）
              <br />
              <br />
              <b>弊社における個人情報の利用目的</b>
              <br />
              人材派遣、職業紹介、再就職支援等のお仕事のご案内、お仕事をご紹介する上で必要な諸手続き、提供する各種サービスへの手続き、各種お問合せ等
              <br />
              <br />
              <b>個人情報を第三者に提供する事項</b>
              <br />
              【提供する個人情報の項目】
              <br />
              ・運転記録証明書(氏名・生年月日・免許証番号・行政処分歴・累積点数）※コピー・過去5年分
              <br />
              ・初任診断受講記録（氏名・受講結果）※コピー
              <br />
              ・運転免許証及び各資格証
              <br />
              <br />
              【提供する目的】
              <br />
              ・就業にあたり事故歴、違反歴の確認のため
              <br />
              ・初任診断受講のエビデンスのため
              <br />
              <br />
              【当該情報の提供を受ける者又は提供を受ける者の組織の種類、及び属性】
              <br />
              ご契約書に記載の派遣先企業
              <br />
              <br />
              【個人情報の取扱いに関する契約の有無】
              <br />
              ・あり
              <br />
              <br />
              【要配慮個人情報の有無】
              <br />
              ・あり
              <br />
              <br />
              【提供の手段又は方法】
              <br />
              ・Email、FAX　又はコピー提出　　　　　　
              <br />
              <br />
              <b>その他</b>
              <br />
              弊社では、日本工業規格「個人情報保護マネジメントシステム－要求事項(JIS
              Q15001)」に準拠した個人情報の適切な保護に努めております。なお、本件に関するお問い合わせは、表記部門までご連絡下さい。
              <br />
              <br />
              ※個人情報のお問い合わせ先 ランスタッド株式会社　
              <br />
              DR事業部　dr_info@randstad.co.jp
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose11} autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={showPopover}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            color: '#0f1941',
            fontSize: '14px',
            padding: '1em',
          }}
        >
          {popovers[popoverContent]}
        </div>
      </Popover>
    </span>
  );
};

export default EntryForm;
