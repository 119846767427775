import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

export interface ApplicationState {
  applications: any[];
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: ApplicationState = {
  applications: [],
  status: 'idle',
  loaded: false,
};

export const retrieveApplications = createAsyncThunk(
  'retrieveApplications',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/v2/applications/full-time`,
      {
        headers: {
          Authorization: `Bearer ${params.idToken}`,
          'X-ClientId': params.channelId,
        },
      },
    );
    return response.data;
  },
);

export const retrieveCjcApplications = createAsyncThunk(
  'retrieveCjcApplications',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/v2/applications/cjc/full-time`,
      {
        headers: {
          Authorization: `Bearer ${params.idToken}`,
          'X-ClientId': params.channelId,
        },
      },
    );
    return response.data;
  },
);

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveApplications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveApplications.fulfilled, (state, action) => {
        state.status = 'idle';
        state.applications = state.applications.concat(action.payload);
        state.loaded = true;
      });

    builder
      .addCase(retrieveCjcApplications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveCjcApplications.fulfilled, (state, action) => {
        state.status = 'idle';
        state.applications = state.applications.concat(action.payload);
        state.loaded = true;
      });
  },
});

export const { hello } = applicationSlice.actions;

export const selectApplications = (state: RootState) =>
  state.application.applications;
export const isLoaded = (state: RootState) => state.application.loaded;

export default applicationSlice.reducer;
