// A mock function to mimic making an async request for data
import liff from '@line/liff';
import { jwtDecode } from 'jwt-decode';

const testUserId = process.env.REACT_APP_TEST_USER_ID || '';
const redirectUri =
  process.env.REACT_APP_LINE_LOGIN_REDIRECT_URI + window.location.pathname;

export function userId() {
  const context = liff.getContext();
  return context?.userId || testUserId;
}

const isIdTokenExpired = (idToken: any) => {
  if (!idToken) return true;

  try {
    const decoded: { exp?: number } = jwtDecode(idToken);
    const now = Math.floor(Date.now() / 1000);

    return decoded.exp !== undefined && decoded.exp < now;
  } catch (error) {
    return true;
  }
};

export function idToken() {
  if (!liff.isLoggedIn()) {
    liff.login({
      redirectUri,
    });
  } else {
    const idToken = liff.getIDToken();
    if (isIdTokenExpired(idToken)) {
      liff.logout();
      setTimeout(() => {
        liff.login({
          redirectUri,
        });
      }, 1000);
    } else {
      return liff.getIDToken();
    }
  }
}

export function channelId() {
  return (window as any).channelId;
}
