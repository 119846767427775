import * as React from 'react';
import { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import { useLocation, useMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import queryString from 'query-string';

import BusinessIcon from '@mui/icons-material/Business';
import TrainIcon from '@mui/icons-material/Train';
import YenIcon from '@mui/icons-material/CurrencyYen';

import {
  isLoaded as isLoadedProfile,
  removeProfessionalPin,
  retrieveProfile,
  saveProfessionalPin,
  selectProfile,
} from '../features/profile/profileSlice';
import {
  isLoaded as isLoadedJob,
  retrieveProfessionalJob,
  selectJobs,
} from '../features/job/jobSlice';

import { channelId, idToken, userId } from '../utils/liff';
import liff from '@line/liff';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

const PROFILE_LIFF_ID = location.pathname.includes('2')
  ? process.env.REACT_APP_PROFILE_2_LIFF_ID
  : process.env.REACT_APP_PROFILE_LIFF_ID;

export default function ProfessionalDetail() {
  const match = useMatch('/professional/detail/:number');
  let number: any = match?.params?.number;
  // console.log(number);
  if (!number) {
    try {
      const search = useLocation().search;
      const query = queryString.parse(search);
      // console.log(query);
      number = query['number'] || '';
    } catch (e) {
      console.error(e);
    }
  }

  let lang = 'ja';
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    lang = String(query['lang']);
  } catch (e) {
    console.error(e);
  }

  const en = lang === 'en';

  const dispatch = useAppDispatch();

  const profile: any = useAppSelector(selectProfile);
  const profileLoaded = useAppSelector(isLoadedProfile);

  const jobs = useAppSelector(selectJobs);
  const jobLoaded = useAppSelector(isLoadedJob);

  let job: any = null;
  if (jobs.length > 0) {
    job = jobs.find((j) => j.number === number) || {};
  }

  useEffect(() => {
    document.title = en ? `Job No. ${number}` : `仕事No. ${number}`;

    if (!profileLoaded) {
      dispatch(
        retrieveProfile({
          idToken: idToken(),
          channelId: channelId(),
        }),
      );
    } else if (!jobLoaded) {
      dispatch(
        retrieveProfessionalJob({
          number,
          lang,
        }),
      );
    }
  }, [profileLoaded, jobLoaded]);

  let pinned = false;
  if (profile && profile.professionalPins) {
    pinned = profile.professionalPins.includes(number);
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSavePin = () => {
    setOpen(true);

    dispatch(
      saveProfessionalPin({
        idToken: idToken(),
        channelId: channelId(),
        number,
      }),
    );

    const text = en
      ? `[Save job]
Job No.${number}「${job.title}」`
      : `[お仕事キープ]
お仕事No.${number}「${job.title}」`;

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleRemovePin = () => {
    setOpen2(true);

    dispatch(
      removeProfessionalPin({
        idToken: idToken(),
        channelId: channelId(),
        number,
      }),
    );
  };

  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => {
    setOpen3(false);
  };

  const openConfirm = () => {
    setOpen3(true);
  };

  const [open4, setOpen4] = React.useState(false);
  const handleClose4 = () => {
    setOpen4(false);

    liff.closeWindow();
  };

  const handleApplyJob = () => {
    setOpen3(false);
    setOpen4(true);

    const text = en
      ? `[Apply job]
Job No.${number}「${job.title}」`
      : `[お仕事応募]
お仕事No.${number}「${job.title}」`;

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [open5, setOpen5] = React.useState(!userId());
  const handleClose5 = () => {
    setOpen5(false);
    liff.closeWindow();
  };

  scrollTo(0, 0);

  // 既に応募済みの求人かチェック
  const applied = (profile ? profile.professionalApplications || [] : []).some(
    (a: string) => a === number,
  );

  return !jobLoaded ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      {jobs.length === 0 || job === null ? (
        <span>
          <Grid
            container
            style={{
              padding: 22,
              backgroundColor: '#ffffff',
            }}
          >
            <Grid item xs={12}>
              {en
                ? 'We cannot find the job information you are looking for.'
                : 'お探しの求人情報が見つかりません。'}
              <br />
              {en
                ? 'The listing period may have ended.'
                : '掲載期間が終了した可能性があります。'}
              <br />
              <br />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="other"
                onClick={() => {
                  liff.openWindow({
                    url: `https://liff.line.me/${PROFILE_LIFF_ID}`,
                  });
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ flexGrow: 1, marginLeft: 1 }}
                >
                  {en
                    ? 'Click here to change your search criteria '
                    : '検索条件の変更はこちら '}
                </Typography>
                <SavedSearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </span>
      ) : (
        <span>
          <Grid
            container
            style={{
              background: '#6ba5b4',
              padding: '1em 1em .5em .2em',
              color: '#ffffff',
            }}
          >
            <Grid item xs={3}>
              <img src={job.image} alt="Job image" height={66} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" gutterBottom component="div">
                {job.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              padding: 22,
              backgroundColor: '#ffffff',
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={1}
                style={{
                  padding: '0 0 1em 0',
                }}
              >
                {job.conditions.map((condition: string, index: number) => {
                  return index < (lang === 'en' ? 3 : 4) ? (
                    <Chip key={index} label={condition} />
                  ) : null;
                })}
              </Stack>
              <div style={{ whiteSpace: 'pre-line' }}>
                {job.formattedDescription}
              </div>
              <br />
              <Divider variant="fullWidth" />
              <br />
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <div>
                  <BusinessIcon style={{ fontSize: 12 }} />
                  {job.industryType}
                </div>
                <div>
                  <TrainIcon style={{ fontSize: 12 }} />
                  {job.company}
                </div>
                <div>
                  <YenIcon style={{ fontSize: 12 }} />
                  {job.salary}
                </div>
              </React.Fragment>
              <br />
              <Divider variant="fullWidth" />
              <br />
            </Grid>
            <Grid item xs={12}>
              <React.Fragment>
                <div>
                  {en
                    ? 'Job Contact: Professional Business Division'
                    : `求人担当: プロフェッショナル事業本部`}
                </div>
              </React.Fragment>
              <div style={{ height: '66px' }} />
            </Grid>
          </Grid>
          <AppBar
            position="fixed"
            color="primary"
            sx={{
              top: 'auto',
              bottom: -2,
              backgroundColor: '#1d6ad4',
            }}
          >
            <Grid
              container
              style={{
                height: 55,
              }}
            >
              <Grid
                item
                xs={5}
                style={{
                  backgroundColor: pinned ? '#e74536' : '#ffac13',
                  textAlign: 'center',
                }}
              >
                {pinned ? (
                  <Button
                    color="inherit"
                    aria-label="remove pin"
                    sx={{
                      backgroundColor: '#e74536',
                      marginTop: 1,
                    }}
                    onClick={handleRemovePin}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {en ? 'Remove save' : 'キープを外す'}
                    </span>
                  </Button>
                ) : (
                  <Button
                    color="inherit"
                    aria-label="push pin"
                    sx={{
                      backgroundColor: '#ffac13',
                      marginTop: 1,
                    }}
                    onClick={handleSavePin}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {en ? 'Save' : 'キープする'}
                    </span>
                  </Button>
                )}
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="open drawer"
                  onClick={openConfirm}
                  sx={{
                    marginTop: 1,
                  }}
                  disabled={applied}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {applied
                      ? en
                        ? 'Applied'
                        : '応募済み'
                      : en
                      ? 'Apply job'
                      : 'この求人に応募する'}
                  </span>
                </Button>
              </Grid>
            </Grid>
            {/*</Toolbar>*/}
          </AppBar>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {en ? 'Info' : 'お知らせ'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {en ? 'Save a job.' : 'お仕事をキープしました。'}
                <br />
                {en
                  ? 'Jobs will be removed from your keep list when they are posted or no longer available.'
                  : '求人の掲載や募集が終了した際はキープリストから削除されます。'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {en ? 'Info' : 'お知らせ'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {en ? 'Removed a saved job' : 'お仕事のキープを解除しました。'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {en ? 'Confirm' : '確認'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {en
                  ? `Are you applying for Job No.${job.number} "${job.title}"?`
                  : `お仕事No.${job.number}「${job.title}」に応募しますか？`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} autoFocus>
                {en ? 'No' : 'いいえ'}
              </Button>
              <Button onClick={handleApplyJob}>{en ? 'Yes' : 'はい'}</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open4}
            onClose={handleClose4}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {en ? 'Info' : 'お知らせ'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {en ? 'Applied a job.' : 'お仕事に応募しました。'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose4} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open5}
            onClose={handleClose5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {en ? 'Info' : 'お知らせ'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {en
                  ? 'Available on the mobile version of LINE.'
                  : 'スマートフォン版LINEでご利用になれます。'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose5} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      )}
    </Box>
  );
}
