import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const HUB_ID = process.env.REACT_APP_HUB_ID || '';

export interface periodicSurveyState {
  jobs: any[];
  status: 'idle' | 'loading' | 'submitting' | 'finished' | 'failed';
  periodicSurvey: any;
  loaded: boolean;
  completed: boolean;
}

/**
 * 更新するコンタクトのオブジェクト
 */
export interface ContactObject_PeriodicSurvey {
  /** 求職ステータス（LINE） */
  line_want_status?: 'WANT' | '' | null;
  /** 求職ステータス変更日時（LINE） */
  line_want_change_date?: number | null;
  /** 就業開始入力日（LINE） */
  line_input_start_date?: string | null;
  /** 希望の雇用形態（LINE） */
  line_employment_type?:
    | 'フルタイムの長期派遣'
    | '時短や扶養内の長期派遣'
    | '短期派遣'
    | '派遣は希望しない'
    | ''
    | null;
  /** 希望職種（LINE） */
  line_preferred_job?:
    | [
        (
          | '事務全般'
          | 'コールセンター・営業・接客販売'
          | '工場・製造系'
          | '倉庫・物流・軽作業'
          | 'エンジニア（IT・技術）'
        ),
        'ドライバー・配送・送迎' | 'ドライバー・配送・送迎',
      ]
    | ''
    | string
    | null;
  /** 正社員求人への希望（LINE） */
  line_preferred_perm?: '興味がある' | '興味がない' | '' | null;
  /** 状況確認最終回答日（LINE） */
  states_last?: number | null;
}

interface PeriodicSurveyParams {
  values: ContactObject_PeriodicSurvey;
  idToken: string;
  channelId: string;
}

const initialState: periodicSurveyState = {
  jobs: [],
  status: 'idle',
  periodicSurvey: null,
  loaded: false,
  completed: false,
};

export const answerPeriodicSurvey = createAsyncThunk(
  'answerPeriodicSurvey',
  async (params: PeriodicSurveyParams) => {
    const { values } = params;
    try {
      // 前の回答を上書きするため未回答の項目は空文字でクリアする
      const properties: ContactObject_PeriodicSurvey = {
        line_want_status: values.line_want_status || '',
        line_want_change_date: new Date().setUTCHours(0, 0, 0, 0),
        line_input_start_date: values.line_input_start_date || '',
        line_employment_type: values.line_employment_type || '',
        line_preferred_job: values.line_preferred_job?.length
          ? Array.from(values.line_preferred_job).join(';')
          : '',
        line_preferred_perm: values.line_preferred_perm || '',
        states_last: new Date().setUTCHours(0, 0, 0, 0),
      };
      const response = await axios.patch(
        `${API_SERVER_URL}/line/v2/${HUB_ID}/contact`,
        properties,
        {
          headers: {
            Authorization: `Bearer ${params.idToken}`,
            'X-ClientId': params.channelId,
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const periodicSurveySlice = createSlice({
  name: 'periodicSurvey',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(answerPeriodicSurvey.pending, (state) => {
        state.status = 'submitting';
      })
      .addCase(answerPeriodicSurvey.fulfilled, (state) => {
        state.status = 'finished';
        state.completed = true;
      })
      .addCase(answerPeriodicSurvey.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectContractRenewStatus = (state: RootState) =>
  state.periodicSurvey.status;
export const isLoaded = (state: RootState) => state.periodicSurvey.loaded;

export default periodicSurveySlice.reducer;
