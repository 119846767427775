import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  isLoaded,
  retrieveProfile,
  selectProfile,
} from '../features/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { channelId, idToken, userId } from '../utils/liff';
import liff from '@line/liff';
import SpotProfileForm from '../components/spotForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function spotProfile() {
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(selectProfile);
  const loaded = useAppSelector(isLoaded);

  let retry = false;
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    retry = String(query['retry']) === 'true';
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    document.title = 'プロフィール';

    if (!loaded) {
      dispatch(
        retrieveProfile({
          idToken: idToken(),
          channelId: channelId(),
        }),
      );
    }
  }, [loaded]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            プロフィール
          </Typography>
        </Toolbar>
      </AppBar>
      {!profile ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <SpotProfileForm profile={profile} retry={retry} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
