import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { channelId, idToken } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';

import liff from '@line/liff';
import TextField from '@mui/material/TextField';
import submitComplaint from '../features/complaint/complaintSlice';

class SafetyConfirmationFormProps {}

const SafetyConfirmationForm: React.FC<SafetyConfirmationFormProps> = () => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      detail: '',
      reply: '',
      replyChannel: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [sending, setSending] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);

  const handleSubmit = () => {
    if (!formState.values.detail) {
      setOpen1(true);
      return;
    }

    if (!formState.values.reply) {
      setOpen2(true);
      return;
    }

    if (formState.values.reply === 'はい' && !formState.values.replyChannel) {
      setOpen3(true);
      return;
    }

    dispatch(
      submitComplaint({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
      }),
    );

    setSending(true);
    setTimeout(() => {
      setSending(false);
      setSubmit(true);
    }, 1000);

    const text = `[当社社員に関するご意見]
お申し出内容:
${formState.values.detail}
折り返しのご連絡希望: ${formState.values.reply}
ご希望のご連絡方法: ${formState.values.replyChannel}`;

    console.log(text);

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    liff.closeWindow();
  };

  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => {
    setOpen3(false);
  };

  const [textLength, setTextLength] = React.useState(0);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component="form" noValidate autoComplete="off">
        {sending ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : submit ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 4,
            }}
          >
            <Typography variant="h5" component="div">
              受付完了
            </Typography>
            <br />
            <FormLabel
              id="gendar-label"
              style={{
                fontSize: '.8em',
                color: '#0f1941',
                textAlign: 'center',
              }}
            >
              ご入力ありがとうございました。
              <br />
              折り返しのご連絡をご希望された場合、
              <br />
              担当者より1営業日以内にご連絡をさせて頂きます。
            </FormLabel>
          </Box>
        ) : (
          <div>
            <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '84%' }}>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                当社社員のご対応に関するご意見受付フォームです。
                <br />
                送信いただいた内容は、支店責任者（苦情処理担当者）へ直接送信されます。
              </FormLabel>
              <br />
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.9em', color: '#0f1941' }}
              >
                ■お申し出内容{' '}
                <span
                  style={{
                    color: '#e74536',
                  }}
                >
                  （必須）
                </span>
              </FormLabel>
              <div>
                <TextField
                  multiline
                  rows={8}
                  id="detail"
                  value={formState.values.detail}
                  onChange={(event) => {
                    handleChange(event, 'detail', event.target.value);
                    setTextLength(String(event.target.value).length);
                  }}
                  sx={{ mt: 2, ml: 0, mr: 0, mb: 0, width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '8px',
                  color: '#555',
                  display: 'flex',
                  justifyContent: 'end',
                  fontSize: '.9em',
                }}
              >
                {textLength} / 65536文字
              </div>
              <br />
              <div>
                <FormLabel
                  id="gendar-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  ■折り返しのご連絡を希望されますか？
                  <span
                    style={{
                      color: '#e74536',
                    }}
                  >
                    （必須）
                  </span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="reply-buttons-group-label"
                  name="reply"
                  value={formState.values.reply}
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(event, 'reply', event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="はい"
                    control={<Radio size={'small'} />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="いいえ"
                    control={<Radio size={'small'} />}
                    label="いいえ"
                  />
                </RadioGroup>
              </div>
              <br />
              <div
                style={{
                  display: formState.values.reply === 'はい' ? '' : 'none',
                }}
              >
                <FormLabel
                  id="gendar-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  ■ご希望のご連絡方法
                  <span
                    style={{
                      color: '#e74536',
                    }}
                  >
                    （必須）
                  </span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="reply-channel-buttons-group-label"
                  name="replyChannel"
                  value={formState.values.replyChannel}
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(event, 'replyChannel', event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="電話"
                    control={<Radio size={'small'} />}
                    label="電話"
                  />
                  <FormControlLabel
                    value="メール"
                    control={<Radio size={'small'} />}
                    label="メール"
                  />
                  <FormControlLabel
                    value="LINE"
                    control={<Radio size={'small'} />}
                    label="LINE"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </div>
        )}

        <div style={{ height: '88px' }} />
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={submit ? handleClose : handleSubmit}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {submit ? '閉じる' : '送信する'}
            </span>
          </Button>
        </Toolbar>
      </AppBar>

      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            お申し出内容が未入力です。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            折り返しのご連絡希望が選択されていません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ご希望のご連絡方法が選択されていません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SafetyConfirmationForm;
