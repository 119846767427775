import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

export const answerSafetyConfirmation = createAsyncThunk(
  'answerSafetyConfirmation',
  async (params: any) => {
    const response = await axios.post(
      `${API_SERVER_URL}/services/randstad/recommend/v2/safety-confirmation`,
      params.values,
      {
        headers: {
          Authorization: `Bearer ${params.idToken}`,
          'X-ClientId': params.channelId,
        },
      },
    );
    return response.data;
  },
);

export default answerSafetyConfirmation;
