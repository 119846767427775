import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';

import liff from '@line/liff';
import TextField from '@mui/material/TextField';
import {
  answerAttendanceReport,
  answerAttendanceScheduleTime,
  answerLeaveEarlyScheduleTime,
} from '../features/attendanceReport/attendanceReportSlice';

class InHouseAttendanceFormProps {
  profile: any;
}

const InHouseAttendanceReportForm: React.FC<InHouseAttendanceFormProps> = ({
  profile,
}) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      report: '',
      reason_1: '',
      reason_2: '',
      fever: '',
      degrees_1: '',
      degrees_2: '',
      attendance_schedule: '',
      misc: '',
      paid_vacation: '',
      hospital_schedule: '',
      staffCode: profile.staffCode,
    },
    errors: {},
    touched: {},
  });

  //回答内容変更時の処理
  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [attendanceTime, setAttendanceTime] = React.useState<Date | null>(
    new Date(),
  );
  const [leaveEarlyTime, setLeaveEarlyTime] = React.useState<Date | null>(
    new Date(),
  );

  const [open, setOpen] = React.useState(false);

  //「回答する」ボタン押下後の処理
  const handleClickOpen = () => {
    setOpen(true);

    //HubSpotプロパティを更新
    dispatch(
      answerAttendanceReport({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
      }),
    );

    const attendanceSchedule =
      formState.values.attendance_schedule === '入力する'
        ? attendanceTime
        : '未定';

    dispatch(
      answerAttendanceScheduleTime({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values.report === '遅刻' ? attendanceSchedule : '',
        // values:
        //   formState.values.report === '遅刻'
        //     ? attendanceSchedule
        //     : formState.values.report === '中抜け'
        //     ? attendanceTime
        //     : '',
      }),
    );

    dispatch(
      answerLeaveEarlyScheduleTime({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values.report === '早退' ? leaveEarlyTime : '',
        // values:
        //   formState.values.report === '早退' ||
        //   formState.values.report === '中抜け'
        //     ? leaveEarlyTime
        //     : '',
      }),
    );

    let text = '';

    const fever = !(
      formState.values.reason_1 === 'その他' ||
      formState.values.reason_2 === 'その他' ||
      formState.values.reason_1 === '交通状況'
    )
      ? formState.values.fever === 'なし（37.0度未満）' &&
        !(formState.values.reason_2 === '発熱')
        ? `\n発熱: ${formState.values.fever}`
        : formState.values.degrees_1 &&
          formState.values.degrees_2 &&
          (formState.values.fever === 'あり' ||
            formState.values.reason_2 === '発熱')
        ? `\n発熱: ${formState.values.degrees_1}.${formState.values.degrees_2}℃`
        : formState.values.degrees_1 &&
          (formState.values.fever === 'あり' ||
            formState.values.reason_2 === '発熱')
        ? `\n発熱: ${formState.values.degrees_1}℃`
        : formState.values.fever === 'あり' ||
          formState.values.reason_2 === '発熱'
        ? '\n発熱: あり（体温未入力）'
        : ''
      : '';

    const paid_vacation =
      formState.values.paid_vacation === 'true'
        ? '使用する'
        : formState.values.paid_vacation === 'false'
        ? '使用しない'
        : '';

    const lateness_time =
      attendanceSchedule === '未定'
        ? attendanceSchedule
        : `${attendanceTime?.getHours()}時${attendanceTime?.getMinutes()}分`;

    const early_time = leaveEarlyTime
      ? `${leaveEarlyTime?.getHours()}時${leaveEarlyTime?.getMinutes()}分`
      : '';

    // const attendance_time = attendanceTime
    //   ? `${attendanceTime?.getHours()}時${attendanceTime?.getMinutes()}分`
    //   : '';

    const reason =
      formState.values.reason_1 === '交通状況' ||
      formState.values.reason_1 === 'その他' ||
      formState.values.reason_2 === ''
        ? `${formState.values.reason_1}`
        : formState.values.reason_1 && formState.values.reason_2
        ? `${formState.values.reason_1}（${formState.values.reason_2}）`
        : '';

    const misc =
      formState.values.reason_1 === 'その他' ||
      (formState.values.reason_2 === 'その他' &&
        !(formState.values.reason_1 === '交通状況'))
        ? `\nその他の理由:\n${formState.values.misc}`
        : '';

    const hospital_schedule =
      formState.values.reason_1 === '自身の体調不良' ||
      formState.values.reason_1 === '家族の体調不良'
        ? `\n医療期間受診予定: ${formState.values.hospital_schedule}`
        : '';

    const report = formState.values.report;

    switch (report) {
      case '欠勤':
        text = `【勤怠報告】
報告内容: ${report}
理由: ${reason}${misc}${fever}
有給休暇: ${paid_vacation}`;
        break;
      case '遅刻':
        text = `【勤怠報告】
報告内容: ${report}
理由: ${reason}${misc}${fever}${hospital_schedule}
出勤予定時刻: ${lateness_time}`;
        break;
      case '早退':
        text = `【勤怠報告】
報告内容: ${report}
理由:
${formState.values.misc}
早退予定時刻: ${early_time}`;
        break;
      //       case '中抜け':
      //         text = `【勤怠報告】
      // 報告内容: ${report}
      // 理由:
      // ${formState.values.misc}
      // 早退予定時刻: ${early_time}
      // 復帰時刻: ${attendance_time}`;
      //         break;
      default:
        console.log('default');
        break;
    }

    console.log(text);

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component="form" noValidate autoComplete="off">
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <div>
              <FormLabel
                id="attendance-label"
                style={{
                  fontSize: '.9em',
                  color: '#e74536',
                }}
              >
                今日の勤怠報告に限ります。
                <br />
                昨日までの休み連絡や、翌日以降の休みの相談等については直接事業所へご連絡ください。（LINEメッセージをお使いください）
              </FormLabel>
              <br />
              <br />
              <FormLabel
                id="attendance-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                報告したいことを選択ください。
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.report}
                onChange={(event) => {
                  handleChange(event, 'report', event.target.value);
                }}
              >
                <FormControlLabel
                  value="欠勤"
                  control={<Radio />}
                  label="欠勤"
                />
                <FormControlLabel
                  value="遅刻"
                  control={<Radio />}
                  label="遅刻"
                />
                <FormControlLabel
                  value="早退"
                  control={<Radio />}
                  label="早退"
                />
                {/*<FormControlLabel*/}
                {/*  value="中抜け"*/}
                {/*  control={<Radio />}*/}
                {/*  label="中抜け"*/}
                {/*/>*/}
              </RadioGroup>
            </div>
            <Divider />
            <br />
            <div
              style={{
                display:
                  formState.values.report === '欠勤' ||
                  formState.values.report === '遅刻'
                    ? ''
                    : 'none',
              }}
            >
              <div
                style={{
                  display: formState.values.report === '欠勤' ? '' : 'none',
                }}
              >
                <FormLabel
                  id="attendance-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  理由を選択ください。
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formState.values.reason_1}
                  onChange={(event) => {
                    handleChange(event, 'reason_1', event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="自身の体調不良"
                    control={<Radio />}
                    label="自身の体調不良"
                  />
                  <FormControlLabel
                    value="家族の体調不良"
                    control={<Radio />}
                    label="家族の体調不良"
                  />
                  <FormControlLabel
                    value="その他"
                    control={<Radio />}
                    label="その他"
                  />
                </RadioGroup>
              </div>
              <div
                style={{
                  display: formState.values.report === '遅刻' ? '' : 'none',
                }}
              >
                <FormLabel
                  id="attendance-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  理由を選択ください。
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formState.values.reason_1}
                  onChange={(event) => {
                    handleChange(event, 'reason_1', event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="自身の体調不良"
                    control={<Radio />}
                    label="自身の体調不良"
                  />
                  <FormControlLabel
                    value="家族の体調不良"
                    control={<Radio />}
                    label="家族の体調不良"
                  />
                  <FormControlLabel
                    value="交通状況"
                    control={<Radio />}
                    label="交通状況"
                  />
                  <FormControlLabel
                    value="その他"
                    control={<Radio />}
                    label="その他"
                  />
                </RadioGroup>
              </div>
              <div
                style={{
                  display:
                    formState.values.reason_1 === '自身の体調不良' ||
                    formState.values.reason_1 === '家族の体調不良'
                      ? ''
                      : 'none',
                }}
              >
                <div>
                  <Divider />
                  <br />
                  <FormLabel
                    id="attendance-label"
                    style={{
                      fontSize: '.8em',
                      color: '#0f1941',
                      display:
                        formState.values.reason_1 === '自身の体調不良'
                          ? ''
                          : 'none',
                    }}
                  >
                    体調について選択ください。（１つのみ最も当てはまるものを選択）
                  </FormLabel>
                  <FormLabel
                    id="attendance-label"
                    style={{
                      fontSize: '.8em',
                      color: '#0f1941',
                      display:
                        formState.values.reason_1 === '家族の体調不良'
                          ? ''
                          : 'none',
                    }}
                  >
                    ご家族のご様子を教えてください。（１つのみ最も当てはまるものを選択）
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formState.values.reason_2}
                    onChange={(event) => {
                      handleChange(event, 'reason_2', event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="発熱"
                      control={<Radio />}
                      label="発熱"
                    />
                    <FormControlLabel
                      value="頭痛"
                      control={<Radio />}
                      label="頭痛"
                    />
                    <FormControlLabel
                      value="腹痛"
                      control={<Radio />}
                      label="腹痛"
                    />
                    <FormControlLabel
                      value="喉の痛み"
                      control={<Radio />}
                      label="喉の痛み"
                    />
                    <FormControlLabel
                      value="咳"
                      control={<Radio />}
                      label="咳"
                    />
                    <FormControlLabel
                      value="倦怠感"
                      control={<Radio />}
                      label="倦怠感"
                    />
                    <FormControlLabel
                      value="その他"
                      control={<Radio />}
                      label="その他"
                    />
                  </RadioGroup>
                </div>
                <div
                  style={{
                    display:
                      formState.values.reason_2 === '頭痛' ||
                      formState.values.reason_2 === '腹痛' ||
                      formState.values.reason_2 === '喉の痛み' ||
                      formState.values.reason_2 === '咳' ||
                      formState.values.reason_2 === '倦怠感'
                        ? ''
                        : 'none',
                  }}
                >
                  <Divider />
                  <br />
                  <div>
                    <FormLabel
                      id="attendance-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      37.0以上の発熱はありますか？
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.fever}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'fever', event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="あり"
                        control={<Radio />}
                        label="あり"
                      />
                      <FormControlLabel
                        value="なし（37.0度未満）"
                        control={<Radio />}
                        label="なし（37.0度未満）"
                      />
                    </RadioGroup>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      formState.values.reason_2 === '発熱' ||
                      formState.values.fever === 'あり'
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <Divider />
                    <br />
                    <FormLabel
                      id="attendance-label"
                      style={{
                        fontSize: '.8em',
                        color: '#0f1941',
                      }}
                    >
                      現在の体温を入力ください。
                    </FormLabel>
                  </div>
                  <div>
                    <FormControl
                      sx={{ ml: 0, mt: 1, mr: 1, mb: 2, width: '25%' }}
                    >
                      <Select
                        labelId="prefecture-label"
                        id="prefecture-select"
                        variant="standard"
                        value={formState.values.degrees_1}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'degrees_1', event.target.value);
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'37'}>37</MenuItem>
                        <MenuItem value={'38'}>38</MenuItem>
                        <MenuItem value={'39'}>39</MenuItem>
                        <MenuItem value={'40'}>40</MenuItem>
                        <MenuItem value={'41'}>41</MenuItem>
                        <MenuItem value={'42'}>42</MenuItem>
                      </Select>
                    </FormControl>
                    <FormLabel
                      id="attendance-label"
                      sx={{ ml: 0, mt: 1, mr: 1, mb: 2, width: '3%' }}
                      style={{ fontSize: '1.8em', color: '#666666' }}
                    >
                      .
                    </FormLabel>
                    <FormControl
                      sx={{ ml: 0, mt: 1, mr: 1, mb: 2, width: '20%' }}
                    >
                      <Select
                        labelId="prefecture-label"
                        id="prefecture-select"
                        variant="standard"
                        value={formState.values.degrees_2}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'degrees_2', event.target.value);
                        }}
                      >
                        <MenuItem value={''} />
                        <MenuItem value={'0'}>0</MenuItem>
                        <MenuItem value={'1'}>1</MenuItem>
                        <MenuItem value={'2'}>2</MenuItem>
                        <MenuItem value={'3'}>3</MenuItem>
                        <MenuItem value={'4'}>4</MenuItem>
                        <MenuItem value={'5'}>5</MenuItem>
                        <MenuItem value={'6'}>6</MenuItem>
                        <MenuItem value={'7'}>7</MenuItem>
                        <MenuItem value={'8'}>8</MenuItem>
                        <MenuItem value={'9'}>9</MenuItem>
                      </Select>
                    </FormControl>
                    <FormLabel
                      id="attendance-label"
                      sx={{ ml: 0, mt: 1, mr: 0, mb: 2, width: '5%' }}
                      style={{ fontSize: '1.1em', color: '#666666' }}
                    >
                      ℃
                    </FormLabel>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display:
                    formState.values.reason_1 === 'その他' ||
                    (formState.values.reason_2 === 'その他' &&
                      !(formState.values.reason_1 === '交通状況'))
                      ? ''
                      : 'none',
                }}
              >
                <Divider />
                <br />
                <FormLabel
                  id="attendance-label"
                  style={{
                    fontSize: '.8em',
                    color: '#0f1941',
                  }}
                >
                  理由を入力ください。
                </FormLabel>
                <TextField
                  multiline
                  rows={4}
                  id="misc"
                  value={formState.values.misc}
                  onChange={(event) => {
                    handleChange(event, 'misc', event.target.value);
                  }}
                  sx={{ mt: 2, ml: 0, mr: 0, mb: 2, width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: formState.values.report === '欠勤' ? '' : 'none',
              }}
            >
              <div>
                <Divider />
                <br />
                <FormLabel
                  id="attendance-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  有給休暇を使用しますか？
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formState.values.paid_vacation}
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(event, 'paid_vacation', event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="はい"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="いいえ"
                  />
                </RadioGroup>
                <br />
                <div
                  style={{
                    fontSize: '.9em',
                    color: '#e74536',
                  }}
                >
                  ※有給休暇については入社日（登録後初めて仕事に就いた日、または有休消滅後に再度契約が開始した日）を起算日とし、6ヶ月以上継続勤務した場合付与されます。付与されているかどうかの確認は
                  <span
                    style={{
                      color: '#1d6ad4',
                    }}
                    onClick={() => {
                      liff.openWindow({
                        url: `https://www.randstad.co.jp/OCLSTF0000.do?uid=${formState.values.staffCode}&pid=line`,
                        external: true,
                      });
                    }}
                  >
                    こちら
                  </span>
                  から
                </div>
              </div>
            </div>
            <div
              style={{
                display: formState.values.report === '遅刻' ? '' : 'none',
              }}
            >
              <div
                style={{
                  display:
                    formState.values.reason_1 === '自身の体調不良' ||
                    formState.values.reason_1 === '家族の体調不良'
                      ? ''
                      : 'none',
                }}
              >
                <Divider />
                <br />
                <FormLabel
                  id="attendance-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  医療機関にて受診予定でしょうか？
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={formState.values.hospital_schedule}
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(
                      event,
                      'hospital_schedule',
                      event.target.value,
                    );
                  }}
                >
                  <FormControlLabel
                    value="医療機関受診後に出勤予定"
                    control={<Radio />}
                    label="医療機関受診後に出勤予定"
                  />
                  <FormControlLabel
                    value="受診せずに出勤予定"
                    control={<Radio />}
                    label="受診せずに出勤予定"
                  />
                </RadioGroup>
              </div>
              <Divider />
              <br />
              <FormLabel
                id="attendance-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                出勤予定時刻を入力ください。
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formState.values.attendance_schedule}
                onChange={(event) => {
                  handleChange(
                    event,
                    'attendance_schedule',
                    event.target.value,
                  );
                }}
              >
                <FormControlLabel
                  value="入力する"
                  control={<Radio />}
                  label="入力する"
                />
                <FormControlLabel
                  value="未定"
                  control={<Radio />}
                  label="未定"
                />
              </RadioGroup>
              <div
                style={{
                  display:
                    formState.values.attendance_schedule === '入力する'
                      ? ''
                      : 'none',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileTimePicker
                    label="出勤予定時刻"
                    value={attendanceTime}
                    onChange={(newValue) => {
                      setAttendanceTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div
              style={{
                display: formState.values.report === '早退' ? '' : 'none',
              }}
            >
              <FormLabel
                id="attendance-label"
                style={{
                  fontSize: '.8em',
                  color: '#0f1941',
                }}
              >
                理由を入力ください。
              </FormLabel>
              <TextField
                multiline
                rows={4}
                id="misc"
                value={formState.values.misc}
                onChange={(event) => {
                  handleChange(event, 'misc', event.target.value);
                }}
                sx={{ mt: 2, ml: 0, mr: 0, mb: 2, width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Divider />
              <br />
              <div>
                <FormLabel
                  id="attendance-label"
                  style={{ fontSize: '.8em', color: '#0f1941' }}
                >
                  早退予定時刻を入力ください。
                </FormLabel>
              </div>
              <div style={{ marginTop: 18 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileTimePicker
                    label="早退予定時刻"
                    value={leaveEarlyTime}
                    onChange={(newValue) => {
                      setLeaveEarlyTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {/*<div*/}
            {/*  style={{*/}
            {/*    display: formState.values.report === '中抜け' ? '' : 'none',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <FormLabel*/}
            {/*    id="attendance-label"*/}
            {/*    style={{*/}
            {/*      fontSize: '.8em',*/}
            {/*      color: '#0f1941',*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    理由を入力ください。*/}
            {/*  </FormLabel>*/}
            {/*  <TextField*/}
            {/*    multiline*/}
            {/*    rows={4}*/}
            {/*    id="misc"*/}
            {/*    value={formState.values.misc}*/}
            {/*    onChange={(event) => {*/}
            {/*      handleChange(event, 'misc', event.target.value);*/}
            {/*    }}*/}
            {/*    sx={{ mt: 2, ml: 0, mr: 0, mb: 2, width: '100%' }}*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <Divider />*/}
            {/*  <br />*/}
            {/*  <div>*/}
            {/*    <FormLabel*/}
            {/*      id="attendance-label"*/}
            {/*      style={{ fontSize: '.8em', color: '#0f1941' }}*/}
            {/*    >*/}
            {/*      早退(中抜けする)予定時刻を入力ください。*/}
            {/*    </FormLabel>*/}
            {/*  </div>*/}
            {/*  <div style={{ marginTop: 18 }}>*/}
            {/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
            {/*      <MobileTimePicker*/}
            {/*        label="早退予定時刻"*/}
            {/*        value={leaveEarlyTime}*/}
            {/*        onChange={(newValue) => {*/}
            {/*          setLeaveEarlyTime(newValue);*/}
            {/*        }}*/}
            {/*        renderInput={(params) => <TextField {...params} />}*/}
            {/*      />*/}
            {/*    </LocalizationProvider>*/}
            {/*  </div>*/}
            {/*  <br />*/}
            {/*  <Divider />*/}
            {/*  <br />*/}
            {/*  <div>*/}
            {/*    <FormLabel*/}
            {/*      id="attendance-label"*/}
            {/*      style={{ fontSize: '.8em', color: '#0f1941' }}*/}
            {/*    >*/}
            {/*      復帰（中抜けから戻る）時刻を入力ください。*/}
            {/*    </FormLabel>*/}
            {/*  </div>*/}
            {/*  <div style={{ marginTop: 18 }}>*/}
            {/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}
            {/*      <MobileTimePicker*/}
            {/*        label="復帰時刻"*/}
            {/*        value={attendanceTime}*/}
            {/*        onChange={(newValue) => {*/}
            {/*          setAttendanceTime(newValue);*/}
            {/*        }}*/}
            {/*        renderInput={(params) => <TextField {...params} />}*/}
            {/*      />*/}
            {/*    </LocalizationProvider>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </FormControl>
        </div>
        <div style={{ height: '88px' }} />
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClickOpen}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              回答する
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'完了'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            勤怠報告を受け付けました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InHouseAttendanceReportForm;
