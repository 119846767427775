import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import _ from 'lodash';
import { saveProfile } from '../features/profile/profileSlice';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import TrainIcon from '@mui/icons-material/Train';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import SettingsIcon from '@mui/icons-material/Settings';

import liff from '@line/liff';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

type ProfileFormProps = {
  profile: any;
  retry: any;
};

const ProfileForm: React.FC<ProfileFormProps> = ({ profile, retry }) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      birthday: profile.birthday,
      staffCode: profile.staffCode,
      gender: profile.gender,
      email: profile.email,
      lang: profile.lang,

      // Staffing
      selectLocation: profile.selectLocation,
      prefectures: profile.prefectures || [],
      areas: profile.areas || [],
      prefecture: profile.prefecture,
      city: profile.city,
      jobCategory: profile.jobCategory,
      conditions: profile.conditions || [],
      broadcastFrequency:
        profile.broadcastFrequency === 'every_day'
          ? 'next_day'
          : profile.broadcastFrequency || 'next_day',
      excludeForklift: profile.excludeForklift,

      // Professional
      professionalPreferredLocation: profile.professionalPreferredLocation,
      professionalPreferredLocations: profile.professionalPreferredLocations,
      professionalPreferredIndustry: profile.professionalPreferredIndustry,
      professionalPreferredIndustries: profile.professionalPreferredIndustries,
      professionalPreferredPosition: profile.professionalPreferredPosition,
      professionalPreferredPositions: profile.professionalPreferredPositions,
      professionalPreferredSalary: profile.professionalPreferredSalary,
      professionalConditions: profile.professionalConditions,
      broadcastFrequencyProfessional:
        profile.broadcastFrequencyProfessional || 'everyday',

      // SPOT
      spotPrefecture: profile.spotPrefecture,
      spotArea: profile.spotArea,
      spotCity: profile.spotCity,
      spotJobCategory: profile.spotJobCategory,
      broadcastFrequencySpot: profile.broadcastFrequencySpot,
      narrowDown: profile.narrowDown,
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickSave = () => {
    setOpen(true);

    // DR事業部の要望対応：選択できる「こだわり条件」を職種毎に分ける
    const conditions = formState.values.conditions.filter(
      (condition: string) => {
        return showCondition(condition);
      },
    );
    const values = {
      ...formState.values,
      conditions,
    };
    setFormState((formState) => ({
      ...formState,
      values: values,
    }));
    //

    dispatch(
      saveProfile({
        idToken: idToken(),
        channelId: channelId(),
        values,
      }),
    );

    if (retry) {
      setTimeout(() => {
        liff
          .sendMessages([
            {
              type: 'text',
              text: '再度検索しなおす',
            },
          ])
          .then(() => {
            console.log('message sent');
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  const doNotShowNoOvertime = (prefecture: string, city: string) => {
    const selection = prefecture + city;

    const targets = [
      '茨城県県北エリア',
      '茨城県県央エリア',
      '茨城県鹿行エリア',
    ];

    return targets.includes(selection);
  };

  const showCondition = (condition: string) => {
    if (condition === 'no_overtime') {
      const prefecture = formState.values.prefecture;
      const city = formState.values.city;
      if (doNotShowNoOvertime(prefecture, city)) {
        return false;
      }
    }

    const jobCategory = formState.values.jobCategory;
    if (jobCategory === 'ドライバー・配送・送迎') {
      switch (condition) {
        case 'start_date':
          return false;
        case 'beginner_ok':
          return false;
        case 'dependents':
          return false;
        case 'no_overtime':
          return false;
        default:
          return true;
      }
    } else {
      switch (condition) {
        case 'over_1500_hourly_pay':
          return false;
        case 'allow_car_commute':
          return false;
        case 'light_car_work':
          return false;
        case 'heavy_car_work':
          return false;
        default:
          return true;
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          border: '1px solid rgb(0 0 0 / 12%)',
          margin: '1em 1em 5em 1em',
        }}
      >
        <div>
          <TextField
            id="standard-basic"
            label="姓"
            variant="standard"
            value={formState.values.lastname}
            onChange={(event) => {
              handleChange(event, 'lastname', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            placeholder="山田"
          />
          <TextField
            id="standard-basic"
            label="名"
            variant="standard"
            value={formState.values.firstname}
            onChange={(event) => {
              handleChange(event, 'firstname', event.target.value);
            }}
            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
            placeholder="太郎"
          />
        </div>
        <div>
          {/*<TextField*/}
          {/*  id="birthday"*/}
          {/*  variant="standard"*/}
          {/*  className={'birthday-field'}*/}
          {/*  label="生年月日"*/}
          {/*  type="date"*/}
          {/*  value={formState.values.birthday || '2000-01-01'}*/}
          {/*  onChange={(event) => {*/}
          {/*    console.log(event.target.value);*/}
          {/*    handleChange(event, 'birthday', event.target.value);*/}
          {/*  }}*/}
          {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
          {/*  InputLabelProps={{*/}
          {/*    shrink: true,*/}
          {/*  }}*/}
          {/*/>*/}
          <MobileDatePicker
            label="生年月日"
            inputFormat="yyyy/M/d"
            toolbarFormat="yyyy/M/d"
            value={formState.values.birthday || '2000/1/1'}
            maxDate={new Date()}
            onChange={(date) => {
              console.log(date);
              handleChange(
                {
                  target: {
                    name: 'birthday',
                  },
                },
                'birthday',
                moment(date).format('YYYY-MM-DD'),
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params}
              />
            )}
          />
        </div>
        <div>
          <TextField
            id="staff-code"
            variant="standard"
            label="スタッフコード"
            type="tel"
            placeholder="00000000"
            value={formState.values.staffCode}
            inputProps={{ maxLength: '8' }}
            onChange={(event) => {
              console.log(event.target.value);
              handleChange(event, 'staffCode', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              性別
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.gender}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'gender', event.target.value);
              }}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="女性"
              />
              <FormControlLabel value="male" control={<Radio />} label="男性" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="その他"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider />
        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="jobCategory-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' 希望職種'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="jobCategory-label"
              id="jobCategory-select"
              variant="standard"
              value={formState.values.jobCategory}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'jobCategory', event.target.value);
                handleChange(event, 'excludeForklift', false);
                handleChange(event, 'prefectures', []);
                handleChange(event, 'areas', []);
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'事務全般'}>事務全般</MenuItem>
              <MenuItem value={'コールセンター・営業・接客販売'}>
                コールセンター・営業・接客販売
              </MenuItem>
              <MenuItem value={'工場・製造系'}>工場・製造系</MenuItem>
              <MenuItem value={'倉庫・物流・軽作業'}>
                倉庫・物流・軽作業
              </MenuItem>
              <MenuItem value={'エンジニア（ＩＴ・技術）'}>
                エンジニア（ＩＴ・技術）
              </MenuItem>
              <MenuItem value={'ドライバー・配送・送迎'}>
                ドライバー・配送・送迎
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.jobCategory === '倉庫・物流・軽作業'
                ? ''
                : 'none',
          }}
        >
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.excludeForklift}
                    onChange={(event) => {
                      console.log(event.target.checked);
                      handleChange(
                        event,
                        'excludeForklift',
                        event.target.checked,
                      );
                    }}
                  />
                }
                label="フォークリフトを除く"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div>
          <InputLabel sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}>
            <TrainIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' 希望勤務地'}
          </InputLabel>
        </div>
        <div
          style={{
            display: !(
              formState.values.jobCategory === 'ドライバー・配送・送迎'
            )
              ? ''
              : 'none',
          }}
        >
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="prefecture-label"
              id="prefecture-select"
              variant="standard"
              value={formState.values.selectLocation}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'selectLocation', event.target.value);
                handleChange(event, 'prefectures', []);
                handleChange(event, 'areas', []);
                handleChange(event, 'prefecture', '');
                handleChange(event, 'city', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'北海道・東北'}>北海道・東北</MenuItem>
              <MenuItem value={'北関東'}>北関東</MenuItem>
              <MenuItem value={'首都圏'}>首都圏</MenuItem>
              <MenuItem value={'東海'}>東海・信越</MenuItem>
              <MenuItem value={'近畿'}>近畿</MenuItem>
              <MenuItem value={'中国・四国'}>中国・四国</MenuItem>
              <MenuItem value={'九州'}>九州</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.jobCategory === 'ドライバー・配送・送迎'
                ? ''
                : 'none',
          }}
        >
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="prefecture-label"
              id="prefecture-select"
              variant="standard"
              value={formState.values.selectLocation}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'selectLocation', event.target.value);
                handleChange(event, 'prefectures', []);
                handleChange(event, 'areas', []);
                handleChange(event, 'prefecture', '');
                handleChange(event, 'city', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'北関東'}>北関東</MenuItem>
              <MenuItem value={'首都圏'}>首都圏</MenuItem>
              <MenuItem value={'東海'}>東海</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.selectLocation === '北関東' ? '' : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '茨城県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('茨城県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '茨城県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/茨城県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="茨城県"
                  />
                }
                label="茨城県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('茨城県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '茨城県水戸市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県水戸市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県水戸市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県水戸市"
                        />
                      }
                      label="水戸市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '茨城県東海村・ひたちなか市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県東海村・ひたちなか市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県東海村・ひたちなか市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県東海村・ひたちなか市"
                        />
                      }
                      label="東海村・ひたちなか市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '茨城県城里町・那珂市・常陸大宮市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県城里町・那珂市・常陸大宮市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県城里町・那珂市・常陸大宮市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県城里町・那珂市・常陸大宮市"
                        />
                      }
                      label="城里町・那珂市・常陸大宮市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '茨城県北茨城市・高萩市・日立市・常陸太田市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '茨城県北茨城市・高萩市・日立市・常陸太田市',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '茨城県北茨城市・高萩市・日立市・常陸太田市'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県北茨城市・高萩市・日立市・常陸太田市市"
                        />
                      }
                      label="北茨城市・高萩市・日立市・常陸太田市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '茨城県小美玉市・茨城町・笠間市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県小美玉市・茨城町・笠間市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県小美玉市・茨城町・笠間市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県小美玉市・茨城町・笠間市"
                        />
                      }
                      label="小美玉市・茨城町・笠間市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '茨城県大洗町・鉾田市・行方市・鹿嶋',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '茨城県大洗町・鉾田市・行方市・鹿嶋',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '茨城県大洗町・鉾田市・行方市・鹿嶋'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県大洗町・鉾田市・行方市・鹿嶋"
                        />
                      }
                      label="大洗町・鉾田市・行方市・鹿嶋"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '茨城県古河市・境町・坂東市・五霞町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '茨城県古河市・境町・坂東市・五霞町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '茨城県古河市・境町・坂東市・五霞町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県古河市・境町・坂東市・五霞町"
                        />
                      }
                      label="古河市・境町・坂東市・五霞町"
                    />
                  </FormGroup>
                  <FormControlLabel
                    style={{ paddingTop: '5px' }}
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area ===
                            '茨城県筑西市・下妻市・桜川市・結城市・八千代町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '茨城県筑西市・下妻市・桜川市・結城市・八千代町',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !==
                                '茨城県筑西市・下妻市・桜川市・結城市・八千代町'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県筑西市・下妻市・桜川市・結城市・八千代町"
                      />
                    }
                    label="筑西市・下妻市・桜川市・結城市・八千代町"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '茨城県つくば市・土浦市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('茨城県つくば市・土浦市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '茨城県つくば市・土浦市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県つくば市・土浦市"
                      />
                    }
                    label="つくば市・土浦市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '茨城県かすみがうら市・石岡市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('茨城県かすみがうら市・石岡市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '茨城県かすみがうら市・石岡市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県かすみがうら市・石岡市"
                      />
                    }
                    label="かすみがうら市・石岡市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '茨城県阿見町・美浦村・稲敷市・神栖市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '茨城県阿見町・美浦村・稲敷市・神栖市',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !== '茨城県阿見町・美浦村・稲敷市・神栖市'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県阿見町・美浦村・稲敷市・神栖市"
                      />
                    }
                    label="阿見町・美浦村・稲敷市・神栖市"
                  />
                  <FormControlLabel
                    style={{ paddingTop: '5px' }}
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '茨城県牛久市・龍ヶ崎市・河内町・利根町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '茨城県牛久市・龍ヶ崎市・河内町・利根町',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !== '茨城県牛久市・龍ヶ崎市・河内町・利根町'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県牛久市・龍ヶ崎市・河内町・利根町"
                      />
                    }
                    label="牛久市・龍ヶ崎市・河内町・利根町"
                  />
                  <FormControlLabel
                    style={{ paddingTop: '5px' }}
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area ===
                            '茨城県取手市・つくばみらい市・守谷市・常総市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '茨城県取手市・つくばみらい市・守谷市・常総市',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !==
                                '茨城県取手市・つくばみらい市・守谷市・常総市'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="茨城県取手市・つくばみらい市・守谷市・常総市"
                      />
                    }
                    label="取手市・つくばみらい市・守谷市・常総市"
                  />
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '茨城県県北エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県県北エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県県北エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県県北エリア"
                        />
                      }
                      label={
                        <div>
                          <div style={{ paddingTop: '10px' }}>県北エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (水戸市、ひたちなか市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '茨城県県南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県県南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県県南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県県南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (牛久市、神栖市、鉾田市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '茨城県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('茨城県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '茨城県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="茨城県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (土浦市、つくば市、古河市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '栃木県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('栃木県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '栃木県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/栃木県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="栃木県"
                  />
                }
                label="栃木県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('栃木県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '栃木県那須塩原市・大田原市・那須町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '栃木県那須塩原市・大田原市・那須町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '栃木県那須塩原市・大田原市・那須町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県那須塩原市・大田原市・那須町"
                        />
                      }
                      label="那須塩原市・大田原市・那須町"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '栃木県さくら市・矢板市・那須烏山市・高根沢町・塩谷町・那珂川町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '栃木県さくら市・矢板市・那須烏山市・高根沢町・塩谷町・那珂川町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '栃木県さくら市・矢板市・那須烏山市・高根沢町・塩谷町・那珂川町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県さくら市・矢板市・那須烏山市・高根沢町・塩谷町・那珂川町"
                        />
                      }
                      label="さくら市・矢板市・那須烏山市・高根沢町・塩谷町・那珂川町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県宇都宮市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県宇都宮市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県宇都宮市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県宇都宮市"
                        />
                      }
                      label="宇都宮市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県鹿沼市・日光市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県鹿沼市・日光市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県鹿沼市・日光市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県鹿沼市・日光市"
                        />
                      }
                      label="鹿沼市・日光市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '栃木県真岡市・芳賀町・益子町・市貝町・茂木町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '栃木県真岡市・芳賀町・益子町・市貝町・茂木町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '栃木県真岡市・芳賀町・益子町・市貝町・茂木町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県真岡市・芳賀町・益子町・市貝町・茂木町"
                        />
                      }
                      label="真岡市・芳賀町・益子町・市貝町・茂木町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '栃木県下野市・上三川町・壬生町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県下野市・上三川町・壬生町');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県下野市・上三川町・壬生町';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県下野市・上三川町・壬生町"
                        />
                      }
                      label="下野市・上三川町・壬生町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '栃木県小山市・栃木市・野木町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県小山市・栃木市・野木町');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県小山市・栃木市・野木町';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県小山市・栃木市・野木町"
                        />
                      }
                      label="小山市・栃木市・野木町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県足利市・佐野市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県足利市・佐野市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県足利市・佐野市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県足利市・佐野市"
                        />
                      }
                      label="足利市・佐野市"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県県北エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県県北エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県県北エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県県北エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県北エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (那須塩原市、太田市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県県南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県県南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県県南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県県南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (小山市、佐野市、足利市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '栃木県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('栃木県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '栃木県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="栃木県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (宇都宮市、真岡市、鹿沼市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '群馬県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('群馬県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '群馬県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/群馬県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="群馬県"
                  />
                }
                label="群馬県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('群馬県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県高崎市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県高崎市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県高崎市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県高崎市"
                        />
                      }
                      label="高崎市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県前橋市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県前橋市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県前橋市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県前橋市"
                        />
                      }
                      label="前橋市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '群馬県渋川市・沼田市・利根郡・北群馬郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '群馬県渋川市・沼田市・利根郡・北群馬郡',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '群馬県渋川市・沼田市・利根郡・北群馬郡'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県渋川市・沼田市・利根郡・北群馬郡"
                        />
                      }
                      label="渋川市・沼田市・利根郡・北群馬郡"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県伊勢崎市・玉村町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県伊勢崎市・玉村町');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県伊勢崎市・玉村町';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県伊勢崎市・玉村町"
                        />
                      }
                      label="伊勢崎市・玉村町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県藤岡市・多野郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県藤岡市・多野郡');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県藤岡市・多野郡';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県藤岡市・多野郡"
                        />
                      }
                      label="藤岡市・多野郡"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '群馬県富岡市・安中市・甘楽郡・吾妻郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '群馬県富岡市・安中市・甘楽郡・吾妻郡',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '群馬県富岡市・安中市・甘楽郡・吾妻郡'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県富岡市・安中市・甘楽郡・吾妻郡"
                        />
                      }
                      label="富岡市・安中市・甘楽郡・吾妻郡"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県太田市・大泉町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県太田市・大泉町');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県太田市・大泉町';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県太田市・大泉町"
                        />
                      }
                      label="太田市・大泉町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '群馬県館林市・邑楽町・千代田町・明和町・板倉町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '群馬県館林市・邑楽町・千代田町・明和町・板倉町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '群馬県館林市・邑楽町・千代田町・明和町・板倉町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県館林市・邑楽町・千代田町・明和町・板倉町"
                        />
                      }
                      label="館林市・邑楽町・千代田町・明和町・板倉町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県桐生市・みどり市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県桐生市・みどり市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県桐生市・みどり市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県桐生市・みどり市"
                        />
                      }
                      label="桐生市・みどり市"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県県北エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県県北エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県県北エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県県北エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県北エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (渋川市、沼田市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県県南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県県南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県県南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県県南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (太田市、玉村町、伊勢崎市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '群馬県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('群馬県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '群馬県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="群馬県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (前橋市、桐生市、高崎市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.selectLocation === '首都圏' ? '' : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '埼玉県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('埼玉県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '埼玉県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/埼玉県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="埼玉県"
                  />
                }
                label="埼玉県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('埼玉県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県さいたま市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県さいたま市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県さいたま市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県さいたま市"
                        />
                      }
                      label="さいたま市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '埼玉県川口市・戸田市・蕨市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県川口市・戸田市・蕨市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県川口市・戸田市・蕨市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県川口市・戸田市・蕨市"
                        />
                      }
                      label="川口市・戸田市・蕨市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '埼玉県上尾市・桶川市・北本市・伊奈町・鴻巣市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県上尾市・桶川市・北本市・伊奈町・鴻巣市',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '埼玉県上尾市・桶川市・北本市・伊奈町・鴻巣市'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県上尾市・桶川市・北本市・伊奈町・鴻巣市"
                        />
                      }
                      label="上尾市・桶川市・北本市・伊奈町・鴻巣市"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '埼玉県草加市・越谷市・三郷市・八潮市など',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県草加市・越谷市・三郷市・八潮市など',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '埼玉県草加市・越谷市・三郷市・八潮市など'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県草加市・越谷市・三郷市・八潮市など"
                        />
                      }
                      label="草加市・越谷市・三郷市・八潮市など"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '埼玉県春日部市・幸手市・宮代町・杉戸町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県春日部市・幸手市・宮代町・杉戸町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '埼玉県春日部市・幸手市・宮代町・杉戸町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県春日部市・幸手市・宮代町・杉戸町"
                        />
                      }
                      label="春日部市・幸手市・宮代町・杉戸町"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '埼玉県久喜市・白岡市・蓮田市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県久喜市・白岡市・蓮田市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県久喜市・白岡市・蓮田市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県久喜市・白岡市・蓮田市"
                        />
                      }
                      label="久喜市・白岡市・蓮田市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県加須市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県加須市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県加須市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県加須市"
                        />
                      }
                      label="加須市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県行田市・羽生市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県行田市・羽生市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県行田市・羽生市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県行田市・羽生市"
                        />
                      }
                      label="行田市・羽生市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '埼玉県富士見市・ふじみ野市・朝霞市・志木市・和光市・新座市・入間郡三芳町',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県富士見市・ふじみ野市・朝霞市・志木市・和光市・新座市・入間郡三芳町',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '埼玉県富士見市・ふじみ野市・朝霞市・志木市・和光市・新座市・入間郡三芳町'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県富士見市・ふじみ野市・朝霞市・志木市・和光市・新座市・入間郡三芳町"
                        />
                      }
                      label="富士見市・ふじみ野市・朝霞市・志木市・和光市・新座市・入間郡三芳町"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '埼玉県所沢市・入間市・飯能市・毛呂山町・日高市・狭山市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県所沢市・入間市・飯能市・毛呂山町・日高市・狭山市',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '埼玉県所沢市・入間市・飯能市・毛呂山町・日高市・狭山市'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県所沢市・入間市・飯能市・毛呂山町・日高市・狭山市"
                        />
                      }
                      label="所沢市・入間市・飯能市・毛呂山町・日高市・狭山市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '埼玉県川越市・坂戸市・鶴ヶ島市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県川越市・坂戸市・鶴ヶ島市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県川越市・坂戸市・鶴ヶ島市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県川越市・坂戸市・鶴ヶ島市"
                        />
                      }
                      label="川越市・坂戸市・鶴ヶ島市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県東松山市・比企郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県東松山市・比企郡');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県東松山市・比企郡';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県東松山市・比企郡"
                        />
                      }
                      label="東松山市・比企郡"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県熊谷市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県熊谷市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県熊谷市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県熊谷市"
                        />
                      }
                      label="熊谷市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県深谷市・大里郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県深谷市・大里郡');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県深谷市・大里郡';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県深谷市・大里郡"
                        />
                      }
                      label="深谷市・大里郡"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '埼玉県本庄市・児玉郡・秩父市・秩父郡',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '埼玉県本庄市・児玉郡・秩父市・秩父郡',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !== '埼玉県本庄市・児玉郡・秩父市・秩父郡'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県本庄市・児玉郡・秩父市・秩父郡"
                        />
                      }
                      label="本庄市・児玉郡・秩父市・秩父郡"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県県西エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県県西エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県県西エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県県西エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県西エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (川越市、所沢市、朝霞市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県県東エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県県東エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県県東エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県県東エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県東エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (さいたま市、春日部市、川口市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '埼玉県県北エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('埼玉県県北エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '埼玉県県北エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="埼玉県県北エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県北エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (熊谷市、久喜市、上里町など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '千葉県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('千葉県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '千葉県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/千葉県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="千葉県"
                  />
                }
                label="千葉県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('千葉県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      style={{ paddingTop: '5px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '千葉県浦安市・市川市・船橋市・習志野市・鎌ヶ谷市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '千葉県浦安市・市川市・船橋市・習志野市・鎌ヶ谷市',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '千葉県浦安市・市川市・船橋市・習志野市・鎌ヶ谷市'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県浦安市・市川市・船橋市・習志野市・鎌ヶ谷市"
                        />
                      }
                      label="浦安市・市川市・船橋市・習志野市・鎌ヶ谷市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県千葉市北',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県千葉市北');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県千葉市北';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県千葉市北"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>千葉市北</div>
                          <div>(花見川区・稲毛区・美浜区)</div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      style={{ paddingTop: '10px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県千葉市南',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県千葉市南');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県千葉市南';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県千葉市南"
                        />
                      }
                      label="千葉市南（中央区・若葉区・緑区）、市原市"
                    />
                    <FormControlLabel
                      style={{ paddingTop: '10px' }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area ===
                              '千葉県柏市・流山市・野田市・松戸市・白井市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push(
                                '千葉県柏市・流山市・野田市・松戸市・白井市',
                              );
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return (
                                  c !==
                                  '千葉県柏市・流山市・野田市・松戸市・白井市'
                                );
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県柏市・流山市・野田市・松戸市・白井市"
                        />
                      }
                      label="柏市・流山市・野田市・松戸市・白井市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県外房',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県外房');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県外房';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県外房"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>外房</div>
                          <div>(山武市、東金市、茂原市など)</div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県内房',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県内房');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県内房';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県内房"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>内房</div>
                          <div>(袖ヶ浦市、木更津市、富津市など)</div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県県北エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県県北エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県県北エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県県北エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県北エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (松戸市、柏市、成田市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県県南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県県南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県県南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県県南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (市原市、君津市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '千葉県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('千葉県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '千葉県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="千葉県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (船橋市、千葉市、茂原市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '東京都',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('東京都');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '東京都';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/東京都/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="東京都"
                  />
                }
                label="東京都"
              />
            </FormGroup>
            <div
              style={{
                display:
                  formState.values.prefectures.includes('東京都') &&
                  formState.values.jobCategory
                    ? ''
                    : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    {/*FA*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '工場・製造系' ||
                          formState.values.jobCategory === '倉庫・物流・軽作業'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区北東部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区北東部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区北東部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区北東部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>23区北東部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (豊島区、板橋区、足立区など)
                          </div>
                        </div>
                      }
                    />
                    {/*OF*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '事務全般' ||
                          formState.values.jobCategory ===
                            'コールセンター・営業・接客販売' ||
                          formState.values.jobCategory ===
                            'エンジニア（ＩＴ・技術）'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区北部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区北部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区北部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区北部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>23区北部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (豊島区、板橋区、足立区など)
                          </div>
                        </div>
                      }
                    />
                    {/*OF*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '事務全般' ||
                          formState.values.jobCategory ===
                            'コールセンター・営業・接客販売' ||
                          formState.values.jobCategory ===
                            'エンジニア（ＩＴ・技術）'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区東部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区東部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区東部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区東部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>23区東部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (江東区、葛飾区、台東区など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区南部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区南部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区南部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区南部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>23区南部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (港区、品川区、目黒区、大田区)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区西部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区西部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区西部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区西部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>23区西部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (新宿区、杉並区、世田谷区など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) =>
                              area === '東京都立川市・日野市・八王子市',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都立川市・日野市・八王子市');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都立川市・日野市・八王子市';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都立川市・日野市・八王子市"
                        />
                      }
                      label="立川市・日野市・八王子市"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都その他23区外',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都その他23区外');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都その他23区外';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都その他23区外"
                        />
                      }
                      label="その他東京23区外"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区"
                        />
                      }
                      label="23区"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '東京都23区以外',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('東京都23区以外');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '東京都23区以外';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="東京都23区以外"
                        />
                      }
                      label="23区以外"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '神奈川県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('神奈川県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '神奈川県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/神奈川県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="神奈川県"
                  />
                }
                label="神奈川県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('神奈川県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県横浜エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県横浜エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県横浜エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県横浜エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>横浜エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (横浜市・横須賀市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県川崎エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県川崎エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県川崎エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県川崎エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>川崎エリア</div>
                          <div style={{ fontSize: '.9em' }}>(川崎市)</div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (厚木市、海老名市、大和市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県相模原エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県相模原エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県相模原エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県相模原エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>相模原エリア</div>
                          <div style={{ fontSize: '.9em' }}>(相模原市)</div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県湘南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県湘南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県湘南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県湘南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>湘南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (藤沢市、平塚市、小田原市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県県西エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県県西エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県県西エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県県西エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県西エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (小田原市、秦野市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県県央エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県県央エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県県央エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県県央エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県央エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (相模原市、厚木市、藤沢市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '神奈川県県東エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('神奈川県県東エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '神奈川県県東エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="神奈川県県東エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県東エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (横浜市、横須賀市、川崎市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.selectLocation === '東海' ? '' : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
                display: !(
                  formState.values.jobCategory === 'ドライバー・配送・送迎'
                )
                  ? ''
                  : 'none',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '新潟県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('新潟県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '新潟県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/新潟県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="新潟県"
                  />
                }
                label="新潟県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('新潟県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県新潟市中央区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県新潟市中央区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県新潟市中央区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県新潟市中央区"
                      />
                    }
                    label="中央区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県新潟市東区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県新潟市東区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県新潟市東区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県新潟市東区"
                      />
                    }
                    label="東区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県新潟市北区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県新潟市北区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県新潟市北区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県新潟市北区"
                      />
                    }
                    label="北区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県その他',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県その他');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県その他';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県その他"
                      />
                    }
                    label="その他（西区・江南区・西蒲区・南区・聖籠町・新発田市・加茂市）"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県五泉市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県五泉市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県五泉市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県五泉市"
                      />
                    }
                    label="五泉市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県県央エリア',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県県央エリア');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県県央エリア';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県県央エリア"
                      />
                    }
                    label={
                      <div>
                        <div>県央エリア</div>
                        <div>(燕市・三条市)</div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県長岡市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県長岡市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県長岡市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県長岡市"
                      />
                    }
                    label="長岡市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県見附市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県見附市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県見附市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県見附市"
                      />
                    }
                    label="見附市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県小千谷市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県小千谷市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県小千谷市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県小千谷市"
                      />
                    }
                    label="小千谷市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '新潟県柏崎市・刈羽郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('新潟県柏崎市・刈羽郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '新潟県柏崎市・刈羽郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="新潟県柏崎市・刈羽郡"
                      />
                    }
                    label="柏崎市・刈羽郡"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
                display:
                  formState.values.jobCategory === 'ドライバー・配送・送迎'
                    ? ''
                    : 'none',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '岐阜県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('岐阜県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '岐阜県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="岐阜県"
                  />
                }
                label="岐阜県"
              />
            </FormGroup>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '静岡県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('静岡県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '静岡県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/静岡県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="静岡県"
                  />
                }
                label="静岡県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('静岡県')
                  ? ''
                  : 'none',
              }}
            >
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '静岡県県西エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('静岡県県西エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '静岡県県西エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="静岡県県西エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県西エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (浜松市、磐田市、藤枝市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '静岡県県東エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('静岡県県東エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '静岡県県東エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="静岡県県東エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県東エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (静岡市、沼津市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '愛知県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('愛知県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '愛知県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/愛知県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="愛知県"
                  />
                }
                label="愛知県"
              />
            </FormGroup>
            <div
              style={{
                display:
                  formState.values.prefectures.includes('愛知県') &&
                  formState.values.jobCategory
                    ? ''
                    : 'none',
              }}
            >
              <div
                style={{
                  display: !(
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                  )
                    ? ''
                    : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県名古屋市内',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県名古屋市内');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県名古屋市内';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県名古屋市内"
                        />
                      }
                      label="名古屋市内"
                    />
                    {/*OF*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '事務全般' ||
                          formState.values.jobCategory ===
                            'コールセンター・営業・接客販売' ||
                          formState.values.jobCategory ===
                            'エンジニア（ＩＴ・技術）'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県尾張地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県尾張地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県尾張地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県尾張地方"
                        />
                      }
                      label={
                        <div>
                          <div>尾張地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (一宮市、稲沢市、小牧市など)
                          </div>
                        </div>
                      }
                    />
                    {/*FA*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '工場・製造系' ||
                          formState.values.jobCategory === '倉庫・物流・軽作業'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県尾張地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県尾張地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県尾張地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県尾張地方"
                        />
                      }
                      label={
                        <div>
                          <div>尾張地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (一宮市、稲沢市など)
                          </div>
                        </div>
                      }
                    />
                    {/*FA*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '工場・製造系' ||
                          formState.values.jobCategory === '倉庫・物流・軽作業'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県尾張東部',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県尾張東部');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県尾張東部';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県尾張東部"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>尾張東部</div>
                          <div style={{ fontSize: '.9em' }}>
                            (小牧市、春日井市、長久手市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県知多地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県知多地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県知多地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県知多地方"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>知多地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (大府市、東海市など)
                          </div>
                        </div>
                      }
                    />
                    {/*OF*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '事務全般' ||
                          formState.values.jobCategory ===
                            'コールセンター・営業・接客販売' ||
                          formState.values.jobCategory ===
                            'エンジニア（ＩＴ・技術）'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県三河地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県三河地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県三河地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県三河地方"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>三河地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (岡崎市、豊田市、豊橋市など)
                          </div>
                        </div>
                      }
                    />
                    {/*FA*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '工場・製造系' ||
                          formState.values.jobCategory === '倉庫・物流・軽作業'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県西三河地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県西三河地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県西三河地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県西三河地方"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>西三河地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (岡崎市、豊田市、刈谷市など)
                          </div>
                        </div>
                      }
                    />
                    {/*FA*/}
                    <FormControlLabel
                      style={{
                        display:
                          formState.values.jobCategory === '工場・製造系' ||
                          formState.values.jobCategory === '倉庫・物流・軽作業'
                            ? ''
                            : 'none',
                      }}
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県東三河地方',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県東三河地方');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県東三河地方';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県東三河地方"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>東三河地方</div>
                          <div style={{ fontSize: '.9em' }}>
                            (豊橋市、豊川市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.jobCategory === 'ドライバー・配送・送迎'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl
                  sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県県西エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県県西エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県県西エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県県西エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県西エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (名古屋市、一宮市、春日井市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県県東エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県県東エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県県東エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県県東エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県東エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (尾張旭市、豊田市、岡崎市など)
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.areas.some(
                            (area: string) => area === '愛知県県南エリア',
                          )}
                          onChange={(event) => {
                            let newAreas: any[] = _.clone(
                              formState.values.areas,
                            );
                            if (event.target.checked) {
                              newAreas.push('愛知県県南エリア');
                            } else {
                              newAreas = _.remove(newAreas, (c) => {
                                return c !== '愛知県県南エリア';
                              });
                            }
                            handleChange(event, 'areas', newAreas);
                            handleChange(event, 'city', '');
                            console.log(newAreas);
                          }}
                          name="愛知県県南エリア"
                        />
                      }
                      label={
                        <div style={{ paddingTop: '10px' }}>
                          <div>県南エリア</div>
                          <div style={{ fontSize: '.9em' }}>
                            (安城市、常滑市、豊橋市など)
                          </div>
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '三重県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('三重県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '三重県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="三重県"
                  />
                }
                label="三重県"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.selectLocation === '北海道・東北' &&
              !(formState.values.jobCategory === 'ドライバー・配送・送迎')
                ? ''
                : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '北海道',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('北海道');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '北海道';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/北海道/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="北海道"
                  />
                }
                label="北海道"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('北海道')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市中央区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市中央区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市中央区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市中央区"
                      />
                    }
                    label="札幌市中央区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市北区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市北区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市北区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市北区"
                      />
                    }
                    label="札幌市北区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市東区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市東区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市東区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市東区"
                      />
                    }
                    label="札幌市東区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市豊平区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市豊平区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市豊平区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市豊平区"
                      />
                    }
                    label="札幌市豊平区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市南区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市南区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市南区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市南区"
                      />
                    }
                    label="札幌市南区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市西区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市西区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市西区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市西区"
                      />
                    }
                    label="札幌市西区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市手稲区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市手稲区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市手稲区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市手稲区"
                      />
                    }
                    label="札幌市手稲区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市白石区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市白石区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市白石区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市白石区"
                      />
                    }
                    label="札幌市白石区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市厚別区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市厚別区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市厚別区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市厚別区"
                      />
                    }
                    label="札幌市厚別区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '札幌市清田区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('札幌市清田区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '札幌市清田区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="札幌市清田区"
                      />
                    }
                    label="札幌市清田区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '石狩市・小樽市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('石狩市・小樽市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '石狩市・小樽市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="石狩市・小樽市"
                      />
                    }
                    label="石狩市・小樽市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '江別市・北広島市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('江別市・北広島市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '江別市・北広島市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="江別市・北広島市"
                      />
                    }
                    label="江別市・北広島市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '北海道千歳市・北広島市・恵庭市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('北海道千歳市・北広島市・恵庭市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '北海道千歳市・北広島市・恵庭市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="北海道千歳市・北広島市・恵庭市"
                      />
                    }
                    label="千歳市・北広島市・恵庭市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '北海道千歳市・苫小牧市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('北海道千歳市・苫小牧市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '北海道千歳市・苫小牧市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="北海道千歳市・苫小牧市"
                      />
                    }
                    label="千歳市・苫小牧市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '北海道苫小牧市・勇払郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('北海道苫小牧市・勇払郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '北海道苫小牧市・勇払郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="北海道苫小牧市・勇払郡"
                      />
                    }
                    label="苫小牧市・勇払郡"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '北海道岩見沢市・三笠市・夕張郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('北海道岩見沢市・三笠市・夕張郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '北海道岩見沢市・三笠市・夕張郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="北海道岩見沢市・三笠市・夕張郡"
                      />
                    }
                    label="岩見沢市・三笠市・夕張郡"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '北海道旭川市・上川郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('北海道旭川市・上川郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '北海道旭川市・上川郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="北海道旭川市・上川郡"
                      />
                    }
                    label="旭川市・上川郡"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '宮城県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('宮城県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '宮城県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/宮城県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="宮城県"
                  />
                }
                label="宮城県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('宮城県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県仙台市青葉区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県仙台市青葉区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県仙台市青葉区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県仙台市青葉区"
                      />
                    }
                    label="仙台市青葉区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県仙台市宮城野区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県仙台市宮城野区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県仙台市宮城野区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県仙台市宮城野区"
                      />
                    }
                    label="仙台市宮城野区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県仙台市若林区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県仙台市若林区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県仙台市若林区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県仙台市若林区"
                      />
                    }
                    label="仙台市若林区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県仙台市泉区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県仙台市泉区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県仙台市泉区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県仙台市泉区"
                      />
                    }
                    label="仙台市泉区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県仙台市太白区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県仙台市太白区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県仙台市太白区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県仙台市太白区"
                      />
                    }
                    label="仙台市太白区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮城県富谷市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮城県富谷市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮城県富谷市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮城県富谷市"
                      />
                    }
                    label="富谷市"
                  />
                </FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.areas.some(
                        (area: string) => area === '宮城県黒川郡',
                      )}
                      onChange={(event) => {
                        let newAreas: any[] = _.clone(formState.values.areas);
                        if (event.target.checked) {
                          newAreas.push('宮城県黒川郡');
                        } else {
                          newAreas = _.remove(newAreas, (c) => {
                            return c !== '宮城県黒川郡';
                          });
                        }
                        handleChange(event, 'areas', newAreas);
                        handleChange(event, 'city', '');
                        console.log(newAreas);
                      }}
                      name="宮城県黒川郡"
                    />
                  }
                  label="黒川郡"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.areas.some(
                        (area: string) => area === '宮城県北部エリア',
                      )}
                      onChange={(event) => {
                        let newAreas: any[] = _.clone(formState.values.areas);
                        if (event.target.checked) {
                          newAreas.push('宮城県北部エリア');
                        } else {
                          newAreas = _.remove(newAreas, (c) => {
                            return c !== '宮城県北部エリア';
                          });
                        }
                        handleChange(event, 'areas', newAreas);
                        handleChange(event, 'city', '');
                        console.log(newAreas);
                      }}
                      name="宮城県北部エリア"
                    />
                  }
                  label={
                    <div>
                      <div>北部エリア</div>
                      <div style={{ fontSize: '.9em' }}>
                        (大崎市・加美郡など)
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.areas.some(
                        (area: string) => area === '宮城県東部エリア',
                      )}
                      onChange={(event) => {
                        let newAreas: any[] = _.clone(formState.values.areas);
                        if (event.target.checked) {
                          newAreas.push('宮城県東部エリア');
                        } else {
                          newAreas = _.remove(newAreas, (c) => {
                            return c !== '宮城県東部エリア';
                          });
                        }
                        handleChange(event, 'areas', newAreas);
                        handleChange(event, 'city', '');
                        console.log(newAreas);
                      }}
                      name="宮城県東部エリア"
                    />
                  }
                  label={
                    <div style={{ paddingTop: '10px' }}>
                      <div>東部エリア</div>
                      <div style={{ fontSize: '.9em' }}>
                        (多賀城市・塩釜市・宮城郡など)
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.areas.some(
                        (area: string) =>
                          area === '宮城県南部エリア（名取市・岩沼市・亘理郡）',
                      )}
                      onChange={(event) => {
                        let newAreas: any[] = _.clone(formState.values.areas);
                        if (event.target.checked) {
                          newAreas.push(
                            '宮城県南部エリア（名取市・岩沼市・亘理郡）',
                          );
                        } else {
                          newAreas = _.remove(newAreas, (c) => {
                            return (
                              c !== '宮城県南部エリア（名取市・岩沼市・亘理郡）'
                            );
                          });
                        }
                        handleChange(event, 'areas', newAreas);
                        handleChange(event, 'city', '');
                        console.log(newAreas);
                      }}
                      name="宮城県南部エリア（名取市・岩沼市・亘理郡）"
                    />
                  }
                  label={
                    <div style={{ paddingTop: '10px' }}>
                      <div>南部エリア</div>
                      <div style={{ fontSize: '.9em' }}>
                        (名取市・岩沼市・亘理郡)
                      </div>
                    </div>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.areas.some(
                        (area: string) =>
                          area ===
                          '宮城県南部エリア（白石市・角田市・柴田郡など）',
                      )}
                      onChange={(event) => {
                        let newAreas: any[] = _.clone(formState.values.areas);
                        if (event.target.checked) {
                          newAreas.push(
                            '宮城県南部エリア（白石市・角田市・柴田郡など）',
                          );
                        } else {
                          newAreas = _.remove(newAreas, (c) => {
                            return (
                              c !==
                              '宮城県南部エリア（白石市・角田市・柴田郡など）'
                            );
                          });
                        }
                        handleChange(event, 'areas', newAreas);
                        handleChange(event, 'city', '');
                        console.log(newAreas);
                      }}
                      name="宮城県南部エリア（白石市・角田市・柴田郡など）"
                    />
                  }
                  label={
                    <div style={{ paddingTop: '10px' }}>
                      <div>南部エリア</div>
                      <div style={{ fontSize: '.9em' }}>
                        (白石市・角田市・柴田郡など)
                      </div>
                    </div>
                  }
                />
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '山形県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('山形県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '山形県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/山形県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="山形県"
                  />
                }
                label="山形県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('山形県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県山形市・上山市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県山形市・上山市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県山形市・上山市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県山形市・上山市"
                      />
                    }
                    label="山形市・上山市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県天童市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県天童市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県天童市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県天童市"
                      />
                    }
                    label="天童市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '山形県寒河江市・中山町・大江町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県寒河江市・中山町・大江町');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県寒河江市・中山町・大江町';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県寒河江市・中山町・大江町"
                      />
                    }
                    label="寒河江市・中山町・大江町"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県東根市・村山市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県東根市・村山市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県東根市・村山市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県東根市・村山市"
                      />
                    }
                    label="東根市・村山市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県米沢市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県米沢市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県米沢市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県米沢市"
                      />
                    }
                    label="米沢市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県南陽市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県南陽市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県南陽市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県南陽市"
                      />
                    }
                    label="南陽市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '山形県高畠町・川西町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県高畠町・川西町');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県高畠町・川西町';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県高畠町・川西町"
                      />
                    }
                    label="高畠町・川西町"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '山形県長井市・飯豊町・白鷹町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('山形県長井市・飯豊町・白鷹町');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '山形県長井市・飯豊町・白鷹町';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="山形県長井市・飯豊町・白鷹町"
                      />
                    }
                    label="長井市・飯豊町・白鷹町"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '福島県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('福島県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '福島県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="福島県"
                  />
                }
                label="福島県"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.selectLocation === '近畿' &&
              !(formState.values.jobCategory === 'ドライバー・配送・送迎')
                ? ''
                : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '滋賀県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('滋賀県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '滋賀県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="滋賀県"
                  />
                }
                label="滋賀県"
              />
            </FormGroup>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '京都府',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('京都府');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '京都府';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="京都府"
                  />
                }
                label="京都府"
              />
            </FormGroup>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '大阪府',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('大阪府');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '大阪府';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/大阪府/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="大阪府"
                  />
                }
                label="大阪府"
              />
            </FormGroup>
            <div
              style={{
                display:
                  formState.values.prefectures.includes('大阪府') &&
                  formState.values.jobCategory
                    ? ''
                    : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '大阪府大阪市内',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('大阪府大阪市内');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '大阪府大阪市内';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="大阪府大阪市内"
                      />
                    }
                    label="大阪市内"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '大阪府北部エリア',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('大阪府北部エリア');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '大阪府北部エリア';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="大阪府北部エリア"
                      />
                    }
                    label={
                      <div>
                        <div>北部エリア</div>
                        <div style={{ fontSize: '.9em' }}>
                          (茨木市、高槻市、吹田市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '大阪府東部・南部エリア',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('大阪府東部・南部エリア');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '大阪府東部・南部エリア';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="大阪府東部・南部エリア"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>東部・南部エリア</div>
                        <div style={{ fontSize: '.9em' }}>
                          (堺市・東大阪市など)
                        </div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '兵庫県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('兵庫県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '兵庫県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="兵庫県"
                  />
                }
                label="兵庫県"
              />
            </FormGroup>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '奈良県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('奈良県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '奈良県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="奈良県"
                  />
                }
                label="奈良県"
              />
            </FormGroup>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '和歌山県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      if (event.target.checked) {
                        newPrefectures.push('和歌山県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '和歌山県';
                        });
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                    }}
                    name="和歌山県"
                  />
                }
                label="和歌山県"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.selectLocation === '中国・四国' &&
              !(formState.values.jobCategory === 'ドライバー・配送・送迎')
                ? ''
                : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '岡山県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('岡山県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '岡山県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/岡山県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="岡山県"
                  />
                }
                label="岡山県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('岡山県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '岡山県岡山市北区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('岡山県岡山市北区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '岡山県岡山市北区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="岡山県岡山市北区"
                      />
                    }
                    label="岡山市北区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '岡山県岡山市南区・中区・東区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('岡山県岡山市南区・中区・東区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '岡山県岡山市南区・中区・東区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="岡山県山市南区・中区・東区"
                      />
                    }
                    label="岡山市南区・中区・東区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '岡山県倉敷市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('岡山県倉敷市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '岡山県倉敷市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="岡山県倉敷市"
                      />
                    }
                    label="倉敷市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '岡山県備前市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('岡山県備前市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '岡山県備前市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="岡山県備前市"
                      />
                    }
                    label="備前市"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '広島県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('広島県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '広島県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/広島県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="広島県"
                  />
                }
                label="広島県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('広島県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市中区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市中区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市中区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市中区"
                      />
                    }
                    label="広島市中区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市西区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市西区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市西区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市西区"
                      />
                    }
                    label="広島市西区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市佐伯区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市佐伯区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市佐伯区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市佐伯区"
                      />
                    }
                    label="広島市佐伯区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '広島県広島市安佐南区・安佐北区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市安佐南区・安佐北区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市安佐南区・安佐北区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市安佐南区・安佐北区"
                      />
                    }
                    label="広島市安佐南区・安佐北区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市南区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市南区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市南区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市南区"
                      />
                    }
                    label="広島市南区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市東区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市東区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市東区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市東区"
                      />
                    }
                    label="広島市東区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県広島市安芸区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県広島市安芸区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県広島市安芸区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県広島市安芸区"
                      />
                    }
                    label="広島市安芸区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県呉市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県呉市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県呉市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県呉市"
                      />
                    }
                    label="呉市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '広島県東広島市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('広島県東広島市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '広島県東広島市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="広島県東広島市"
                      />
                    }
                    label="東広島市"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '徳島県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('徳島県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '徳島県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/徳島県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="徳島県"
                  />
                }
                label="徳島県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('徳島県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '徳島県徳島市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('徳島県徳島市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '徳島県徳島市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="徳島県徳島市"
                      />
                    }
                    label="徳島市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '徳島県徳島市以外',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('徳島県徳島市以外');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '徳島県徳島市以外';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="徳島県徳島市以外"
                      />
                    }
                    label="徳島市以外"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '香川県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('香川県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '香川県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/香川県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="香川県"
                  />
                }
                label="香川県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('香川県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '香川県高松市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('香川県高松市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '香川県高松市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="香川県高松市"
                      />
                    }
                    label="高松市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '香川県観音寺市・三豊市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('香川県観音寺市・三豊市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '香川県観音寺市・三豊市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="香川県観音寺市・三豊市"
                      />
                    }
                    label="観音寺市・三豊市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '香川県坂出市・丸亀市・宇多津町',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('香川県坂出市・丸亀市・宇多津町');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '香川県坂出市・丸亀市・宇多津町';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="香川県坂出市・丸亀市・宇多津町"
                      />
                    }
                    label="坂出市・丸亀市・宇多津町"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '香川県東かがわ市・さぬき市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('香川県東かがわ市・さぬき市');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '香川県東かがわ市・さぬき市';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="香川県東かがわ市・さぬき市"
                      />
                    }
                    label="東かがわ市・さぬき市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '香川県木田郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('香川県木田郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '香川県木田郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="香川県木田郡"
                      />
                    }
                    label="木田郡"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '愛媛県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('愛媛県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '愛媛県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/愛媛県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="愛媛県"
                  />
                }
                label="愛媛県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('愛媛県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '愛媛県中予',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('愛媛県中予');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '愛媛県中予';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="愛媛県中予"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>中予</div>
                        <div style={{ fontSize: '.9em' }}>
                          (松山市、伊予市、東温市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '愛媛県東予',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('愛媛県東予');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '愛媛県東予';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="愛媛県東予"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>東予</div>
                        <div style={{ fontSize: '.9em' }}>
                          (西条市、新居浜市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '愛媛県南予',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('愛媛県南予');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '愛媛県南予';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="愛媛県南予"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>南予</div>
                        <div style={{ fontSize: '.9em' }}>
                          (宇和島市、大洲市など)
                        </div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '高知県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('高知県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '高知県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/高知県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="高知県"
                  />
                }
                label="高知県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('高知県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '高知県中部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('高知県中部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '高知県中部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="高知県中部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>高知県中部</div>
                        <div style={{ fontSize: '.9em' }}>
                          (高知市、土佐町、南国市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '高知県西部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('高知県西部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '高知県西部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="高知県西部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>高知県西部</div>
                        <div style={{ fontSize: '.9em' }}>
                          (室戸市、安芸市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '高知県東部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('高知県東部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '高知県東部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="高知県東部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>高知県東部</div>
                        <div style={{ fontSize: '.9em' }}>
                          (四万十市、宿毛市など)
                        </div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.selectLocation === '九州' &&
              !(formState.values.jobCategory === 'ドライバー・配送・送迎')
                ? ''
                : 'none',
            whiteSpace: 'pre-wrap',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '福岡県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('福岡県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '福岡県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/福岡県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="福岡県"
                  />
                }
                label="福岡県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('福岡県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    style={{ paddingTop: '5px' }}
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '福岡県福岡市東区・宗像市・福津市・古賀市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '福岡県福岡市東区・宗像市・福津市・古賀市',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !== '福岡県福岡市東区・宗像市・福津市・古賀市'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県福岡市東区・宗像市・福津市・古賀市"
                      />
                    }
                    label="福岡市東区・宗像市・福津市・古賀市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県糟屋郡',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県糟屋郡');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県糟屋郡';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県糟屋郡"
                      />
                    }
                    label="糟屋郡"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県福岡市博多区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県福岡市博多区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県福岡市博多区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県福岡市博多区"
                      />
                    }
                    label="福岡市博多区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area === '福岡県福岡市中央区・福岡市南区',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県福岡市中央区・福岡市南区');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県福岡市中央区・福岡市南区';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県福岡市中央区・福岡市南区"
                      />
                    }
                    label="福岡市中央区・福岡市南区"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) =>
                            area ===
                            '福岡県福岡市城南区・福岡市早良区・福岡市西区・糸島市',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push(
                              '福岡県福岡市城南区・福岡市早良区・福岡市西区・糸島市',
                            );
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return (
                                c !==
                                '福岡県福岡市城南区・福岡市早良区・福岡市西区・糸島市'
                              );
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県福岡市城南区・福岡市早良区・福岡市西区・糸島市"
                      />
                    }
                    label="福岡市城南区・福岡市早良区・福岡市西区・糸島市"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県福岡市近郊',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県福岡市近郊');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県福岡市近郊';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県福岡市近郊"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>福岡市近郊</div>
                        <div>(大野城市・春日市など)</div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県筑後エリア',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県筑後エリア');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県筑後エリア';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県筑後エリア"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>筑後エリア</div>
                        <div>(久留米市、大牟田市など)</div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県北九州市内',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県北九州市内');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県北九州市内';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県北九州市内"
                      />
                    }
                    label="北九州市内"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '福岡県北九州市近郊',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('福岡県北九州市近郊');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '福岡県北九州市近郊';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="福岡県北九州市近郊"
                      />
                    }
                    label={
                      <div>
                        <div>北九州市近郊</div>
                        <div>(豊前市、行橋市など)</div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '熊本県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('熊本県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '熊本県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/熊本県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="熊本県"
                  />
                }
                label="熊本県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('熊本県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '熊本県熊本市内',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('熊本県熊本市内');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '熊本県熊本市内';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="熊本県熊本市内"
                      />
                    }
                    label="熊本市内"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '熊本県熊本市外',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('熊本県熊本市外');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '熊本県熊本市外';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="熊本県熊本市外"
                      />
                    }
                    label="熊本市外"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '宮崎県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('宮崎県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '宮崎県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/宮崎県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="宮崎県"
                  />
                }
                label="宮崎県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('宮崎県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮崎県宮崎市近郊',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮崎県宮崎市近郊');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮崎県宮崎市近郊';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮崎県宮崎市近郊"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>宮崎市近郊</div>
                        <div style={{ fontSize: '.9em' }}>
                          (宮崎市、児湯郡、東諸県郡、西都市)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮崎県北部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮崎県北部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮崎県北部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮崎県北部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>宮崎県北部</div>
                        <div style={{ fontSize: '.9em' }}>
                          (延岡市、日向市、門川町)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮崎県西部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮崎県西部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮崎県西部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮崎県西部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>宮崎県西部</div>
                        <div style={{ fontSize: '.9em' }}>
                          (都城市、三股町、小林市、えびの市)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '宮崎県南部',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('宮崎県南部');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '宮崎県南部';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="宮崎県南部"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>宮崎県南部</div>
                        <div style={{ fontSize: '.9em' }}>(日南市)</div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            <FormGroup
              style={{
                borderBottom: 'solid',
                borderColor: '#dcdcdc',
                borderWidth: '1px',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.prefectures.some(
                      (prefecture: string) => prefecture === '鹿児島県',
                    )}
                    onChange={(event) => {
                      let newPrefectures: any[] = _.clone(
                        formState.values.prefectures,
                      );
                      let removeArea: any[] = _.clone(formState.values.areas);
                      if (event.target.checked) {
                        newPrefectures.push('鹿児島県');
                      } else {
                        newPrefectures = _.remove(newPrefectures, (c) => {
                          return c !== '鹿児島県';
                        });
                        removeArea = removeArea.filter(
                          (area: any) => area.match(/鹿児島県/) === null,
                        );
                      }
                      handleChange(event, 'prefectures', newPrefectures);
                      handleChange(event, 'areas', removeArea);
                      handleChange(event, 'city', '');
                      console.log(newPrefectures);
                      console.log(removeArea);
                    }}
                    name="鹿児島県"
                  />
                }
                label="鹿児島県"
              />
            </FormGroup>
            <div
              style={{
                display: formState.values.prefectures.includes('鹿児島県')
                  ? ''
                  : 'none',
              }}
            >
              <FormControl
                sx={{ ml: 3, mt: 0, mr: 2, mb: 2, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '鹿児島県鹿児島市・南薩',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('鹿児島県鹿児島市・南薩');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '鹿児島県鹿児島市・南薩';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="鹿児島県鹿児島市・南薩"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>鹿児島市・南薩</div>
                        <div style={{ fontSize: '.9em' }}>
                          (鹿児島市、南九州市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '鹿児島県北薩',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('鹿児島県北薩');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '鹿児島県北薩';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="鹿児島県北薩"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>北薩</div>
                        <div style={{ fontSize: '.9em' }}>
                          (日置市、薩摩川内市など)
                        </div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.areas.some(
                          (area: string) => area === '鹿児島県姶良・霧島・大隅',
                        )}
                        onChange={(event) => {
                          let newAreas: any[] = _.clone(formState.values.areas);
                          if (event.target.checked) {
                            newAreas.push('鹿児島県姶良・霧島・大隅');
                          } else {
                            newAreas = _.remove(newAreas, (c) => {
                              return c !== '鹿児島県姶良・霧島・大隅';
                            });
                          }
                          handleChange(event, 'areas', newAreas);
                          handleChange(event, 'city', '');
                          console.log(newAreas);
                        }}
                        name="鹿児島県姶良・霧島・大隅"
                      />
                    }
                    label={
                      <div style={{ paddingTop: '10px' }}>
                        <div>姶良・霧島・大隅</div>
                        <div style={{ fontSize: '.9em' }}>
                          (姶良市、霧島市、鹿屋市など)
                        </div>
                      </div>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
          </FormControl>
        </div>
        <div>
          <InputLabel
            sx={{
              ml: 4,
              mt: 2,
              color: '#0f1941',
              fontSize: '1em',
            }}
          >
            <StarIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' こだわり条件'}
          </InputLabel>
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                style={{
                  display: showCondition('start_date') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'start_date',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('start_date');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'start_date';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="start_date"
                  />
                }
                label="即日スタート"
              />
              <FormControlLabel
                style={{
                  display: showCondition('beginner_ok') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'beginner_ok',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('beginner_ok');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'beginner_ok';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="beginner_ok"
                  />
                }
                label="未経験OK"
              />
              <FormControlLabel
                style={{
                  display: showCondition('dependents') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'dependents',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('dependents');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'dependents';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="dependents"
                  />
                }
                label="扶養内"
              />
              <FormControlLabel
                style={{
                  display: showCondition('no_overtime') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'no_overtime',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('no_overtime');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'no_overtime';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="no_overtime"
                  />
                }
                label="残業なし"
              />
              <FormControlLabel
                style={{
                  display: showCondition('short_term') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'short_term',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('short_term');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'short_term';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="short_term"
                  />
                }
                label="短期"
              />
              <FormControlLabel
                style={{
                  display: showCondition('over_1500_hourly_pay') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) =>
                        condition === 'over_1500_hourly_pay',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('over_1500_hourly_pay');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'over_1500_hourly_pay';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="over_1500_hourly_pay"
                  />
                }
                label="時給1500円以上"
              />
              <FormControlLabel
                style={{
                  display: showCondition('allow_car_commute') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'allow_car_commute',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('allow_car_commute');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'allow_car_commute';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="allow_car_commute"
                  />
                }
                label="車通勤OK"
              />
              <FormControlLabel
                style={{
                  display: showCondition('light_car_work') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'light_car_work',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('light_car_work');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'light_car_work';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="light_car_work"
                  />
                }
                label="軽・普通車のお仕事"
              />
              <FormControlLabel
                style={{
                  display: showCondition('heavy_car_work') ? '' : 'none',
                }}
                control={
                  <Checkbox
                    checked={formState.values.conditions.some(
                      (condition: string) => condition === 'heavy_car_work',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.conditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('heavy_car_work');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'heavy_car_work';
                        });
                      }
                      handleChange(event, 'conditions', newConditions);
                    }}
                    name="heavy_car_work"
                  />
                }
                label="中型・大型車のお仕事"
              />
            </FormGroup>
          </FormControl>
        </div>
        <Divider />
        <div>
          <InputLabel sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}>
            <SettingsIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' その他設定'}
          </InputLabel>
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="broadcast_frequency"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              おすすめ求人の配信頻度
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.broadcastFrequency}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'broadcastFrequency', event.target.value);
              }}
            >
              {/*<FormControlLabel*/}
              {/*  value="every_day"*/}
              {/*  control={<Radio />}*/}
              {/*  label="毎日"*/}
              {/*/>*/}
              <FormControlLabel
                value="next_day"
                control={<Radio />}
                label="週３"
              />
              <FormControlLabel
                value="one_day"
                control={<Radio />}
                label="週１"
              />
              <FormControlLabel
                value="opt_out"
                control={<Radio />}
                label="配信を希望しない"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClickSave}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              登録する
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            プロフィールを登録しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileForm;
