import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { saveProfile } from '../features/profile/profileSlice';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import SettingsIcon from '@mui/icons-material/Settings';

import liff from '@line/liff';
import BusinessIcon from '@mui/icons-material/Business';
import StarIcon from '@mui/icons-material/Star';
import _ from 'lodash';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

type ProfessionalProfileFormProps = {
  profile: any;
  lang: string;
  locations: any[];
  industries: any[];
  positions: any[];
  retry: any;
};

const ProfessionalProfileForm: React.FC<ProfessionalProfileFormProps> = ({
  profile,
  lang,
  locations,
  industries,
  positions,
  retry,
}) => {
  const en = profile.lang ? profile.lang === 'en' : lang === 'en';

  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      birthday: profile.birthday,
      staffCode: profile.staffCode,
      gender: profile.gender,
      email: profile.email,
      lang: profile.lang ? profile.lang : en ? 'en' : 'ja',

      // Staffing
      selectLocation: profile.selectLocation,
      prefectures: profile.prefectures,
      areas: profile.areas,
      prefecture: profile.prefecture,
      city: profile.city,
      jobCategory: profile.jobCategory,
      conditions: profile.conditions || [],
      broadcastFrequency: profile.broadcastFrequency || 'every_day',

      // Professional
      professionalPreferredLocation: profile.professionalPreferredLocation,
      professionalPreferredLocations:
        profile.professionalPreferredLocations || [],
      professionalPreferredIndustry: profile.professionalPreferredIndustry,
      professionalPreferredIndustries:
        profile.professionalPreferredIndustries || [],
      professionalPreferredPosition: profile.professionalPreferredPosition,
      professionalPreferredPositions:
        profile.professionalPreferredPositions || [],
      professionalPreferredSalary: profile.professionalPreferredSalary,
      professionalConditions: profile.professionalConditions || [],
      broadcastFrequencyProfessional:
        profile.broadcastFrequencyProfessional || 'everyday',

      // SPOT
      spotPrefecture: profile.spotPrefecture,
      spotArea: profile.spotArea,
      spotCity: profile.spotCity,
      spotJobCategory: profile.spotJobCategory,
      broadcastFrequencySpot: profile.broadcastFrequencySpot,
      narrowDown: profile.narrowDown,
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [emailErrorText, setEmailErrorText] = React.useState('');
  const checkEmail = (email: string) => {
    const valid =
      email === '' ||
      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/.test(
        email,
      );
    if (valid) {
      setInvalidEmail(false);
      setEmailErrorText('');
    } else {
      setInvalidEmail(true);
      setEmailErrorText(
        en
          ? 'Please enter a valid email address.'
          : '有効なEメールアドレスを入力してください。',
      );
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickSave = () => {
    setOpen(true);

    dispatch(
      saveProfile({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
      }),
    );

    if (retry) {
      setTimeout(() => {
        liff
          .sendMessages([
            {
              type: 'text',
              text: '再度検索しなおす',
            },
          ])
          .then(() => {
            console.log('message sent');
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();

    if (profile.lang !== formState.values.lang) {
      liff
        .sendMessages([
          {
            type: 'text',
            text:
              formState.values.lang === 'en'
                ? `I have changed my language preference.`
                : '言語を変更しました。',
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          border: '1px solid rgb(0 0 0 / 12%)',
          margin: '1em 1em 5em 1em',
        }}
      >
        <div>
          <TextField
            id="standard-basic"
            label={en ? 'Last name' : '姓'}
            variant="standard"
            value={formState.values.lastname}
            onChange={(event) => {
              handleChange(event, 'lastname', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            placeholder="山田"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="standard-basic"
            label={en ? 'Last name' : '名'}
            variant="standard"
            value={formState.values.firstname}
            onChange={(event) => {
              handleChange(event, 'firstname', event.target.value);
            }}
            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
            placeholder="太郎"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          {/*<TextField*/}
          {/*  id="birthday"*/}
          {/*  variant="standard"*/}
          {/*  className={'birthday-field'}*/}
          {/*  label={en ? 'Birthday' : '生年月日'}*/}
          {/*  type="date"*/}
          {/*  value={formState.values.birthday || '2000-01-01'}*/}
          {/*  onChange={(event) => {*/}
          {/*    console.log(event.target.value);*/}
          {/*    handleChange(event, 'birthday', event.target.value);*/}
          {/*  }}*/}
          {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
          {/*  InputLabelProps={{*/}
          {/*    shrink: true,*/}
          {/*  }}*/}
          {/*/>*/}
          <MobileDatePicker
            label="生年月日"
            inputFormat="yyyy/M/d"
            toolbarFormat="yyyy/M/d"
            value={formState.values.birthday || '2000/1/1'}
            maxDate={new Date()}
            onChange={(date) => {
              console.log(date);
              handleChange(
                {
                  target: {
                    name: 'birthday',
                  },
                },
                'birthday',
                moment(date).format('YYYY-MM-DD'),
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params}
              />
            )}
          />
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              {en ? 'Gendar' : '性別'}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.gender}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'gender', event.target.value);
              }}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={en ? 'Female' : '女性'}
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={en ? 'Male' : '男性'}
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label={en ? 'Other' : 'その他'}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <TextField
            id="standard-basic"
            label={en ? 'Email' : 'メールアドレス'}
            type="email"
            variant="standard"
            value={formState.values.email}
            onChange={(event) => {
              handleChange(event, 'email', event.target.value);

              checkEmail(event.target.value);
            }}
            error={invalidEmail}
            helperText={emailErrorText}
            sx={{ mt: 0, ml: 4, mr: 2, mb: 2, width: '70%' }}
            placeholder="example@email.com"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Divider />

        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="professionalPreferredLocation-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Preferred work location' : ' 希望勤務地'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="professionalPreferredLocation-label"
              id="professionalPreferredLocation-select"
              variant="standard"
              value={formState.values.professionalPreferredLocation}
              onChange={(event) => {
                console.log(event.target.value);

                handleChange(
                  event,
                  'professionalPreferredLocation',
                  event.target.value,
                );

                switch (event.target.value) {
                  case '全国':
                    handleChange(
                      event,
                      'professionalPreferredLocations',
                      locations.map((l) => l.value),
                    );
                    break;
                  default:
                    handleChange(event, 'professionalPreferredLocations', []);
                    break;
                }
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'関東'}>Kanto / 関東</MenuItem>
              <MenuItem value={'北海道・東北'}>
                Hokkaido･Tohoku / 北海道・東北
              </MenuItem>
              <MenuItem value={'甲信越・北陸'}>
                Koshinetsu･Hokuriku / 甲信越・北陸
              </MenuItem>
              <MenuItem value={'東海'}>Tokai / 東海</MenuItem>
              <MenuItem value={'関西'}>Kansai / 関西</MenuItem>
              <MenuItem value={'中国'}>Chugoku / 中国</MenuItem>
              <MenuItem value={'四国'}>Shikoku / 四国</MenuItem>
              <MenuItem value={'九州'}>Kyushu / 九州</MenuItem>
              <MenuItem value={'海外'}>Overseas / 海外</MenuItem>
              <MenuItem value={'全国'}>Throughout Japan / 全国</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '関東' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '関東')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation ===
                '北海道・東北' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '北海道・東北')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation ===
                '甲信越・北陸' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '甲信越・北陸')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '東海' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '東海')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '関西' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '関西')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '中国' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '中国')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '四国' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '四国')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '九州' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '九州')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredLocation === '海外' ||
              formState.values.professionalPreferredLocation === '全国'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              {locations
                .filter((l) => l.category === '海外')
                .map((l, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredLocations.some(
                            (location: string) => location === l.value,
                          )}
                          onChange={(event) => {
                            let newLocations: any[] = _.clone(
                              formState.values.professionalPreferredLocations,
                            );
                            if (event.target.checked) {
                              newLocations.push(l.value);
                            } else {
                              newLocations = _.remove(newLocations, (c) => {
                                return c !== l.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredLocations',
                              newLocations,
                            );
                          }}
                          name={l.value}
                        />
                      }
                      label={l.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <Divider />

        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="professionalPreferredIndustry-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Preferred industry' : ' 希望業界'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="professionalPreferredIndustry-label"
              id="professionalPreferredIndustry-select"
              variant="standard"
              value={formState.values.professionalPreferredIndustry}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(
                  event,
                  'professionalPreferredIndustry',
                  event.target.value,
                );

                switch (event.target.value) {
                  default:
                    handleChange(event, 'professionalPreferredIndustries', []);
                    break;
                }
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'B2C'}>B2C / 消費財, 高級消費財</MenuItem>
              <MenuItem value={'医療'}>Medical Device / 医療機器</MenuItem>
              <MenuItem value={'製薬'}>Pharmaceutical / 製薬</MenuItem>
              <MenuItem value={'製造業'}>Manufacturing / 製造業</MenuItem>
              <MenuItem value={'テクノロジー'}>
                Technology / テクノロジー
              </MenuItem>
              <MenuItem value={'銀行/証券/保険'}>
                Banking&Insurance / 銀行/証券/保険
              </MenuItem>
              <MenuItem value={'サービス業'}>Service / サービス業</MenuItem>
              <MenuItem value={'コンサルティング'}>
                Consulting / コンサルティング
              </MenuItem>
              <MenuItem value={'建設業'}>Construction / 建設業</MenuItem>
              <MenuItem value={'教育'}>Education / 教育</MenuItem>
              <MenuItem value={'その他'}>Other / その他</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === 'B2C'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === 'B2C').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === 'B2C').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === 'B2C')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === 'B2C').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === 'B2C')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === '医療'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '医療').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === '医療').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '医療')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '医療').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '医療')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === '製薬'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '製薬').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === '製薬').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '製薬')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '製薬').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '製薬')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === '製造業'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '製造業').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === '製造業').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '製造業')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '製造業').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '製造業')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === 'テクノロジー'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === 'テクノロジー')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === 'テクノロジー')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === 'テクノロジー')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === 'テクノロジー').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === 'テクノロジー')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry ===
              '銀行/証券/保険'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '銀行/証券/保険')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter(
                          (i) => i.category === '銀行/証券/保険',
                        ).length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '銀行/証券/保険')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '銀行/証券/保険')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '銀行/証券/保険')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === 'サービス業'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === 'サービス業')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === 'サービス業')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === 'サービス業')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === 'サービス業').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === 'サービス業')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry ===
              'コンサルティング'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter(
                        (i) => i.category === 'コンサルティング',
                      ).length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter(
                          (i) => i.category === 'コンサルティング',
                        ).length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === 'コンサルティング')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === 'コンサルティング')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === 'コンサルティング')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === '建設業'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '建設業').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === '建設業').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '建設業')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '建設業').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '建設業')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === '教育'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === '教育').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === '教育').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === '教育')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === '教育').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === '教育')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredIndustry === 'その他'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredIndustries
                        .length ===
                      industries.filter((i) => i.category === 'その他').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredIndustries
                          .length ===
                        industries.filter((i) => i.category === 'その他').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredIndustries',
                          industries
                            .filter((i) => i.category === 'その他')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredIndustries.length ===
                  industries.filter((i) => i.category === 'その他').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {industries
                .filter((i) => i.category === 'その他')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredIndustries.some(
                            (industry: string) => industry === i.value,
                          )}
                          onChange={(event) => {
                            let newIndustries: any[] = _.clone(
                              formState.values.professionalPreferredIndustries,
                            );
                            if (event.target.checked) {
                              newIndustries.push(i.value);
                            } else {
                              newIndustries = _.remove(newIndustries, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredIndustries',
                              newIndustries,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <Divider />

        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="professionalPreferredPosition-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Preferred position' : ' 希望職種'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="professionalPreferredPosition-label"
              id="professionalPreferredPosition-select"
              variant="standard"
              value={formState.values.professionalPreferredPosition}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(
                  event,
                  'professionalPreferredPosition',
                  event.target.value,
                );

                switch (event.target.value) {
                  default:
                    handleChange(event, 'professionalPreferredPositions', []);
                    break;
                }
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'営業'}>Sales / 営業, セールス</MenuItem>
              <MenuItem value={'エンジニア'}>Engineering / エンジニア</MenuItem>
              <MenuItem value={'技術系専門職'}>
                IT Engineer / 技術系専門職
              </MenuItem>
              <MenuItem value={'医療'}>
                Medical/Lifescience / 医薬, 医療機器専門職
              </MenuItem>
              <MenuItem value={'人事'}>
                Human Resource & Administration / 人事 &
                総務・アドミニストレーション
              </MenuItem>
              <MenuItem value={'法務'}>
                Legal & Compliance&Insurance / 法務 & コンプライアンス
              </MenuItem>
              <MenuItem value={'経理'}>
                Accounting & Finance / 経理, 財務
              </MenuItem>
              <MenuItem value={'企画'}>Planning / 企画</MenuItem>
              <MenuItem value={'サプライチェーン'}>
                Supply Chain / サプライチェーン
              </MenuItem>
              <MenuItem value={'マーケティング'}>
                Marketing / マーケティング
              </MenuItem>
              <MenuItem value={'小売'}>Retail / 小売</MenuItem>
              <MenuItem value={'コンサルティング'}>
                Consulting / コンサルティング
              </MenuItem>
              <MenuItem value={'品質'}>Quality / 品質</MenuItem>
              <MenuItem value={'金融'}>
                Real Estate & Finance / 不動産 & 金融
              </MenuItem>
              <MenuItem value={'ウェブデザイン'}>
                Web Design / ウェブデザイン
              </MenuItem>
              <MenuItem value={'エグゼクティブ'}>
                Executive / エグゼクティブ
              </MenuItem>
              <MenuItem value={'その他'}>Other / その他</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '営業'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '営業').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '営業').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '営業')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '営業').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '営業')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === 'エンジニア'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'エンジニア')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === 'エンジニア')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'エンジニア')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'エンジニア').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'エンジニア')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '技術系専門職'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '技術系専門職')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '技術系専門職')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '技術系専門職')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '技術系専門職').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '技術系専門職')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '医療'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '医療').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '医療').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '医療')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '医療').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '医療')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '人事'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '人事').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '人事').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '人事')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '人事').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '人事')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '法務'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '法務').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '法務').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '法務')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '法務').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '法務')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '経理'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '経理').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '経理').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '経理')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '経理').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '経理')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '企画'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '企画').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '企画').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '企画')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '企画').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '企画')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition ===
              'サプライチェーン'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'サプライチェーン')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter(
                          (i) => i.category === 'サプライチェーン',
                        ).length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'サプライチェーン')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'サプライチェーン')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'サプライチェーン')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition ===
              'マーケティング'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'マーケティング')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === 'マーケティング')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'マーケティング')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'マーケティング')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'マーケティング')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '小売'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '小売').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '小売').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '小売')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '小売').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '小売')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition ===
              'コンサルティング'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'コンサルティング')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter(
                          (i) => i.category === 'コンサルティング',
                        ).length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'コンサルティング')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'コンサルティング')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'コンサルティング')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '品質'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '品質').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '品質').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '品質')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '品質').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '品質')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === '金融'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === '金融').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === '金融').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === '金融')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === '金融').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === '金融')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition ===
              'ウェブデザイン'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'ウェブデザイン')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === 'ウェブデザイン')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'ウェブデザイン')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'ウェブデザイン')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'ウェブデザイン')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition ===
              'エグゼクティブ'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'エグゼクティブ')
                        .length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === 'エグゼクティブ')
                          .length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'エグゼクティブ')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'エグゼクティブ')
                    .length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'エグゼクティブ')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <div
          style={{
            display:
              formState.values.professionalPreferredPosition === 'その他'
                ? ''
                : 'none',
          }}
        >
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formState.values.professionalPreferredPositions.length ===
                      positions.filter((i) => i.category === 'その他').length
                    }
                    onChange={(event) => {
                      if (
                        formState.values.professionalPreferredPositions
                          .length ===
                        positions.filter((i) => i.category === 'その他').length
                      ) {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          [],
                        );
                      } else {
                        handleChange(
                          event,
                          'professionalPreferredPositions',
                          positions
                            .filter((i) => i.category === 'その他')
                            .map((i) => i.value),
                        );
                      }
                    }}
                  />
                }
                label={
                  formState.values.professionalPreferredPositions.length ===
                  positions.filter((i) => i.category === 'その他').length
                    ? '全選択を解除'
                    : '全て選択'
                }
              />
              {positions
                .filter((i) => i.category === 'その他')
                .map((i, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={formState.values.professionalPreferredPositions.some(
                            (position: string) => position === i.value,
                          )}
                          onChange={(event) => {
                            let newPositions: any[] = _.clone(
                              formState.values.professionalPreferredPositions,
                            );
                            if (event.target.checked) {
                              newPositions.push(i.value);
                            } else {
                              newPositions = _.remove(newPositions, (c) => {
                                return c !== i.value;
                              });
                            }
                            handleChange(
                              event,
                              'professionalPreferredPositions',
                              newPositions,
                            );
                          }}
                          name={i.value}
                        />
                      }
                      label={i.label}
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>

        <Divider />

        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="jobCategory-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Preferred anual income (Yen)' : ' 希望年収'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="professionalPreferredSalary-label"
              id="professionalPreferredSalary-select"
              variant="standard"
              value={formState.values.professionalPreferredSalary}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(
                  event,
                  'professionalPreferredSalary',
                  event.target.value,
                );
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={600}>6M - 9M / 600万円以上900万円未満</MenuItem>
              <MenuItem value={900}>
                9M - 12M / 900万円以上1,200万円未満
              </MenuItem>
              <MenuItem value={1200}>
                12M - 15M / 1,200万円以上1,500万円未満
              </MenuItem>
              <MenuItem value={1500}>15M or more / 1,500万円以上</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div>
          <InputLabel
            sx={{
              ml: 4,
              mt: 2,
              color: '#0f1941',
              fontSize: '1em',
            }}
          >
            <StarIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Job preference' : ' こだわり条件'}
          </InputLabel>
          <FormControl
            sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}
            component="fieldset"
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'foreign',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('foreign');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'foreign';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="foreign"
                  />
                }
                label={en ? 'Foreign company' : '外資系企業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'listed',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('listed');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'listed';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="listed"
                  />
                }
                label={en ? 'Listed company' : '上場企業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'venture',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('venture');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'venture';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="venture"
                  />
                }
                label={en ? 'Venture company' : 'ベンチャー企業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'female_workers',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('female_workers');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'female_workers';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="female_workers"
                  />
                }
                label={en ? 'Female workers' : '女性活躍企業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'global',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('global');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'global';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="global"
                  />
                }
                label={en ? 'Global business' : 'グローバル事業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'new_business',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('new_business');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'new_business';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="new_business"
                  />
                }
                label={en ? 'New business' : '新規事業'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'no_transfer',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('no_transfer');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'no_transfer';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="no_transfer"
                  />
                }
                label={en ? 'No transfer' : '転勤なし'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'close_weekend',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('close_weekend');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'close_weekend';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="close_weekend"
                  />
                }
                label={en ? 'Close weekend' : '土日祝休み'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'less_overtime',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('less_overtime');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'less_overtime';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="less_overtime"
                  />
                }
                label={en ? 'Less overtime' : '残業少なめ'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'flex',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('flex');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'flex';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="flex"
                  />
                }
                label={en ? 'Flex' : 'フレックス'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'language_skill',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('language_skill');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'language_skill';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="language_skill"
                  />
                }
                label={en ? 'Language skill' : '語学力'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'casual_dress',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('casual_dress');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'casual_dress';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="casual_dress"
                  />
                }
                label={en ? 'Casual dress' : '服装自由'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'remote_ok',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('remote_ok');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'remote_ok';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="remote_ok"
                  />
                }
                label={en ? 'Remote' : '在宅'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.values.professionalConditions.some(
                      (condition: string) => condition === 'side_business_ok',
                    )}
                    onChange={(event) => {
                      let newConditions: any[] = _.clone(
                        formState.values.professionalConditions,
                      );
                      if (event.target.checked) {
                        newConditions.push('side_business_ok');
                      } else {
                        newConditions = _.remove(newConditions, (c) => {
                          return c !== 'side_business_ok';
                        });
                      }
                      handleChange(
                        event,
                        'professionalConditions',
                        newConditions,
                      );
                    }}
                    name="side_business_ok"
                  />
                }
                label={en ? 'Side business ok' : '副業可'}
              />
            </FormGroup>
          </FormControl>
        </div>
        <Divider />

        <div>
          <InputLabel sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}>
            <SettingsIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {en ? ' Other preference' : ' その他設定'}
          </InputLabel>
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="broadcast_frequency_professional"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              {en ? ' Receive frequency' : 'おすすめ求人の配信頻度'}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.broadcastFrequencyProfessional}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(
                  event,
                  'broadcastFrequencyProfessional',
                  event.target.value,
                );
              }}
            >
              <FormControlLabel
                value="everyday"
                control={<Radio />}
                label={en ? 'Everyday' : '毎日'}
              />
              <FormControlLabel
                value="nextday"
                control={<Radio />}
                label={en ? 'Nextday' : '週３'}
              />
              <FormControlLabel
                value="oneday"
                control={<Radio />}
                label={en ? 'Oneday' : '週１'}
              />
              <FormControlLabel
                value="opt_out"
                control={<Radio />}
                label={en ? 'Opt out' : '配信を希望しない'}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ mt: 0, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="lang-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              {' 言語 / Language'}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="lang-buttons-group-label"
              name="lang-buttons-group"
              value={formState.values.lang}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'lang', event.target.value);
              }}
            >
              <FormControlLabel
                value="ja"
                control={<Radio />}
                label={'日本語'}
              />
              <FormControlLabel
                value="en"
                control={<Radio />}
                label={'English'}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClickSave}
            disabled={invalidEmail}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {en ? 'Save' : '登録する'}
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {en ? 'Info' : 'お知らせ'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {en ? 'Saved your profile.' : 'プロフィールを登録しました。'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfessionalProfileForm;
