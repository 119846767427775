import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { saveProfile } from '../features/profile/profileSlice';
import { channelId, idToken, userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import SettingsIcon from '@mui/icons-material/Settings';

import liff from '@line/liff';
import BusinessIcon from '@mui/icons-material/Business';
import TrainIcon from '@mui/icons-material/Train';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

type SpotProfileFormProps = {
  profile: any;
  retry: any;
};

const SpotProfileForm: React.FC<SpotProfileFormProps> = ({
  profile,
  retry,
}) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      birthday: profile.birthday,
      staffCode: profile.staffCode,
      gender: profile.gender,
      email: profile.email,
      lang: profile.lang,

      // Staffing
      selectLocation: profile.selectLocation,
      prefectures: profile.prefectures,
      areas: profile.areas,
      prefecture: profile.prefecture,
      city: profile.city,
      jobCategory: profile.jobCategory,
      conditions: profile.conditions || [],
      broadcastFrequency: profile.broadcastFrequency || 'every_day',
      excludeForklift: profile.excludeForklift,

      // Professional
      professionalPreferredLocation: profile.professionalPreferredLocation,
      professionalPreferredLocations: profile.professionalPreferredLocations,
      professionalPreferredIndustry: profile.professionalPreferredIndustry,
      professionalPreferredIndustries: profile.professionalPreferredIndustries,
      professionalPreferredPosition: profile.professionalPreferredPosition,
      professionalPreferredPositions: profile.professionalPreferredPositions,
      professionalPreferredSalary: profile.professionalPreferredSalary,
      professionalConditions: profile.professionalConditions,
      broadcastFrequencyProfessional:
        profile.broadcastFrequencyProfessional || 'everyday',

      // SPOT
      spotPrefecture: profile.spotPrefecture,
      spotArea: profile.spotArea,
      spotCity: profile.spotCity,
      spotJobCategory: profile.spotJobCategory,
      broadcastFrequencySpot: profile.broadcastFrequencySpot,
      narrowDown: profile.narrowDown,
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [open, setOpen] = React.useState(false);

  const handleClickSave = () => {
    setOpen(true);

    dispatch(
      saveProfile({
        idToken: idToken(),
        channelId: channelId(),
        values: formState.values,
      }),
    );

    if (retry) {
      setTimeout(() => {
        liff
          .sendMessages([
            {
              type: 'text',
              text: '再度検索しなおす',
            },
          ])
          .then(() => {
            console.log('message sent');
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    }
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          border: '1px solid rgb(0 0 0 / 12%)',
          margin: '1em 1em 5em 1em',
        }}
      >
        <div>
          <TextField
            id="standard-basic"
            label="姓"
            variant="standard"
            value={formState.values.lastname}
            onChange={(event) => {
              handleChange(event, 'lastname', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            placeholder="山田"
          />
          <TextField
            id="standard-basic"
            label="名"
            variant="standard"
            value={formState.values.firstname}
            onChange={(event) => {
              handleChange(event, 'firstname', event.target.value);
            }}
            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
            placeholder="太郎"
          />
        </div>
        <div>
          {/*<TextField*/}
          {/*  id="birthday"*/}
          {/*  variant="standard"*/}
          {/*  className={'birthday-field'}*/}
          {/*  label="生年月日"*/}
          {/*  type="date"*/}
          {/*  value={formState.values.birthday || '2000-01-01'}*/}
          {/*  onChange={(event) => {*/}
          {/*    console.log(event.target.value);*/}
          {/*    handleChange(event, 'birthday', event.target.value);*/}
          {/*  }}*/}
          {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
          {/*  InputLabelProps={{*/}
          {/*    shrink: true,*/}
          {/*  }}*/}
          {/*/>*/}
          <MobileDatePicker
            label="生年月日"
            inputFormat="yyyy/M/d"
            toolbarFormat="yyyy/M/d"
            value={formState.values.birthday || '2000/1/1'}
            maxDate={new Date()}
            onChange={(date) => {
              console.log(date);
              handleChange(
                {
                  target: {
                    name: 'birthday',
                  },
                },
                'birthday',
                moment(date).format('YYYY-MM-DD'),
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params}
              />
            )}
          />
        </div>
        <div>
          <TextField
            id="staff-code"
            variant="standard"
            label="スタッフコード"
            type="tel"
            placeholder="00000000"
            value={formState.values.staffCode}
            inputProps={{ maxLength: '8' }}
            onChange={(event) => {
              console.log(event.target.value);
              handleChange(event, 'staffCode', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              性別
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.gender}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'gender', event.target.value);
              }}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="女性"
              />
              <FormControlLabel value="male" control={<Radio />} label="男性" />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="その他"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Divider />
        <div>
          <InputLabel
            sx={{ ml: 4, mt: 4, color: '#0f1941', fontSize: '1em' }}
            id="jobCategory-label"
          >
            <BusinessIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' 希望職種'}
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="jobCategory-label"
              id="jobCategory-select"
              variant="standard"
              value={formState.values.spotJobCategory}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotJobCategory', event.target.value);
              }}
            >
              <MenuItem value={''} />
              <MenuItem
                style={{ whiteSpace: 'pre-wrap', fontSize: '0.85em' }}
                value={
                  'アクティブワーク（軽作業、仕分け、検査、事務所移転など）'
                }
              >
                {'アクティブワーク\n(軽作業、仕分け、検査、事務所移転など)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap', fontSize: '0.85em' }}
                value={
                  'オフィスワーク(事務、試験監督、選挙、コールセンターなど)'
                }
              >
                {'オフィスワーク\n(事務、試験監督、選挙、コールセンターなど)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap', fontSize: '0.85em' }}
                value={'接客サービス(イベント、サンプリング、飲食、調査など)'}
              >
                {'接客サービス\n(イベント、サンプリング、飲食、調査など)'}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <InputLabel sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}>
            <TrainIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' 希望勤務地'}
          </InputLabel>
        </div>
        <div>
          <InputLabel
            sx={{ ml: 4, mt: 1, fontSize: '.8em' }}
            id="prefecture-label"
          >
            都道府県
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="prefecture-label"
              id="prefecture-select"
              variant="standard"
              value={formState.values.spotPrefecture}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotPrefecture', event.target.value);
                handleChange(event, 'spotArea', '');
                handleChange(event, 'spotCity', '');
                handleChange(event, 'narrowDown', false);
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'北海道'}>北海道</MenuItem>
              <MenuItem value={'宮城県'}>宮城県</MenuItem>
              <MenuItem value={'福島県'}>福島県</MenuItem>
              <MenuItem value={'茨城県'}>茨城県</MenuItem>
              <MenuItem value={'栃木県'}>栃木県</MenuItem>
              <MenuItem value={'群馬県'}>群馬県</MenuItem>
              <MenuItem value={'埼玉県'}>埼玉県</MenuItem>
              <MenuItem value={'千葉県'}>千葉県</MenuItem>
              <MenuItem value={'東京都'}>東京都</MenuItem>
              <MenuItem value={'神奈川県'}>神奈川県</MenuItem>
              <MenuItem value={'新潟県'}>新潟県</MenuItem>
              <MenuItem value={'静岡県'}>静岡県</MenuItem>
              <MenuItem value={'愛知県'}>愛知県</MenuItem>
              <MenuItem value={'三重県'}>三重県</MenuItem>
              <MenuItem value={'滋賀県'}>滋賀県</MenuItem>
              <MenuItem value={'京都府'}>京都府</MenuItem>
              <MenuItem value={'大阪府'}>大阪府</MenuItem>
              <MenuItem value={'兵庫県'}>兵庫県</MenuItem>
              <MenuItem value={'奈良県'}>奈良県</MenuItem>
              <MenuItem value={'和歌山県'}>和歌山県</MenuItem>
              <MenuItem value={'広島県'}>広島県</MenuItem>
              <MenuItem value={'香川県'}>香川県</MenuItem>
              <MenuItem value={'愛媛県'}>愛媛県</MenuItem>
              <MenuItem value={'福岡県'}>福岡県</MenuItem>
              <MenuItem value={'佐賀県'}>佐賀県</MenuItem>
              <MenuItem value={'熊本県'}>熊本県</MenuItem>
              <MenuItem value={'宮崎県'}>宮崎県</MenuItem>
              <MenuItem value={'鹿児島県'}>鹿児島県</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '北海道' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: '.8em' }} id="city-label">
            市区町村
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotCity}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotCity', event.target.value);
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'札幌市'}>
                {'札幌市'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'旭川市'}>
                {'旭川市'}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '茨城県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'県央・鹿行エリア'}
              >
                {'県央・鹿行エリア\n(水戸市、笠間市、小美玉市、行方市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県西エリア'}>
                {'県西エリア\n(筑西市、古河市、猿島郡など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県南エリア'}>
                {'県南エリア\n(つくば市、土浦市、取手市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県北エリア'}>
                {'県北エリア\n(日立市、那珂市、ひたちなか市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県央・鹿行エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'水戸市'}
                    >
                      {'水戸市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'笠間市'}
                    >
                      {'笠間市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小美玉市'}
                    >
                      {'小美玉市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東茨城郡'}
                    >
                      {'東茨城郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鉾田市'}
                    >
                      {'鉾田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鹿嶋市'}
                    >
                      {'鹿嶋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'行方市'}
                    >
                      {'行方市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'潮来市'}
                    >
                      {'潮来市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'神栖市'}
                    >
                      {'神栖市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県西エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'筑西市'}
                    >
                      {'筑西市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'結城市'}
                    >
                      {'結城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'桜川市'}
                    >
                      {'桜川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'下妻市'}
                    >
                      {'下妻市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'結城郡'}
                    >
                      {'結城郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'古河市'}
                    >
                      {'古河市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'常総市'}
                    >
                      {'常総市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'猿島郡'}
                    >
                      {'猿島郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'坂東市'}
                    >
                      {'坂東市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県南エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'つくば市'}
                    >
                      {'つくば市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'土浦市'}
                    >
                      {'土浦市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'つくばみらい市'}
                    >
                      {'つくばみらい市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'石岡市'}
                    >
                      {'石岡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'かすみがうら市'}
                    >
                      {'かすみがうら市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'守谷市'}
                    >
                      {'守谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'牛久市'}
                    >
                      {'牛久市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'稲敷郡'}
                    >
                      {'稲敷郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'稲敷市'}
                    >
                      {'稲敷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'龍ヶ崎市'}
                    >
                      {'龍ヶ崎市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'取手市'}
                    >
                      {'取手市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北相馬郡利根町'}
                    >
                      {'北相馬郡（利根町）'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県北エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'ひたちなか市'}
                    >
                      {'ひたちなか市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'日立市'}
                    >
                      {'日立市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高萩市'}
                    >
                      {'高萩市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那珂郡東海村'}
                    >
                      {'那珂郡（東海村）'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那珂市'}
                    >
                      {'那珂市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'常陸太田市'}
                    >
                      {'常陸太田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'常陸大宮市'}
                    >
                      {'常陸大宮市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北茨城市'}
                    >
                      {'北茨城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'久慈郡大子町'}
                    >
                      {'久慈郡（大子町）'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '栃木県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県北エリア'}>
                {'県北エリア\n(日光市、大田原市、さくら市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県央エリア'}>
                {'県央エリア\n(宇都宮市、鹿沼市、真岡市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'県南エリア'}>
                {'県南エリア\n(小山市、下野市、足利市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県北エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'日光市'}
                    >
                      {'日光市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那須塩原市'}
                    >
                      {'那須塩原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那須郡'}
                    >
                      {'那須郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大田原市'}
                    >
                      {'大田原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'塩谷郡'}
                    >
                      {'塩谷郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那須烏山市'}
                    >
                      {'那須烏山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'さくら市'}
                    >
                      {'さくら市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'矢板市'}
                    >
                      {'矢板市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県央エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'宇都宮市'}
                    >
                      {'宇都宮市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鹿沼市'}
                    >
                      {'鹿沼市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'河内郡'}
                    >
                      {'河内郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'真岡市'}
                    >
                      {'真岡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'芳賀郡'}
                    >
                      {'芳賀郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'下都賀郡壬生町'}
                    >
                      {'下都賀郡（壬生町）'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '県南エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小山市'}
                    >
                      {'小山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'下野市'}
                    >
                      {'下野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'栃木市'}
                    >
                      {'栃木市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'下都賀郡野木町'}
                    >
                      {'下都賀郡（野木町）'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'佐野市'}
                    >
                      {'佐野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'足利市'}
                    >
                      {'足利市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '群馬県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'中毛・西毛エリア'}
              >
                {'中毛・西毛エリア\n(高崎市、前橋市、藤岡市、伊勢崎市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'東毛エリア'}>
                {'東毛エリア\n(館林市、太田市、邑楽郡など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'北毛エリア'}>
                {'北毛エリア\n(利根郡、吾妻郡、沼田市)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '中毛・西毛エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'前橋市'}
                    >
                      {'前橋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'伊勢崎市'}
                    >
                      {'伊勢崎市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'渋川市'}
                    >
                      {'渋川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北群馬郡'}
                    >
                      {'北群馬郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'佐波郡'}
                    >
                      {'佐波郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高崎市'}
                    >
                      {'高崎市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'藤岡市'}
                    >
                      {'藤岡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'富岡市'}
                    >
                      {'富岡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'安中市'}
                    >
                      {'安中市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'多野郡'}
                    >
                      {'多野郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'甘楽郡'}
                    >
                      {'甘楽郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '東毛エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'桐生市'}
                    >
                      {'桐生市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'太田市'}
                    >
                      {'太田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'館林市'}
                    >
                      {'館林市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'みどり市'}
                    >
                      {'みどり市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'邑楽郡'}
                    >
                      {'邑楽郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '北毛エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'利根郡'}
                    >
                      {'利根郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'吾妻郡'}
                    >
                      {'吾妻郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'沼田市'}
                    >
                      {'沼田市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '埼玉県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'中央地域'}>
                {'中央地域\n(さいたま市、戸田市、桶川市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'東部地域'}>
                {'東部地域\n(久喜市、加須市、越谷市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'西部地域'}>
                {'西部地域\n(所沢市、川越市、東松山市など)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'北部・秩父地域'}
              >
                {'北部・秩父地域\n(熊谷市・深谷市・本庄市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '中央地域' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'さいたま市'}
                    >
                      {'さいたま市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'川口市'}
                    >
                      {'川口市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'戸田市'}
                    >
                      {'戸田市'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'蕨市'}>
                      {'蕨市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'上尾市'}
                    >
                      {'上尾市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'桶川市'}
                    >
                      {'桶川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北本市'}
                    >
                      {'北本市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鴻巣市'}
                    >
                      {'鴻巣市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北足立郡'}
                    >
                      {'北足立郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '東部地域' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'久喜市'}
                    >
                      {'久喜市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'加須市'}
                    >
                      {'加須市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'幸手市'}
                    >
                      {'幸手市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'羽生市'}
                    >
                      {'羽生市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'行田市'}
                    >
                      {'行田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'蓮田市'}
                    >
                      {'蓮田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'白岡市'}
                    >
                      {'白岡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北葛飾郡'}
                    >
                      {'北葛飾郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'南埼玉郡'}
                    >
                      {'南埼玉郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'春日部市'}
                    >
                      {'春日部市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'越谷市'}
                    >
                      {'越谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'吉川市'}
                    >
                      {'吉川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三郷市'}
                    >
                      {'三郷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八潮市'}
                    >
                      {'八潮市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'草加市'}
                    >
                      {'草加市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '西部地域' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'川越市'}
                    >
                      {'川越市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'所沢市'}
                    >
                      {'所沢市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'飯能市'}
                    >
                      {'飯能市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東松山市'}
                    >
                      {'東松山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'狭山市'}
                    >
                      {'狭山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'入間市'}
                    >
                      {'入間市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'朝霞市'}
                    >
                      {'朝霞市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'志木市'}
                    >
                      {'志木市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'和光市'}
                    >
                      {'和光市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'新座市'}
                    >
                      {'新座市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'富士見市'}
                    >
                      {'富士見市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'ふじみ野市'}
                    >
                      {'ふじみ野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'坂戸市'}
                    >
                      {'坂戸市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鶴ヶ島市'}
                    >
                      {'鶴ヶ島市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'日高市'}
                    >
                      {'日高市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'入間郡'}
                    >
                      {'入間郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'比企郡'}
                    >
                      {'比企郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'秩父郡'}
                    >
                      {'秩父郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '北部・秩父地域'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'熊谷市'}
                    >
                      {'熊谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'深谷市'}
                    >
                      {'深谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'本庄市'}
                    >
                      {'本庄市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大里郡'}
                    >
                      {'大里郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'児玉郡'}
                    >
                      {'児玉郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'秩父市'}
                    >
                      {'秩父市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'秩父郡'}
                    >
                      {'秩父郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '千葉県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'湾岸エリア'}>
                {'湾岸エリア\n(市川市、船橋市、習志野市など)'}
              </MenuItem>
              <MenuItem value={'千葉エリア'}>千葉エリア</MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'東葛エリア'}>
                {'東葛エリア\n(柏市、野田市、松戸市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'外房エリア'}>
                {'外房エリア\n(山武市、茂原市、東金市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'内房エリア'}>
                {'内房エリア\n(木更津市、市原市、富津市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'印旛エリア'}>
                {'印旛エリア\n(八街市、四街道市、成田市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display:
                !(formState.values.spotArea === '千葉エリア') &&
                formState.values.spotArea
                  ? ''
                  : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '湾岸エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'市川市'}
                    >
                      {'市川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'船橋市'}
                    >
                      {'船橋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'習志野市'}
                    >
                      {'習志野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八千代市'}
                    >
                      {'八千代市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'浦安市'}
                    >
                      {'浦安市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '東葛エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'松戸市'}
                    >
                      {'松戸市'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'柏市'}>
                      {'柏市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'野田市'}
                    >
                      {'野田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'流山市'}
                    >
                      {'流山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'我孫子市'}
                    >
                      {'我孫子市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鎌ケ谷市'}
                    >
                      {'鎌ケ谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'印西市'}
                    >
                      {'印西市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'白井市'}
                    >
                      {'白井市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '外房エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'館山市'}
                    >
                      {'館山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'茂原市'}
                    >
                      {'茂原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東金市'}
                    >
                      {'東金市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'勝浦市'}
                    >
                      {'勝浦市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鴨川市'}
                    >
                      {'鴨川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'南房総市'}
                    >
                      {'南房総市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'山武市'}
                    >
                      {'山武市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'いすみ市'}
                    >
                      {'いすみ市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大網白里市'}
                    >
                      {'大網白里市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'銚子市'}
                    >
                      {'銚子市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'山武郡'}
                    >
                      {'山武郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'長生郡'}
                    >
                      {'長生郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'安房郡'}
                    >
                      {'安房郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'夷隅郡'}
                    >
                      {'夷隅郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '内房エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'木更津市'}
                    >
                      {'木更津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'市原市'}
                    >
                      {'市原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'君津市'}
                    >
                      {'君津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'富津市'}
                    >
                      {'富津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'袖ケ浦市'}
                    >
                      {'袖ケ浦市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '印旛エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'成田市'}
                    >
                      {'成田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'佐倉市'}
                    >
                      {'佐倉市'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'旭市'}>
                      {'旭市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'四街道市'}
                    >
                      {'四街道市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八街市'}
                    >
                      {'八街市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'富里市'}
                    >
                      {'富里市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'匝瑳市'}
                    >
                      {'匝瑳市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'香取市'}
                    >
                      {'香取市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'印旛郡'}
                    >
                      {'印旛郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'香取郡'}
                    >
                      {'香取郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '東京都' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'23区北部'}>
                {'23区北部\n(豊島区、板橋区、足立区など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'23区東部'}>
                {'23区東部\n(江東区、葛飾区、台東区など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'23区南部'}>
                {'23区南部\n(港区、品川区、目黒区、大田区)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'23区西部'}>
                {'23区西部\n(新宿区、杉並区、世田谷区など)'}
              </MenuItem>
              <MenuItem value={'23区以外'}>23区以外</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '23区北部' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'文京区'}
                    >
                      {'文京区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊島区'}
                    >
                      {'豊島区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'板橋区'}
                    >
                      {'板橋区'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'北区'}>
                      {'北区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'荒川区'}
                    >
                      {'荒川区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'足立区'}
                    >
                      {'足立区'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '23区東部' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'千代田区'}
                    >
                      {'千代田区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'中央区'}
                    >
                      {'中央区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'江東区'}
                    >
                      {'江東区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'台東区'}
                    >
                      {'台東区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'墨田区'}
                    >
                      {'墨田区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'葛飾区'}
                    >
                      {'葛飾区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'江戸川区'}
                    >
                      {'江戸川区'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '23区南部' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'品川区'}
                    >
                      {'品川区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'目黒区'}
                    >
                      {'目黒区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大田区'}
                    >
                      {'大田区'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'港区'}>
                      {'港区'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '23区西部' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'渋谷区'}
                    >
                      {'渋谷区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'新宿区'}
                    >
                      {'新宿区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'世田谷区'}
                    >
                      {'世田谷区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'中野区'}
                    >
                      {'中野区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'杉並区'}
                    >
                      {'杉並区'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'練馬区'}
                    >
                      {'練馬区'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '23区以外' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八王子市'}
                    >
                      {'八王子市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'立川市'}
                    >
                      {'立川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'武蔵野市'}
                    >
                      {'武蔵野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三鷹市'}
                    >
                      {'三鷹市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'青梅市'}
                    >
                      {'青梅市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'府中市'}
                    >
                      {'府中市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'昭島市'}
                    >
                      {'昭島市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'調布市'}
                    >
                      {'調布市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'町田市'}
                    >
                      {'町田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小金井市'}
                    >
                      {'小金井市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小平市'}
                    >
                      {'小平市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'日野市'}
                    >
                      {'日野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東村山市'}
                    >
                      {'東村山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'国分寺市'}
                    >
                      {'国分寺市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'国立市'}
                    >
                      {'国立市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'福生市'}
                    >
                      {'福生市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'狛江市'}
                    >
                      {'狛江市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東大和市'}
                    >
                      {'東大和市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'清瀬市'}
                    >
                      {'清瀬市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東久留米市'}
                    >
                      {'東久留米市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'武蔵村山市'}
                    >
                      {'武蔵村山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'多摩市'}
                    >
                      {'多摩市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'稲城市'}
                    >
                      {'稲城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'羽村市'}
                    >
                      {'羽村市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'あきる野市'}
                    >
                      {'あきる野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'西東京市'}
                    >
                      {'西東京市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'西多摩郡'}
                    >
                      {'西多摩郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'島嶼部'}
                    >
                      {'島嶼部'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display:
              formState.values.spotPrefecture === '神奈川県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'横浜・川崎エリア'}
              >
                {'横浜・川崎エリア\n(横浜市、川崎市)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'湘南エリア'}>
                {'湘南エリア\n(藤沢市、鎌倉市、茅ケ崎市)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'横須賀・三浦エリア'}
              >
                {'横須賀・三浦エリア\n(逗子市、横須賀市など)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'厚木・海老名エリア'}
              >
                {'厚木・海老名エリア\n(厚木市、海老名市、大和市など)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'小田原・箱根エリア'}
              >
                {'小田原・箱根エリア\n(小田原市、足柄下郡など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: formState.values.spotArea ? '' : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '横浜・川崎エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'横浜市'}
                    >
                      {'横浜市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'川崎市'}
                    >
                      {'川崎市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '湘南エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'藤沢市'}
                    >
                      {'藤沢市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鎌倉市'}
                    >
                      {'鎌倉市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'茅ヶ崎市'}
                    >
                      {'茅ヶ崎市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '横須賀・三浦エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'逗子市'}
                    >
                      {'逗子市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三浦郡'}
                    >
                      {'三浦郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'横須賀市'}
                    >
                      {'横須賀市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三浦市'}
                    >
                      {'三浦市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '厚木・海老名エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'厚木市'}
                    >
                      {'厚木市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大和市'}
                    >
                      {'大和市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'海老名市'}
                    >
                      {'海老名市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'座間市'}
                    >
                      {'座間市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'綾瀬市'}
                    >
                      {'綾瀬市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'相模原市'}
                    >
                      {'相模原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'愛甲郡'}
                    >
                      {'愛甲郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'平塚市'}
                    >
                      {'平塚市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'伊勢原市'}
                    >
                      {'伊勢原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'秦野市'}
                    >
                      {'秦野市'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'中郡'}>
                      {'中郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高座郡'}
                    >
                      {'高座郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '小田原・箱根エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小田原市'}
                    >
                      {'小田原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'南足柄市'}
                    >
                      {'南足柄市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'足柄下郡'}
                    >
                      {'足柄下郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'足柄上郡'}
                    >
                      {'足柄上郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '新潟県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            市区町村
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotCity}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotCity', event.target.value);
              }}
            >
              <MenuItem value={''} />
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'新潟市'}>
                {'新潟市'}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '愛知県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'名古屋市内'}>名古屋市内</MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'尾張地方'}>
                {'尾張地方\n(一宮市、稲沢市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'尾張東部'}>
                {'尾張東部\n(小牧市、春日井市、長久手市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'知多地方'}>
                {'知多地方\n(大府市、東海市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'西三河地方'}>
                {'西三河地方\n(岡崎市、豊田市、刈谷市など)'}
              </MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'東三河地方'}>
                {'東三河地方\n(豊橋市、豊川市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display:
                !(formState.values.spotArea === '名古屋市内') &&
                formState.values.spotArea
                  ? ''
                  : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '尾張地方' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'一宮市'}
                    >
                      {'一宮市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'江南市'}
                    >
                      {'江南市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'稲沢市'}
                    >
                      {'稲沢市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'岩倉市'}
                    >
                      {'岩倉市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'清須市'}
                    >
                      {'清須市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北名古屋市'}
                    >
                      {'北名古屋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'丹羽郡'}
                    >
                      {'丹羽郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'津島市'}
                    >
                      {'津島市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'愛西市'}
                    >
                      {'愛西市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'弥富市'}
                    >
                      {'弥富市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'あま市'}
                    >
                      {'あま市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'海部郡'}
                    >
                      {'海部郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '尾張東部' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'瀬戸市'}
                    >
                      {'瀬戸市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'春日井市'}
                    >
                      {'春日井市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'犬山市'}
                    >
                      {'犬山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小牧市'}
                    >
                      {'小牧市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'尾張旭市'}
                    >
                      {'尾張旭市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊明市'}
                    >
                      {'豊明市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'日進市'}
                    >
                      {'日進市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'長久手市'}
                    >
                      {'長久手市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'愛知郡'}
                    >
                      {'愛知郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'西春日井郡'}
                    >
                      {'西春日井郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '知多地方' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'半田市'}
                    >
                      {'半田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'常滑市'}
                    >
                      {'常滑市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東海市'}
                    >
                      {'東海市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大府市'}
                    >
                      {'大府市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'知多市'}
                    >
                      {'知多市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'知多郡'}
                    >
                      {'知多郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '西三河地方' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'岡崎市'}
                    >
                      {'岡崎市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'碧南市'}
                    >
                      {'碧南市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'刈谷市'}
                    >
                      {'刈谷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊田市'}
                    >
                      {'豊田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'安城市'}
                    >
                      {'安城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'西尾市'}
                    >
                      {'西尾市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'知立市'}
                    >
                      {'知立市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高浜市'}
                    >
                      {'高浜市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'みよし市'}
                    >
                      {'みよし市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'額田郡'}
                    >
                      {'額田郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '東三河地方' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊橋市'}
                    >
                      {'豊橋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊川市'}
                    >
                      {'豊川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'蒲郡市'}
                    >
                      {'蒲郡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'新城市'}
                    >
                      {'新城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'田原市'}
                    >
                      {'田原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'北設楽郡'}
                    >
                      {'北設楽郡'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '大阪府' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'大阪市内'}>大阪市内</MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'北部エリア'}>
                {'北部エリア\n(茨木市、高槻市、吹田市など)'}
              </MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'東部・南部エリア'}
              >
                {'東部・南部エリア\n(堺市・東大阪市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display:
                !(formState.values.spotArea === '大阪市内') &&
                formState.values.spotArea
                  ? ''
                  : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '北部エリア' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'吹田市'}
                    >
                      {'吹田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊中市'}
                    >
                      {'豊中市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'摂津市'}
                    >
                      {'摂津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高槻市'}
                    >
                      {'高槻市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'茨木市'}
                    >
                      {'茨木市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三島郡'}
                    >
                      {'三島郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'箕面市'}
                    >
                      {'箕面市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'池田市'}
                    >
                      {'池田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊能郡'}
                    >
                      {'豊能郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'枚方市'}
                    >
                      {'枚方市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'寝屋川市'}
                    >
                      {'寝屋川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'守口市'}
                    >
                      {'守口市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'門真市'}
                    >
                      {'門真市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '東部・南部エリア'
                      ? ''
                      : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八尾市'}
                    >
                      {'八尾市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大東市'}
                    >
                      {'大東市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'東大阪市'}
                    >
                      {'東大阪市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'四條畷市'}
                    >
                      {'四條畷市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'交野市'}
                    >
                      {'交野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'柏原市'}
                    >
                      {'柏原市'}
                    </MenuItem>
                    <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'堺市'}>
                      {'堺市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'和泉市'}
                    >
                      {'和泉市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'高石市'}
                    >
                      {'高石市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'泉大津市'}
                    >
                      {'泉大津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'泉北郡'}
                    >
                      {'泉北郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'岸和田市'}
                    >
                      {'岸和田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'貝塚市'}
                    >
                      {'貝塚市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'泉南郡'}
                    >
                      {'泉南郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'泉佐野市'}
                    >
                      {'泉佐野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'泉南市'}
                    >
                      {'泉南市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'阪南市'}
                    >
                      {'阪南市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'松原市'}
                    >
                      {'松原市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'羽曳野市'}
                    >
                      {'羽曳野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'藤井寺市'}
                    >
                      {'藤井寺市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'河内長野市'}
                    >
                      {'河内長野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大阪狭山市'}
                    >
                      {'大阪狭山市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'南河内郡'}
                    >
                      {'南河内郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'富田林市'}
                    >
                      {'富田林市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: formState.values.spotPrefecture === '福岡県' ? '' : 'none',
          }}
        >
          <InputLabel sx={{ ml: 4, mt: 2, fontSize: 14 }} id="city-label">
            エリア
          </InputLabel>
          <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
            <Select
              labelId="city-label"
              id="city-select"
              variant="standard"
              value={formState.values.spotArea}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(event, 'spotArea', event.target.value);
                handleChange(event, 'spotCity', '');
              }}
            >
              <MenuItem value={''} />
              <MenuItem value={'福岡市内'}>福岡市内</MenuItem>
              <MenuItem style={{ whiteSpace: 'pre-wrap' }} value={'福岡市近郊'}>
                {'福岡市近郊\n(久留米市、糟屋郡など)'}
              </MenuItem>
              <MenuItem value={'北九州市'}>北九州市内</MenuItem>
              <MenuItem
                style={{ whiteSpace: 'pre-wrap' }}
                value={'北九州市近郊'}
              >
                {'北九州市近郊\n(豊前市、行橋市など)'}
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display:
                !(
                  formState.values.spotArea === '福岡市内' ||
                  formState.values.spotArea === '北九州市'
                ) && formState.values.spotArea
                  ? ''
                  : 'none',
            }}
          >
            <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.narrowDown}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        handleChange(event, 'narrowDown', event.target.checked);
                        handleChange(event, 'spotCity', '');
                      }}
                    />
                  }
                  label="さらに絞り込む"
                />
              </FormGroup>
            </FormControl>
            <div
              style={{
                display: formState.values.narrowDown ? '' : 'none',
              }}
            >
              <InputLabel sx={{ ml: 4, fontSize: 14 }} id="city-label">
                市区町村
              </InputLabel>
              <div
                style={{
                  display:
                    formState.values.spotArea === '福岡市近郊' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'宗像市'}
                    >
                      {'宗像市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'福津市'}
                    >
                      {'福津市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'古賀市'}
                    >
                      {'古賀市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'糟屋郡'}
                    >
                      {'糟屋郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'糸島市'}
                    >
                      {'糸島市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'春日市'}
                    >
                      {'春日市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'太宰府市'}
                    >
                      {'太宰府市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'那珂川市'}
                    >
                      {'那珂川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大野城市'}
                    >
                      {'大野城市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'筑紫野市'}
                    >
                      {'筑紫野市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'朝倉郡'}
                    >
                      {'朝倉郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'朝倉市'}
                    >
                      {'朝倉市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'小郡市'}
                    >
                      {'小郡市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三井郡'}
                    >
                      {'三井郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'久留米市'}
                    >
                      {'久留米市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'うきは市'}
                    >
                      {'うきは市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大川市'}
                    >
                      {'大川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'三潴郡'}
                    >
                      {'三潴郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'筑後市'}
                    >
                      {'筑後市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八女郡'}
                    >
                      {'八女郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'八女市'}
                    >
                      {'八女市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'柳川市'}
                    >
                      {'柳川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'みやま市'}
                    >
                      {'みやま市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'大牟田市'}
                    >
                      {'大牟田市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'鞍手郡'}
                    >
                      {'鞍手郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'宮若市'}
                    >
                      {'宮若市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'直方市'}
                    >
                      {'直方市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'飯塚市'}
                    >
                      {'飯塚市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'田川郡'}
                    >
                      {'田川郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'田川市'}
                    >
                      {'田川市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'嘉穂郡'}
                    >
                      {'嘉穂郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'嘉麻市'}
                    >
                      {'嘉麻市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display:
                    formState.values.spotArea === '北九州市近郊' ? '' : 'none',
                }}
              >
                <FormControl sx={{ ml: 4, mt: 1, mr: 2, mb: 2, width: '80%' }}>
                  <Select
                    labelId="city-label"
                    id="city-select"
                    variant="standard"
                    value={formState.values.spotCity}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'spotCity', event.target.value);
                    }}
                  >
                    <MenuItem value={''} />
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'遠賀郡'}
                    >
                      {'遠賀郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'中間市'}
                    >
                      {'中間市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'行橋市'}
                    >
                      {'行橋市'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'京都郡'}
                    >
                      {'京都郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'築上郡'}
                    >
                      {'築上郡'}
                    </MenuItem>
                    <MenuItem
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={'豊前市'}
                    >
                      {'豊前市'}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <InputLabel sx={{ ml: 4, mt: 2, color: '#0f1941', fontSize: '1em' }}>
            <SettingsIcon
              style={{
                fontSize: '.9em',
              }}
            />
            {' その他設定'}
          </InputLabel>
        </div>
        <div>
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="broadcast_frequency"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              おすすめ求人の配信頻度
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.broadcastFrequencySpot}
              onChange={(event) => {
                console.log(event.target.value);
                handleChange(
                  event,
                  'broadcastFrequencySpot',
                  event.target.value,
                );
              }}
            >
              <FormControlLabel
                value="nextday"
                control={<Radio />}
                label="週３"
              />
              <FormControlLabel
                value="oneday"
                control={<Radio />}
                label="週１"
              />
              <FormControlLabel
                value="opt_out"
                control={<Radio />}
                label="配信を希望しない"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Box>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClickSave}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              登録する
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            プロフィールを登録しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SpotProfileForm;
